import { Group, Paper, Select, SelectItem, Text } from '@mantine/core'
import { forwardRef, useEffect, useState } from 'react'
import { SelectProps } from '@mantine/core/lib/Select/Select'
import Chairman from '@api/Chairman'

interface IInputChairman extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
    editMode?: boolean
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    value: string
    fullname: string
    email: string
    phone: string
    label: string
}

const InputChairman = ({
    editMode = true,
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: IInputChairman) => {
    const { data } = Chairman.useGetAllChairmans({ limit: 1000 })
    const [list, setlist] = useState<ItemProps[]>([])
    useEffect(() => {
        if (data) {
            setlist(
                data.lists.map((e) => {
                    return {
                        value: String(e.chairman_id),
                        fullname: e.chairman_fullname,
                        email: e.chairman_email,
                        phone: e.chairman_phone,
                        label: e.chairman_fullname,
                    }
                })
            )
        }
    }, [data])
    if (editMode === false) {
        const valueinput = list.filter((e) => e.value === String(value))
        if (valueinput.length) {
            return (
                <div>
                    <Text size='sm'>ประธานโครงการ</Text>
                    <Paper p='sm' withBorder mt='2px'>
                        <SelectItemComponent {...valueinput[0]} />
                    </Paper>
                </div>
            )
        }
    }
    return (
        <Select
            key={list.length}
            className={className}
            itemComponent={SelectItemComponent}
            label='ประธานโครงการ'
            value={String(value)}
            placeholder='ประธานโครงการ'
            searchable
            data={list}
            filter={(value, item: ItemProps) => item.fullname.toLowerCase().includes(value.toLowerCase().trim())}
            {...others}
        />
    )
}

export default InputChairman

const SelectItemComponent = forwardRef<HTMLDivElement, ItemProps>(
    ({ fullname, email, phone, label, ...others }: ItemProps, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <div>
                    <Text size='sm'>{fullname}</Text>
                    {phone && <Text size='xs'>เบอร์โทร : {phone}</Text>}
                    {email && <Text size='xs'>อีเมล : {email}</Text>}
                </div>
            </Group>
        </div>
    )
)

import FormCreateOrganization from '@components/form/chairman/organization/FormCreateOrganization'
import PageLayout from '@components/page/PageLayout'
const CreateOrganization = () => {
    return (
        <PageLayout pageTitle='สร้างสังกัดประธานโครงการ'>
            <FormCreateOrganization />
        </PageLayout>
    )
}

export default CreateOrganization

import Vendor from '@api/Vendor'
import CardVendorDetail from '@components/card/CardVendorDetail'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const ViewVendor = () => {
    const params = useParams()
    const vendor_id = params.vendor_id
    const { isLoading, isFetching, isError, data } = Vendor.useGetVendorByID({
        vendor_id: vendor_id!,
    })
    if (isLoading || isFetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404 label='กลับไปหน้าผู้ค้าทั้งหมด' url='/agencies' />
        )
    }
    return (
        <PageLayout pageTitle='ข้อมูลผู้ค้า'>
            <CardVendorDetail data={data}></CardVendorDetail>
        </PageLayout>
    )
}

export default ViewVendor

import { SimpleGrid } from '@mantine/core'
import React from 'react'
import CardDownload, { IDownloadCardProps } from '@components/card/CardDownload'

const CardDownloadList = ({
    downloads,
}: {
    downloads: IDownloadCardProps[]
}) => {
    return (
        <SimpleGrid
            cols={3}
            spacing='xl'
            breakpoints={[{ maxWidth: 'md', cols: 1 }]}
        >
            {downloads.map((download, index) => (
                <CardDownload key={index} {...download} />
            ))}
        </SimpleGrid>
    )
}

export default CardDownloadList

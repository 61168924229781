import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core'
import { Link, useNavigate } from 'react-router-dom'
import { IRequestCreateUserPosition } from '@interfaces/api/IUserPosition'
import UserPosition from '@api/UserPosition'

const FormCreateUserPosition = ({
    aftersuccess,
}: {
    aftersuccess?: () => any
}) => {
    const form = useForm<IRequestCreateUserPosition>({
        initialValues: {
            user_position_name: '',
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = UserPosition.useCreateUserPosition({
        form,
        aftersuccess: () => navigate('/users#user_position'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อตำแหน่ง'
                        placeholder='ชื่อตำแหน่ง'
                        {...form.getInputProps('user_position_name')}
                    />
                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/users#user_position'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            สร้างตำแหน่ง
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormCreateUserPosition

import api from '@config/api'
import { useQuery } from 'react-query'
import {
    ICoordinatorID,
    IRequestAllCoordinators,
    IRequestCreateCoordinator,
    IRequestUpdateCoordinator,
    IResponseAllCoordinators,
    IResponseGetCoordinatorByID,
} from '@interfaces/api/ICoordinator'
import { IFormMutationHandlerProps } from '@interfaces/default/IDefault'
import { useQueryConfig } from './useQueryConfig'
import { useFormMutationHandler } from '@utils/useFormMutationHandler'

interface IUseCreateCoordinator
    extends IFormMutationHandlerProps<IRequestCreateCoordinator> {}

interface IUseUpdateCoordinator
    extends ICoordinatorID,
        IFormMutationHandlerProps<IRequestUpdateCoordinator> {}

interface IUseDeleteCoordinator
    extends ICoordinatorID,
        IFormMutationHandlerProps<ICoordinatorID> {}

const Coordinator = {
    useGetAllCoordinators: ({
        limit = 100,
        orderby = 'coordinator_id',
        order = 'desc',
        page = 1,
        search = '',
        has_enabled,
        status = '',
        checkowner = 0,
    }: IRequestAllCoordinators) =>
        useQuery(
            'allcoordinators' + orderby,
            async () => {
                try {
                    const res = await api.get('/coordinators', {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                            has_enabled,
                            checkowner,
                            status,
                        },
                    })
                    return res.data.body as IResponseAllCoordinators
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useGetCoordinatorByID: ({ coordinator_id }: ICoordinatorID) =>
        useQuery(
            'getcoordinatorbyid' + coordinator_id,
            async () => {
                try {
                    const res = await api.get(`/coordinators/${coordinator_id}`)
                    return res.data.body as IResponseGetCoordinatorByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useCreateCoordinator: ({ form, aftersuccess }: IUseCreateCoordinator) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: ['/coordinators', 'post'],
            aftersuccess,
        })
    },
    useUpdateCoordinator: ({
        form,
        coordinator_id,
        aftersuccess,
    }: IUseUpdateCoordinator) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/coordinators/${coordinator_id}`, 'put'],
            aftersuccess,
        })
    },

    useDeleteCoordinator: ({
        coordinator_id,
        aftersuccess,
    }: IUseDeleteCoordinator) => {
        return useFormMutationHandler({
            pathWithMethod: [`/coordinators/${coordinator_id}`, 'delete'],
            aftersuccess,
        })
    },
}

export default Coordinator

import { useForm } from '@mantine/form'
import { Button, Flex, Group, LoadingOverlay, NumberInput, Paper, Text, TextInput } from '@mantine/core'
import Project from '@api/Project'
import LoadingPage from '@components/loading/LoadingPage'
import { IRequestEvaluationProjectVendor, IResponseAllProjectVendorsRowData } from '@interfaces/api/IProject'
import InputVendor from '../input/InputVendor'
import { useEffect, useState } from 'react'
import TableEditButton from '@components/button/TableEditButton'
import TextEvaluationBy from '@components/text/TextEvaluationBy'
import CancelButton from '@components/button/CancleButton'

const FormEvaluationProjectVendor = ({
    project_id,
    editMode,
    refetchProjectInfo,
}: {
    project_id: number | string
    editMode: boolean
    refetchProjectInfo: () => void
}) => {
    const [currentEditList, setCurrentEditList] = useState<number | null>(null)

    useEffect(() => {
        if (editMode === false) {
            setCurrentEditList(null)
        }
        // eslint-disable-next-line
    }, [editMode, currentEditList])

    let { isLoading, data, refetch } = Project.useGetAllProjectVendorByID({
        project_id: project_id,
    })

    if (isLoading) {
        return <LoadingPage />
    }

    const refetchProjectVendor = () => {
        refetchProjectInfo()
        setCurrentEditList(null)
        refetch()
    }

    return (
        <Flex gap='md' direction='column'>
            {data!.map((Vendor) => {
                return (
                    <EvaluationProjectVendor
                        key={Vendor.project_vendor_id}
                        editMode={editMode}
                        data={Vendor}
                        refetchProjectVendor={refetchProjectVendor}
                        currentEditList={currentEditList}
                        setCurrentEditList={setCurrentEditList}
                    />
                )
            })}
            {!data?.length && (
                <Paper p='lg'>
                    <Group position='center'>ไม่พบรายชื่อผู้ค้าของโครงการ</Group>
                </Paper>
            )}
        </Flex>
    )
}

export default FormEvaluationProjectVendor

interface IEvaluationProjectVendorProps {
    data: IResponseAllProjectVendorsRowData
    editMode: boolean
    refetchProjectVendor: () => void
    currentEditList: null | number
    setCurrentEditList: React.Dispatch<React.SetStateAction<number | null>>
}

const EvaluationProjectVendor = ({
    data,
    editMode,
    refetchProjectVendor,
    currentEditList,
    setCurrentEditList,
}: IEvaluationProjectVendorProps) => {
    const form = useForm<IRequestEvaluationProjectVendor>({
        initialValues: {
            vendor_evaluation_result: data?.vendor_evaluation_result ? Number(data.vendor_evaluation_result) : '',
            vendor_evaluation_factor_1: data?.vendor_evaluation_factor_1 ? Number(data.vendor_evaluation_factor_1) : '',
            vendor_evaluation_factor_2: data?.vendor_evaluation_factor_2 ? Number(data.vendor_evaluation_factor_2) : '',
            vendor_evaluation_factor_3: data?.vendor_evaluation_factor_3 ? Number(data.vendor_evaluation_factor_3) : '',
            vendor_evaluation_grade: data?.vendor_evaluation_grade ? data.vendor_evaluation_grade : 'C',
        },
    })

    const { isLoading, onSubmit } = Project.useEvaluationProjectVendor({
        project_id: data.project_id,
        project_vendor_id: data.project_vendor_id,
        form,
        aftersuccess: refetchProjectVendor,
    })

    const [editList, setEditList] = useState(false)

    // useEffect(() => {
    //     if (currentEditList !== data.project_vendor_id) {
    //         setEditList(false);
    //     }
    //     // eslint-disable-next-line
    // }, [currentEditList]);

    useEffect(() => {
        const factor1 = Number(form.getInputProps('vendor_evaluation_factor_1').value)
        const factor2 = Number(form.getInputProps('vendor_evaluation_factor_2').value)
        const factor3 = Number(form.getInputProps('vendor_evaluation_factor_3').value)

        const total = factor1 + factor2 + factor3
        form.setFieldValue('vendor_evaluation_result', total)
        form.setFieldValue('vendor_evaluation_grade', total >= 85 ? 'A' : total >= 70 ? 'B' : 'C')
        // eslint-disable-next-line
    }, [
        form.values.vendor_evaluation_factor_1,
        form.values.vendor_evaluation_factor_2,
        form.values.vendor_evaluation_factor_3,
    ])

    const editListOnClick = () => {
        setCurrentEditList(data.project_vendor_id)
        setEditList(true)
        form.setFieldValue(
            'vendor_evaluation_result',
            data?.vendor_evaluation_result ? Number(data.vendor_evaluation_result) : 0
        )
    }

    return (
        <Paper pos='relative' shadow='md' p='md'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit} style={{ pointerEvents: editMode ? 'all' : 'none' }}>
                <Flex gap='sm' direction='column'>
                    <InputVendor label='' editMode={false} value={String(data.vendor_id)}></InputVendor>
                    {editList && (
                        <>
                            <NumberInput
                                withAsterisk
                                label='ผลประเมินการประสานงานก่อนการซื้อขายหรือบริการ'
                                placeholder='0.00'
                                defaultValue={0.0}
                                precision={2}
                                min={0.0}
                                step={0.5}
                                max={30}
                                {...form.getInputProps('vendor_evaluation_factor_1')}
                            />
                            <NumberInput
                                withAsterisk
                                label='ผลประเมินการซื้อขายและการให้บริการระหว่างการซื้อขายหรือบริการ'
                                placeholder='0.00'
                                defaultValue={0.0}
                                precision={2}
                                min={0.0}
                                step={0.5}
                                max={50}
                                {...form.getInputProps('vendor_evaluation_factor_2')}
                            />
                            <NumberInput
                                withAsterisk
                                label='ผลประเมินการให้บริการหลังการขายหรือบริการ'
                                placeholder='0.00'
                                defaultValue={0.0}
                                precision={2}
                                min={0.0}
                                step={0.5}
                                max={20}
                                {...form.getInputProps('vendor_evaluation_factor_3')}
                            />
                            <NumberInput
                                withAsterisk
                                disabled
                                label='ผลรวมคะแนนประเมินผู้ค้า'
                                placeholder='0.00'
                                defaultValue={0.0}
                                precision={2}
                                min={0.0}
                                step={0.5}
                                max={5}
                                {...form.getInputProps('vendor_evaluation_result')}
                            />
                            <TextInput
                                withAsterisk
                                disabled
                                label='ผลการให้คะแนน'
                                {...form.getInputProps('vendor_evaluation_grade')}
                            />
                        </>
                    )}
                    {!editList && editMode && (
                        <Flex w='100%'>
                            {editMode && (
                                <TableEditButton right='10px' bottom='10px' pos='absolute' onClick={editListOnClick} />
                            )}
                            <TextEvaluationProjectVendor data={data} />
                        </Flex>
                    )}
                    {editList && (
                        <Flex ml='auto' gap='md' mt='md'>
                            <CancelButton onClick={() => setEditList(false)} />
                            <Button type='submit' w='fit-content' ml='auto'>
                                บันทึกการประเมิน
                            </Button>
                        </Flex>
                    )}
                    {editMode === false && (
                        <Flex justify='space-between' gap='md' w='100%'>
                            <TextEvaluationProjectVendor data={data} />
                            <TextEvaluationBy {...data} />
                        </Flex>
                    )}
                </Flex>
            </form>
        </Paper>
    )
}

const TextEvaluationProjectVendor = ({ data }: { data: IResponseAllProjectVendorsRowData }) => {
    return (
        <Text size='sm'>
            ผลประเมินวิทยากร :{' '}
            {data.vendor_evaluation_result ? (
                <>
                    <b>{data.vendor_evaluation_grade}</b>
                    {` (${data.vendor_evaluation_result!} คะแนน)`}
                </>
            ) : (
                'ยังไม่ประเมิน'
            )}
        </Text>
    )
}

import React from 'react'

import { Card, Flex, Title } from '@mantine/core'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'
import { IResponseGetProjectTypeByID } from '@interfaces/api/IProjectType'

const CardProjectTypeDetail = ({
    data,
}: {
    data: IResponseGetProjectTypeByID
}) => {
    return (
        <Card key={data.project_type_id} shadow='md' radius='md' padding='md'>
            <Flex gap='5px' direction='column'>
                <Title order={5}>{data.project_type_name}</Title>
                <TextCreatedBy {...data} />
                <TextUpdatedBy {...data} />
            </Flex>
        </Card>
    )
}

export default CardProjectTypeDetail

import CardDashboardStat from '@components/card/CardDashboardStat'
import CardDashboardStatGroup from '@components/card/CardDashboardStatGroup'
import AgenciesAmountColumnChart from '@components/chart/AgenciesAmountColumnChart'
import AgencyAmountByAgencyTypePieChart from '@components/chart/AgencyAmountByAgencyTypePieChart'
import AgencyRankingChart from '@components/chart/AgencyRankingChart'
import AgencyTableListChart from '@components/chart/AgencyTableListChart'
import ChairmanRankingChart from '@components/chart/ChairmanRankingChart'
import ChartFlexSection from '@components/chart/ChartFlexSection'
import CoordinatorRankingChart from '@components/chart/CoordinatorRankingChart'
import LecturerRankingChart from '@components/chart/LecturerRankingChart'
import ProjectBudgetByAgencyTypePieChart from '@components/chart/ProjectBudgetByAgencyTypePieChart'
import ProjectBudgetByProjectTypePieChart from '@components/chart/ProjectBudgetByProjectTypePieChart'
import ProjectBudgetByQuarterColumnChart from '@components/chart/ProjectBudgetByQuarterColumnChart'
import ProjectRankingChart from '@components/chart/ProjectRankingChart'
import ProjectRankingEvaluationChart from '@components/chart/ProjectRankingEvaluationChart'
import VendorRankingChart from '@components/chart/VendorRankingChart'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import TextChartTitle from '@components/text/TextChartTitle'
import TextDashboardSectionTitle from '@components/text/TextDashboardSectionTitle'
import api from '@config/api'
import {
    IRequestDataForDashboard,
    IRequestSingleDashboard,
    IResponseGetProjectDataForDashboard,
    IResponseGetProjectDataForDashboardRowData,
    IResponseGetProjectLecturerDataForDashboard,
    IResponseGetProjectLecturerDataForDashboardRowData,
    IResponseGetProjectVendorDataForDashboard,
    IResponseGetProjectVendorDataForDashboardRowData,
} from '@interfaces/api/IDashboard'
import { Button, Flex, Paper } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { IconExclamationCircle } from '@tabler/icons-react'
import exportDataDashboardToExcel from '@utils/exportDataDashboardToExcel'
import Percen from '@utils/Percen'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const DashboardSingle = () => {
    const { state }: { state: IRequestSingleDashboard } = useLocation()
    const navigate = useNavigate()

    if (!state) {
        navigate('/dashboard')
    }
    const [isProjectDataLoading, setIsProjectDataLoading] = useState(false)
    const [projectData, setProjectData] = useState<IResponseGetProjectDataForDashboardRowData[]>([])
    const [isProjectLecturerDataLoading, setIsProjectLecturerDataLoading] = useState(false)
    const [projectLecturerData, setProjectLecturerData] = useState<
        IResponseGetProjectLecturerDataForDashboardRowData[]
    >([])
    const [isProjectVendorDataLoading, setIsProjectVendorDataLoading] = useState(false)
    const [projectVendorData, setProjectVendorData] = useState<IResponseGetProjectVendorDataForDashboardRowData[]>([])
    const budget_year = Number(state?.start_budget_year) + 543

    useEffect(() => {
        if (state === null) {
            navigate('/dashboard')
        } else {
            if (!projectData.length) {
                LoadProjectData(state)
            }
            if (!projectLecturerData.length) {
                LoadProjectLecturerData(state)
            }
            if (!projectVendorData.length) {
                LoadProjectVendorData(state)
            }
        }
        // eslint-disable-next-line
    }, [])

    const LoadProjectData = async (state: IRequestSingleDashboard) => {
        setIsProjectDataLoading(true)
        try {
            let page = 1
            let maxPage = 1

            const project_progress_status_id = state?.project_progress_status_id
            const start_budget_year = state?.start_budget_year
            const end_budget_year = state?.end_budget_year

            let bulkData: IResponseGetProjectDataForDashboardRowData[] = []

            while (page <= maxPage) {
                const reqbody: IRequestDataForDashboard = {
                    page,
                    project_progress_status_id,
                    start_budget_year,
                    end_budget_year,
                }
                const res = await api.post('/dashboard/projects', reqbody)
                const data = res.data.body as IResponseGetProjectDataForDashboard
                maxPage = data.totalPages
                bulkData = [...bulkData, ...data.lists]
                page += 1

                await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait for 1 second
            }
            setProjectData(bulkData)
        } catch (err) {
            notifications.show({
                title: 'เกิดข้อผิดพลาด',
                message: 'กรุณากรอกแบบฟอร์มข้อมูลสำหรับสรุปผล',
                color: 'red',
                icon: <IconExclamationCircle size='1rem' />,
            })
            navigate('/dashboard')
        }
        setIsProjectDataLoading(false)
    }

    const LoadProjectLecturerData = async (state: IRequestSingleDashboard) => {
        setIsProjectLecturerDataLoading(true)
        try {
            let page = 1
            let maxPage = 1

            const project_progress_status_id = state?.project_progress_status_id
            const start_budget_year = state?.start_budget_year
            const end_budget_year = state?.end_budget_year

            let bulkData: IResponseGetProjectLecturerDataForDashboardRowData[] = []

            while (page <= maxPage) {
                const reqbody: IRequestDataForDashboard = {
                    page,
                    project_progress_status_id,
                    start_budget_year,
                    end_budget_year,
                }
                const res = await api.post('/dashboard/project_lecturers', reqbody)
                const data = res.data.body as IResponseGetProjectLecturerDataForDashboard
                maxPage = data.totalPages
                bulkData = [...bulkData, ...data.lists]
                page += 1

                await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait for 1 second
            }

            setProjectLecturerData(bulkData)
        } catch (err) {
            notifications.show({
                title: 'เกิดข้อผิดพลาด',
                message: 'กรุณากรอกแบบฟอร์มข้อมูลสำหรับสรุปผล',
                color: 'red',
                icon: <IconExclamationCircle size='1rem' />,
            })
            navigate('/dashboard')
        }
        setIsProjectLecturerDataLoading(false)
    }
    const LoadProjectVendorData = async (state: IRequestSingleDashboard) => {
        setIsProjectVendorDataLoading(true)
        try {
            let page = 1
            let maxPage = 1

            const project_progress_status_id = state?.project_progress_status_id
            const start_budget_year = state?.start_budget_year
            const end_budget_year = state?.end_budget_year

            let bulkData: IResponseGetProjectVendorDataForDashboardRowData[] = []

            while (page <= maxPage) {
                const reqbody: IRequestDataForDashboard = {
                    page,
                    project_progress_status_id,
                    start_budget_year,
                    end_budget_year,
                }
                const res = await api.post('/dashboard/project_vendors', reqbody)
                const data = res.data.body as IResponseGetProjectVendorDataForDashboard
                maxPage = data.totalPages
                bulkData = [...bulkData, ...data.lists]
                page += 1

                await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait for 1 second
            }

            setProjectVendorData(bulkData)
        } catch (err) {
            notifications.show({
                title: 'เกิดข้อผิดพลาด',
                message: 'กรุณากรอกแบบฟอร์มข้อมูลสำหรับสรุปผล',
                color: 'red',
                icon: <IconExclamationCircle size='1rem' />,
            })
            navigate('/dashboard')
        }
        setIsProjectVendorDataLoading(false)
    }

    const pageTitle = `สรุปผลการดำเนินโครงการประจำปีงบประมาณ ${budget_year}`

    const oldAgency = _.uniqBy(
        _.filter(projectData, (p) => p.agency_budget_year < Number(state?.start_budget_year)),
        'agency_name'
    ).length

    const newAgency = _.uniqBy(
        _.filter(projectData, {
            agency_budget_year: Number(state?.start_budget_year),
        }),
        'agency_name'
    ).length

    const allAgency = _.uniqBy(projectData, 'agency_name').length
    const allVendor = _.uniqBy(projectVendorData, 'vendor_name').length
    const newVendor = _.uniqBy(
        _.filter(projectVendorData, {
            vendor_budget_year: Number(state?.start_budget_year),
        }),
        'vendor_name'
    ).length
    const oldVendor = _.uniqBy(
        _.filter(projectVendorData, (p) => p.vendor_budget_year !== Number(state?.start_budget_year)),
        'vendor_name'
    ).length

    const ProjectAgencyData = _(projectData)
        .groupBy('agency_name')
        .map((values, key) => ({
            agency_name: key,
            agency_type_name: values[0].agency_type_name,
            agency_budget_year: values[0].agency_budget_year,
            total_project_amount: _.uniqBy(values, 'project_id').length,
            total_project_budget: _.sumBy(
                values.map((e) => {
                    return { ...e, project_budget: Number(e.project_budget) }
                }),
                'project_budget'
            ),
        }))
        .value()

    const handleExportClick = () => {
        exportDataDashboardToExcel(
            {
                projectData: projectData,
                projectLecturerData: projectLecturerData,
                projectVendorData: projectVendorData,
            },
            'reportdata.xlsx'
        )
    }
    if (isProjectDataLoading || isProjectLecturerDataLoading || isProjectVendorDataLoading) {
        return <LoadingPage />
    }

    if (!projectData.length && !projectLecturerData.length && !projectVendorData.length) {
        return <PageLayout pageTitle={pageTitle}>ไม่พบข้อมูล</PageLayout>
    }

    return (
        <PageLayout pageTitle={pageTitle}>
            <Paper mb='lg'>
                <Button mr='lg' onClick={() => window.print()}>
                    ดาวน์โหลด PDF
                </Button>
                <Button mr='lg' onClick={handleExportClick}>
                    ดาวน์โหลด Excel
                </Button>
            </Paper>
            <Flex direction='column'>
                {projectData.length > 0 && (
                    <>
                        <div>
                            <TextDashboardSectionTitle>สรุปผลข้อมูลโครงการ</TextDashboardSectionTitle>
                            {state?.opt1_project_stats && (
                                <ChartFlexSection>
                                    <TextChartTitle>
                                        สถิติยอดจำนวนโครงการ และมูลค่าของโครงการทั้งหมด ประจำปีงบประมาณ {budget_year}
                                    </TextChartTitle>
                                    <CardDashboardStatGroup>
                                        <CardDashboardStat w={150} label='ปีงบประมาณ' value={budget_year} />
                                        <CardDashboardStat
                                            w={200}
                                            unit='โครงการ'
                                            label='จำนวนโครงการ'
                                            value={projectData.length}
                                        />
                                        <CardDashboardStat
                                            w={350}
                                            label='มูลค่าโครงการ'
                                            unit='บาท'
                                            isMoney
                                            value={_.sumBy(projectData, (e) => Number(e.project_budget))}
                                        />
                                    </CardDashboardStatGroup>
                                </ChartFlexSection>
                            )}

                            {state?.opt2_project_quarter && (
                                <ProjectBudgetByQuarterColumnChart
                                    title={`ยอดมูลค่าโครงการทั้งหมดแบ่งสัดส่วนตามไตรมาส ประจำปีงบประมาณ ${budget_year}`}
                                    data={projectData}
                                    // keySort={'quarter'}
                                    // keyValue={'project_budget'}
                                    // keyType={'quarter'}
                                    // typeName='ไตรมาสปีงบประมาณ (Quarter)'
                                    // valueName='มูลค่างบประมาณโครงการ'
                                />
                            )}
                            {state?.opt3_6_budget_by_project_type && (
                                <ProjectBudgetByProjectTypePieChart
                                    title={`ยอดมูลค่าโครงการทั้งหมด แบ่งสัดส่วนตามประเภทกิจกรรมบริการวิชาการ ประจำปีงบประมาณ ${budget_year}`}
                                    typeName='ประเภทกิจกรรมบริการวิชาการ'
                                    valueName='งบประมาณ'
                                    data={projectData}
                                />
                            )}
                            {state?.opt3_project_budget_ranking && (
                                <ProjectRankingChart
                                    title={`จัดอันดับโครงการสำคัญที่มียอดมูลค่าโครงการสูงสุด 5 อันดับแรก ประจำปีงบประมาณ ${budget_year}`}
                                    data={projectData}
                                />
                            )}
                            {state?.opt3_5_project_rating_ranking && (
                                <ProjectRankingEvaluationChart
                                    title={`จัดอันดับโครงการตามคะแนนความพึงพอใจ ประจำปีงบประมาณ 2566`}
                                    data={projectData}
                                />
                            )}
                        </div>
                        <div className='pagebreak'>
                            <TextDashboardSectionTitle>สรุปผลข้อมูลหน่วยงาน/ลูกค้า</TextDashboardSectionTitle>
                            {state?.opt4_agency_stats && (
                                <ChartFlexSection>
                                    <TextChartTitle>
                                        สถิติยอดจำนวนลูกค้าทั้งหมด และจำนวนลูกค้ารายเก่าและลูกค้ารายใหม่ ประจำปีงบประมาณ{' '}
                                        {budget_year}
                                    </TextChartTitle>
                                    <CardDashboardStatGroup>
                                        <CardDashboardStat w={150} label='ปีงบประมาณ' value={budget_year} />

                                        <CardDashboardStat
                                            w={200}
                                            unit='หน่วยงาน'
                                            label='จำนวนลูกค้าทั้งหมด'
                                            value={allAgency}
                                        />
                                        <CardDashboardStat
                                            w={200}
                                            unit='หน่วยงาน'
                                            label='จำนวนลูกค้ารายเก่า'
                                            value={`${oldAgency} (${Percen(oldAgency, allAgency)}%)`}
                                        />
                                        <CardDashboardStat
                                            w={200}
                                            unit='หน่วยงาน'
                                            label='จำนวนลูกค้ารายใหม่'
                                            value={`${newAgency} (${Percen(newAgency, allAgency)}%)`}
                                        />
                                    </CardDashboardStatGroup>
                                </ChartFlexSection>
                            )}
                            {state?.opt5_agency_new_and_old && (
                                <AgenciesAmountColumnChart
                                    title={`ยอดจำนวนลูกค้ารายใหม่และรายเก่า ประจำปีงบประมาณ ${budget_year}`}
                                    data={projectData}
                                    keySort={'quarter'}
                                    keyValue={'project_budget'}
                                    keyType={'quarter'}
                                    typeName='ปีงบประมาณ'
                                    valueName='จำนวนหน่วยงาน'
                                />
                            )}
                        </div>
                        {state?.opt6_agency_list && (
                            <>
                                <div className='pagebreak'>
                                    <AgencyTableListChart
                                        data={ProjectAgencyData.sort(
                                            (a, b) => b.agency_budget_year - a.agency_budget_year
                                        ).filter((p) => p.agency_budget_year < Number(state?.start_budget_year))}
                                        title={`รายการข้อมูลลูกค้ารายเก่า ประจำปีงบประมาณ ${budget_year} (${oldAgency} หน่วยงาน) `}
                                    />
                                </div>
                                <div className='pagebreak'>
                                    <AgencyTableListChart
                                        data={ProjectAgencyData.sort(
                                            (a, b) => b.total_project_budget - a.total_project_budget
                                        ).filter((p) => p.agency_budget_year === Number(state?.start_budget_year))}
                                        title={`รายการข้อมูลลูกค้ารายใหม่ ประจำปีงบประมาณ ${budget_year} (${newAgency} หน่วยงาน)`}
                                    />
                                </div>
                            </>
                        )}
                        <div className='pagebreak'>
                            {state?.opt7_agency_by_type && (
                                <AgencyAmountByAgencyTypePieChart
                                    title={`ยอดจำนวนลูกค้าทั้งหมด แบ่งสัดส่วนตามประเภทหน่วยงาน ประจำปีงบประมาณ ${budget_year}`}
                                    typeName='ประเภทหน่วยงาน'
                                    valueName='หน่วยงาน'
                                    data={ProjectAgencyData}
                                />
                            )}
                            {state?.opt8_budget_by_agency_type && (
                                <ProjectBudgetByAgencyTypePieChart
                                    title={`ยอดมูลค่าโครงการทั้งหมด แบ่งสัดส่วนตามประเภทหน่วยงาน ประจำปีงบประมาณ ${budget_year}`}
                                    typeName='ประเภทหน่วยงาน'
                                    valueName='งบประมาณ'
                                    data={projectData}
                                />
                            )}
                        </div>
                        {state?.opt9_agency_budget_ranking && (
                            <div className='pagebreak'>
                                <AgencyRankingChart data={projectData} />
                            </div>
                        )}
                        <div className='pagebreak'>
                            <TextDashboardSectionTitle>สรุปผลข้อมูลผู้ประสานงานโครงการ</TextDashboardSectionTitle>
                            {state?.opt10_coodinator_budget_rank && <CoordinatorRankingChart data={projectData} />}
                        </div>

                        <div className='pagebreak'>
                            <TextDashboardSectionTitle>สรุปผลข้อมูลประธานโครงการ</TextDashboardSectionTitle>
                            {state?.opt11_chairman_budget_rank && <ChairmanRankingChart data={projectData} />}
                        </div>
                    </>
                )}
                {projectLecturerData.length > 0 && (
                    <>
                        <div className='pagebreak'>
                            <TextDashboardSectionTitle>สรุปผลข้อมูลวิทยากร</TextDashboardSectionTitle>
                            {state?.opt12_lecturer_rating_rank && <LecturerRankingChart data={projectLecturerData} />}
                        </div>
                    </>
                )}
                {projectVendorData.length > 0 && (
                    <>
                        <div className='pagebreak'>
                            <TextDashboardSectionTitle>สรุปผลข้อมูลผู้ค้า</TextDashboardSectionTitle>
                            {state?.opt13_vendor_stats && (
                                <ChartFlexSection>
                                    <TextChartTitle>
                                        สถิติยอดจำนวนผู้ค้าทั้งหมด และจำนวนผู้ค้ารายเก่าและผู้ค้ารายใหม่ ประจำปีงบประมาณ{' '}
                                        {budget_year}
                                    </TextChartTitle>
                                    <CardDashboardStatGroup>
                                        <CardDashboardStat w={150} label='ปีงบประมาณ' value={budget_year} />

                                        <CardDashboardStat
                                            w={200}
                                            unit='หน่วยงาน'
                                            label='จำนวนผู้ค้าทั้งหมด'
                                            value={allVendor}
                                        />
                                        <CardDashboardStat
                                            w={200}
                                            unit='หน่วยงาน'
                                            label='จำนวนผู้ค้ารายเก่า'
                                            value={`${oldVendor} (${Percen(oldVendor, allVendor)}%)`}
                                        />
                                        <CardDashboardStat
                                            w={200}
                                            unit='หน่วยงาน'
                                            label='จำนวนผู้ค้ารายใหม่'
                                            value={`${newVendor} (${Percen(newVendor, allVendor)}%)`}
                                        />
                                    </CardDashboardStatGroup>
                                </ChartFlexSection>
                            )}
                            {state?.opt14_vendor_rating_rank && <VendorRankingChart data={projectVendorData} />}
                        </div>
                    </>
                )}
            </Flex>
        </PageLayout>
    )
}

export default DashboardSingle

import { Select, SelectItem } from '@mantine/core'
import { useEffect, useState } from 'react'
import { SelectProps } from '@mantine/core/lib/Select/Select'
import ProjectType from '@api/ProjectType'

interface IInputProjectType extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
    withAll?: boolean
}

const InputProjectType = ({
    withAll = false,
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: IInputProjectType) => {
    const { data } = ProjectType.useGetAll({ limit: 1000 })
    const [list, setlist] = useState<{ value: string; label: string }[]>([])
    useEffect(() => {
        if (data) {
            const selectlists = data.lists.map((e) => {
                return {
                    value: String(e.project_type_id),
                    label: e.project_type_name,
                }
            })
            if (withAll) {
                selectlists.unshift({
                    value: '',
                    label: 'ทั้งหมด',
                })
            }
            setlist(selectlists)
        }
        // eslint-disable-next-line
    }, [data])

    return (
        <Select
            key={list.length}
            className={className}
            label='ประเภทกิจกรรมบริการวิชาการ'
            value={String(value)}
            placeholder='ประเภทกิจกรรมบริการวิชาการ'
            searchable
            data={list}
            {...others}
        />
    )
}

export default InputProjectType

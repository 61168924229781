import PageLayout from '@components/page/PageLayout'
import TableAllExpertise from '@components/table/database/TableAllExpertise'

const AllExpertise = () => {
    return (
        <PageLayout pageTitle='ความเชี่ยวชาญ'>
            <TableAllExpertise />
        </PageLayout>
    )
}

export default AllExpertise

import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core'
import { Link, useNavigate } from 'react-router-dom'
import InputStatus from '@components/form/input/InputStatus'
import {
    IRequestUpdateProjectTypeByID,
    IResponseGetProjectTypeByID,
} from '@interfaces/api/IProjectType'
import ProjectType from '@api/ProjectType'

const FormEditProjectType = ({
    data,
    aftersuccess,
}: {
    data: IResponseGetProjectTypeByID
    aftersuccess?: () => any
}) => {
    const form = useForm<IRequestUpdateProjectTypeByID>({
        initialValues: {
            project_type_name: data.project_type_name,
            status: data.status,
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = ProjectType.useUpdateByID({
        project_type_id: data.project_type_id,
        form,
        aftersuccess: () => navigate('/projects/types'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ประเภทกิจกรรมบริการวิชาการ'
                        placeholder='ประเภทกิจกรรมบริการวิชาการ'
                        {...form.getInputProps('project_type_name')}
                    />
                    <InputStatus {...form.getInputProps('status')} />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/projects/types'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            บันทึกการแก้ไขประเภทกิจกรรมบริการวิชาการ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormEditProjectType

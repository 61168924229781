import { useForm } from '@mantine/form';
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import { IRequestCreateVendorType } from '@interfaces/api/IVendor';
import Vendor from '@api/Vendor';

const FormCreateVendorType = ({
    aftersuccess,
}: {
    aftersuccess?: () => any;
}) => {
    const form = useForm<IRequestCreateVendorType>({
        initialValues: {
            vendor_type_name: '',
        },
    });
    const navigate = useNavigate();

    const { isLoading, onSubmit } = Vendor.useCreateVendorType({
        form,
        aftersuccess: () => navigate('/vendors/types'),
    });

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ประเภทผู้ค้า'
                        placeholder='ประเภทผู้ค้า'
                        {...form.getInputProps('vendor_type_name')}
                    />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/vendors/types'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            สร้างประเภทผู้ค้า
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    );
};

export default FormCreateVendorType;

import PageLayout from '@components/page/PageLayout'
import { Tabs } from '@mantine/core'
import { IconCategory2, IconUsersGroup } from '@tabler/icons-react'
import { useHash } from '@mantine/hooks'
import { useEffect, useState } from 'react'
import TableAllUser from '@components/table/database/TableAllUser'
import TableAllUserPosition from '@components/table/database/TableAllUserPosition'

const AllUser = () => {
    const [hash, setHash] = useHash()
    const [tab, setTab] = useState(hash.slice(0))
    useEffect(() => {
        if (hash === '#user_position') {
            setTab('user_position')
        } else {
            setTab('user')
        }
    }, [hash])

    const onTabChange = (value: string) => {
        setHash(value)
    }

    return (
        <PageLayout pageTitle='ผู้ใช้งานระบบ'>
            <Tabs value={tab} onTabChange={onTabChange}>
                <Tabs.List>
                    <Tabs.Tab
                        value='user'
                        icon={<IconUsersGroup size='0.8rem' />}
                    >
                        ผู้ใช้งานระบบ
                    </Tabs.Tab>
                    <Tabs.Tab
                        value='user_position'
                        icon={<IconCategory2 size='0.8rem' />}
                    >
                        ตำแหน่ง
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value='user' pt='xs'>
                    <TableAllUser />
                </Tabs.Panel>

                <Tabs.Panel value='user_position' pt='xs'>
                    <TableAllUserPosition />
                </Tabs.Panel>
            </Tabs>
        </PageLayout>
    )
}

export default AllUser

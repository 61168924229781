import { Checkbox, CheckboxGroupProps, Flex, SelectItem } from '@mantine/core'
import { ReactNode, useEffect, useState } from 'react'
import { IResponseGetAllExpertisesRowData } from '@interfaces/api/IExpertise'
import Expertise from '@api/Expertise'
interface IInputExpertises
    extends Omit<
        CheckboxGroupProps & React.RefAttributes<HTMLInputElement>,
        'children'
    > {
    editMode?: boolean
    children?: ReactNode
}

interface ItemProps extends SelectItem, IResponseGetAllExpertisesRowData {}

const InputExpertises = ({
    editMode = true,
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: IInputExpertises) => {
    const { data } = Expertise.useGetAllExpertise({})
    const [list, setlist] = useState<ItemProps[]>([])
    useEffect(() => {
        if (data) {
            setlist(
                data.lists.map((e) => {
                    return {
                        ...e,
                        value: String(e.expertise_id),
                        label: e.expertise_name,
                    }
                })
            )
        }
    }, [data])

    return (
        <Checkbox.Group
            value={value}
            withAsterisk
            label='ความเชี่ยวชาญ'
            {...others}
        >
            <Flex direction='column' gap='xs' mt='xs' mb='xs'>
                {list.map((value) => (
                    <Checkbox
                        key={value.expertise_id}
                        value={String(value.expertise_id)}
                        label={value.expertise_name}
                    />
                ))}
            </Flex>
        </Checkbox.Group>
    )
}

export default InputExpertises

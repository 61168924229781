import api from '@config/api'
import { useQuery } from 'react-query'

import {
    IRequestCreateVendor,
    IRequestCreateVendorType,
    IRequestGetAllVendorTypes,
    IRequestUpdateVendor,
    IRequestUpdateVendorType,
    IResponseGetAllVendors,
    IResponseGetAllVendorTypes,
    IResponseGetVendorByID,
    IResponseGetVendorTypeByID,
    IVendorID,
    IVendorTypeID,
} from '@interfaces/api/IVendor'
import { useFormMutationHandler } from '@utils/useFormMutationHandler'
import { IFormMutationHandlerProps } from '@interfaces/default/IDefault'
import { useQueryConfig } from './useQueryConfig'
import { IRequestGetAllVendors } from '@interfaces/api/IVendor'
interface IUseCreateVendor
    extends IFormMutationHandlerProps<IRequestCreateVendor> {}

interface IUseUpdateVendor
    extends IVendorID,
        IFormMutationHandlerProps<IRequestUpdateVendor> {}

interface IUseDeleteVendor
    extends IVendorID,
        IFormMutationHandlerProps<IVendorID> {}

interface IUseCreateVendorType
    extends IFormMutationHandlerProps<IRequestCreateVendorType> {}

interface IUseUpdateVendorType
    extends IVendorTypeID,
        IFormMutationHandlerProps<IRequestUpdateVendorType> {}

interface IUseDeleteVendorType
    extends IVendorTypeID,
        IFormMutationHandlerProps<IVendorTypeID> {}

const Vendor = {
    useGetAllVendors: ({
        limit = 100,
        orderby = 'vendor_id',
        order = 'desc',
        page = 1,
        search = '',
        checkowner = 0,
        budget_year = '',
        status = '',
    }: IRequestGetAllVendors) =>
        useQuery(
            'allvendors',
            async () => {
                try {
                    const res = await api.get('/vendors', {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                            checkowner,
                            budget_year,
                            status,
                        },
                    })
                    return res.data.body as IResponseGetAllVendors
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useGetVendorByID: ({ vendor_id }: IVendorID) =>
        useQuery(
            'getVendorByID' + vendor_id,
            async () => {
                try {
                    const res = await api.get(`/vendors/${vendor_id}`)
                    return res.data.body as IResponseGetVendorByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useCreateVendor: ({ form, aftersuccess }: IUseCreateVendor) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: ['/vendors', 'post'],
            aftersuccess,
        })
    },
    useUpdateVendor: ({ form, vendor_id, aftersuccess }: IUseUpdateVendor) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/vendors/${vendor_id}`, 'put'],
            aftersuccess,
        })
    },

    useDeleteVendor: ({ vendor_id, aftersuccess }: IUseDeleteVendor) => {
        return useFormMutationHandler({
            pathWithMethod: [`/vendors/${vendor_id}`, 'delete'],
            aftersuccess,
        })
    },

    useGetAllVendorTypes: ({
        limit = 100,
        orderby = 'vendor_type_id',
        order = 'desc',
        page = 1,
        search = '',
        status = '',
        checkowner = 0,
    }: IRequestGetAllVendorTypes) =>
        useQuery(
            'getAllVendorTypes',
            async () => {
                try {
                    const res = await api.get('/vendors/types', {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                            status,
                            checkowner,
                        },
                    })
                    return res.data.body as IResponseGetAllVendorTypes
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),
    useGetVendorTypeByID: ({ vendor_type_id }: IVendorTypeID) =>
        useQuery(
            'getVendorTypeByID',
            async () => {
                try {
                    const res = await api.get(
                        `/vendors/types/${vendor_type_id}`
                    )
                    return res.data.body as IResponseGetVendorTypeByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),
    useCreateVendorType: ({ form, aftersuccess }: IUseCreateVendorType) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: ['/vendors/types', 'post'],
            aftersuccess,
        })
    },
    useUpdateVendorType: ({
        form,
        vendor_type_id,
        aftersuccess,
    }: IUseUpdateVendorType) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/vendors/types/${vendor_type_id}`, 'put'],
            aftersuccess,
        })
    },

    useDeleteVendorType: ({
        vendor_type_id,
        aftersuccess,
    }: IUseDeleteVendorType) => {
        return useFormMutationHandler({
            pathWithMethod: [`/vendors/types/${vendor_type_id}`, 'delete'],
            aftersuccess,
        })
    },
}

export default Vendor

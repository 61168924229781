import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core'

import { Link, useNavigate } from 'react-router-dom'
import {
    IRequestUpdateBudgetYear,
    IResponseGetBudgetYearByID,
} from '@interfaces/api/IBudgetYear'
import BudgetYear from '@api/BudgetYear'

const FormEditBudgetYear = ({
    aftersuccess,
    data,
}: {
    aftersuccess?: () => any
    data: IResponseGetBudgetYearByID
}) => {
    const form = useForm<IRequestUpdateBudgetYear>({
        initialValues: {
            budget_year_name: data.budget_year_name,
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = BudgetYear.useUpdateBudgetYear({
        budget_year: data.budget_year,
        form,
        aftersuccess: () => navigate('/budgetyears'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อปีงบประมาณ'
                        placeholder='ชื่อปีงบประมาณ'
                        {...form.getInputProps('budget_year_name')}
                    />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/budgetyears'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            บันทึกการแก้ไขปีงบประมาณ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormEditBudgetYear

import { Select, SelectItem } from '@mantine/core'
import { useEffect, useState } from 'react'
import { SelectProps } from '@mantine/core/lib/Select/Select'
import ChairmanOrganization from '@api/ChairmanOrganization'
import { IResponseGetAllChairmanOrganizationsRowData } from '@interfaces/api/IChairmanOrganization'

interface IInputChairmanOrganization
    extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
    withAll?: boolean
}

const InputChairmanOrganization = ({
    withAll = false,
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: IInputChairmanOrganization) => {
    const orderby: keyof IResponseGetAllChairmanOrganizationsRowData =
        'chairman_organization_name'
    const { data } = ChairmanOrganization.useGetAllChairmanOrganizations({
        orderby: orderby,
        order: 'asc',
    })
    const [list, setlist] = useState<{ value: string; label: string }[]>([])
    useEffect(() => {
        if (data) {
            const selectlists = data.lists.map((e) => {
                return {
                    value: String(e.chairman_organization_id),
                    label: e.chairman_organization_name,
                }
            })
            if (withAll) {
                selectlists.unshift({
                    value: '',
                    label: 'ทั้งหมด',
                })
            }
            setlist(selectlists)
        }
        // eslint-disable-next-line
    }, [data])

    return (
        <Select
            key={list.length}
            className={className}
            label='สังกัดประธานโครงการ'
            value={String(value)}
            placeholder='สังกัดประธานโครงการ'
            searchable
            data={list}
            {...others}
        />
    )
}

export default InputChairmanOrganization

import { Flex, Select, SelectItem } from '@mantine/core'
import { SelectProps } from '@mantine/core/lib/Select/Select'

interface MyComponentProps
    extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
}
const InputCheckOwner = ({
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: MyComponentProps) => {
    return (
        <Flex align='center' gap='md'>
            <Select
                label='สร้างโดย'
                className={className}
                placeholder='สร้างโดย'
                defaultValue={'0'}
                value={String(value)}
                data={[
                    { value: '0', label: 'ทั้งหมด' },
                    { value: '1', label: 'ฉัน' },
                ]}
                {...others}
            />
        </Flex>
    )
}

export default InputCheckOwner

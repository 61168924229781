import { Card, Flex, Title } from '@mantine/core'
import TextParameter from '../text/TextParameter'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'
import { IResponseGetBudgetYearByID } from '../../interfaces/api/IBudgetYear'

const CardBudgetYearDetail = ({
    data,
}: {
    data: IResponseGetBudgetYearByID
}) => {
    const textparameters = [
        {
            title: 'รหัสปีงบประมาณ',
            info: String(data.budget_year),
        },
    ]
    return (
        <Card key={data.budget_year} shadow='md' radius='md' padding='md'>
            <Flex gap='5px' direction='column'>
                <Title order={5}>{data.budget_year_name}</Title>
                {textparameters.map((value) => (
                    <TextParameter
                        key={value.title}
                        title={value.title}
                        info={value.info!}
                    />
                ))}
                <TextCreatedBy {...data} />
                <TextUpdatedBy {...data} />
            </Flex>
        </Card>
    )
}

export default CardBudgetYearDetail

import { ButtonProps, createPolymorphicComponent } from '@mantine/core'
import { IconEye } from '@tabler/icons-react'
import React from 'react'
import TableActionButton from './TableActionButton'
interface MyComponentProps
    extends ButtonProps,
        React.RefAttributes<HTMLButtonElement> {}
const _TableViewButton = ({
    classNames,
    styles,
    unstyled,
    className,
    ...others
}: MyComponentProps) => {
    return (
        <TableActionButton
            type='button'
            Icon={IconEye}
            label={'ดู'}
            classNames={classNames}
            styles={styles}
            unstyled={unstyled}
            className={className}
            {...others}
        />
    )
}

const TableViewButton = createPolymorphicComponent<'button', MyComponentProps>(
    _TableViewButton
)
export default TableViewButton

import Joi from 'joi'
export const maxdate = new Date('01-01-2155')

// project_info

export const project_id = Joi.number().required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'any.required': `กรุณากรอกไอดีโครงการ`,
    'any.only': `กรุณากรอกไอดีโครงการให้ถูกต้อง`,
})

export const project_code = Joi.string()
    .pattern(/^\d{2}-\d{3,4}$/)
    .required()
    .messages({
        'string.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'any.required': `กรุณากรอกรหัสโครงการ`,
        'any.only': `กรุณากรอกรหัสโครงการให้ถูกต้อง เช่น 66-001`,
        'string.pattern.base': `กรุณากรอกรหัสโครงการให้ถูกต้อง เช่น 66-001`,
    })

export const project_type_id = Joi.number().required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'any.required': `กรุณากรอกข้อมูล`,
})

export const project_expertises = Joi.array()
    .items(Joi.number())
    .required()
    .min(1)
    .max(10)
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'array.max': 'ระบุเลือกตัวเลือกได้ไม่เกิน 10 ตัวเลือก',
        'any.required': `กรุณากรอกข้อมูล`,
        'array.min': `กรุณากรอกข้อมูล`,
    })

export const project_no = Joi.number().min(1).required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'number.min': 'กรุณากรอกลำดับโครงการ',
    'any.required': `กรุณากรอกลำดับโครงการ`,
    'any.only': `กรุณากรอกลำดับโครงการให้ถูกต้อง`,
})
export const budget_year = Joi.number()
    .min(2000)
    .max(2300)
    .required()
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'number.max': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'number.min': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'any.required': `กรุณากรอกปีงบประมาณ`,
        'any.only': `กรุณากรอกปีงบประมาณให้ถูกต้อง`,
    })
export const project_promise_no = Joi.string()
    .max(45)
    .allow('', null)
    .messages({
        'string.empty': `กรุณากรอกข้อมูล`,
        'any.required': `กรุณากรอกข้อมูล`,
        'string.min': `กรุณากรอกข้อมูล`,
        'string.max': `ตัวอักษรห้ามไม่เกิน 45 ตัว`,
    })
export const project_name = Joi.string().max(255).required().messages({
    'string.empty': `กรุณากรอกชื่อโครงการ`,
    'string.base': `กรุณากรอกชื่อโครงการ`,
    'any.required': `กรุณากรอกชื่อโครงการ`,
    'string.min': `กรุณากรอกชื่อโครงการ`,
    'string.max': `ตัวอักษรห้ามไม่เกิน 255 ตัว`,
})
export const project_detail = Joi.string().max(2500).messages({
    'string.empty': `กรุณากรอกรายละเอียดโครงการ`,
    'string.base': `กรุณากรอกรายละเอียดโครงการ`,
    'any.required': `กรุณากรอกรายละเอียดโครงการ`,
    'string.min': `กรุณากรอกรายละเอียดโครงการ`,
    'string.max': `ตัวอักษรห้ามไม่เกิน 2500 ตัว`,
})
export const project_other_note = Joi.string()
    .required()
    .allow(null, '')
    .max(4000)
    .messages({
        'string.empty': `กรุณากรอกหมายเหตุ`,
        'any.required': `กรุณากรอกหมายเหตุ`,
        'string.min': `กรุณากรอกหมายเหตุ`,
        'string.max': `ตัวอักษรห้ามไม่เกิน 2500 ตัว`,
    })
export const project_start_date = Joi.date()
    .iso()
    .max(maxdate)
    .required()
    .messages({
        'any.required': `กรุณากรอกวันที่เริ่มโครงการ`,
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
    })

export const project_end_date = Joi.date()
    .iso()
    .max(maxdate)
    .greater(Joi.ref('project_start_date'))
    .required()
    .messages({
        'any.required': `กรุณากรอกวันที่สิ้นสุดโครงการ`,
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,

        'date.greater': `วันสิ้นสุดโครงการต้องเป็นวันหลังจากวันเริ่มโครงการ`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
    })

export const project_duration = Joi.number().required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'any.required': `กรุณากรอกระยะเวลาโครงการ`,
})

export const project_budget = Joi.number().max(1000000000).required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'number.min': 'กรุณากรอกงบประมาณโครงการ',
    'number.max': 'จำนวนงบประมาณมากเกินไป',
    'any.required': `กรุณากรอกงบประมาณโครงการ`,
})

export const project_period_amount = Joi.number().max(20).required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'any.required': `กรุณากรอกจำนวนงบโครงการ ไม่ทราบใส่ 0`,
    'number.max': `จำนวนงวดมากที่สุดไม่เกิน 20 งวด`,
})

export const financial_report_submission_date = Joi.date()
    .iso()
    .required()
    .allow(null)
    .max(maxdate)
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'any.required': `กรุณากรอกข้อมูล`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
    })
export const has_project_chair_agreement = Joi.boolean()
    .required()
    .allow(null, '')
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'any.required': `กรุณากรอกข้อมูล`,
        'any.only': `รูปแบบข้อมูลไม่ถูกต้อง`,
    })

export const has_letter_to_dean_about_chairman = Joi.boolean()
    .required()
    .allow(null, '')
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'any.only': `รูปแบบข้อมูลไม่ถูกต้อง`,
        'any.required': `กรุณากรอกข้อมูล`,
    })
export const rector_project_approval_date = Joi.date()
    .iso()
    .max(maxdate)
    .required()
    .allow(null)
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'any.required': `กรุณากรอกข้อมูล`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
    })
export const rector_signing_employment_contract_request_date = Joi.date()
    .iso()
    .max(maxdate)
    .required()
    .allow(null)
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'any.required': `กรุณากรอกข้อมูล`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
    })
export const rector_budget_adjustment_request_date = Joi.date()
    .iso()
    .max(maxdate)
    .required()
    .allow(null)
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'any.required': `กรุณากรอกข้อมูล`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
    })
export const rector_budget_detail_adjustment_request_date = Joi.date()
    .iso()
    .max(maxdate)
    .required()
    .allow(null)
    .raw()
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'any.required': `กรุณากรอกข้อมูล`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
    })
export const rector_project_name_change_request_date = Joi.date()
    .iso()
    .max(maxdate)
    .required()
    .allow(null)
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'any.required': `กรุณากรอกข้อมูล`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
    })
export const rector_project_period_extension_request_date = Joi.date()
    .iso()
    .max(maxdate)
    .required()
    .allow(null)
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'any.required': `กรุณากรอกข้อมูล`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
    })

export const old_project_budget = Joi.number()
    .max(1000000000)
    .required()
    .allow(null)
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'number.min': 'กรุณากรอกงบประมาณโครงการ',
        'number.max': 'จำนวนงบประมาณมากเกินไป',
        'any.required': `กรุณากรอกงบประมาณโครงการ`,
    })

export const old_project_name = Joi.string()
    .required()
    .allow(null, '')
    .max(255)
    .messages({
        'string.max': `ตัวอักษรห้ามไม่เกิน 255 ตัว`,
        'any.required': `กรุณากรอกข้อมูล`,
    })
export const old_project_duration = Joi.number()
    .required()
    .allow(null)
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'any.required': `กรุณากรอกข้อมูล`,
    })
export const chairman_id = Joi.number().required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'any.required': `กรุณากรอกประธานโครงการ`,
})
export const coordinator_id = Joi.number().required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'any.required': `กรุณากรอกผู้ประสานงานโครงการ`,
})
export const agency_id = Joi.number().required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'any.required': `กรุณากรอกหน่วยงานลูกค้า`,
})
export const project_progress_status_id = Joi.number()
    .allow(null, '')
    .min(1)
    .required()
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'any.required': `กรุณากรอกข้อมูล`,
    })

export const project_complete_status = Joi.number()
    .valid(0, 1)
    .required()
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'any.required': `กรุณากรอกข้อมูล`,
        'any.only': `รูปแบบข้อมูลไม่ถูกต้อง`,
    })

export const project_evaluation_result = Joi.number()
    .allow(null)
    .required()
    .min(1)
    .max(5)
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'any.required': `กรุณากรอกผลประเมินความพึงพอใจโครงการ`,
        'number.max': `ผลประเมินห้ามมากกว่า 5`,
        'number.min': `ผลประเมินห้ามต่ำกว่า 0`,
    })

// export const project_evaluation_by = number
// export const project_progress = number
// export const created_at = Date
// export const created_by = number
// export const updated_at = Date
// export const updated_by = number

// project period

export const period_budget = Joi.number()
    .min(1)
    .max(1000000000)
    .required()
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'number.min': 'กรุณากรอกงบประมาณงวดงาน',
        'number.max': 'จำนวนงบประมาณมากเกินไป',
        'any.required': `กรุณากรอกงบประมาณงวดงาน`,
    })

export const period_start_date = Joi.date()
    .required()
    .iso()
    .max(maxdate)
    .raw()
    .messages({
        'any.required': `กรุณากรอกวันที่เริ่มต้นงวดงาน`,
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,

        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
    })

export const period_end_date = Joi.date()
    .iso()
    .max(maxdate)
    .allow(null)
    .greater(Joi.ref('period_start_date'))
    .required()
    .messages({
        'any.required': `กรุณากรอกวันที่สิ้นสุดงวดงาน`,
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.greater': `วันสิ้นสุดงวดงานต้องเป็นวันหลังจากวันเริ่มงวดงาน`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
    })

export const period_duration = Joi.number().required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'any.required': `กรุณากรอกระยะเวลางวดงาน`,
})

export const period_report_submission_date = Joi.date()
    .iso()
    .allow(null)
    .required()
    .max(maxdate)
    .raw()
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

export const disbursement_submission_date = Joi.date()
    .iso()
    .max(maxdate)
    .allow(null)
    .required()
    .raw()
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

export const disbursement_received_date = Joi.date()
    .iso()
    .max(maxdate)
    .allow(null)
    .required()
    .raw()
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

export const chairman_disbursement_date = Joi.date()
    .iso()
    .max(maxdate)
    .allow(null)
    .required()
    .raw()
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

export const has_parcel = Joi.number()
    .allow(null)
    .required()
    .valid(0, 1)
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'any.required': `กรุณากรอกข้อมูล`,
        'any.only': `รูปแบบข้อมูลไม่ถูกต้อง`,
    })

export const submit_to_finance_department_date = Joi.date()
    .iso()
    .allow(null)

    .max(maxdate)
    .required()
    .raw()
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

export const submit_to_fixed_date = Joi.date()
    .iso()
    .allow(null)

    .max(maxdate)
    .required()
    .raw()
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

export const manager_sign_date = Joi.date()
    .iso()
    .allow(null)

    .max(maxdate)
    .required()
    .raw()
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

export const director_sign_date = Joi.date()
    .iso()
    .allow(null)
    .max(maxdate)
    .required()
    .raw()
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

export const accounting_department_collect_date = Joi.date()
    .iso()
    .allow(null)
    .max(maxdate)
    .required()
    .raw()
    .messages({
        'date.base': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.format': `กรุณากรอกข้อมูลเป็นวันที่`,
        'date.max': `ปีของวันที่ต้องเป็นปี ค.ศ.`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

// lecturer

export const project_lecturer_id = Joi.number().required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'any.required': `กรุณากรอกข้อมูล`,
})

export const lecturer_id = Joi.number().required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'any.required': `กรุณากรอกข้อมูล`,
})

export const lecturer_evaluation_result = Joi.number()
    .max(5)
    .min(1)
    .allow(null)
    .required()
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'number.max': `ผลประเมินห้ามมากกว่า 5`,
        'number.min': `ผลประเมินห้ามต่ำกว่า 1`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

// vendor

export const project_vendor_id = Joi.number().required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'any.required': `กรุณากรอกข้อมูล`,
})

export const vendor_id = Joi.number().required().messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'any.required': `กรุณากรอกข้อมูล`,
})

export const vendor_evaluation_factor_1 = Joi.number()
    .required()
    .max(30)
    .min(1)
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'number.max': `ผลประเมินห้ามมากกว่า 30`,
        'number.min': `ผลประเมินห้ามต่ำกว่า 1`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

export const vendor_evaluation_factor_2 = Joi.number()
    .required()
    .max(50)
    .min(1)
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'number.max': `ผลประเมินห้ามมากกว่า 50`,
        'number.min': `ผลประเมินห้ามต่ำกว่า 1`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

export const vendor_evaluation_factor_3 = Joi.number()
    .required()
    .max(20)
    .min(1)
    .messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'number.max': `ผลประเมินห้ามมากกว่า 20`,
        'number.min': `ผลประเมินห้ามต่ำกว่า 1`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

export const vendor_evaluation_result = Joi.number()
    .max(100)
    .min(1)
    .required()
    .messages({
        'number.max': `ผลประเมินห้ามมากกว่า 100`,
        'number.min': `ผลประเมินห้ามต่ำกว่า 1`,
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'any.required': `กรุณากรอกข้อมูล`,
    })

export const vendor_evaluation_grade = Joi.string()
    .valid('A', 'B', 'C')
    .required()
    .messages({
        'string.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'any.only': `รูปแบบข้อมูลไม่ถูกต้อง`,
        'any.required': `กรุณากรอกข้อมูล`,
    })

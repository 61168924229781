import { ButtonProps, createPolymorphicComponent } from '@mantine/core'
import { IconTrash } from '@tabler/icons-react'
import React from 'react'
import TableActionButton from './TableActionButton'
interface MyComponentProps
    extends ButtonProps,
        React.RefAttributes<HTMLButtonElement> {}
const _TableDeleteButton = ({
    classNames,
    styles,
    unstyled,
    className,
    ...others
}: MyComponentProps) => {
    return (
        <TableActionButton
            Icon={IconTrash}
            label={'ลบ'}
            classNames={classNames}
            styles={styles}
            unstyled={unstyled}
            className={className}
            {...others}
        />
    )
}

const TableDeleteButton = createPolymorphicComponent<
    'button',
    MyComponentProps
>(_TableDeleteButton)
export default TableDeleteButton

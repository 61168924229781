import PageLayout from '@components/page/PageLayout'
import { Tabs } from '@mantine/core'
import { IconCategory2, IconUsersGroup } from '@tabler/icons-react'
import { useState } from 'react'
import TableAllVendors from '@components/table/database/TableAllVendor'
import TableAllVendorType from '@components/table/database/TableAllVendorType'
import { useNavigate } from 'react-router-dom'

const AllVendor = ({ defaultTab = 'vendor' }: { defaultTab?: string }) => {
    const [tab, setTab] = useState(defaultTab)
    const navigate = useNavigate()
    const onTabChange = (value: string) => {
        setTab(value)
        if (value === 'vendor_type') {
            navigate('/vendors/types')
        } else {
            navigate('/vendors')
        }
    }

    return (
        <PageLayout pageTitle='ผู้ค้า'>
            <Tabs value={tab} onTabChange={onTabChange}>
                <Tabs.List>
                    <Tabs.Tab
                        value='vendor'
                        icon={<IconUsersGroup size='0.8rem' />}
                    >
                        ผู้ค้า
                    </Tabs.Tab>
                    <Tabs.Tab
                        value='vendor_type'
                        icon={<IconCategory2 size='0.8rem' />}
                    >
                        ประเภทผู้ค้า
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value='vendor' pt='xs'>
                    <TableAllVendors />
                </Tabs.Panel>

                <Tabs.Panel value='vendor_type' pt='xs'>
                    <TableAllVendorType />
                </Tabs.Panel>
            </Tabs>
        </PageLayout>
    )
}

export default AllVendor

import React from 'react'
import TableListChart from './TableListChart'

interface IAgencyTableListChart {
    title: string
    data: {
        agency_name: string
        agency_type_name: string
        agency_budget_year: number
        total_project_amount: number
        total_project_budget: number
    }[]
}
const AgencyTableListChart = ({ title, data }: IAgencyTableListChart) => {
    return (
        <TableListChart
            title={title}
            th={[
                { label: 'ลำดับ', width: '5%' },
                {
                    label: 'หน่วยงาน',
                    width: '40%',
                },
                {
                    label: 'ประเภท',
                    width: '15%',
                },
                {
                    label: 'ลูกค้าเมื่อปีงบประมาณ',
                    width: '15%',
                },
            ]}
        >
            {data.map((p, index) => {
                return (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{p.agency_name}</td>
                        <td>{p.agency_type_name}</td>
                        <td>{p.agency_budget_year + 543}</td>
                    </tr>
                )
            })}
        </TableListChart>
    )
}

export default AgencyTableListChart

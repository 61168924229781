import TextChartTitle from '@components/text/TextChartTitle'
import { IResponseGetProjectDataForDashboardRowData } from '@interfaces/api/IDashboard'
import ShortMoney from '@utils/MoneyShort'
import _ from 'lodash'
import React from 'react'
import Chart from 'react-google-charts'
import ChartFlexSection from './ChartFlexSection'
import { ColumnChartOptions } from './setting'
interface IProjectBudgetByQuarterColumnChart {
    title: string
    data: IResponseGetProjectDataForDashboardRowData[]
}

const ProjectBudgetByQuarterColumnChart = ({
    title,
    data,
}: IProjectBudgetByQuarterColumnChart) => {
    const groupedData = _.groupBy(data, 'project_budget_year')
    const prepareDatawithAnnotation = Object.keys(groupedData).map((Byear) => {
        const DataByQuarter = _.groupBy(groupedData[Byear], (p) => p.quarter)
        const DataQ1 = _.sumBy(DataByQuarter['Q1'], (p) =>
            Number(p.project_budget)
        )
        const DataQ2 = _.sumBy(DataByQuarter['Q2'], (p) =>
            Number(p.project_budget)
        )
        const DataQ3 = _.sumBy(DataByQuarter['Q3'], (p) =>
            Number(p.project_budget)
        )
        const DataQ4 = _.sumBy(DataByQuarter['Q4'], (p) =>
            Number(p.project_budget)
        )

        return [
            `ปีงบประมาณ ${Number(Byear) + 543} \n (Q1-Q4)`,
            DataQ1,
            ShortMoney(DataQ1),
            DataQ2,
            ShortMoney(DataQ2),
            DataQ3,
            ShortMoney(DataQ3),
            DataQ4,
            ShortMoney(DataQ4),
        ]
    })

    const columnChartData = [
        [
            'ปีงบประมาณ',
            'ไตรมาสที่ 1 (Q1)',
            { role: 'annotation' },
            'ไตรมาสที่ 2 (Q2)',
            { role: 'annotation' },
            'ไตรมาสที่ 3 (Q3)',
            { role: 'annotation' },
            'ไตรมาสที่ 4 (Q4)',
            { role: 'annotation' },
        ],
        ...prepareDatawithAnnotation,
    ]
    const options = ColumnChartOptions(
        'มูลค่างบประมาณโครงการ',
        'ไตรมาสปีงบประมาณ (Quarter)'
    )

    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <Chart
                width={'100%'}
                height={'500px'}
                chartType='ColumnChart'
                loader={<div>Loading Chart</div>}
                data={columnChartData}
                options={options}
            />
        </ChartFlexSection>
    )
}

export default ProjectBudgetByQuarterColumnChart

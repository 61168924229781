import PageLayout from '@components/page/PageLayout'
import TableAllProject from '@components/table/database/project/TableAllProject'

const AllProject = () => {
    return (
        <PageLayout pageTitle='โครงการ'>
            <TableAllProject />
        </PageLayout>
    )
}

export default AllProject

import { Flex, Paper, Skeleton } from '@mantine/core'

const LoadingPage = () => {
    return (
        <Paper w='100%' p='lg' mah='100vh' style={{ overflow: 'hidden' }}>
            <Skeleton height={300} width='100%' mb='xl' />
            <Flex gap='xl'>
                <Skeleton height={300} width='70%' mb='xl' />
                <Skeleton height={300} width='30%' mb='xl' />
            </Flex>
            <Flex gap='xl' h='30%'>
                <Flex w='70%' direction='column' justify='space-between'>
                    <Skeleton height={'5%'} mt={6} width='100%' radius='xl' />
                    <Skeleton height={'5%'} mt={6} width='100%' radius='xl' />
                    <Skeleton height={'5%'} mt={6} width='100%' radius='xl' />
                    <Skeleton height={'5%'} mt={6} width='100%' radius='xl' />
                    <Skeleton height={'5%'} mt={6} width='100%' radius='xl' />
                    <Skeleton height={'5%'} mt={6} width='100%' radius='xl' />
                    <Skeleton height={'5%'} mt={6} width='100%' radius='xl' />
                    <Skeleton height={'5%'} mt={6} width='100%' radius='xl' />
                    <Skeleton height={'5%'} mt={6} width='100%' radius='xl' />
                </Flex>
                <Skeleton height={300} width='30%' mb='xl' />
            </Flex>
        </Paper>
    )
}

export default LoadingPage

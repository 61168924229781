import { Select, SelectItem } from '@mantine/core'
import { useEffect, useState } from 'react'
import { SelectProps } from '@mantine/core/lib/Select/Select'
import UserPrivilege from '@api/UserPrivilege'
interface IInputUserPrivilege
    extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
    withAll?: boolean
}

interface ItemProps extends SelectItem {}

const InputUserPrivilege = ({
    withAll = false,
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: IInputUserPrivilege) => {
    const { data } = UserPrivilege.useGetAllUserPrivileges({})
    const [list, setlist] = useState<ItemProps[]>([])
    useEffect(() => {
        if (data) {
            const selectlists = data.lists.map((e) => {
                return {
                    value: String(e.privilege_id),
                    label: e.privilege_name,
                }
            })
            if (withAll) {
                selectlists.unshift({
                    value: '',
                    label: 'ทั้งหมด',
                })
            }
            setlist(selectlists)
        }
        // eslint-disable-next-line
    }, [data])

    return (
        <Select
            key={list.length}
            className={className}
            label='สิทธิการใช้งาน'
            value={String(value)}
            placeholder='สิทธิการใช้งาน'
            data={list}
            filter={(value, item: ItemProps) =>
                item.label!.toLowerCase().includes(value.toLowerCase().trim())
            }
            {...others}
        />
    )
}

export default InputUserPrivilege

import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core'
import { Link, useNavigate } from 'react-router-dom'
import { IRequestUpdateUser, IResponseGetUserByID } from '@interfaces/api/IUser'
import User from '@api/User'
import InputUserPosition from '../input/InputUserPosition'
import InputUserPrivilege from '../input/InputUserPrivilege'
import InputUserStatus from '../input/InputUserStatus'

const FormEditUser = ({
    aftersuccess,
    data,
}: {
    aftersuccess?: () => any
    data: IResponseGetUserByID
}) => {
    const form = useForm<IRequestUpdateUser>({
        initialValues: {
            username: data.username ?? '',
            user_fullname: data.user_fullname ?? '',
            user_email: data.user_email ?? '',
            user_position_id: data.user_position_id,
            privilege_id: data.privilege_id,
            status: data.status,
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = User.useUpdateUser({
        user_id: data.user_id,
        form,
        aftersuccess: () => navigate('/users'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อผู้ใช้งาน'
                        placeholder='ชื่อผู้ใช้งาน'
                        {...form.getInputProps('username')}
                    />
                    <TextInput
                        withAsterisk
                        label='ชื่อจริงผู้ใช้งาน'
                        placeholder='ชื่อจริงผู้ใช้งาน'
                        {...form.getInputProps('user_fullname')}
                    />
                    <TextInput
                        label='อีเมล'
                        placeholder='อีเมล'
                        {...form.getInputProps('user_email')}
                    />
                    <InputUserPosition
                        {...form.getInputProps('user_position_id')}
                    />
                    <InputUserPrivilege
                        {...form.getInputProps('privilege_id')}
                    />

                    <InputUserStatus {...form.getInputProps('status')} />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/users'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            บันทึกการแก้ไขผู้ใช้งานระบบ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormEditUser

import PageLayout from '@components/page/PageLayout'
import { Tabs } from '@mantine/core'
import { IconCategory2, IconUsersGroup } from '@tabler/icons-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TableAllChairman from '@components/table/database/TableAllChairman'
import TableAllChairmanOrganization from '@components/table/database/TableAllChairmanOrganization'

const AllChairman = ({ defaultTab = 'chairman' }: { defaultTab: string }) => {
    const [tab, setTab] = useState(defaultTab)
    const [loadedTab, setLoadedTab] = useState([tab])
    const navigate = useNavigate()
    const onTabChange = (value: string) => {
        if (value && !loadedTab.includes(value)) {
            setLoadedTab((prev) => [...prev, value])
        }
        setTab(value)
        if (value === 'organization') {
            navigate('/chairmans/organizations')
        } else {
            navigate('/chairmans')
        }
    }

    return (
        <PageLayout pageTitle='ประธานโครงการ'>
            <Tabs
                value={tab}
                defaultValue={defaultTab}
                onTabChange={onTabChange}
            >
                <Tabs.List>
                    <Tabs.Tab
                        value='chairman'
                        icon={<IconUsersGroup size='0.8rem' />}
                    >
                        ประธานโครงการ
                    </Tabs.Tab>
                    <Tabs.Tab
                        value='organization'
                        icon={<IconCategory2 size='0.8rem' />}
                    >
                        สังกัดประธานโครงการ
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value='chairman' pt='xs'>
                    {loadedTab.includes('chairman') && <TableAllChairman />}
                </Tabs.Panel>
                <Tabs.Panel value='organization' pt='xs'>
                    {loadedTab.includes('organization') && (
                        <TableAllChairmanOrganization />
                    )}
                </Tabs.Panel>
            </Tabs>
        </PageLayout>
    )
}

export default AllChairman

import { useForm } from '@mantine/form'
import { Button, Flex, Group, LoadingOverlay, Paper, Text } from '@mantine/core'
import Project from '@api/Project'
import LoadingPage from '@components/loading/LoadingPage'
import { IRequestAddAndUpdateProjectVendor, IResponseAllProjectVendorsRowData } from '@interfaces/api/IProject'
import InputVendor from '../input/InputVendor'
import { useEffect, useState } from 'react'
import TableEditButton from '@components/button/TableEditButton'
import TableDeleteButton from '@components/button/TableDeleteButton'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'
import TextEvaluationBy from '@components/text/TextEvaluationBy'
import TableAddNewButton from '@components/button/TableAddNewButton'
import { modals } from '@mantine/modals'
import CancelButton from '@components/button/CancleButton'

const FormManageProjectVendor = ({
    project_id,
    refetchProjectInfo,
    editMode,
}: {
    project_id: number | string
    refetchProjectInfo: () => void
    editMode: boolean
}) => {
    const [createMode, setCreateMode] = useState(false)
    const [currentEditList, setCurrentEditList] = useState<number | null>(null)

    useEffect(() => {
        if (editMode === false) {
            setCreateMode(false)
            setCurrentEditList(null)
        }
        // eslint-disable-next-line
    }, [editMode, createMode, currentEditList])

    let { isLoading, data, refetch } = Project.useGetAllProjectVendorByID({
        project_id: project_id,
    })

    if (isLoading) {
        return <LoadingPage />
    }

    const refetchProjectVendor = async () => {
        setCurrentEditList(null)
        refetchProjectInfo()
        await refetch()
    }

    return (
        <Flex gap='md' direction='column'>
            {data!.map((vendor) => {
                return (
                    <ProjectVendorViewAndEditComponent
                        key={vendor.project_vendor_id}
                        editMode={editMode}
                        data={vendor}
                        refetchProjectVendor={refetchProjectVendor}
                        currentEditList={currentEditList}
                        setCurrentEditList={setCurrentEditList}
                    />
                )
            })}
            {!data?.length && !editMode && (
                <Paper p='lg'>
                    <Group position='center'>ไม่พบรายชื่อผู้ค้าของโครงการ</Group>
                </Paper>
            )}

            <ProjectVendorCreateComponent
                key={project_id}
                project_id={project_id}
                editMode={editMode}
                createMode={createMode}
                setCreateMode={setCreateMode}
                refetchProjectVendor={refetchProjectVendor}
            />
            {editMode && <TableAddNewButton label='เพิ่มผู้ค้าโครงการ' onClick={() => setCreateMode(!createMode)} />}
        </Flex>
    )
}

export default FormManageProjectVendor

interface IProjectVendorViewAndEditComponentProps {
    data: IResponseAllProjectVendorsRowData
    editMode: boolean
    refetchProjectVendor: () => void
    currentEditList: null | number
    setCurrentEditList: React.Dispatch<React.SetStateAction<number | null>>
}

const ProjectVendorViewAndEditComponent = ({
    data,
    editMode,
    refetchProjectVendor,
    currentEditList,
    setCurrentEditList,
}: IProjectVendorViewAndEditComponentProps) => {
    const form = useForm<IRequestAddAndUpdateProjectVendor>({
        initialValues: {
            vendor_id: Number(data.vendor_id),
        },
    })

    const { isLoading, onSubmit } = Project.useUpdateProjectVendor({
        project_id: data.project_id,
        project_vendor_id: data.project_vendor_id,
        form,
        aftersuccess: refetchProjectVendor,
    })

    const reqDeleteProjectVendor = Project.useDeleteProjectVendor({
        project_id: data.project_id,
        project_vendor_id: data.project_vendor_id,
        aftersuccess: refetchProjectVendor,
    })

    const openDeleteConfirmModal = () =>
        modals.openConfirmModal({
            title: 'กรุณายืนยันการลบ',
            children: (
                <Text>
                    ลบผู้ค้าออกจากโครงการ <br />
                    {data.vendor_name}
                </Text>
            ),
            labels: { confirm: 'ยืนยัน', cancel: 'ยกเลิก' },
            onConfirm: reqDeleteProjectVendor.onSubmit,
        })

    const [editList, setEditList] = useState(false)

    useEffect(() => {
        if (currentEditList !== data.project_vendor_id) {
            setEditList(false)
        } // eslint-disable-next-line
    }, [currentEditList])

    const editListOnClick = () => {
        setCurrentEditList(data.project_vendor_id)
        setEditList(true)
    }
    return (
        <Paper w='100%' p='md' shadow='md' pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit} style={{ pointerEvents: editMode ? 'all' : 'none' }}>
                <Flex gap='sm' direction='column'>
                    <InputVendor
                        editMode={editList}
                        label={editList ? 'ผู้ค้าโครงการ' : ''}
                        {...form.getInputProps('vendor_id')}
                    >
                        {editMode && (
                            <Group h='100%' pos='absolute' top='0%' right='0%' px='md' spacing='xs'>
                                <TableEditButton onClick={editListOnClick} />
                                <TableDeleteButton onClick={openDeleteConfirmModal} />
                            </Group>
                        )}
                    </InputVendor>
                    {editList && (
                        <Flex ml='auto' gap='md'>
                            <CancelButton onClick={() => setEditList(false)} />
                            <Button type='submit' w='fit-content'>
                                บันทันการแก้ไข
                            </Button>
                        </Flex>
                    )}
                    {editMode === false && (
                        <Flex justify='end' gap='md'>
                            <TextEvaluationBy {...data} />
                            <TextCreatedBy {...data} />
                            <TextUpdatedBy {...data} />
                        </Flex>
                    )}
                </Flex>
            </form>
        </Paper>
    )
}

interface IProjectVendorCreateComponent {
    editMode: boolean
    createMode: boolean
    project_id: string | number
    setCreateMode: React.Dispatch<React.SetStateAction<boolean>>
    refetchProjectVendor: () => Promise<void>
}
const ProjectVendorCreateComponent = ({
    editMode,
    createMode,
    project_id,
    setCreateMode,
    refetchProjectVendor,
}: IProjectVendorCreateComponent) => {
    const form = useForm<IRequestAddAndUpdateProjectVendor>()
    const onSubmitCreate = async () => {
        await refetchProjectVendor()
        setCreateMode(false)
        form.reset()
    }

    const { isLoading, onSubmit } = Project.useAddProjectVendor({
        project_id: project_id,
        vendor_id: form.getInputProps('vendor_id').value,
        form,
        aftersuccess: onSubmitCreate,
    })

    return (
        <Paper display={createMode && editMode ? 'initial' : 'none'} w='100%' p='md' shadow='md' pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit} style={{ pointerEvents: editMode ? 'all' : 'none' }}>
                <Flex gap='sm' direction='column'>
                    <InputVendor withAsterisk {...form.getInputProps('vendor_id')}></InputVendor>
                    <Flex ml='auto' gap='md'>
                        <CancelButton onClick={() => setCreateMode(false)} />
                        <Button type='submit' w='fit-content'>
                            เพิ่มผู้ค้าโครงการ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

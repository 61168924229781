import { Flex, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import TableViewButton from '@components/button/TableViewButton.'
import TableEditButton from '@components/button/TableEditButton'
import TableDeleteButton from '@components/button/TableDeleteButton'
import TablePagination from '@components/table/template/TablePagination'
import TableTemplate from '@components/table/template/TableTemplate'
import { ITHeadMap } from '@interfaces/ITable'
import { Link } from 'react-router-dom'
import TableAddNewButton from '@components/button/TableAddNewButton'
import { modals } from '@mantine/modals'
import { QueryObserverResult } from 'react-query'

import User from '@api/User'
import {
    IResponseGetAllUsers,
    IResponseGetAllUsersRowData,
} from '@interfaces/api/IUser'
import InputUserStatus from '@components/form/input/InputUserStatus'
import TableSearchFilter from '@components/table/filter/TableSearchFilter'
import TableFilterWrapper from '@components/table/template/TableFilterWrapper'
import DateColumn from '@components/table/template/DateColumn'

const TableAllUser = () => {
    const [limit, setlimit] = useState(10)
    const [orderby, setorderby] =
        useState<keyof IResponseGetAllUsersRowData>('user_id')
    const [order, setorder] = useState('desc')
    const [page, setpage] = useState(1)
    const [search, setsearch] = useState('')
    const [status, setstatus] = useState('')
    const lists = User.useGetAllUsers({
        limit,
        orderby,
        order,
        page,
        search,
        status,
    })

    useEffect(() => {
        lists.refetch()
        // eslint-disable-next-line
    }, [status, page, limit, order, orderby])

    const thead: ITHeadMap<IResponseGetAllUsersRowData>[] = [
        {
            sortkey: 'user_fullname',
            label: 'ชื่อจริงผู้ใช้งาน',
            width: '40%',
        },

        {
            sortkey: 'privilege_name',
            label: 'สิทธิการใช้งาน',
            width: '30%',
        },
        {
            sortkey: 'last_login_at',
            label: 'เข้าสู่ระบบล่าสุดเมื่อ',
            width: '11%',
        },
        {
            sortkey: 'status',
            label: 'สถานะ',
            width: '15%',
        },
        {
            label: 'Action',
            width: '10%',
        },
    ]

    return (
        <>
            <TableFilterWrapper>
                <TableSearchFilter
                    page={page}
                    setpage={setpage}
                    setsearch={setsearch}
                    search={search}
                    refresh={lists.refetch}
                />
                <InputUserStatus
                    withAll
                    miw='180px'
                    value={String(status)}
                    onChange={(value) => {
                        if (value !== status) {
                            setstatus(String(value))
                            setpage(1)
                        }
                    }}
                />
                <TableAddNewButton
                    component={Link}
                    to='/users/create'
                    label='เพิ่มผู้ใช้งาน'
                />
            </TableFilterWrapper>

            <TableTemplate<IResponseGetAllUsersRowData>
                thead={thead}
                orderby={orderby}
                order={order}
                setorder={setorder}
                setorderby={setorderby}
                lists={lists?.data?.lists}
            >
                {lists?.data?.lists.map((value) => (
                    <TableRow
                        key={value.user_id}
                        data={value}
                        refreshTable={lists.refetch}
                    />
                ))}
            </TableTemplate>
            {lists.data?.lists.length ? (
                <TablePagination
                    limit={lists.data?.lists.length}
                    setlimit={setlimit}
                    setpage={setpage}
                    page={page}
                    totalPages={lists.data.totalPages}
                    totalRows={lists.data.totalRows}
                />
            ) : (
                <></>
            )}
        </>
    )
}

export default TableAllUser

const TableRow = ({
    data,
    refreshTable,
}: {
    data: IResponseGetAllUsersRowData
    refreshTable: () => Promise<
        QueryObserverResult<IResponseGetAllUsers, unknown>
    >
}) => {
    const Delete = User.useDeleteUser({
        user_id: data.user_id,
        aftersuccess: refreshTable,
    })

    const openDeleteConfirmModal = () =>
        modals.openConfirmModal({
            title: 'กรุณายืนยันการลบ',
            children: (
                <Text>
                    ผู้ใช้งาน <br />
                    {data.user_fullname}
                </Text>
            ),
            labels: { confirm: 'ยืนยัน', cancel: 'ยกเลิก' },
            onConfirm: Delete.onSubmit,
        })

    return (
        <tr key={data.user_id}>
            <td>{data.user_fullname}</td>
            <td>{data.privilege_name}</td>
            <DateColumn day={data.last_login_at} />
            <td>{data.status_description}</td>
            <td>
                <Flex gap='xs' pos='relative'>
                    <TableViewButton
                        component={Link}
                        to={`/users/${data.user_id}/view`}
                    />
                    <TableEditButton
                        component={Link}
                        to={`/users/${data.user_id}/edit`}
                    />
                    <TableDeleteButton onClick={openDeleteConfirmModal} />
                </Flex>
            </td>
        </tr>
    )
}

import { Flex, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import TableViewButton from '@components/button/TableViewButton.'
import TableEditButton from '@components/button/TableEditButton'
import TableDeleteButton from '@components/button/TableDeleteButton'
import TablePagination from '@components/table/template/TablePagination'
import TableTemplate from '@components/table/template/TableTemplate'
import { ITHeadMap } from '@interfaces/ITable'
import { Link } from 'react-router-dom'
import TableAddNewButton from '@components/button/TableAddNewButton'
import { modals } from '@mantine/modals'
import { QueryObserverResult } from 'react-query'
import {
    IResponseAllAgencyTypes,
    IResponseAllAgencyTypesRowData,
} from '@interfaces/api/IAgency'
import Agency from '@api/Agency'
import TableFilter from '../filter/TableFilter'
const TableAllAgencyType = () => {
    const [limit, setlimit] = useState(10)
    const [orderby, setorderby] =
        useState<keyof IResponseAllAgencyTypesRowData>('agency_type_id')
    const [order, setorder] = useState('desc')
    const [page, setpage] = useState(1)
    const [search, setsearch] = useState('')
    const [checkowner, setcheckowner] = useState(0)
    const [status, setstatus] = useState('')

    const lists = Agency.useGetAllAgencyTypes({
        limit,
        orderby,
        order,
        page,
        search,
        checkowner,
        status,
    })

    useEffect(() => {
        lists.refetch()
        // eslint-disable-next-line
    }, [page, status, limit, order, orderby, checkowner])

    const thead: ITHeadMap<IResponseAllAgencyTypesRowData>[] = [
        {
            sortkey: 'agency_type_name',
            label: 'ชื่อประเภทหน่วยงาน',
            width: '30%',
        },
        {
            sortkey: 'status',
            label: 'สถานะ',
            width: '30%',
        },
        // {
        //     sortkey: 'created_at',
        //     label: 'สร้างเมื่อ',
        //     width: '11%',
        // },
        // {
        //     sortkey: 'updated_at',
        //     label: 'แก้ไขล่าสุด',
        //     width: '11%',
        // },
        {
            label: 'Action',
            width: '10%',
        },
    ]

    return (
        <>
            <TableFilter
                {...{
                    lists,
                    setpage,
                    page,
                    setsearch,
                    search,
                    setcheckowner,
                    checkowner,
                    setstatus,
                    status,
                }}
            >
                <TableAddNewButton
                    component={Link}
                    to='/agencies/types/create'
                    label='เพิ่มประเภทหน่วยงาน'
                />
            </TableFilter>

            <TableTemplate<IResponseAllAgencyTypesRowData>
                thead={thead}
                orderby={orderby}
                order={order}
                setorder={setorder}
                setorderby={setorderby}
                lists={lists?.data?.lists}
            >
                {lists?.data?.lists.map((value) => (
                    <TableRow
                        key={value.agency_type_id}
                        data={value}
                        refreshTable={lists.refetch}
                    />
                ))}
            </TableTemplate>
            {lists.data?.lists.length ? (
                <TablePagination
                    limit={lists.data?.lists.length}
                    setlimit={setlimit}
                    setpage={setpage}
                    page={page}
                    totalPages={lists.data.totalPages}
                    totalRows={lists.data.totalRows}
                />
            ) : (
                <></>
            )}
        </>
    )
}

export default TableAllAgencyType

const TableRow = ({
    data,
    refreshTable,
}: {
    data: IResponseAllAgencyTypesRowData
    refreshTable: () => Promise<
        QueryObserverResult<IResponseAllAgencyTypes, unknown>
    >
}) => {
    const Delete = Agency.useDeleteAgencyType({
        agency_type_id: data.agency_type_id,
        aftersuccess: refreshTable,
    })

    const openDeleteConfirmModal = () =>
        modals.openConfirmModal({
            title: 'กรุณายืนยันการลบ',
            children: (
                <Text>
                    ลบประเภทหน่วยงาน/ลูกค้า <br />
                    {data.agency_type_name}
                </Text>
            ),
            labels: { confirm: 'ยืนยัน', cancel: 'ยกเลิก' },
            onConfirm: Delete.onSubmit,
        })

    return (
        <tr key={data.agency_type_id}>
            <td>{data.agency_type_name}</td>
            <td>{data.status_description}</td>
            {/* <DateAndFullNameColumn
                day={data.created_at}
                fullname={data.created_by_user_fullname}
                user_id={data.created_by_user_id}
            />
            <DateAndFullNameColumn
                day={data.updated_at}
                fullname={data.updated_by_user_fullname}
                user_id={data.updated_by_user_id}
            /> */}
            <td>
                <Flex gap='xs' pos='relative'>
                    <TableViewButton
                        component={Link}
                        to={`/agencies/types/${data.agency_type_id}/view`}
                    />
                    <TableEditButton
                        component={Link}
                        to={`/agencies/types/${data.agency_type_id}/edit`}
                    />
                    <TableDeleteButton onClick={openDeleteConfirmModal} />
                </Flex>
            </td>
        </tr>
    )
}

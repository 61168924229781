import React, { useContext } from 'react'
import dayjs from '@config/dayjs'
import { AuthContext } from '@context/AuthContext'
import { Text } from '@mantine/core'
const DateAndFullNameColumn = ({
    day,
    fullname,
    user_id,
}: {
    day: string
    fullname: string
    user_id: number
}) => {
    const { auth } = useContext(AuthContext)
    return (
        <td>
            <Text>{dayjs(day).fromNow()}</Text>
            <Text
                maw='100px'
                size='xs'
                truncate='end'
                sx={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}
            >
                โดย {auth?.user_id === user_id ? 'คุณ' : fullname}
            </Text>
        </td>
    )
}

export default DateAndFullNameColumn

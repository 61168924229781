import Agency from '@api/Agency';
import CardAgencyTypeDetail from '@components/card/CardAgencyTypeDetail';
import LoadingPage from '@components/loading/LoadingPage';
import PageLayout from '@components/page/PageLayout';
import PageNotFound404 from '@pages/PageNotFound404';
import { useParams } from 'react-router-dom';

const ViewAgencyType = () => {
    const params = useParams();
    const agency_type_id = params.agency_type_id;
    const { isLoading, isFetching, isError, data } =
        Agency.useGetAgencyTypeByID({
            agency_type_id: agency_type_id!,
        });
    if (isLoading || isFetching) {
        return <LoadingPage />;
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าประเภทหน่วยงานทั้งหมด'
                url='/agencies/types'
            />
        );
    }
    return (
        <PageLayout pageTitle='ข้อมูลประเภทหน่วยงาน'>
            <CardAgencyTypeDetail data={data}></CardAgencyTypeDetail>
        </PageLayout>
    );
};

export default ViewAgencyType;

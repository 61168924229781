import FormCreateVendor from '@components/form/vendor/FormCreateVendor'
import PageLayout from '@components/page/PageLayout'

const CreateVendor = () => {
    return (
        <PageLayout pageTitle='สร้างผู้ค้า'>
            <FormCreateVendor />
        </PageLayout>
    )
}

export default CreateVendor

import CardDashboardStat from '@components/card/CardDashboardStat'
import CardDashboardStatGroup from '@components/card/CardDashboardStatGroup'
import TextChartTitle from '@components/text/TextChartTitle'
import { IResponseGetProjectDataForDashboardRowData } from '@interfaces/api/IDashboard'
import { Flex } from '@mantine/core'
import PercenDiff from '@utils/PercenDiff'
import _ from 'lodash'
import React from 'react'
import ChartFlexSection from './ChartFlexSection'

const ProjectStatsGroup = ({
    data,
}: {
    data: IResponseGetProjectDataForDashboardRowData[]
}) => {
    const groupedData = _.groupBy(data, 'project_budget_year')
    const start_budget_year = Number(Object.keys(groupedData)[0]) + 543
    const end_budget_year =
        Number(Object.keys(groupedData)[Object.keys(groupedData).length - 1]) +
        543

    const dataForEachYear = Object.keys(groupedData).map((Byear, index) => {
        return {
            budget_year: Number(Byear) + 543,
            total_project_amount: groupedData[Byear].length,
            total_project_budget: _.sumBy(groupedData[Byear], (d) =>
                Number(d.project_budget)
            ),
        }
    })

    return (
        <ChartFlexSection>
            <TextChartTitle>
                สถิติยอดจำนวนโครงการ และมูลค่าของโครงการทั้งหมด ประจำปีงบประมาณ{' '}
                {start_budget_year} - {end_budget_year}
            </TextChartTitle>
            <CardDashboardStatGroup>
                <CardDashboardStat
                    w={200}
                    unit='โครงการ'
                    label='จำนวนโครงการทั้งหมด'
                    value={data.length}
                />
                <CardDashboardStat
                    w={350}
                    label='มูลค่าโครงการทั้งหมด'
                    isMoney
                    value={_.sumBy(data, (e) => Number(e.project_budget))}
                />
            </CardDashboardStatGroup>
            <Flex direction='column'>
                {dataForEachYear.map((p, index) => (
                    <CardDashboardStatGroup key={index}>
                        <CardDashboardStat
                            w={150}
                            label={index === 0 ? 'ปีงบประมาณ' : ''}
                            value={p.budget_year}
                        />
                        <CardDashboardStat
                            w={200}
                            diff={
                                index === 0
                                    ? undefined
                                    : PercenDiff(
                                          dataForEachYear[index - 1]
                                              .total_project_amount,
                                          p.total_project_amount
                                      )
                            }
                            unit='โครงการ'
                            label={index === 0 ? 'จำนวนโครงการ' : ''}
                            value={p.total_project_amount}
                        />
                        <CardDashboardStat
                            w={350}
                            diff={
                                index === 0
                                    ? undefined
                                    : PercenDiff(
                                          dataForEachYear[index - 1]
                                              .total_project_budget,
                                          p.total_project_budget
                                      )
                            }
                            label={index === 0 ? 'มูลค่าโครงการ' : ''}
                            isMoney
                            unit='บาท'
                            value={p.total_project_budget}
                        />
                    </CardDashboardStatGroup>
                ))}
            </Flex>
        </ChartFlexSection>
    )
}

export default ProjectStatsGroup

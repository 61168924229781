import { IResponseGetAllLecturersRowData } from '@interfaces/api/ILecturer'

const mapLecturerTable = (data: IResponseGetAllLecturersRowData[]) => {
    const mapData = data.map((value) => {
        return {
            ไอดีวิทยากร: value.lecturer_id,
            ชื่อวิทยากร: value.lecturer_fullname,
            อีเมล: value.lecturer_email,
            เบอร์โทร: value.lecturer_phone,
            สังกัด: value.lecturer_organization,
            ความเชี่ยวชาญ: value.lecturer_expertises_name?.split('&')?.join(' ') ?? '',
            ไอดีสถานะข้อมูล: value.status,
            ชื่อสถานะข้อมูล: value.status_description,
            สร้างเมื่อ: value.created_at,
            สร้างโดยผู้ใช้งานไอดี: value.created_by_user_id,
            สร้างโดยผู้ใช้งานชื่อ: value.created_by_user_fullname,
            แก้ไขเมื่อ: value.updated_at,
            แก้ไขโดยผู้ใช้งานไอดี: value.updated_by_user_id,
            แก้ไขโดยผู้ใช้งานชื่อ: value.updated_by_user_fullname,
        }
    })

    return mapData
}

export default mapLecturerTable

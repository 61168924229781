import ProgressBadge from '@components/badge/ProgressBadge'
import { IResponseGetProjectsOverview } from '@interfaces/api/IProject'
import { Card, Flex, Text, Title } from '@mantine/core'
import React from 'react'

interface ICardProjectOverview {
    data?: IResponseGetProjectsOverview
    title: string
}
const CardProjectOverview = ({ data, title }: ICardProjectOverview) => {
    if (!data?.length) {
        return <></>
    }
    return (
        <Card withBorder radius='md' padding='md'>
            <Title order={5} mb='5px'>
                {title}
            </Title>
            <Flex gap='lg' wrap='wrap'>
                {data.map((r, index) => (
                    <Card shadow='xs' key={index}>
                        <Flex
                            w='fit-content'
                            justify='start'
                            direction='column'
                            gap='8px'
                        >
                            <ProgressBadge
                                progress_color={r.project_progress_status_color}
                                progress_label={r.project_progress_status_name}
                            />
                            <Flex
                                w='fit-content'
                                justify='start'
                                align='end'
                                gap='4px'
                            >
                                <Text fw='bold'>{r.totalProjects}</Text>
                                <Text>โครงการ</Text>
                            </Flex>
                        </Flex>
                    </Card>
                ))}
            </Flex>
        </Card>
    )
}

export default CardProjectOverview

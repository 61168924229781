import InputBudgetYear from '@components/form/input/InputBudgetYear'
import React from 'react'
interface ITableBudgetYearFilter {
    setbudget_year: (value: React.SetStateAction<string>) => void
    budget_year: string
    setpage: (value: React.SetStateAction<number>) => void
}
const TableBudgetYearFilter = ({
    setbudget_year,
    budget_year,
    setpage,
}: ITableBudgetYearFilter) => {
    return (
        <InputBudgetYear
            withAll
            miw='195px'
            value={String(budget_year)}
            onChange={(value) => {
                if (value !== String(budget_year)) {
                    setbudget_year((prev) => String(value))
                    setpage(1)
                }
            }}
        />
    )
}

export default TableBudgetYearFilter

import { Text } from '@mantine/core'
import { useContext } from 'react'
import dayjs from '@config/dayjs'
import { AuthContext } from '@context/AuthContext'

interface ITextCreatedBy {
    created_by_user_fullname: string
    created_by_user_id: number
    created_at: string
}

const TextCreatedBy = ({
    created_by_user_fullname,
    created_at,
    created_by_user_id,
}: ITextCreatedBy) => {
    const { auth } = useContext(AuthContext)

    return (
        <Text size='sm'>
            สร้างเมื่อ : {dayjs(created_at).fromNow()} โดย{' '}
            {created_by_user_id === auth?.user_id
                ? 'คุณ'
                : created_by_user_fullname}
        </Text>
    )
}

export default TextCreatedBy

export const ProjectCodeGenerate = (
    budget_year?: number,
    project_no?: number
): string => {
    if (budget_year && project_no) {
        const project_code =
            String(Number(budget_year) + 543).slice(-2) +
            '-' +
            String(Number(project_no)).padStart(3, '0');

        return budget_year === null ? '' : project_code;
    } else {
        return '';
    }
};

import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core'
import { Link, useNavigate } from 'react-router-dom'
import { IRequestCreateExpertise } from '@interfaces/api/IExpertise'
import Expertise from '@api/Expertise'

const FormCreateExpertise = ({
    aftersuccess,
}: {
    aftersuccess?: () => any
}) => {
    const form = useForm<IRequestCreateExpertise>({
        initialValues: {
            expertise_name: '',
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = Expertise.useCreateExpertise({
        form,
        aftersuccess: () => navigate('/expertises'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ความเชี่ยวชาญ'
                        placeholder='ความเชี่ยวชาญ'
                        {...form.getInputProps('expertise_name')}
                    />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/expertises'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            สร้างความเชี่ยวชาญ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormCreateExpertise

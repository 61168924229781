import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core'

import {
    IRequestUpdateAgencyType,
    IResponseGetAgencyTypeByID,
} from '@interfaces/api/IAgency'
import Agency from '@api/Agency'
import { useNavigate } from 'react-router-dom'
import InputStatus from '../input/InputStatus'

const FormEditAgencyType = ({ data }: { data: IResponseGetAgencyTypeByID }) => {
    const form = useForm<IRequestUpdateAgencyType>({
        initialValues: {
            agency_type_name: data.agency_type_name,
            status: data.status,
        },
    })
    const navigate = useNavigate()

    const onAfterSuccess = () => {
        navigate('/agencies#agency_type')
    }

    const { isLoading, onSubmit } = Agency.useUpdateAgencyType({
        agency_type_id: data.agency_type_id,
        form,
        aftersuccess: onAfterSuccess,
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อประเภทหน่วยงาน'
                        placeholder='ชื่อประเภทหน่วยงาน'
                        {...form.getInputProps('agency_type_name')}
                    />
                    <InputStatus {...form.getInputProps('status')} />
                    <Flex mt='md' justify='end' gap='sm'>
                        <Button type='submit' w='fit-content'>
                            บันทึกการแก้ไขประเภทหน่วยงาน
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormEditAgencyType

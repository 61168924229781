import React from 'react'

import { Card, Flex, Title } from '@mantine/core'
import TextParameter from '../text/TextParameter'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'
import { IResponseGetUserByID } from '@interfaces/api/IUser'
import dayjs from '@config/dayjs'

const CardUserDetail = ({ data }: { data: IResponseGetUserByID }) => {
    const textparameters = [
        {
            title: 'ชื่อผู้ใช้งาน',
            info: String(data.username).split(',').join(', '),
        },
        {
            title: 'อีเมล',
            info: data.user_email,
        },
        {
            title: 'สิทธิการใช้งาน',
            info: data.privilege_name,
        },
        {
            title: 'สถานะบัญชี',
            info: data.status_description,
        },
        {
            title: 'เข้าสู่ระบบล่าสุดเมื่อ',
            info: dayjs(data.last_login_at).fromNow(),
        },
    ]
    return (
        <Card
            key={data.user_id}
            shadow='md'
            radius='md'
            // className={classes.card}
            padding='md'
        >
            <Flex gap='5px' direction='column'>
                <Title order={5}>{data.user_fullname}</Title>
                {textparameters.map((value) => (
                    <TextParameter
                        key={value.title}
                        title={value.title}
                        info={value.info!}
                    />
                ))}
                <Flex gap='md' mt='xs'>
                    <TextCreatedBy {...data} />
                    <TextUpdatedBy {...data} />
                </Flex>
            </Flex>
        </Card>
    )
}

export default CardUserDetail

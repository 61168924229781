import FormCreateAgencyType from '@components/form/agency/FormCreateAgencyType'
import PageLayout from '@components/page/PageLayout'

const CreateAgencyType = () => {
    return (
        <PageLayout pageTitle='สร้างประเภทหน่วยงาน'>
            <FormCreateAgencyType />
        </PageLayout>
    )
}

export default CreateAgencyType

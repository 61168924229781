import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import FormEditExpertise from '@components/form/expertise/FormEditExpertise'

import { useParams } from 'react-router-dom'
import Expertise from '@api/Expertise'
const EditExpertise = () => {
    const params = useParams()
    const expertise_id = params.expertise_id
    const { isLoading, isError, data, isRefetching } = Expertise.useGetExpertiseByID({
        expertise_id: expertise_id!,
    })
    if (isLoading || isRefetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return <PageNotFound404 label='กลับไปหน้าความเชี่ยวชาญทั้งหมด' url='/expertises' />
    }
    return (
        <PageLayout pageTitle='แก้ไขความเชี่ยวชาญ'>
            <FormEditExpertise data={data} />
        </PageLayout>
    )
}

export default EditExpertise

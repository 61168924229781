import { Flex } from '@mantine/core'
import React from 'react'
import { UseQueryResult } from 'react-query'
import TableStatusFilter from '@components/table/filter/TableStatusFilter'
import TableCheckOwnerFilter from '@components/table/filter/TableCheckOwnerFilter'
import TableSearchFilter from '@components/table/filter/TableSearchFilter'

interface ITableDefaultFilter<T> {
    lists: UseQueryResult<T, unknown>
    setsearch: (value: React.SetStateAction<string>) => void
    search: string
    setcheckowner: React.Dispatch<React.SetStateAction<number>>
    checkowner: number
    setpage: React.Dispatch<React.SetStateAction<number>>
    page: number
    setstatus: React.Dispatch<React.SetStateAction<string>>
    status: string
    children?: React.ReactNode
}
const TableFilter = <T,>({
    lists,
    setsearch,
    search,
    setcheckowner,
    checkowner,
    setpage,
    page,
    setstatus,
    status,
    children,
}: ITableDefaultFilter<T>) => {
    return (
        <Flex gap='md' direction={{ xs: 'column', lg: 'row' }}>
            <TableSearchFilter
                page={page}
                setpage={setpage}
                setsearch={setsearch}
                search={search}
                refresh={lists.refetch}
            />
            <TableCheckOwnerFilter
                {...{ setcheckowner, checkowner, setpage }}
            />
            <TableStatusFilter {...{ setpage, setstatus, status }} />
            {children}
        </Flex>
    )
}

export default TableFilter

import FormCreateProject from '@components/form/project/FormCreateProject'
import PageLayout from '@components/page/PageLayout'

const CreateProject = () => {
    return (
        <PageLayout pageTitle='สร้างโครงการ'>
            <FormCreateProject />
        </PageLayout>
    )
}

export default CreateProject

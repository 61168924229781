import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core'
interface ICancelButton extends ButtonProps {
    label?: string
}

const _CancelButton = ({
    label = 'ยกเลิก',
    classNames,
    styles,
    unstyled,
    className,
    ...others
}: ICancelButton) => {
    return (
        <Button
            className={className}
            color='gray'
            type='button'
            variant='default'
            w='fit-content'
            {...others}
        >
            ยกเลิก
        </Button>
    )
}

const CancelButton = createPolymorphicComponent<'button', ICancelButton>(
    _CancelButton
)
export default CancelButton

import TextChartTitle from '@components/text/TextChartTitle'
import { IResponseGetProjectDataForDashboardRowData } from '@interfaces/api/IDashboard'
import Money from '@utils/Money'
import _ from 'lodash'
import React from 'react'
import Chart from 'react-google-charts'
import ChartFlexSection from './ChartFlexSection'
import { PieChartOptions } from './setting'

interface IProjectBudgetByAgencyTypePieChart {
    title: string
    typeName: string
    valueName: string
    data: IResponseGetProjectDataForDashboardRowData[]
}

const ProjectBudgetByAgencyTypePieChart = ({
    title,
    typeName,
    valueName,
    data,
}: IProjectBudgetByAgencyTypePieChart) => {
    const prepareData = _(data)
        .groupBy('agency_type_name')
        .map((values, key) => {
            return {
                agency_type_name: key,
                total_project_amount: values.length,
                total_project_budget: _.sumBy(values, (p) =>
                    Number(p.project_budget)
                ),
            }
        })
        .value()

    const prepareDatawithAnnotation = prepareData.map((p) => [
        `${p.agency_type_name} (${p.total_project_amount} โครงการ)
        มูลค่า ${Money(p.total_project_budget)}`,
        p.total_project_budget,
    ])

    const ProjectBudgetByAgencyTypepieChartData = [
        [typeName, valueName],
        ...prepareDatawithAnnotation,
    ]

    const options = PieChartOptions(valueName, typeName)

    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <Chart
                width={'100%'}
                height={'500px'}
                chartType='PieChart'
                loader={<div>Loading Chart</div>}
                data={ProjectBudgetByAgencyTypepieChartData}
                options={options}
            />
        </ChartFlexSection>
    )
}

export default ProjectBudgetByAgencyTypePieChart

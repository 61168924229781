import Expertise from '@api/Expertise'
import CardLecturerExpertiseDetail from '@components/card/CardLecturerExpertiseDetail'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const ViewExpertise = () => {
    const params = useParams()
    const expertise_id = params.expertise_id
    const { isLoading, isFetching, isError, data } =
        Expertise.useGetExpertiseByID({
            expertise_id: expertise_id!,
        })
    if (isLoading || isFetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าความเชี่ยวชาญทั้งหมด'
                url='/lecturers'
            />
        )
    }
    return (
        <PageLayout pageTitle='ข้อมูลความเชี่ยวชาญ'>
            <CardLecturerExpertiseDetail data={data} />
        </PageLayout>
    )
}

export default ViewExpertise

import TableHeaderColumn from '@components/table/template/TableHeaderColumn'
import TextChartTitle from '@components/text/TextChartTitle'
import { IResponseGetProjectDataForDashboardRowData } from '@interfaces/api/IDashboard'
import { Flex, Rating, Table } from '@mantine/core'
import Money from '@utils/Money'
import ChartFlexSection from './ChartFlexSection'
interface IProjectRankingEvaluationChart {
    title: string
    data: IResponseGetProjectDataForDashboardRowData[]
}

const ProjectRankingEvaluationChart = ({
    title,
    data,
}: IProjectRankingEvaluationChart) => {
    data.sort(
        (a, b) =>
            Number(b.project_evaluation_result) -
            Number(a.project_evaluation_result)
    )
    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <Table pos={'relative'}>
                <thead>
                    <tr>
                        <TableHeaderColumn label={'อันดับ'} width={'3%'} />
                        <TableHeaderColumn label={'รหัสโครงการ'} width={'5%'} />
                        <TableHeaderColumn
                            label={'ชื่อโครงการ'}
                            width={'70%'}
                        />
                        <TableHeaderColumn
                            label={'ผลการประเมินความพึงพอใจ'}
                            width={'22%'}
                        />
                    </tr>
                </thead>
                <tbody>
                    {data.map((p, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{p.project_code}</td>
                            <td>{p.project_name}</td>
                            <td>
                                <Flex gap='lg'>
                                    {p.project_evaluation_result ? (
                                        <>
                                            {Money(
                                                Number(
                                                    p.project_evaluation_result
                                                )
                                            )}
                                            <Rating
                                                fractions={5}
                                                value={Number(
                                                    p.project_evaluation_result
                                                )}
                                                readOnly
                                            />
                                        </>
                                    ) : (
                                        'ยังไม่ประเมิน'
                                    )}
                                </Flex>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </ChartFlexSection>
    )
}

export default ProjectRankingEvaluationChart

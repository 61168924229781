import {
    Button,
    Flex,
    Grid,
    Image,
    List,
    Paper,
    Text,
    ThemeIcon,
    Title,
} from '@mantine/core'
import { IconCircleCheck } from '@tabler/icons-react'
import { Link } from 'react-router-dom'

const Home: React.FC = () => {
    const keywords = [
        'ติดตามความคืบหน้าโครงการ',
        'สรุปผลข้อมูลโครงการตามปีงบประมาณ',
        'คัดกรอง ตรวจสอบความถูกต้องข้อมูล',
    ]
    return (
        <Flex
            justify='center'
            align={{ md: 'center' }}
            w='100%'
            mih='100vh'
            bg='primary.0'
        >
            <Paper
                shadow='xl'
                bg='primary.8'
                h='fit-content'
                m='4%'
                p='xl'
                pos='relative'
                radius='lg'
            >
                <Grid gutter='lg'>
                    <Grid.Col span={12} md={7}>
                        <Flex direction='column' gap='md'>
                            <Flex wrap='wrap'>
                                <Title color='white'>
                                    Project Tracking System
                                </Title>
                            </Flex>
                            <Text size='lg' color='white'>
                                ระบบติดตามผลการดำเนินการโครงการ
                                <Flex wrap='wrap'>
                                    <Text mr='5px'>ศูนย์บริการวิชาการ</Text>
                                    <Text>มหาวิทยาลัยศรีนครินทรวิโรฒ</Text>
                                </Flex>
                            </Text>
                            <Paper p='lg' radius='md' bg='primary.9'>
                                <List
                                    spacing='xs'
                                    size='sm'
                                    center
                                    icon={
                                        <ThemeIcon
                                            color='teal'
                                            size={24}
                                            radius='xl'
                                        >
                                            <IconCircleCheck size='1rem' />
                                        </ThemeIcon>
                                    }
                                >
                                    {keywords.map((keyword) => (
                                        <List.Item key={keyword}>
                                            <Text color='white'>
                                                {' '}
                                                {keyword}
                                            </Text>
                                        </List.Item>
                                    ))}
                                </List>
                            </Paper>
                            <Flex gap='md' mt='xs'>
                                <Button
                                    to='/login'
                                    color='primary.4'
                                    component={Link}
                                    px='xl'
                                >
                                    เข้าสู่ระบบ
                                </Button>

                                <Button
                                    variant='light'
                                    px='xl'
                                    onClick={() => {
                                        window.location.href =
                                            'mailto:pnpspyksw@gmail.com?subject=แจ้งปัญหา/สอบถามเกี่ยวกับระบบ PTS เรื่อง'
                                    }}
                                >
                                    ติดต่อสอบถามเจ้าหน้าที่
                                </Button>
                            </Flex>
                        </Flex>
                    </Grid.Col>
                    <Grid.Col span={12} sm={10} md={5} p='xl'>
                        <Flex h='100%' align='center' justify='center'>
                            <Image
                                width='100%'
                                alt='CASSWU'
                                src='/assets/homegraphic.svg'
                            />
                        </Flex>
                    </Grid.Col>
                </Grid>
            </Paper>
        </Flex>
    )
}

export default Home

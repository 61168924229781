import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core'
import { Link, useNavigate } from 'react-router-dom'
import ChairmanOrganization from '@api/ChairmanOrganization'
import { IRequestCreateChairmanOrganization } from '@interfaces/api/IChairmanOrganization'

const FormCreateOrganization = ({
    aftersuccess,
}: {
    aftersuccess?: () => any
}) => {
    const form = useForm<IRequestCreateChairmanOrganization>({
        initialValues: {
            chairman_organization_name: '',
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } =
        ChairmanOrganization.useCreateChairmanOrganization({
            form,
            aftersuccess: () => navigate('/chairmans/organizations'),
        })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='สังกัดประธานโครงการ'
                        placeholder='สังกัดประธานโครงการ'
                        {...form.getInputProps('chairman_organization_name')}
                    />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/chairmans/organizations'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            สร้างสังกัดประธานโครงการ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormCreateOrganization

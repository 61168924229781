import * as XLSX from 'xlsx'

const excelFormatCellAuthFitWidth = (
    worksheet: XLSX.WorkSheet,
    data: { [key: string]: string }[]
) => {
    if (data.length) {
        const wchMap = Object.keys(data[0]).map((key, index) => {
            const value: number =
                String(data[0][key]).length < key.length
                    ? key.length
                    : String(data[0][key]).length
            const width =
                value < 20
                    ? value
                    : value > 50
                    ? Math.min(value + value / 4, 60)
                    : value
            return width
        })
        const columnWidths = wchMap.map((value) => ({ wch: value }))

        worksheet['!cols'] = columnWidths
    }
}
export default excelFormatCellAuthFitWidth

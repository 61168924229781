import FormCreateUser from '@components/form/user/FormCreateUser'
import PageLayout from '@components/page/PageLayout'
const CreateUser = () => {
    return (
        <PageLayout pageTitle='สร้างผู้ใช้งานระบบ'>
            <FormCreateUser />
        </PageLayout>
    )
}

export default CreateUser

import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, NumberInput, Paper } from '@mantine/core'
import Project from '@api/Project'
import { IRequestEvaluationProject } from '@interfaces/api/IProject'
import TextEvaluationBy from '@components/text/TextEvaluationBy'
import LoadingForm from '@components/loading/LoadingForm'

const FormEvaluationProject = ({
    project_id,
    editMode,
}: {
    project_id: number | string
    editMode: boolean
}) => {
    let projectinfo = Project.useGetProjectInfoByID({
        project_id: project_id!,
    })
    const form = useForm<IRequestEvaluationProject>({
        initialValues: {
            project_evaluation_personnel_result: projectinfo.data
                ?.project_evaluation_personnel_result
                ? Number(projectinfo?.data.project_evaluation_personnel_result)
                : '',
            project_evaluation_process_result: projectinfo.data
                ?.project_evaluation_process_result
                ? Number(projectinfo?.data.project_evaluation_process_result)
                : '',
            project_evaluation_quality_result: projectinfo.data
                ?.project_evaluation_quality_result
                ? Number(projectinfo?.data.project_evaluation_quality_result)
                : '',
            project_evaluation_facility_result: projectinfo.data
                ?.project_evaluation_facility_result
                ? Number(projectinfo?.data.project_evaluation_facility_result)
                : '',
            project_evaluation_result: projectinfo.data
                ?.project_evaluation_result
                ? Number(projectinfo?.data.project_evaluation_result)
                : '',
        },
    })

    const { isLoading, onSubmit } = Project.useEvaluationProject({
        project_id,
        form,
        aftersuccess: projectinfo.refetch,
    })
    if (projectinfo.isLoading) {
        return <LoadingForm numberInput={1} />
    }

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form
                onSubmit={onSubmit}
                style={{ pointerEvents: editMode ? 'all' : 'none' }}
            >
                <Flex gap='sm' direction='column'>
                    <NumberInput
                        withAsterisk
                        label='ด้านคุณภาพการให้บริการ'
                        placeholder='0.00'
                        defaultValue={0.0}
                        precision={2}
                        min={0.0}
                        step={0.5}
                        max={5}
                        {...form.getInputProps(
                            'project_evaluation_quality_result'
                        )}
                    />
                    <NumberInput
                        withAsterisk
                        label='ด้านกระบวนการ ขั้นตอนการให้บริการ'
                        placeholder='0.00'
                        defaultValue={0.0}
                        precision={2}
                        min={0.0}
                        step={0.5}
                        max={5}
                        {...form.getInputProps(
                            'project_evaluation_process_result'
                        )}
                    />
                    <NumberInput
                        withAsterisk
                        label='ด้านเจ้าหน้าที่ผู้ให้บริการ'
                        placeholder='0.00'
                        defaultValue={0.0}
                        precision={2}
                        min={0.0}
                        step={0.5}
                        max={5}
                        {...form.getInputProps(
                            'project_evaluation_personnel_result'
                        )}
                    />
                    <NumberInput
                        withAsterisk
                        label='ด้านสิ่งอำนวยความสะดวก'
                        placeholder='0.00'
                        defaultValue={0.0}
                        precision={2}
                        min={0.0}
                        step={0.5}
                        max={5}
                        {...form.getInputProps(
                            'project_evaluation_facility_result'
                        )}
                    />
                    <NumberInput
                        withAsterisk
                        label='ผลประเมินความพึงพอใจโครงการ'
                        placeholder='0.00'
                        defaultValue={0.0}
                        precision={2}
                        min={0.0}
                        step={0.5}
                        max={5}
                        {...form.getInputProps('project_evaluation_result')}
                    />
                    {editMode ? (
                        <Button type='submit' w='fit-content' mt='md' ml='auto'>
                            บันทึกการประเมิน
                        </Button>
                    ) : (
                        <Flex justify='end' gap='md'>
                            <TextEvaluationBy {...projectinfo.data!} />
                        </Flex>
                    )}
                </Flex>
            </form>
        </Paper>
    )
}

export default FormEvaluationProject

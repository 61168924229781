import BudgetYear from '@api/BudgetYear'
import CardBudgetYearDetail from '@components/card/CardBudgetYearDetail'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const ViewBudgetYear = () => {
    const params = useParams()
    const budget_year = params.budget_year
    const { isLoading, isFetching, isError, data } =
        BudgetYear.useGetBudgetYearByID({
            budget_year: budget_year!,
        })
    if (isLoading || isFetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าปีงบประมาณทั้งหมด'
                url='/budgetyears'
            />
        )
    }
    return (
        <PageLayout pageTitle='ข้อมูลปีงบประมาณ'>
            <CardBudgetYearDetail data={data}></CardBudgetYearDetail>
        </PageLayout>
    )
}

export default ViewBudgetYear

import InputAgencyType from '@components/form/input/InputAgencyType'
import InputBudgetYear from '@components/form/input/InputBudgetYear'
import api from '@config/api'
import {
    IRequestAllAgencies,
    IResponseAllAgencies,
    IResponseAllAgenciesRowData,
} from '@interfaces/api/IAgency'

import { Button, Flex, LoadingOverlay, Paper, Text } from '@mantine/core'
import { joiResolver, useForm } from '@mantine/form'
import { modals } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import { IconExclamationCircle } from '@tabler/icons-react'
import mapAgencyTable from '@utils/excel_map_functions/mapAgencyTable'
import exportTableToExcel from '@utils/exportTableToExcel'
import Joi from 'joi'
import React, { useState } from 'react'
import ModalDownloadButton from './ModalDownloadButton'

const ModalDownloadAgencyToExcel = () => {
    const openModal = () => {
        modals.open({
            closeOnClickOutside: false,
            closeOnEscape: false,
            withCloseButton: false,
            title: <Text fw='bold'>ดาวน์โหลดข้อมูลลูกค้า</Text>,
            children: <ModalBody />,
        })
    }

    return <ModalDownloadButton onClick={openModal} />
}

export default ModalDownloadAgencyToExcel

const ModalBody = () => {
    const [isLoading, setIsLoading] = useState(false)
    const form = useForm<IRequestAllAgencies>({
        initialValues: {
            limit: 100,
            orderby: 'agency_id',
            order: 'asc',
            search: '',
            status: '',
            has_enabled: 0,
            budget_year: '',
            page: 1,
            checkowner: 0,
            agency_type_id: 0,
        },
        validate: joiResolver(schema),
    })
    const LoadData = async (values: IRequestAllAgencies) => {
        try {
            let page = 1
            let maxPage = 1

            let bulkData: IResponseAllAgenciesRowData[] = []

            while (page <= maxPage) {
                const reqbody: IRequestAllAgencies = {
                    ...values,
                    page: page,
                }
                const res = await api.get('/agencies', { params: reqbody })
                const data = res.data.body as IResponseAllAgencies
                maxPage = data.totalPages
                bulkData = [...bulkData, ...data.lists]
                page += 1

                await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait for 1 second
            }

            return bulkData
        } catch (err) {
            throw err
        }
    }

    const onSubmit = async (values: IRequestAllAgencies) => {
        setIsLoading(true)
        try {
            const data = await LoadData(values)

            if (!data?.length) {
                throw new Error('ไม่พบข้อมูล')
            }
            exportTableToExcel(data, mapAgencyTable, 'ข้อมูลลูกค้า.xlsx')

            modals.closeAll()
        } catch (error) {
            modals.closeAll()
            notifications.show({
                title: 'เกิดข้อผิดพลาด',
                message: 'ไม่พบข้อมูล',
                color: 'red',
                icon: <IconExclamationCircle size='1rem' />,
            })
        }
        setIsLoading(false)
    }

    return (
        <>
            <Text size='sm' c='gray.7'>
                กรุณากรอกช่องกรอกเพื่อคัดกรองข้อมูลที่ต้องการจะดาวน์โหลด
            </Text>
            <Paper mt='md'>
                <LoadingOverlay visible={isLoading} overlayBlur={2} />
                <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                    <Flex gap='sm' direction='column'>
                        <InputBudgetYear
                            maxDropdownHeight={130}
                            {...form.getInputProps('budget_year')}
                        />
                        <InputAgencyType
                            maxDropdownHeight={120}
                            {...form.getInputProps('agency_type_id')}
                        />
                        <Flex mt='md' justify='end' gap='sm'>
                            <Button
                                onClick={() => modals.closeAll()}
                                variant='default'
                                w='fit-content'
                            >
                                ยกเลิก
                            </Button>
                            <Button type='submit' w='fit-content'>
                                ดาวน์โหลดข้อมูล
                            </Button>
                        </Flex>
                    </Flex>
                </form>
            </Paper>
        </>
    )
}

const schema = Joi.object().keys({
    limit: Joi.number().required().max(100),
    orderby: Joi.any().required(),
    order: Joi.string().required().valid('asc', 'desc'),
    page: Joi.number().required().min(1),
    search: Joi.string().required().allow(''),
    status: Joi.number().valid(0, 1, 2).allow(null, ''),
    has_enabled: Joi.number().valid(0, 1),
    checkowner: Joi.number().required().valid(1, 0),
    budget_year: Joi.number().allow(0, null, ''),
    agency_type_id: Joi.number().allow('', null),
})

import { useForm } from '@mantine/form'
import {
    Button,
    Flex,
    LoadingOverlay,
    Paper,
    Textarea,
    TextInput,
} from '@mantine/core'

import {
    IRequestUpdateAgency,
    IResponseGetAgencyByID,
} from '@interfaces/api/IAgency'
import Agency from '@api/Agency'
import InputBudgetYear from '@components/form/input/InputBudgetYear'
import InputAgencyType from '@components/form/input/InputAgencyType'
import { useNavigate } from 'react-router-dom'
import InputStatus from '../input/InputStatus'

const FormEditAgency = ({ data }: { data: IResponseGetAgencyByID }) => {
    const form = useForm<IRequestUpdateAgency>({
        initialValues: {
            agency_name: data.agency_name,
            budget_year: data.budget_year,
            agency_type_id: data.agency_type_id,
            agency_address: data.agency_address ?? '',
            agency_detail: data.agency_detail ?? '',
            agency_email: data.agency_email ?? '',
            agency_phone: data.agency_phone ?? '',
            status: data.status,
        },
    })
    const navigate = useNavigate()

    const onAfterSuccess = () => {
        navigate('/agencies')
    }

    const { isLoading, onSubmit } = Agency.useUpdateAgency({
        agency_id: data.agency_id,
        form,
        aftersuccess: onAfterSuccess,
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อหน่วยงาน'
                        placeholder='ชื่อหน่วยงาน'
                        {...form.getInputProps('agency_name')}
                    />
                    <InputAgencyType
                        withAsterisk
                        {...form.getInputProps('agency_type_id')}
                    />
                    <InputBudgetYear
                        withAsterisk
                        {...form.getInputProps('budget_year')}
                    />
                    <TextInput
                        label='อีเมล'
                        placeholder='อีเมล'
                        {...form.getInputProps('agency_email')}
                    />
                    <TextInput
                        label='เบอร์โทร'
                        placeholder='เบอร์โทร'
                        {...form.getInputProps('agency_phone')}
                    />

                    <TextInput
                        label='ที่อยู่'
                        placeholder='ที่อยู่'
                        {...form.getInputProps('agency_address')}
                    />
                    <Textarea
                        rows={2}
                        minRows={2}
                        label='เกี่ยวกับหน่วยงาน'
                        placeholder='เกี่ยวกับหน่วยงาน'
                        {...form.getInputProps('agency_detail')}
                    />
                    <InputStatus {...form.getInputProps('status')} />
                    <Flex mt='md' justify='end' gap='sm'>
                        <Button type='submit' w='fit-content'>
                            บันทึกการแก้ไขหน่วยงาน
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormEditAgency

import { joiResolver, useForm } from '@mantine/form'
import {
    IRequestUpdateProjectInfo,
    IResponseProjectInfo,
} from '@interfaces/api/IProject'
import {
    Button,
    Checkbox,
    Flex,
    LoadingOverlay,
    NumberInput,
    Paper,
    Text,
    Textarea,
    TextInput,
} from '@mantine/core'
import { DateValue } from '@mantine/dates'

import InputBudgetYear from '@components/form/input/InputBudgetYear'
import { ProjectCodeGenerate } from '@utils/ProjectCodeGenerate'
import InputDate from '@components/form/input/InputDate'
import dayjs from 'dayjs'
import InputCoordinator from '../input/InputCoordinator'
import InputChairman from '../input/InputChairman'
import InputAgency from '../input/InputAgency'
import InputCurrency from '../input/InputCurrency'
import Project from '@api/Project'
import { useEffect, useState } from 'react'
import Agency from '@api/Agency'
import { updateproject } from './projectFormSchema'
import InputProjectType from '../input/InputProjectType'
import InputExpertises from '../input/InputExpertises'

const FormViewAndUpdateProjectInfo = ({
    data,
    refetchProjectInfo,
    editMode,
}: {
    data: IResponseProjectInfo
    refetchProjectInfo: () => void
    editMode: boolean
}) => {
    const agencies = Agency.useGetAllAgencies({})

    const [isAgencyTypeRegister, setIsAgencyTypeRegister] = useState(false)

    const form = useForm<IRequestUpdateProjectInfo>({
        initialValues: {
            project_no: data.project_no,
            budget_year: data.budget_year,
            project_promise_no: data.project_promise_no
                ? data.project_promise_no
                : '',
            project_name: data.project_name,
            project_type_id: data.project_type_id,
            project_expertises: data.project_expertises_id
                ? String(data.project_expertises_id).split(',')
                : [],
            project_detail: data.project_detail ?? '',
            project_start_date: data.project_start_date,
            project_end_date: data.project_end_date,
            project_duration: data.project_duration ?? '',
            project_budget: Number(data.project_budget),
            project_period_amount: data.project_period_amount ?? '',
            financial_report_submission_date:
                data.financial_report_submission_date,
            has_project_chair_agreement:
                data.has_project_chair_agreement === 1 ? true : false,
            has_letter_to_dean_about_chairman:
                data.has_letter_to_dean_about_chairman === 1 ? true : false,
            rector_project_approval_date: data.rector_project_approval_date,
            rector_signing_employment_contract_request_date:
                data.rector_signing_employment_contract_request_date,
            rector_budget_adjustment_request_date:
                data.rector_budget_adjustment_request_date,
            rector_budget_detail_adjustment_request_date:
                data.rector_budget_detail_adjustment_request_date,
            rector_project_name_change_request_date:
                data.rector_project_name_change_request_date,
            rector_project_period_extension_request_date:
                data.rector_project_period_extension_request_date,
            old_project_budget: data.old_project_budget,
            old_project_name: data.old_project_name
                ? data.old_project_name
                : '',
            old_project_duration: data.old_project_duration,
            project_other_note: data.project_other_note
                ? data.project_other_note
                : '',
            chairman_id: data.chairman_id ?? '',
            coordinator_id: data.coordinator_id ?? '',
            agency_id: data.agency_id ?? '',
        },
        validate: isAgencyTypeRegister ? undefined : joiResolver(updateproject),
    })

    const onProjectStartDateChange = (value: DateValue) => {
        if (form.getInputProps('project_duration').value && value) {
            const end_date = dayjs(value)
                .add(form.getInputProps('project_duration').value, 'day')
                .toDate()
            form.setFieldValue('project_end_date', end_date as any)
        }
        return form.setFieldValue('project_start_date', value)
    }

    const onProjectDurationChange = (value: number) => {
        if (form.getInputProps('project_start_date').value && value) {
            const start_date = form.getInputProps('project_start_date').value
            const new_date = dayjs(start_date).add(value, 'day').toDate()
            form.setFieldValue('project_end_date', new_date)
        }
        return form.setFieldValue('project_duration', value)
    }

    const onProjectEndDateChange = (value: DateValue) => {
        if (form.getInputProps('project_start_date').value && value) {
            const start_date = dayjs(
                form.getInputProps('project_start_date').value
            )
            const end_date = dayjs(value)
            const duration = end_date.diff(start_date, 'day')
            form.setFieldValue('project_duration', duration)
        }
        return form.setFieldValue('project_end_date', value)
    }

    const { onSubmit, isLoading } = Project.useUpdateProjectInfo({
        project_id: data.project_id,
        form,
        aftersuccess: refetchProjectInfo,
    })

    useEffect(() => {
        if (agencies?.data?.lists?.length) {
            const agency = agencies.data.lists.filter(
                (a) => a.agency_id === Number(form.values.agency_id)
            )
            if (agency?.length) {
                if (agency[0].agency_type_name.includes('ลงทะเบียน')) {
                    setIsAgencyTypeRegister(true)
                } else {
                    setIsAgencyTypeRegister(false)
                }
            }
        }
    }, [form.values.agency_id, agencies])

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form
                onSubmit={onSubmit}
                style={{ pointerEvents: editMode ? 'all' : 'none' }}
            >
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อโครงการ'
                        placeholder='ชื่อโครงการ'
                        {...form.getInputProps('project_name')}
                    />
                    <InputBudgetYear {...form.getInputProps('budget_year')} />
                    <NumberInput
                        withAsterisk
                        label='ลำดับโครงการ'
                        placeholder='ลำดับโครงการ'
                        {...form.getInputProps('project_no')}
                    />
                    <TextInput
                        withAsterisk
                        label='รหัสโครงการ'
                        placeholder='รหัสโครงการ'
                        value={ProjectCodeGenerate(
                            form.getInputProps('budget_year').value,
                            form.getInputProps('project_no').value
                        )}
                        disabled
                    />
                    <TextInput
                        withAsterisk
                        label='เลขที่สัญญา'
                        placeholder='เลขที่สัญญา'
                        {...form.getInputProps('project_promise_no')}
                    />
                    <InputAgency
                        withAsterisk
                        editMode={editMode}
                        {...form.getInputProps('agency_id')}
                    />
                    <InputCoordinator
                        withAsterisk
                        editMode={editMode}
                        {...form.getInputProps('coordinator_id')}
                    />
                    <InputChairman
                        withAsterisk
                        editMode={editMode}
                        {...form.getInputProps('chairman_id')}
                    />
                    <InputProjectType
                        withAsterisk
                        {...form.getInputProps('project_type_id')}
                    />
                    <InputExpertises
                        label='สาขาและความเชี่ยวชาญ'
                        {...form.getInputProps('project_expertises')}
                    />
                    <Textarea
                        withAsterisk={!isAgencyTypeRegister}
                        rows={10}
                        minRows={5}
                        label='รายละเอียดโครงการ'
                        placeholder='รายละเอียดโครงการ'
                        {...form.getInputProps('project_detail')}
                    />
                    <InputDate
                        clearable={editMode}
                        withAsterisk={!isAgencyTypeRegister}
                        label='วันเริ่มโครงการ'
                        placeholder='วันเริ่มโครงการ'
                        {...form.getInputProps('project_start_date')}
                        onChange={onProjectStartDateChange}
                    />
                    <NumberInput
                        withAsterisk={!isAgencyTypeRegister}
                        label='ระยะเวลาโครงการ'
                        placeholder='ลำดับโครงการ'
                        {...form.getInputProps('project_duration')}
                        onChange={onProjectDurationChange}
                    />
                    <InputDate
                        clearable={editMode}
                        withAsterisk={!isAgencyTypeRegister}
                        label='วันสิ้นสุดโครงการ'
                        placeholder='วันสิ้นสุดโครงการ'
                        {...form.getInputProps('project_end_date')}
                        onChange={onProjectEndDateChange}
                        minDate={form.getInputProps('project_start_date').value}
                    />
                    <InputCurrency
                        withAsterisk={!isAgencyTypeRegister}
                        label='งบประมาณ'
                        {...form.getInputProps('project_budget')}
                    />
                    <NumberInput
                        withAsterisk={!isAgencyTypeRegister}
                        label='จำนวนงวด'
                        placeholder='จำนวนงวด'
                        {...form.getInputProps('project_period_amount')}
                    />
                    <InputDate
                        clearable={editMode}
                        label='วันที่อธิการบดีอนุมัติโครงการ'
                        placeholder='วันที่อธิการบดีอนุมัติโครงการ'
                        {...form.getInputProps('rector_project_approval_date')}
                    />
                    <InputDate
                        clearable={editMode}
                        label='วันที่แจ้งอธิการบดี เรื่อง ลงนามสัญญาจ้าง'
                        placeholder='วันที่แจ้งอธิการบดี เรื่อง ลงนามสัญญาจ้าง'
                        {...form.getInputProps(
                            'rector_signing_employment_contract_request_date'
                        )}
                    />
                    <InputDate
                        clearable={editMode}
                        label='วันที่ส่งรายงานการเงิน'
                        placeholder='วันที่ส่งรายงานการเงิน'
                        {...form.getInputProps(
                            'financial_report_submission_date'
                        )}
                    />
                    <Flex gap='md' wrap='wrap' mt='sm'>
                        <Checkbox
                            label='มีข้อตกลงประธานโครงการ'
                            checked={
                                form.getInputProps(
                                    'has_project_chair_agreement'
                                ).value
                            }
                            {...form.getInputProps(
                                'has_project_chair_agreement'
                            )}
                        />
                        <Checkbox
                            label='มีหนังสือแจ้งคณบดี เรื่อง ประธานโครงการ'
                            checked={
                                form.getInputProps(
                                    'has_letter_to_dean_about_chairman'
                                ).value
                            }
                            {...form.getInputProps(
                                'has_letter_to_dean_about_chairman'
                            )}
                        />
                    </Flex>
                    <Text fw='500' mt='md'>
                        หมายเหตุ
                    </Text>
                    <InputCurrency
                        label='งบประมาณเดิม'
                        {...form.getInputProps('old_project_budget')}
                    />
                    <InputDate
                        clearable={editMode}
                        label='วันที่ส่งหนังสือแจ้งอธิการบดี เรื่อง ขอปรับงบประมาณ'
                        placeholder='วันที่ส่งรายงานการเงิน'
                        {...form.getInputProps(
                            'rector_budget_adjustment_request_date'
                        )}
                    />
                    <InputDate
                        clearable={editMode}
                        label='วันที่ส่งหนังสือแจ้งอธิการบดี เรื่อง ขอปรับรายระเอียดงบประมาณ'
                        placeholder='วันที่ส่งรายงานการเงิน'
                        {...form.getInputProps(
                            'rector_budget_detail_adjustment_request_date'
                        )}
                    />
                    <TextInput
                        label='ชื่อโครงการเดิม'
                        placeholder='ชื่อโครงการเดิม'
                        {...form.getInputProps('old_project_name')}
                    />
                    <InputDate
                        clearable={editMode}
                        label='วันที่ส่งหนังสือแจ้งอธิการบดี เรื่อง เปลี่ยนแปลงชื่อโครงการ'
                        placeholder='ส่งหนังสือแจ้งอธิการบดี เรื่อง เปลี่ยนแปลงชื่อโครงการ'
                        {...form.getInputProps(
                            'rector_project_name_change_request_date'
                        )}
                    />

                    <NumberInput
                        label='ระยะเวลาโครงการเดิม'
                        placeholder='ระยะเวลาโครงการเดิม'
                        {...form.getInputProps('old_project_duration')}
                        value={
                            form.getInputProps('old_project_duration').value ===
                            null
                                ? undefined
                                : form.getInputProps('old_project_duration')
                                      .value
                        }
                    />
                    <InputDate
                        clearable={editMode}
                        label='วันที่ส่งหนังสือแจ้งอธิการบดี เรื่อง ขอเปลี่ยนระยะเวลาโครงการ'
                        placeholder='ส่งหนังสือแจ้งอธิการบดี เรื่อง ขอเปลี่ยนระยะเวลาโครงการ'
                        {...form.getInputProps(
                            'rector_project_period_extension_request_date'
                        )}
                    />
                    <Textarea
                        rows={10}
                        minRows={5}
                        label='หมายเหตุอื่น ๆ'
                        placeholder='หมายเหตุอื่น ๆ'
                        {...form.getInputProps('project_other_note')}
                    />

                    {editMode && (
                        <Button type='submit' w='fit-content' mt='md' ml='auto'>
                            บันทึกการแก้ไขโครงการ
                        </Button>
                    )}
                </Flex>
            </form>
        </Paper>
    )
}

export default FormViewAndUpdateProjectInfo

import api from '@config/api'
import { useQuery } from 'react-query'
import { useQueryConfig } from './useQueryConfig'
import {
    IProjectTypeID,
    IRequestCreateProjectType,
    IRequestGetAllProjectTypes,
    IRequestUpdateProjectTypeByID,
    IResponseGetAllProjectTypes,
    IResponseGetProjectTypeByID,
} from '@interfaces/api/IProjectType'
import { IFormMutationHandlerProps } from '@interfaces/default/IDefault'
import { useFormMutationHandler } from '@utils/useFormMutationHandler'

const API_URL = '/projects/types'

interface IUseCreate
    extends IFormMutationHandlerProps<IRequestCreateProjectType> {}

interface IUseUpdateByID
    extends IProjectTypeID,
        IFormMutationHandlerProps<IRequestUpdateProjectTypeByID> {}

interface IUseDeleteByID
    extends IProjectTypeID,
        IFormMutationHandlerProps<IProjectTypeID> {}

const ProjectType = {
    useGetAll: ({
        limit = 100,
        orderby = 'project_type_id',
        order = 'desc',
        page = 1,
        search = '',
        has_enabled,
        checkowner = 0,
        status = '',
    }: IRequestGetAllProjectTypes) =>
        useQuery(
            'allprojecttype' + orderby,
            async () => {
                try {
                    const res = await api.get(API_URL, {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                            has_enabled,
                            checkowner,
                            status,
                        },
                    })
                    return res.data.body as IResponseGetAllProjectTypes
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useGetByID: ({ project_type_id }: IProjectTypeID) =>
        useQuery(
            'getprojecttypebyid' + project_type_id,
            async () => {
                try {
                    const res = await api.get(`${API_URL}/${project_type_id}`)
                    return res.data.body as IResponseGetProjectTypeByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useCreate: ({ form, aftersuccess }: IUseCreate) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [API_URL, 'post'],
            aftersuccess,
        })
    },
    useUpdateByID: ({
        form,
        project_type_id,
        aftersuccess,
    }: IUseUpdateByID) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`${API_URL}/${project_type_id}`, 'put'],
            aftersuccess,
        })
    },

    useDeleteByID: ({ project_type_id, aftersuccess }: IUseDeleteByID) => {
        return useFormMutationHandler({
            pathWithMethod: [`${API_URL}/${project_type_id}`, 'delete'],
            aftersuccess,
        })
    },
}

export default ProjectType

import { Card, Flex, Title } from '@mantine/core'
import TextParameter from '../text/TextParameter'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'
import { IResponseGetVendorTypeByID } from '@interfaces/api/IVendor'

const CardVendorTypeDetail = ({
    data,
}: {
    data: IResponseGetVendorTypeByID
}) => {
    const textparameters = [
        {
            title: 'สถานะข้อมูล',
            info: data.status_description,
        },
    ]
    return (
        <Card key={data.vendor_type_id} shadow='md' radius='md' padding='md'>
            <Flex gap='5px' direction='column'>
                <Title order={5}>{data.vendor_type_name}</Title>
                {textparameters.map((value) => (
                    <TextParameter
                        key={value.title}
                        title={value.title}
                        info={value.info!}
                    />
                ))}
                <TextCreatedBy {...data} />
                <TextUpdatedBy {...data} />
            </Flex>
        </Card>
    )
}

export default CardVendorTypeDetail

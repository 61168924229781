import { menus } from '@config/menus'
import { AuthContext } from '@context/AuthContext'
import {
    createStyles,
    Flex,
    Navbar,
    rem,
    Stack,
    Tooltip,
    UnstyledButton,
} from '@mantine/core'
import { IconLogout, IconMenu2 } from '@tabler/icons-react'
import { authPrivilege } from '@utils/authprivilege.lib'
import { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Logo } from './Logo'
const useStyles = createStyles((theme) => ({
    navbar: {
        paddingBottom: 0,
        padding: '1rem',
        maxHeight: '100%',
        overflowY: 'auto',
    },

    header: {
        padding: theme.spacing.md,
        paddingTop: 0,
        marginLeft: `calc(${theme.spacing.md} * -1)`,
        marginRight: `calc(${theme.spacing.md} * -1)`,
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },

    links: {
        marginLeft: `calc(${theme.spacing.md} * -1)`,
        marginRight: `calc(${theme.spacing.md} * -1)`,
    },

    menuburgur: {
        width: rem(50),
        height: rem(50),
        borderRadius: theme.radius.md,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.white,
        opacity: 0.85,
        backgroundColor: theme.colors['primary'][6],
        variant: 'filled',
    },

    linksInner: {
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
    },

    footer: {
        marginLeft: `calc(${theme.spacing.md} * -1)`,
        marginRight: `calc(${theme.spacing.md} * -1)`,
        borderTop: `${rem(1)} solid ${
            theme.colorScheme === 'dark'
                ? theme.colors.dark[4]
                : theme.colors.gray[3]
        }`,
    },
    link: {
        // width: rem(50),
        textAlign: 'start',
        height: rem(50),
        borderRadius: theme.radius.md,
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        color: theme.white,
        opacity: 0.85,

        '&:hover': {
            opacity: 1,
            backgroundColor: theme.fn.lighten(
                theme.fn.variant({
                    variant: 'filled',
                    color: theme.primaryColor,
                }).background!,
                0.1
            ),
        },
    },

    active: {
        opacity: 1,
        '&, &:hover': {
            backgroundColor: theme.fn.lighten(
                theme.fn.variant({
                    variant: 'filled',
                    color: theme.primaryColor,
                }).background!,
                0.15
            ),
        },
    },
}))

const SideBarMenu = () => {
    const { classes } = useStyles()
    const [hide, setHide] = useState(false)
    const location = useLocation()
    const { isAuthenticated, logout, auth } = useContext(AuthContext)
    if (isAuthenticated() === false || location.pathname === '/') {
        return <></>
    }

    const onlyshowmenu = menus.filter(
        (value) => typeof value.label === 'string' && value.icon !== undefined
    )
    const onlyshowmenubyprivilege = onlyshowmenu.filter(
        (menu) =>
            menu.privilege === undefined ||
            authPrivilege(menu.privilege, auth?.privilege_id!)
    ) as {
        icon: React.FC<any>
        label: string
        path: string
    }[]

    const links = onlyshowmenubyprivilege.map((link, index) => (
        <NavbarLink
            {...link}
            hide={hide}
            key={link.label}
            active={location.pathname === link.path}
        />
    ))
    return (
        <Navbar
            id='sidebar'
            pos='sticky'
            top={0}
            bg='primary.9'
            className={classes.navbar}
            miw={hide ? '87px' : '300px'}
            w={hide ? '87px' : '300px'}
        >
            <Navbar.Section className={classes.header}>
                <Flex
                    pos='relative'
                    gap='md'
                    wrap='nowrap'
                    align='center'
                    h='50px'
                >
                    {hide === false && <Logo width={rem(120)} />}
                    <UnstyledButton
                        onClick={() => setHide(!hide)}
                        className={classes.menuburgur}
                    >
                        <IconMenu2 />
                    </UnstyledButton>
                </Flex>
            </Navbar.Section>
            <Navbar.Section grow mt={10}>
                <Stack justify='center' spacing={0}>
                    {links}
                </Stack>
            </Navbar.Section>
            <Navbar.Section>
                <Stack justify='center' spacing={0}>
                    <NavbarLink
                        onClick={logout}
                        hide={hide}
                        label='ออกจากระบบ'
                        path='#'
                        icon={IconLogout}
                    />
                </Stack>
            </Navbar.Section>
        </Navbar>
    )
}

export default SideBarMenu

interface NavbarLinkProps {
    icon: React.FC<any>
    label: string
    active?: boolean
    path: string
    hide: boolean
    onClick?(): void
}

function NavbarLink({
    icon: Icon,
    label,
    path,
    active,
    hide,
    onClick,
}: NavbarLinkProps) {
    const { classes, cx } = useStyles()
    return (
        <Tooltip
            label={label}
            pos='fixed'
            zIndex={300}
            position='right'
            hidden={hide === true ? false : true}
            transitionProps={{ duration: 0 }}
        >
            <UnstyledButton
                component={Link}
                to={path}
                onClick={onClick}
                className={cx(classes.link, { [classes.active]: active })}
            >
                <Flex gap='sm' p='md' w='100%' align='center'>
                    <Icon size='1.2rem' stroke={1.5} />
                    {hide === false ? label : ''}
                </Flex>
            </UnstyledButton>
        </Tooltip>
    )
}

import PageLayout from '@components/page/PageLayout'
import TableAllCoordinator from '@components/table/database/TableAllCoordinator'

const AllCoordinator = () => {
    return (
        <PageLayout pageTitle='ผู้ประสานงาน'>
            <TableAllCoordinator />
        </PageLayout>
    )
}

export default AllCoordinator

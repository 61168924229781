import ProgressBadge from '@components/badge/ProgressBadge'

const ProjectProgressColumn = ({
    project_progress_status_name,
    project_progress,
    project_progress_status_color,
}: {
    project_progress_status_name: string
    project_progress: number
    project_progress_status_color: string
}) => {
    return (
        <td>
            <ProgressBadge
                progress_color={project_progress_status_color}
                progress_label={project_progress_status_name}
                progress_score={project_progress}
            />
        </td>
    )
}

export default ProjectProgressColumn

import dayjs from '@config/dayjs'
import { Text } from '@mantine/core'
const DateColumn = ({ day }: { day: string }) => {
    if (!day) {
        return <td>-</td>
    }
    return (
        <td>
            <Text>{dayjs(day).fromNow()}</Text>
        </td>
    )
}

export default DateColumn

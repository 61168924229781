import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
interface MyComponentProps extends ButtonProps {}

const _ModalDownloadButton = ({
    classNames,
    styles,
    unstyled,
    className,
    ...others
}: MyComponentProps) => {
    return (
        <Button
            className={className}
            leftIcon={<IconDownload size={'1rem'} />}
            size='sm'
            px='8px'
            h={'36px'}
            mt='auto'
            {...others}
        >
            ดาวน์โหลดข้อมูล
        </Button>
    )
}

const ModalDownloadButton = createPolymorphicComponent<
    'button',
    MyComponentProps
>(_ModalDownloadButton)
export default ModalDownloadButton

import PageLayout from '@components/page/PageLayout'
import TableAllBudgetYear from '@components/table/database/TableAllBudgetYear'

const AllBudgetYear = () => {
    return (
        <PageLayout pageTitle='ปีงบประมาณ'>
            <TableAllBudgetYear />
        </PageLayout>
    )
}

export default AllBudgetYear

import FormCreateExpertise from '@components/form/expertise/FormCreateExpertise'
import PageLayout from '@components/page/PageLayout'
const CreateExpertise = () => {
    return (
        <PageLayout pageTitle='สร้างความเชี่ยวชาญ'>
            <FormCreateExpertise />
        </PageLayout>
    )
}

export default CreateExpertise

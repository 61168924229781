import TableHeaderColumn from '@components/table/template/TableHeaderColumn'
import TextChartTitle from '@components/text/TextChartTitle'
import { Table } from '@mantine/core'
import React from 'react'
import ChartFlexSection from './ChartFlexSection'
interface ITableListChart {
    title: string
    th: { label: string; width: string }[]
    children: React.ReactNode
}

const TableListChart = ({ title, children, th }: ITableListChart) => {
    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <Table pos={'relative'}>
                <thead>
                    <tr>
                        {th.map((t, index) => (
                            <TableHeaderColumn
                                key={index}
                                label={t.label}
                                width={t.width}
                            />
                        ))}
                    </tr>
                </thead>
                <tbody>{children}</tbody>
            </Table>
        </ChartFlexSection>
    )
}

export default TableListChart

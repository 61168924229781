import Coordinator from '@api/Coordinator'
import FormEditCoordinator from '@components/form/coordinator/FormEditCoordinator'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const EditCoordinator = () => {
    const params = useParams()
    const coordinator_id = params.coordinator_id
    const { isLoading, isError, data, isRefetching } =
        Coordinator.useGetCoordinatorByID({
            coordinator_id: coordinator_id!,
        })
    if (isLoading || isRefetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าผู้ประสานงานทั้งหมด'
                url='/coordinators'
            />
        )
    }
    return (
        <PageLayout pageTitle='แก้ไขผู้ประสานงาน'>
            <FormEditCoordinator data={data} />
        </PageLayout>
    )
}

export default EditCoordinator

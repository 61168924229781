import InputAgency from '@components/form/input/InputAgency'
import InputAgencyType from '@components/form/input/InputAgencyType'
import InputBudgetYear from '@components/form/input/InputBudgetYear'
import InputChairman from '@components/form/input/InputChairman'
import InputCoordinator from '@components/form/input/InputCoordinator'
import InputProjectProgressStatus from '@components/form/input/InputProjectProgressStatus'
import InputUser from '@components/form/input/InputUser'
import api from '@config/api'
import { AuthContext } from '@context/AuthContext'
import {
    IRequestDownloadProjectData,
    IResponseDownloadProjectData,
    IResponseDownloadProjectDataRowData,
    IResponseDownloadProjectLecturerData,
    IResponseDownloadProjectLecturerDataRowData,
    IResponseDownloadProjectPeriodData,
    IResponseDownloadProjectPeriodDataRowData,
    IResponseDownloadProjectVendorData,
    IResponseDownloadProjectVendorDataRowData,
} from '@interfaces/api/IDownload'
import { Button, Flex, LoadingOverlay, Paper, Text } from '@mantine/core'
import { joiResolver, useForm } from '@mantine/form'
import { modals } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import { IconExclamationCircle } from '@tabler/icons-react'
import exportDownloadProjectDataToExcel from '@utils/exportDownloadProjectDataToExcel'
import Joi from 'joi'
import React, { useContext, useState } from 'react'
import ModalDownloadButton from './ModalDownloadButton'

const ModalDownloadProjectToExcel = () => {
    const openDownloadProjectData = () => {
        modals.open({
            closeOnClickOutside: false,
            closeOnEscape: false,
            withCloseButton: false,
            title: <Text fw='bold'>ดาวน์โหลดข้อมูลโครงการ</Text>,
            children: <ModalBody />,
        })
    }

    return <ModalDownloadButton onClick={openDownloadProjectData} />
}

export default ModalDownloadProjectToExcel

const ModalBody = () => {
    const [isLoading, setIsLoading] = useState(false)
    const { auth } = useContext(AuthContext)

    const form = useForm<IRequestDownloadProjectData>({
        initialValues: {
            agency_id: '',
            agency_type_id: '',
            coordinator_id: '',
            chairman_id: '',
            budget_year: '',
            project_progress_status_id: '',
            page: 1,
            checkowner: '',
            created_by: '',
        },
        validate: joiResolver(schema),
    })
    const LoadProjectData = async (values: IRequestDownloadProjectData) => {
        try {
            let page = 1
            let maxPage = 1

            let bulkData: IResponseDownloadProjectDataRowData[] = []

            while (page <= maxPage) {
                const reqbody: IRequestDownloadProjectData = {
                    ...values,
                    page: page,
                }
                const res = await api.post('/download/projects', reqbody)
                const data = res.data.body as IResponseDownloadProjectData
                maxPage = data.totalPages
                bulkData = [...bulkData, ...data.lists]
                page += 1

                await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait for 1 second
            }

            return bulkData
        } catch (err) {
            return []
        }
    }
    const LoadProjectPeriodData = async (
        values: IRequestDownloadProjectData
    ) => {
        try {
            let page = 1
            let maxPage = 1

            let bulkData: IResponseDownloadProjectPeriodDataRowData[] = []

            while (page <= maxPage) {
                const reqbody: IRequestDownloadProjectData = {
                    ...values,
                    page: page,
                }
                const res = await api.post(
                    '/download/projects/periods',
                    reqbody
                )
                const data = res.data.body as IResponseDownloadProjectPeriodData
                maxPage = data.totalPages
                bulkData = [...bulkData, ...data.lists]
                page += 1

                await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait for 1 second
            }

            return bulkData
        } catch (err) {
            return []
        }
    }
    const LoadProjectLecturerData = async (
        values: IRequestDownloadProjectData
    ) => {
        try {
            let page = 1
            let maxPage = 1

            let bulkData: IResponseDownloadProjectLecturerDataRowData[] = []

            while (page <= maxPage) {
                const reqbody: IRequestDownloadProjectData = {
                    ...values,
                    page: page,
                }
                const res = await api.post(
                    '/download/projects/lecturers',
                    reqbody
                )
                const data = res.data
                    .body as IResponseDownloadProjectLecturerData
                maxPage = data.totalPages
                bulkData = [...bulkData, ...data.lists]
                page += 1

                await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait for 1 second
            }

            return bulkData
        } catch (err) {
            return []
        }
    }
    const LoadProjectVendorData = async (
        values: IRequestDownloadProjectData
    ) => {
        try {
            let page = 1
            let maxPage = 1

            let bulkData: IResponseDownloadProjectVendorDataRowData[] = []

            while (page <= maxPage) {
                const reqbody: IRequestDownloadProjectData = {
                    ...values,
                    page: page,
                }
                const res = await api.post(
                    '/download/projects/vendors',
                    reqbody
                )
                const data = res.data.body as IResponseDownloadProjectVendorData
                maxPage = data.totalPages
                bulkData = [...bulkData, ...data.lists]
                page += 1

                await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait for 1 second
            }

            return bulkData
        } catch (err) {
            return []
        }
    }

    const onSubmit = async (values: IRequestDownloadProjectData) => {
        setIsLoading(true)
        try {
            const projectData = await LoadProjectData(values)
            const projectPeriodData = await LoadProjectPeriodData(values)
            const projectLecturers = await LoadProjectLecturerData(values)
            const projectVendors = await LoadProjectVendorData(values)

            if (!projectData?.length) {
                throw new Error('ไม่พบข้อมูล')
            }

            exportDownloadProjectDataToExcel({
                projects: projectData,
                periods: projectPeriodData,
                lecturers: projectLecturers,
                vendors: projectVendors,
            })
            modals.closeAll()
        } catch (error) {
            modals.closeAll()
            notifications.show({
                title: 'เกิดข้อผิดพลาด',
                message: 'ไม่พบข้อมูล',
                color: 'red',
                icon: <IconExclamationCircle size='1rem' />,
            })
        }
        setIsLoading(false)
    }
    return (
        <>
            <Text size='sm' c='gray.7'>
                กรุณากรอกช่องกรอกเพื่อคัดกรองข้อมูลที่ต้องการจะดาวน์โหลด
            </Text>
            <Paper mt='md'>
                <LoadingOverlay visible={isLoading} overlayBlur={2} />

                <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                    <Flex gap='sm' direction='column'>
                        <InputAgency
                            clearable
                            {...form.getInputProps('agency_id')}
                        />
                        <InputAgencyType
                            clearable
                            {...form.getInputProps('agency_type_id')}
                        />
                        <InputCoordinator
                            clearable
                            {...form.getInputProps('coordinator_id')}
                        />
                        <InputChairman
                            clearable
                            {...form.getInputProps('chairman_id')}
                        />
                        <InputBudgetYear
                            withAsterisk
                            {...form.getInputProps('budget_year')}
                        />
                        <InputProjectProgressStatus
                            withAll={false}
                            clearable
                            {...form.getInputProps(
                                'project_progress_status_id'
                            )}
                        />
                        {auth?.privilege_id !== 4 && (
                            <InputUser
                                clearable
                                {...form.getInputProps('created_by')}
                            />
                        )}

                        <Flex mt='md' justify='end' gap='sm'>
                            <Button
                                onClick={() => modals.closeAll()}
                                variant='default'
                                w='fit-content'
                            >
                                ยกเลิก
                            </Button>
                            <Button type='submit' w='fit-content'>
                                ดาวน์โหลดข้อมูล
                            </Button>
                        </Flex>
                    </Flex>
                </form>
            </Paper>
        </>
    )
}

const filterOption = Joi.number().allow('', null).messages({
    'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
    'any.required': `กรุณาเลือกตัวเลือก`,
    'any.only': `กรุณาเลือกตัวเลือก`,
})

const filterOptionReq = Joi.number().messages({
    'number.base': 'กรุณากรอกข้อมูล',
    'any.required': `กรุณากรอกข้อมูล`,
    'any.only': `กรุณากรอกข้อมูล`,
})

const schema = Joi.object().keys({
    agency_id: filterOption,
    agency_type_id: filterOption,
    coordinator_id: filterOption,
    chairman_id: filterOption,
    budget_year: filterOptionReq,
    project_progress_status_id: Joi.number()
        .allow(null, '')
        .min(1)
        .required()
        .messages({
            'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
            'any.required': `กรุณากรอกข้อมูล`,
        }),
    page: filterOptionReq,
    checkowner: Joi.number().required().allow('', null).valid(1, 0),
    created_by: Joi.number().allow('', null),
})

import FormMutipleDashboard from '@components/form/dashboard/FormMutipleDashboard'
import FormSingleDashboard from '@components/form/dashboard/FormSingleDashboard'
import PageLayout from '@components/page/PageLayout'
import { Tabs } from '@mantine/core'
import { IconFileAnalytics } from '@tabler/icons-react'
import React from 'react'

const DashboardHome = () => {
    return (
        <PageLayout pageTitle='สรุปผลข้อมูลระบบ (Dashboard)'>
            <Tabs defaultValue='single'>
                <Tabs.List>
                    <Tabs.Tab value='single' icon={<IconFileAnalytics size='0.8rem' />}>
                        สรุปผลดำเนินโครงการประจำปีงบประมาณ
                    </Tabs.Tab>
                    <Tabs.Tab value='mutiple' icon={<IconFileAnalytics size='0.8rem' />}>
                        สรุปผลดำเนินโครงการระหว่างปีงบประมาณ
                    </Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value='single' pt='md'>
                    <FormSingleDashboard />
                </Tabs.Panel>

                <Tabs.Panel value='mutiple' pt='md'>
                    <FormMutipleDashboard />
                </Tabs.Panel>
            </Tabs>
        </PageLayout>
    )
}

export default DashboardHome

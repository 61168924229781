import PageLayout from '@components/page/PageLayout'
import {
    IconBuilding,
    IconBuildingStore,
    IconNews,
    IconUserBolt,
    IconUserPause,
    IconUserStar,
} from '@tabler/icons-react'
import { IDownloadCardProps } from '@components/card/CardDownload'
import CardDownloadList from '@components/card/CardDownloadList'
import ModalDownloadProjectToExcel from '@components/modal/ModalDownloadProjectToExcel'
import ModalDownloadAgencyToExcel from '@components/modal/ModalDownloadAgencyToExcel'
import ModalDownloadCoordinatorToExcel from '@components/modal/ModalDownloadCoordinatorToExcel'
import ModalDownloadChairmanToExcel from '@components/modal/ModalDownloadChairmanToExcel'
import ModalDownloadLecturerToExcel from '@components/modal/ModalDownloadLecturerToExcel'
import ModalDownloadVendorToExcel from '@components/modal/ModalDownloadVendorToExcel'
const AllDownload = () => {
    const downloads: IDownloadCardProps[] = [
        {
            title: 'ข้อมูลโครงการ',
            description:
                'รายการข้อมูลโครงการโดยสังเขปและข้อมูลรายละเอียดรายโครงการ',
            path: '/download/projects',
            icon: IconNews,
            downloadBtn: ModalDownloadProjectToExcel,
        },
        {
            title: 'ข้อมูลหน่วยงาน',
            description:
                'รายการข้อมูลหน่วยงาน ชื่อ ปีงบประมาณ เบอร์โทร อีเมล ที่อยู่ และอื่น ๆ',
            path: '/download/projects',
            icon: IconBuilding,
            downloadBtn: ModalDownloadAgencyToExcel,
        },
        {
            title: 'ข้อมูลผู้ประสานงาน',
            description: 'รายการข้อมูลผู้ประสานงาน ชื่อ อีเมล เบอร์โทร',
            path: '/download/projects',
            icon: IconUserBolt,
            downloadBtn: ModalDownloadCoordinatorToExcel,
        },
        {
            title: 'ข้อมูลประธานโครงการ',
            description: 'รายการข้อมูลประธานโครงการ ชื่อ อีเมล เบอร์โทร',
            path: '/download/projects',
            icon: IconUserPause,
            downloadBtn: ModalDownloadChairmanToExcel,
        },
        {
            title: 'ข้อมูลวิทยากร',
            description:
                'รายการข้อมูลวิทยากร ชื่อ อีเมล เบอร์โทร ความเชี่ยวชาญ',
            path: '/download/projects',
            icon: IconUserStar,
            downloadBtn: ModalDownloadLecturerToExcel,
        },
        {
            title: 'ข้อมูลผู้ค้า',
            description:
                'รายการข้อมูลผู้ค้า ชื่อหน่วยงาน ปีงบประมาณ อีเมล เบอร์โทร ที่อยู่ ประเภทสินค้า/บริการ',
            path: '/download/projects',
            icon: IconBuildingStore,
            downloadBtn: ModalDownloadVendorToExcel,
        },
    ]
    return (
        <PageLayout pageTitle='ดาวน์โหลดข้อมูล'>
            <CardDownloadList downloads={downloads} />
        </PageLayout>
    )
}

export default AllDownload

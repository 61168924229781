import InputCheckOwner from '@components/form/input/InputCheckOwner'
import React from 'react'
interface ITableCheckOwnerFilter {
    checkowner: number
    setcheckowner: (value: React.SetStateAction<number>) => void
    setpage: (value: React.SetStateAction<number>) => void
}
const TableCheckOwnerFilter = ({
    checkowner,
    setcheckowner,
    setpage,
}: ITableCheckOwnerFilter) => {
    return (
        <InputCheckOwner
            miw='130px'
            w='100%'
            value={String(checkowner)}
            onChange={(value) => {
                if (value !== String(checkowner)) {
                    setcheckowner(Number(value))
                    setpage(1)
                }
            }}
        />
    )
}

export default TableCheckOwnerFilter

import Agency from '@api/Agency'
import CardAgencyDetail from '@components/card/CardAgencyDetail'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const ViewAgency = () => {
    const params = useParams()
    const agency_id = params.agency_id
    const { isLoading, isFetching, isError, data } = Agency.useGetAgencyByID({
        agency_id: agency_id!,
    })
    if (isLoading || isFetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าหน่วยงานทั้งหมด'
                url='/agencies'
            />
        )
    }
    return (
        <PageLayout pageTitle='ข้อมูลหน่วยงาน'>
            <CardAgencyDetail data={data}></CardAgencyDetail>
        </PageLayout>
    )
}

export default ViewAgency

import { Flex } from '@mantine/core'
import React from 'react'

interface ITableFilterWrapper {
    children: React.ReactNode
}

const TableFilterWrapper = ({ children }: ITableFilterWrapper) => {
    return (
        <Flex gap='md' direction={{ xs: 'column', lg: 'row' }}>
            {children}
        </Flex>
    )
}

export default TableFilterWrapper

import { Select, SelectItem } from '@mantine/core'
import { SelectProps } from '@mantine/core/lib/Select/Select'

interface MyComponentProps
    extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
}
const InputHasOrNot = ({
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: MyComponentProps) => {
    return (
        <Select
            className={className}
            value={String(value)}
            placeholder='มี/ไม่มี'
            data={[
                { value: '0', label: 'ไม่มี' },
                { value: '1', label: 'มี' },
            ]}
            {...others}
        />
    )
}

export default InputHasOrNot

import { Flex, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import TableViewButton from '@components/button/TableViewButton.'
import TableEditButton from '@components/button/TableEditButton'
import TableDeleteButton from '@components/button/TableDeleteButton'

import { ITHeadMap } from '@interfaces/ITable'
import { Link } from 'react-router-dom'
import TableAddNewButton from '@components/button/TableAddNewButton'
import { modals } from '@mantine/modals'
import { QueryObserverResult } from 'react-query'
import {
    IResponseGetAllChairmans,
    IResponseGetAllChairmansRowData,
} from '@interfaces/api/IChairman'
import Chairman from '@api/Chairman'
import TableFilterWrapper from '../template/TableFilterWrapper'
import TableCheckOwnerFilter from '../filter/TableCheckOwnerFilter'
import InputChairmanOrganization from '@components/form/input/InputChairmanOrganization'
import { default_header } from './common_table_header'
import TableSearchFilter from '@components/table/filter/TableSearchFilter'
import TablePagination from '@components/table/template/TablePagination'
import TableTemplate from '@components/table/template/TableTemplate'
import TableStatusFilter from '@components/table/filter/TableStatusFilter'

const TableAllChairman = () => {
    const [limit, setlimit] = useState(10)
    const [orderby, setorderby] =
        useState<keyof IResponseGetAllChairmansRowData>('chairman_id')
    const [order, setorder] = useState('desc')
    const [page, setpage] = useState(1)
    const [search, setsearch] = useState('')
    const [checkowner, setcheckowner] = useState(0)
    const [status, setstatus] = useState('')
    const [chairman_organization_id, setChairmanOrganizationID] = useState('')
    const lists = Chairman.useGetAllChairmans({
        limit,
        orderby,
        order,
        page,
        search,
        checkowner,
        status,
        chairman_organization_id,
    })

    useEffect(() => {
        lists.refetch()
        // eslint-disable-next-line
    }, [
        status,
        page,
        limit,
        order,
        orderby,
        checkowner,
        chairman_organization_id,
    ])

    const thead: ITHeadMap<IResponseGetAllChairmansRowData>[] = [
        {
            sortkey: 'chairman_fullname',
            label: 'ชื่อประธานโครงการ',
            width: '40%',
        },
        {
            sortkey: 'chairman_organization_name',
            label: 'สังกัด',
            width: '30%',
        },
        ...default_header,
    ]

    return (
        <>
            <TableFilterWrapper>
                <TableSearchFilter
                    page={page}
                    setpage={setpage}
                    setsearch={setsearch}
                    search={search}
                    refresh={lists.refetch}
                />
                <TableCheckOwnerFilter
                    {...{ setcheckowner, checkowner, setpage }}
                />
                <TableStatusFilter {...{ setpage, setstatus, status }} />
                <InputChairmanOrganization
                    withAll
                    miw='200px'
                    value={String(chairman_organization_id)}
                    onChange={(value) => {
                        if (value !== chairman_organization_id) {
                            setChairmanOrganizationID(String(value))
                            setpage(1)
                        }
                    }}
                />
                <TableAddNewButton
                    component={Link}
                    to='/chairmans/create'
                    label='เพิ่มประธานโครงการ'
                />
            </TableFilterWrapper>

            <TableTemplate<IResponseGetAllChairmansRowData>
                thead={thead}
                orderby={orderby}
                order={order}
                setorder={setorder}
                setorderby={setorderby}
                lists={lists?.data?.lists}
            >
                {lists?.data?.lists.map((value) => (
                    <AllProjectTableRow
                        key={value.chairman_id}
                        data={value}
                        refreshTable={lists.refetch}
                    />
                ))}
            </TableTemplate>
            {lists.data?.lists.length ? (
                <TablePagination
                    limit={lists.data?.lists.length}
                    setlimit={setlimit}
                    setpage={setpage}
                    page={page}
                    totalPages={lists.data.totalPages}
                    totalRows={lists.data.totalRows}
                />
            ) : (
                <></>
            )}
        </>
    )
}

const AllProjectTableRow = ({
    data,
    refreshTable,
}: {
    data: IResponseGetAllChairmansRowData
    refreshTable: () => Promise<
        QueryObserverResult<IResponseGetAllChairmans, unknown>
    >
}) => {
    const Delete = Chairman.useDeleteChairman({
        chairman_id: data.chairman_id,
        aftersuccess: refreshTable,
    })

    const openDeleteConfirmModal = () =>
        modals.openConfirmModal({
            title: 'กรุณายืนยันการลบ',
            children: (
                <Text>
                    ลบประธานโครงการ
                    <br />
                    {data.chairman_fullname}
                </Text>
            ),
            labels: { confirm: 'ยืนยัน', cancel: 'ยกเลิก' },
            onConfirm: Delete.onSubmit,
        })

    return (
        <tr key={data.chairman_id}>
            <td>{data.chairman_fullname}</td>
            <td>{data.chairman_organization_name}</td>
            <td>{data.status_description}</td>
            <td>
                <Flex gap='xs' pos='relative'>
                    <TableViewButton
                        component={Link}
                        to={`/chairmans/${data.chairman_id}/view`}
                    />
                    <TableEditButton
                        component={Link}
                        to={`/chairmans/${data.chairman_id}/edit`}
                    />
                    <TableDeleteButton onClick={openDeleteConfirmModal} />
                </Flex>
            </td>
        </tr>
    )
}

export default TableAllChairman

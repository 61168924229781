import InputPageLimit from '@components/form/input/InputPageLimit';
import { Flex, Pagination, Text } from '@mantine/core';
import React from 'react';
interface ITablePagination {
    limit: number;
    setlimit: (value: React.SetStateAction<number>) => void;
    page: number;
    setpage: (value: React.SetStateAction<number>) => void;

    totalRows: number;
    totalPages: number;
}
const TablePagination = ({
    limit,
    setlimit,
    page,
    setpage,
    totalRows,
    totalPages,
}: ITablePagination) => {
    return (
        <Flex
            mt='md'
            direction={{ xs: 'column', md: 'row' }}
            gap='md'
            justify='space-between'
        >
            <InputPageLimit
                onChange={(changeLimit) => {
                    if (limit !== Number(changeLimit)) {
                        setlimit(Number(changeLimit));
                        setpage(1);
                    }
                }}
            />
            <Flex gap='md' align='center'>
                <Text>จำนวน {totalRows} แถว</Text>
                <Pagination
                    value={page}
                    total={totalPages}
                    onChange={(changePage) => {
                        setpage(changePage);
                    }}
                />
            </Flex>
        </Flex>
    );
};

export default TablePagination;

import { useForm } from '@mantine/form'
import {
    Button,
    Flex,
    LoadingOverlay,
    Paper,
    Textarea,
    TextInput,
} from '@mantine/core'

import { Link, useNavigate } from 'react-router-dom'
import {
    IRequestUpdateChairman,
    IResponseGetChairmanByID,
} from '@interfaces/api/IChairman'
import Chairman from '@api/Chairman'
import InputStatus from '../input/InputStatus'
import InputChairmanOrganization from '../input/InputChairmanOrganization'
import InputExpertises from '../input/InputExpertises'

const FormEditChairman = ({
    data,
    aftersuccess,
}: {
    data: IResponseGetChairmanByID
    aftersuccess?: () => any
}) => {
    const form = useForm<IRequestUpdateChairman>({
        initialValues: {
            chairman_fullname: data.chairman_fullname ?? '',
            chairman_organization_id: data.chairman_organization_id ?? '',
            chairman_email: data.chairman_email ?? '',
            chairman_phone: data.chairman_phone ?? '',
            chairman_detail: data.chairman_detail ?? '',
            chairman_expertises: data.chairman_expertises_id
                ? String(data.chairman_expertises_id).split(',')
                : [],
            status: data.status,
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = Chairman.useUpdateChairman({
        form,
        chairman_id: data.chairman_id,
        aftersuccess: () => navigate('/Chairmans'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อประธานโครงการ'
                        placeholder='ชื่อประธานโครงการ'
                        {...form.getInputProps('chairman_fullname')}
                    />
                    <InputChairmanOrganization
                        withAsterisk
                        label='สังกัดประธานโครงการ'
                        placeholder='สังกัดประธานโครงการ'
                        {...form.getInputProps('chairman_organization_id')}
                    />
                    <TextInput
                        label='อีเมล'
                        placeholder='อีเมล'
                        {...form.getInputProps('chairman_email')}
                    />
                    <TextInput
                        label='เบอร์โทร'
                        placeholder='เบอร์โทร'
                        {...form.getInputProps('chairman_phone')}
                    />
                    <Textarea
                        rows={2}
                        minRows={2}
                        label='เกี่ยวกับประธานโครงการ'
                        placeholder='เกี่ยวกับประธานโครงการ'
                        {...form.getInputProps('chairman_detail')}
                    />
                    <InputExpertises
                        {...form.getInputProps('chairman_expertises')}
                    />
                    <InputStatus {...form.getInputProps('status')} />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/chairmans'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            บันทึกการแก้ไขประธานโครงการ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormEditChairman

import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core'

import { Link, useNavigate } from 'react-router-dom'
import {
    IRequestUpdateCoordinator,
    IResponseGetCoordinatorByID,
} from '@interfaces/api/ICoordinator'
import Coordinator from '@api/Coordinator'
import InputStatus from '../input/InputStatus'

const FormEditCoordinator = ({
    data,
    aftersuccess,
}: {
    data: IResponseGetCoordinatorByID
    aftersuccess?: () => any
}) => {
    const form = useForm<IRequestUpdateCoordinator>({
        initialValues: {
            coordinator_fullname: data.coordinator_fullname ?? '',
            coordinator_email: data.coordinator_email ?? '',
            coordinator_phone: data.coordinator_phone ?? '',
            status: data.status,
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = Coordinator.useUpdateCoordinator({
        form,
        coordinator_id: data.coordinator_id,
        aftersuccess: () => navigate('/coordinators'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อผู้ประสานงาน'
                        placeholder='ชื่อผู้ประสานงาน'
                        {...form.getInputProps('coordinator_fullname')}
                    />
                    <TextInput
                        label='อีเมล'
                        placeholder='อีเมล'
                        {...form.getInputProps('coordinator_email')}
                    />
                    <TextInput
                        label='เบอร์โทร'
                        placeholder='เบอร์โทร'
                        {...form.getInputProps('coordinator_phone')}
                    />
                    <InputStatus {...form.getInputProps('status')} />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/coordinators'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            บันทึกการแก้ไขผู้ประสานงาน
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormEditCoordinator

import { Checkbox, CheckboxGroupProps, Flex, SelectItem } from '@mantine/core'
import { ReactNode, useEffect, useState } from 'react'

import { IResponseGetAllVendorTypesRowData } from '@interfaces/api/IVendor'
import Vendor from '@api/Vendor'
interface IInputVendorTypes extends Omit<CheckboxGroupProps & React.RefAttributes<HTMLInputElement>, 'children'> {
    editMode?: boolean
    children?: ReactNode
}

interface ItemProps extends SelectItem, IResponseGetAllVendorTypesRowData {}

const InputVendorTypes = ({
    editMode = true,
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: IInputVendorTypes) => {
    const { data } = Vendor.useGetAllVendorTypes({ limit: 1000 })
    const [list, setlist] = useState<ItemProps[]>([])
    useEffect(() => {
        if (data) {
            setlist(
                data.lists.map((e) => {
                    return {
                        ...e,
                        value: String(e.vendor_type_id),
                        label: e.vendor_type_name,
                    }
                })
            )
        }
    }, [data])

    return (
        <Checkbox.Group value={value} withAsterisk label='ประเภทสินค้า/บริการของผู้ค้า' {...others}>
            <Flex direction='column' gap='xs' mt='xs' mb='xs'>
                {list.map((value) => (
                    <Checkbox
                        key={value.vendor_type_id}
                        value={String(value.vendor_type_id)}
                        label={value.vendor_type_name}
                    />
                ))}
            </Flex>
        </Checkbox.Group>
    )
}

export default InputVendorTypes

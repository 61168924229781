import PageLayout from '@components/page/PageLayout'
import FormCreateProjectType from '../../../components/form/project/type/FormCreateProjectType'
const CreateProjectType = () => {
    return (
        <PageLayout pageTitle='สร้างประเภทกิจกรรมบริการวิชาการ'>
            <FormCreateProjectType />
        </PageLayout>
    )
}

export default CreateProjectType

import { NumberInput, NumberInputProps, TextInput } from '@mantine/core'
import { ChangeEvent, forwardRef, useEffect, useRef, useState } from 'react'
import Money from '@utils/Money'

// interface IInputCurrency
//     extends Omit<InputProps & React.RefAttributes<HTMLInputElement>, 'data'> {
//     data?: readonly (string | SelectItem)[];
// }

const InputCurrency = forwardRef<HTMLInputElement, NumberInputProps>(
    (
        { classNames, styles, unstyled, className, label, onChange = () => {}, value, ...others }: NumberInputProps,
        ref
    ) => {
        const [inputValue, setInputValue] = useState<string>(value ? `${Money(value)}` : '')
        const [isBackSpace, setIsBackSpace] = useState<boolean>(false)
        const [typeChange, setTypeChange] = useState<boolean>(false)
        const inputRef = useRef<HTMLInputElement | null>(null)
        const selectionStart = useRef(0)
        const selectionEnd = useRef(0)

        useEffect(() => {
            if (inputRef.current) {
                inputRef.current.selectionStart = selectionStart.current
                inputRef.current.selectionEnd = selectionEnd.current
            }
        }, [typeChange])

        const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
            setTypeChange(!typeChange)
            let cleanedValue = event.target.value.replace(/[^\d.]/g, '')
            let isPointMoreThanTwo = cleanedValue.split('.').length >= 3 ? true : false
            const beforeFormat = event.target.value
            let afterFormat = cleanedValue === '' ? '' : Money(Number(cleanedValue))

            let typeValue = event.target.value[event.target.selectionEnd! - 1]
            let selectionTarget = event.target.selectionStart

            if (isPointMoreThanTwo === false) {
                if (isBackSpace && beforeFormat.length < afterFormat.length && afterFormat[selectionTarget!] === ',') {
                    let reFormat: string | string[] = afterFormat.split('')
                    reFormat[selectionTarget! - 1] = ''
                    reFormat[selectionTarget!] = ''
                    reFormat = reFormat.filter((e) => e !== ',').join('')
                    reFormat = Money(Number(reFormat))
                    afterFormat = reFormat
                    console.log('reFormat=>', reFormat)

                    setInputValue(reFormat)
                } else {
                    setInputValue(afterFormat)
                }
            }

            setIsBackSpace(false)

            if (typeValue === '.' && beforeFormat[event.target.selectionEnd!] === '.') {
                selectionStart.current = event.target.selectionStart!
                selectionEnd.current = event.target.selectionStart!
                return false
            }

            if (typeValue === '.') {
                console.log('BLOCK')
                event.preventDefault()
                return false
            }

            const isSelectionAfterDecimal = beforeFormat.length - selectionTarget! <= 2

            if (isSelectionAfterDecimal === true) {
                selectionStart.current = event.target.selectionStart!
                selectionEnd.current = event.target.selectionStart!
                return false
            }

            let differenceLength = afterFormat.length - beforeFormat.length
            let paddingSelectionType = differenceLength

            if (paddingSelectionType < 0) {
                if (event.target.selectionStart === 0) {
                    selectionStart.current = 0
                    selectionEnd.current = 0
                } else {
                    selectionStart.current = event.target.selectionEnd! + Math.min(1, paddingSelectionType)
                    selectionEnd.current = event.target.selectionEnd! + Math.min(1, paddingSelectionType)
                }
            } else {
                selectionStart.current = event.target.selectionEnd! + paddingSelectionType
                selectionEnd.current = event.target.selectionEnd! + paddingSelectionType
            }

            onChange(parseFloat(cleanedValue) > 0 ? parseFloat(cleanedValue) : 0.0)
        }

        const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.code === 'Backspace') {
                setIsBackSpace(true)
            } else {
                setIsBackSpace(false)
            }
        }

        return (
            <>
                <TextInput
                    ref={inputRef}
                    value={inputValue}
                    label={label}
                    placeholder={'0.00'}
                    onKeyDown={onKeyDown}
                    onChange={handleInputChange}
                    {...others}
                />
                <NumberInput
                    style={{ display: 'none' }}
                    className={className}
                    value={Number(value)}
                    placeholder='0.00'
                    precision={2}
                    step={0.99}
                    onChange={onChange}
                    {...others}
                />
            </>
        )
    }
)

export default InputCurrency

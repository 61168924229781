import api from '@config/api'
import { useQuery } from 'react-query'
import {
    IAgencyID,
    IAgencyTypeID,
    IRequestAllAgencies,
    IRequestAllAgencyTypes,
    IRequestCreateAgency,
    IRequestCreateAgencyType,
    IRequestUpdateAgency,
    IRequestUpdateAgencyType,
    IResponseAllAgencies,
    IResponseAllAgencyTypes,
    IResponseGetAgencyByID,
    IResponseGetAgencyTypeByID,
} from '@interfaces/api/IAgency'
import { useFormMutationHandler } from '@utils/useFormMutationHandler'
import { IFormMutationHandlerProps } from '@interfaces/default/IDefault'
import { useQueryConfig } from './useQueryConfig'

interface IUseCreateAgency extends IFormMutationHandlerProps<IRequestCreateAgency> {}

interface IUseUpdateAgency extends IAgencyID, IFormMutationHandlerProps<IRequestUpdateAgency> {}

interface IUseDeleteAgency extends IAgencyID, IFormMutationHandlerProps<IAgencyID> {}

interface IUseCreateAgencyType extends IFormMutationHandlerProps<IRequestCreateAgencyType> {}

interface IUseUpdateAgencyType extends IAgencyTypeID, IFormMutationHandlerProps<IRequestUpdateAgencyType> {}

interface IUseDeleteAgencyType extends IAgencyTypeID, IFormMutationHandlerProps<IAgencyTypeID> {}

const Agency = {
    useGetAllAgencies: ({
        limit = 100,
        orderby = 'agency_id',
        order = 'desc',
        page = 1,
        search = '',
        checkowner = 0,
        budget_year = '',
        status = '',
    }: IRequestAllAgencies) =>
        useQuery(
            'allagencies',
            async () => {
                try {
                    const res = await api.get('/agencies', {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                            checkowner,
                            budget_year,
                            status,
                        },
                    })
                    return res.data.body as IResponseAllAgencies
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useGetAgencyByID: ({ agency_id }: IAgencyID) =>
        useQuery(
            'getagencybyid' + agency_id,
            async () => {
                try {
                    const res = await api.get(`/agencies/${agency_id}`)
                    return res.data.body as IResponseGetAgencyByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useCreateAgency: ({ form, aftersuccess }: IUseCreateAgency) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: ['/agencies', 'post'],
            aftersuccess,
        })
    },
    useUpdateAgency: ({ form, agency_id, aftersuccess }: IUseUpdateAgency) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/agencies/${agency_id}`, 'put'],
            aftersuccess,
        })
    },

    useDeleteAgency: ({ agency_id, aftersuccess }: IUseDeleteAgency) => {
        return useFormMutationHandler({
            pathWithMethod: [`/agencies/${agency_id}`, 'delete'],
            aftersuccess,
        })
    },

    useGetAllAgencyTypes: ({
        limit = 100,
        orderby = 'agency_type_id',
        order = 'desc',
        page = 1,
        search = '',
        status = '',
        checkowner = 0,
    }: IRequestAllAgencyTypes) =>
        useQuery(
            'allagencytypes',
            async () => {
                try {
                    const res = await api.get('/agencies/types', {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                            status,
                            checkowner,
                        },
                    })
                    return res.data.body as IResponseAllAgencyTypes
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),
    useGetAgencyTypeByID: ({ agency_type_id }: IAgencyTypeID) =>
        useQuery(
            'getagencytypebyid',
            async () => {
                try {
                    const res = await api.get(`/agencies/types/${agency_type_id}`)
                    return res.data.body as IResponseGetAgencyTypeByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),
    useCreateAgencyType: ({ form, aftersuccess }: IUseCreateAgencyType) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: ['/agencies/types', 'post'],
            aftersuccess,
        })
    },
    useUpdateAgencyType: ({ form, agency_type_id, aftersuccess }: IUseUpdateAgencyType) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/agencies/types/${agency_type_id}`, 'put'],
            aftersuccess,
        })
    },
    useDeleteAgencyType: ({ agency_type_id, aftersuccess }: IUseDeleteAgencyType) => {
        return useFormMutationHandler({
            pathWithMethod: [`/agencies/types/${agency_type_id}`, 'delete'],
            aftersuccess,
        })
    },
}

export default Agency

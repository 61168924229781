import Project from '@api/Project'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import {
    Box,
    createStyles,
    Flex,
    Paper,
    RingProgress,
    Switch,
    Tabs,
    TabsValue,
    Text,
    Title,
} from '@mantine/core'
import PageNotFound404 from '@pages/PageNotFound404'
import {
    IconFileCertificate,
    IconListDetails,
    IconNews,
} from '@tabler/icons-react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useContext, useState } from 'react'
import FormViewAndUpdateProjectInfo from '@components/form/project/FormViewAndUpdateProjectInfo'
import FormViewAndUpdateProjectPeriod from '@components/form/project/FormViewAndUpdateProjectPeriod'
import ProgressBadge from '@components/badge/ProgressBadge'
import FormManageProjectLecturer from '@components/form/project/FormManageProjectLecturer'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'
import FormManageProjectVendor from '@components/form/project/FormManageProjectVendor'
import FormEvaluationAll from '@components/form/project/FormEvaluationAll'
import FormCloseProject from '@components/form/project/FormCloseProject'
import TableProjectUpdateHistories from '@components/table/database/project/TableProjectUpdateHistories'
import { AuthContext } from '@context/AuthContext'
import { authPrivilege } from '@utils/authprivilege.lib'
import AccessDenied from '@pages/AccessDenied'

const useStyles = createStyles((theme) => ({
    tabsList: {
        flexDirection: 'row',
        marginBottom: theme.spacing.md,

        [theme.fn.smallerThan('sm')]: {
            justifyContent: 'center',
            textAlign: 'center',
            border: 'none',
            flexDirection: 'column',
            borderRadius: 0,
        },
    },
    tab: {
        [theme.fn.smallerThan('sm')]: {
            width: '100%',
            textAlign: 'left',
            display: 'flex',
            borderRadius: 0,
            justifyContent: 'start',
        },
    },
}))

const ViewAndEditProject = () => {
    const { auth } = useContext(AuthContext)
    const navigate = useNavigate()
    const params = useParams()
    const project_id = params.project_id
    const tab = params.tab ?? 'project_info'
    const { classes } = useStyles()
    const location = useLocation()
    const [editMode, setEditMode] = useState(location.hash === '#edit')
    const [loadedTab, setLoadedTab] = useState([tab])
    let { isLoading, isError, data, refetch } = Project.useGetProjectInfoByID({
        project_id: project_id!,
    })

    const onTabChange = (value: TabsValue) => {
        if (value && !loadedTab.includes(value)) {
            setLoadedTab((prev) => [...prev, value])
        }
        navigate(`/projects/${project_id}/${value}${editMode ? '#edit' : ''}`)
    }

    if (isLoading) {
        return <LoadingPage />
    }
    if (
        data?.created_by_user_id !== auth?.user_id &&
        authPrivilege('manageProjects', auth?.privilege_id!) === false
    ) {
        return <AccessDenied />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404 label='กลับไปหน้าโครงการทั้งหมด' url='/projects' />
        )
    }

    const refetchProjectInfo = async () => {
        await refetch()
        // setRefresh((prev) => prev + 1)
    }

    return (
        <PageLayout pageTitle='รายละเอียดโครงการ'>
            <Paper p='md' shadow='md'>
                <Flex
                    justify='space-between'
                    gap='md'
                    direction={{ xs: 'column', lg: 'row' }}
                >
                    <Flex gap='5px' direction='column' w='fit-content'>
                        <Title order={5}>{data.project_name}</Title>
                        <Text size='sm'>รหัสโครงการ: {data.project_code}</Text>
                        <TextCreatedBy {...data} />
                        <TextUpdatedBy {...data} />
                    </Flex>
                    <Paper shadow='sm' px='lg' w='fit-content'>
                        <Flex align='start' gap='md'>
                            <Box>
                                <Text>สถานะ: </Text>
                                <ProgressBadge
                                    progress_color={
                                        data.project_progress_status_color
                                    }
                                    progress_label={
                                        data.project_progress_status_name
                                    }
                                />

                                <Title order={1} mt='5px' fw='bold'>
                                    {data.project_progress}%
                                </Title>
                            </Box>
                            <RingProgress
                                size={100}
                                sections={[
                                    {
                                        value: Number(data.project_progress),
                                        color: data.project_progress_status_color,
                                    },
                                ]}
                            ></RingProgress>
                        </Flex>
                    </Paper>
                </Flex>
            </Paper>
            <Tabs
                onTabChange={onTabChange}
                value={tab}
                defaultValue={tab}
                mt='md'
                classNames={{ tabsList: classes.tabsList, tab: classes.tab }}
            >
                <Tabs.List mt='xl'>
                    <Tabs.Tab
                        fw='bold'
                        value='project_info'
                        icon={<IconNews size='0.8rem' />}
                    >
                        โครงการ
                    </Tabs.Tab>
                    <Tabs.Tab
                        fw='bold'
                        value='project_period'
                        icon={<IconListDetails size='0.8rem' />}
                    >
                        งวดงาน
                    </Tabs.Tab>
                    <Tabs.Tab
                        fw='bold'
                        value='project_lecturer'
                        icon={<IconListDetails size='0.8rem' />}
                    >
                        วิทยากร
                    </Tabs.Tab>
                    <Tabs.Tab
                        fw='bold'
                        value='project_vendor'
                        icon={<IconListDetails size='0.8rem' />}
                    >
                        ผู้ค้า
                    </Tabs.Tab>
                    <Tabs.Tab
                        fw='bold'
                        value='project_evaluation'
                        icon={<IconListDetails size='0.8rem' />}
                    >
                        ประเมินความพึงพอใจ
                    </Tabs.Tab>
                    <Tabs.Tab
                        fw='bold'
                        value='close_project'
                        icon={<IconFileCertificate size='0.8rem' />}
                    >
                        ปิดโครงการ
                    </Tabs.Tab>
                    <Tabs.Tab
                        fw='bold'
                        value='project_update_histories'
                        icon={<IconFileCertificate size='0.8rem' />}
                    >
                        ประวัติการแก้ไข
                    </Tabs.Tab>
                    <Box ml='auto' p='5px'>
                        <Switch
                            checked={editMode}
                            onChange={(event) =>
                                setEditMode(event.currentTarget.checked)
                            }
                            labelPosition='left'
                            label='เปิดโหมดการแก้ไข'
                        />
                    </Box>
                </Tabs.List>
                <Tabs.Panel value='project_info'>
                    <FormViewAndUpdateProjectInfo
                        key={data.project_id + data.project_code}
                        data={data}
                        refetchProjectInfo={refetchProjectInfo}
                        editMode={editMode}
                    />
                </Tabs.Panel>
                <Tabs.Panel value='project_period'>
                    {loadedTab.includes('project_period') && (
                        <FormViewAndUpdateProjectPeriod
                            project_id={project_id!}
                            key={
                                String(data.project_period_amount) +
                                String(data.project_start_date) +
                                project_id
                            }
                            refetchProjectInfo={refetchProjectInfo}
                            editMode={editMode}
                        />
                    )}
                </Tabs.Panel>
                <Tabs.Panel value='project_lecturer'>
                    {loadedTab.includes('project_lecturer') && (
                        <FormManageProjectLecturer
                            project_id={project_id!}
                            refetchProjectInfo={refetchProjectInfo}
                            editMode={editMode}
                        />
                    )}
                </Tabs.Panel>
                <Tabs.Panel value='project_vendor'>
                    {loadedTab.includes('project_vendor') && (
                        <FormManageProjectVendor
                            project_id={project_id!}
                            refetchProjectInfo={refetchProjectInfo}
                            editMode={editMode}
                        />
                    )}
                </Tabs.Panel>
                <Tabs.Panel value='project_evaluation'>
                    {loadedTab.includes('project_evaluation') && (
                        <FormEvaluationAll
                            project_id={project_id!}
                            refetchProjectInfo={refetchProjectInfo}
                            editMode={editMode}
                        />
                    )}
                </Tabs.Panel>
                <Tabs.Panel value='close_project'>
                    {loadedTab.includes('close_project') && (
                        <FormCloseProject
                            project_id={project_id!}
                            editMode={editMode}
                        />
                    )}
                </Tabs.Panel>
                <Tabs.Panel value='project_update_histories'>
                    {tab === 'project_update_histories' && (
                        <TableProjectUpdateHistories
                            key={project_id!}
                            project_id={project_id!}
                        />
                    )}
                </Tabs.Panel>
            </Tabs>
        </PageLayout>
    )
}

export default ViewAndEditProject

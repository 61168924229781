import Chairman from '@api/Chairman'
import FormEditChairman from '@components/form/chairman/FormEditChairman'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const EditChairman = () => {
    const params = useParams()
    const chairman_id = params.chairman_id
    const { isLoading, isError, data, isRefetching } = Chairman.useGetChairmanByID({
        chairman_id: chairman_id!,
    })
    if (isLoading || isRefetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return <PageNotFound404 label='กลับไปหน้าประธานโครงการทั้งหมด' url='/chairmans' />
    }
    return (
        <PageLayout pageTitle='แก้ไขประธานโครงการ'>
            <FormEditChairman data={data} />
        </PageLayout>
    )
}

export default EditChairman

import { CookieSetOptions } from 'universal-cookie'

export const cookie_config: CookieSetOptions = {
    sameSite: true,
    secure: true,
    httpOnly: false,
    path: '/',
}

export const secure_cookie_config: CookieSetOptions = {
    httpOnly: true,
    sameSite: true,
    secure: true,
    path: '/',
}

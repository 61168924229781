import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core'
import { Link, useNavigate } from 'react-router-dom'
import { IRequestCreateProjectType } from '@interfaces/api/IProjectType'
import ProjectType from '@api/ProjectType'

const FormCreateProjectType = ({
    aftersuccess,
}: {
    aftersuccess?: () => any
}) => {
    const form = useForm<IRequestCreateProjectType>({
        initialValues: {
            project_type_name: '',
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = ProjectType.useCreate({
        form,
        aftersuccess: () => navigate('/projects/types'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ประเภทกิจกรรมบริการวิชาการ'
                        placeholder='ประเภทกิจกรรมบริการวิชาการ'
                        {...form.getInputProps('project_type_name')}
                    />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/projects/types'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            สร้างประเภทกิจกรรมบริการวิชาการ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormCreateProjectType

import PageLayout from '@components/page/PageLayout'
import { Tabs } from '@mantine/core'
import { IconCategory2, IconUsersGroup } from '@tabler/icons-react'
import TableAllAgency from '@components/table/database/TableAllAgency'
import TableAllAgencyType from '@components/table/database/TableAllAgencyType'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const AllAgency = ({ defaultTab }: { defaultTab?: string }) => {
    const [tab, setTab] = useState(defaultTab)
    const navigate = useNavigate()
    const onTabChange = (value: string) => {
        setTab(value)
        if (value === 'agency_type') {
            navigate('/agencies/types')
        } else {
            navigate('/agencies')
        }
    }

    return (
        <PageLayout pageTitle='หน่วยงาน/ลูกค้า'>
            <Tabs
                value={tab}
                defaultValue={defaultTab}
                onTabChange={onTabChange}
            >
                <Tabs.List>
                    <Tabs.Tab
                        value='agency'
                        icon={<IconUsersGroup size='0.8rem' />}
                    >
                        หน่วยงาน
                    </Tabs.Tab>
                    <Tabs.Tab
                        value='agency_type'
                        icon={<IconCategory2 size='0.8rem' />}
                    >
                        ประเภทหน่วยงาน
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value='agency' pt='xs'>
                    <TableAllAgency />
                </Tabs.Panel>

                <Tabs.Panel value='agency_type' pt='xs'>
                    <TableAllAgencyType />
                </Tabs.Panel>
            </Tabs>
        </PageLayout>
    )
}

export default AllAgency

import { Card, Flex, Text } from '@mantine/core'
import { createStyles, rem } from '@mantine/core'
import { TablerIconsProps } from '@tabler/icons-react'

export interface IDownloadCardProps {
    title: string
    description: string
    path: string
    icon: (props: TablerIconsProps) => JSX.Element
    downloadBtn: () => JSX.Element
}

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(34),
        fontWeight: 900,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
        },
    },

    description: {
        maxWidth: 600,
        margin: 'auto',

        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: rem(45),
            height: rem(2),
            marginTop: theme.spacing.sm,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    card: {
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark'
                ? theme.colors.dark[5]
                : theme.colors.gray[1]
        }`,
    },

    cardTitle: {
        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: rem(45),
            height: rem(2),
            marginTop: theme.spacing.sm,
        },
    },
}))

const CardDownload = (download: IDownloadCardProps) => {
    const { classes, theme } = useStyles()

    return (
        <Card
            key={download.title}
            shadow='md'
            radius='md'
            className={classes.card}
            padding='xl'
        >
            <Flex direction='column' h='100%'>
                <download.icon
                    size={rem(50)}
                    stroke={2}
                    color={theme.fn.primaryColor()}
                />
                <Text fz='lg' fw={500} className={classes.cardTitle} mt='md'>
                    {download.title}
                </Text>
                <Text fz='sm' c='dimmed' mt='sm' mb='md'>
                    {download.description}
                </Text>
                <download.downloadBtn />
            </Flex>
        </Card>
    )
}

export default CardDownload

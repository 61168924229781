import { TextInput } from '@mantine/core'
import React from 'react'

interface ITableSearchFilter {
    setpage: (value: React.SetStateAction<number>) => void
    setsearch: (value: React.SetStateAction<string>) => void
    search: string
    page: number
    refresh: () => Promise<any>
}

const TableSearchFilter = ({ refresh, setpage, page, setsearch, search }: ITableSearchFilter) => {
    return (
        <TextInput
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    if (page !== 1) {
                        setpage(1)
                    } else {
                        refresh()
                    }
                }
            }}
            w='100%'
            value={search}
            onChange={(event) => setsearch(event.target.value)}
            placeholder='ค้นหา'
            label='ค้นหารายการ'
        />
    )
}

export default TableSearchFilter

import React from 'react'

import { Card, createStyles, Flex, Textarea, Title } from '@mantine/core'
import TextParameter from '../text/TextParameter'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'
import { IResponseGetVendorByID } from '@interfaces/api/IVendor'
import GroupTagBadge from '@components/badge/GroupTagBadge'

const useStyles = createStyles((theme) => ({
    textarea: {
        '&:focus': {
            borderColor: theme.colors.gray[4],
        },
    },
}))

const CardVendorDetail = ({ data }: { data: IResponseGetVendorByID }) => {
    const { classes } = useStyles()
    const textparameters = [
        {
            title: 'ประเภทหน่วยงาน',
            info: <GroupTagBadge labels={data.vendor_types_name} />,
        },
        {
            title: 'ประจำปีงบประมาณ',
            info: data.budget_year_name,
        },
        {
            title: 'อีเมล',
            info: data.vendor_email,
        },
        {
            title: 'เบอร์โทร',
            info: data.vendor_phone,
        },
        {
            title: 'สถานะข้อมูล',
            info: data.status_description,
        },
        {
            title: 'ที่อยู่',
            type: 'textarea',
            info: data.vendor_address,
        },
        {
            title: 'เกี่ยวกับผู้ค้า',
            type: 'textarea',
            info: data.vendor_detail,
        },
    ]
    return (
        <Card
            key={data.vendor_id}
            shadow='md'
            radius='md'
            // className={classes.card}
            padding='md'
        >
            <Flex gap='5px' direction='column'>
                <Title order={5}>{data.vendor_name}</Title>
                {textparameters.map((value) => {
                    return value.type === 'textarea' ? (
                        <Textarea
                            key={value.title}
                            classNames={{ input: classes.textarea }}
                            autosize
                            rows={0}
                            readOnly
                            defaultValue={value.info}
                            label={value.title + ':'}
                        />
                    ) : (
                        <TextParameter
                            key={value.title}
                            title={value.title}
                            info={value.info!}
                        />
                    )
                })}
                <TextCreatedBy {...data} />
                <TextUpdatedBy {...data} />
            </Flex>
        </Card>
    )
}

export default CardVendorDetail

import { Flex, MantineProvider } from '@mantine/core'
import { QueryClient, QueryClientProvider } from 'react-query'

import { Route, Routes } from 'react-router-dom'
import { Notifications } from '@mantine/notifications'
import AuthProvider from '@context/AuthContext'
import { menus } from '@config/menus'
import SideBarMenu from '@components/menu/SideBarMenu'
import { BrowserRouter as Router } from 'react-router-dom'
import ProtectedRoute from '@utils/ProtectedRoute'
import { ModalsProvider } from '@mantine/modals'
export default function App() {
    const queryClient = new QueryClient()
    const router = menus.filter((value) => value.path && true)
    const theme = {
        colors: {
            primary: [
                '#EDF2FF',
                '#DBE4FF',
                '#BAC8FF',
                '#91A7FF',
                '#748FFC',
                '#5C7CFA',
                '#4C6EF5',
                '#4263EB',
                '#3B5BDB',
                '#364FC7',
            ],
        },
        primaryColor: 'primary',
        breakpoints: {
            xs: '0em',
            sm: '48em',
            md: '64em',
            lg: '74em',
            xl: '90em',
        },
        fontFamily: 'Noto Sans Thai',
        components: {},
    }

    const routesList = router.map((value) => {
        if (value.protected === true) {
            return (
                <Route
                    key={value.path}
                    path={value.path}
                    element={
                        <ProtectedRoute privilege={value.privilege}>
                            {value.element}
                        </ProtectedRoute>
                    }
                />
            )
        } else {
            return (
                <Route
                    key={value.path}
                    path={value.path}
                    element={value.element}
                />
            )
        }
    })
    return (
        <Router>
            <QueryClientProvider client={queryClient}>
                <MantineProvider
                    withGlobalStyles
                    withNormalizeCSS
                    theme={theme as any}
                >
                    <Notifications position='top-right' zIndex={10001} />
                    <AuthProvider>
                        <ModalsProvider modalProps={{ zIndex: 10000 }}>
                            <Flex justify='flex-end' pos='relative'>
                                <SideBarMenu />
                                <Routes>{routesList}</Routes>
                            </Flex>
                        </ModalsProvider>
                    </AuthProvider>
                </MantineProvider>
            </QueryClientProvider>
        </Router>
    )
}

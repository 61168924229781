import {
    IRequestCreateProject,
    IRequestUpdateProjectInfo,
} from '@interfaces/api/IProject'
import Joi from 'joi'
import {
    agency_id,
    budget_year,
    chairman_id,
    coordinator_id,
    financial_report_submission_date,
    has_letter_to_dean_about_chairman,
    has_project_chair_agreement,
    old_project_budget,
    old_project_duration,
    old_project_name,
    project_budget,
    project_detail,
    project_duration,
    project_end_date,
    project_expertises,
    project_name,
    project_no,
    project_other_note,
    project_period_amount,
    project_promise_no,
    project_start_date,
    project_type_id,
    rector_budget_adjustment_request_date,
    rector_budget_detail_adjustment_request_date,
    rector_project_approval_date,
    rector_project_name_change_request_date,
    rector_project_period_extension_request_date,
    rector_signing_employment_contract_request_date,
} from './projectFormSchemaIndex'

export const createproject = Joi.object<IRequestCreateProject>().keys({
    project_no,
    budget_year,
    project_promise_no,
    project_name,
    project_type_id,
    project_expertises,
    project_detail,
    project_start_date,
    project_end_date,
    project_duration,
    project_budget,
    project_period_amount,
    chairman_id,
    coordinator_id,
    agency_id,
})

export const updateproject = Joi.object<IRequestUpdateProjectInfo>().keys({
    project_no,
    budget_year,
    project_promise_no,
    project_name,
    project_type_id,
    project_expertises,
    project_detail,
    project_start_date,
    project_end_date,
    project_duration,
    project_budget,
    project_period_amount,
    financial_report_submission_date,
    has_project_chair_agreement,
    has_letter_to_dean_about_chairman,
    rector_project_approval_date,
    rector_signing_employment_contract_request_date,
    rector_budget_adjustment_request_date,
    rector_budget_detail_adjustment_request_date,
    rector_project_name_change_request_date,
    rector_project_period_extension_request_date,
    old_project_budget,
    old_project_name,
    old_project_duration,
    project_other_note,
    chairman_id,
    coordinator_id,
    agency_id,
})

import { useForm } from '@mantine/form'
import {
    Button,
    Flex,
    LoadingOverlay,
    Paper,
    PasswordInput,
    TextInput,
} from '@mantine/core'
import { Link, useNavigate } from 'react-router-dom'
import { IRequestCreateUser } from '@interfaces/api/IUser'
import User from '@api/User'
import InputUserPosition from '../input/InputUserPosition'
import InputUserPrivilege from '../input/InputUserPrivilege'

const FormCreateUser = ({ aftersuccess }: { aftersuccess?: () => any }) => {
    const form = useForm<IRequestCreateUser>({
        initialValues: {
            username: '',
            user_fullname: '',
            user_email: '',
            password: '',
            confirmpassword: '',
            user_position_id: undefined,
            privilege_id: undefined,
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = User.useCreateUser({
        form,
        aftersuccess: () => navigate('/users'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อผู้ใช้งาน'
                        placeholder='ชื่อผู้ใช้งาน'
                        {...form.getInputProps('username')}
                    />
                    <TextInput
                        withAsterisk
                        label='ชื่อจริงผู้ใช้งาน'
                        placeholder='ชื่อจริงผู้ใช้งาน'
                        {...form.getInputProps('user_fullname')}
                    />
                    <TextInput
                        label='อีเมล'
                        withAsterisk
                        placeholder='อีเมล'
                        {...form.getInputProps('user_email')}
                    />
                    <InputUserPosition
                        withAsterisk
                        {...form.getInputProps('user_position_id')}
                    />
                    <InputUserPrivilege
                        withAsterisk
                        {...form.getInputProps('privilege_id')}
                    />
                    <PasswordInput
                        withAsterisk
                        label='รหัสผ่าน'
                        placeholder='รหัสผ่าน'
                        {...form.getInputProps('password')}
                    />
                    <PasswordInput
                        withAsterisk
                        label='ยืนยันรหัสผ่าน'
                        placeholder='ยืนยันรหัสผ่าน'
                        {...form.getInputProps('confirmpassword')}
                    />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/users'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            สร้างผู้ใช้งานระบบ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormCreateUser

import { Paper, Title } from '@mantine/core'
import React from 'react'

const PageLayout: React.FC<{
    children?: React.ReactNode
    pageTitle?: string
    spaceAround?: boolean
}> = ({ children, pageTitle, spaceAround = true }) => {
    return (
        <Paper
            pos='relative'
            style={{ overflowY: 'auto', zIndex: 1, overflowX: 'hidden' }}
            mih='100vh'
            w='100%'
            bg='white'
            p={spaceAround ? 'lg' : '0px'}
        >
            {pageTitle && (
                <Title order={2} p={spaceAround ? '0px' : 'lg'} mb='sm'>
                    {pageTitle}
                </Title>
            )}
            {children}
        </Paper>
    )
}

export default PageLayout

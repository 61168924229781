import { IResponseAllAgenciesRowData } from '@interfaces/api/IAgency'

const mapAgencyTable = (data: IResponseAllAgenciesRowData[]) => {
    const mapData = data.map((value) => {
        return {
            ไอดีลูกค้า: value.agency_id,
            ชื่อลูกค้า: value.agency_name,
            อีเมล: value.agency_email,
            เบอร์โทร: value.agency_phone,
            ที่อยู่: value.agency_address,
            ไอดีประเภท: value.agency_type_id,
            ชื่อประเภท: value.agency_type_name,
            ลูกค้าเมื่อปีงบประมาณ: value.budget_year,
            ไอดีสถานะข้อมูล: value.status,
            ชื่อสถานะข้อมูล: value.status_description,
            สร้างเมื่อ: value.created_at,
            สร้างโดยผู้ใช้งานไอดี: value.created_by_user_id,
            สร้างโดยผู้ใช้งานชื่อ: value.created_by_user_fullname,
            แก้ไขเมื่อ: value.updated_at,
            แก้ไขโดยผู้ใช้งานไอดี: value.updated_by_user_id,
            แก้ไขโดยผู้ใช้งานชื่อ: value.updated_by_user_fullname,
        }
    })

    return mapData
}

export default mapAgencyTable

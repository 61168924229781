import accounting from 'accounting'

const Money = (money: number) => {
    return accounting.formatMoney(money, {
        symbol: '',
        precision: 2,
    })
}

export default Money

import Agency from '@api/Agency'
import FormEditAgency from '@components/form/agency/FormEditAgency'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const EditAgency = () => {
    const params = useParams()
    const agency_id = params.agency_id
    const { isLoading, isError, data, isRefetching } = Agency.useGetAgencyByID({
        agency_id: agency_id!,
    })
    if (isLoading || isRefetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าหน่วยงานทั้งหมด'
                url='/agencies'
            />
        )
    }
    return (
        <PageLayout pageTitle='แก้ไขหน่วยงาน/ลูกค้า'>
            <FormEditAgency data={data} />
        </PageLayout>
    )
}

export default EditAgency

import Project from '@api/Project'
import TableDeleteButton from '@components/button/TableDeleteButton'
import TableEditButton from '@components/button/TableEditButton'
import TableViewButton from '@components/button/TableViewButton.'
import {
    IResponseAllProjects,
    IResponseAllProjectsRowData,
} from '@interfaces/api/IProject'
import { Flex, Text } from '@mantine/core'
import { modals } from '@mantine/modals'
import accounting from 'accounting'
import React from 'react'
import { QueryObserverResult } from 'react-query'
import { Link } from 'react-router-dom'
import ProjectProgressColumn from './ProjectProgressColumn'

const AllProjectTableRow = ({
    data,
    refreshTable,
}: {
    data: IResponseAllProjectsRowData
    refreshTable: () => Promise<
        QueryObserverResult<IResponseAllProjects, unknown>
    >
}) => {
    const reqDeleteProjectVendor = Project.useDeleteProject({
        project_id: data.project_id,
        aftersuccess: refreshTable,
    })

    const openDeleteConfirmModal = () =>
        modals.openConfirmModal({
            title: 'กรุณายืนยันการลบ',
            children: (
                <Text>
                    ลบโครงการ <br />
                    {data.project_name}
                </Text>
            ),
            labels: { confirm: 'ยืนยัน', cancel: 'ยกเลิก' },
            onConfirm: reqDeleteProjectVendor.onSubmit,
        })

    return (
        <tr key={data.project_id}>
            <td>{data.project_code}</td>
            <td>{data.project_name}</td>
            <td>
                {accounting.formatMoney(data.project_budget, {
                    symbol: '',
                    precision: 2,
                })}
            </td>
            <td>
                {data.total_project_lecturer
                    ? data.total_project_lecturer
                    : 'ไม่มี'}
            </td>
            <td>
                {data.total_project_vendor
                    ? data.total_project_vendor
                    : 'ไม่มี'}
            </td>
            <td>
                {data.project_evaluation_result
                    ? data.project_evaluation_result
                    : 'ยังไม่ประเมิน'}
            </td>
            <ProjectProgressColumn {...data} />
            {/* <DateAndFullNameColumn
                day={data.created_at}
                fullname={data.created_by_user_fullname}
                user_id={data.created_by_user_id}
            />
            <DateAndFullNameColumn
                day={data.updated_at}
                fullname={data.updated_by_user_fullname}
                user_id={data.updated_by_user_id}
            /> */}
            <td>
                <Flex gap='xs' pos='relative'>
                    <TableViewButton
                        component={Link}
                        to={`/projects/${data.project_id}`}
                    />
                    <TableEditButton
                        component={Link}
                        to={`/projects/${data.project_id}#edit`}
                    />
                    <TableDeleteButton onClick={openDeleteConfirmModal} />
                </Flex>
            </td>
        </tr>
    )
}

export default AllProjectTableRow

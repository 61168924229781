import TableHeaderColumn from '@components/table/template/TableHeaderColumn'
import TextChartTitle from '@components/text/TextChartTitle'
import { IResponseGetProjectDataForDashboardRowData } from '@interfaces/api/IDashboard'
import { Progress, Table } from '@mantine/core'
import Money from '@utils/Money'
import _ from 'lodash'
import React from 'react'
import ChartFlexSection from './ChartFlexSection'
interface IChairmanRankingMutipleYearsChart {
    data: IResponseGetProjectDataForDashboardRowData[]
    title: string
}

const ChairmanRankingMutipleYearsChart = ({
    data,
    title,
}: IChairmanRankingMutipleYearsChart) => {
    const preparedata = _(data)
        .groupBy('chairman_fullname')
        .map((values, key) => ({
            chairman_fullname: key,
            avg_project_rating: _.meanBy(values, (p) =>
                Number(p.project_evaluation_result)
            ),
            total_project_amount: _.uniqBy(values, 'project_id').length,
            total_project_budget: _.sumBy(
                values.map((e) => {
                    return {
                        ...e,
                        project_budget: Number(e.project_budget),
                    }
                }),
                'project_budget'
            ),
        }))
        .value()

    preparedata.sort(
        (a, b) =>
            Number(b.total_project_budget) - Number(a.total_project_budget)
    )
    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <Table pos={'relative'}>
                <thead>
                    <tr>
                        <TableHeaderColumn label={'อันดับ'} width={'5%'} />
                        <TableHeaderColumn
                            label={'ประธานโครงการ'}
                            width={'25%'}
                        />
                        <TableHeaderColumn
                            label={'ค่าเฉลี่ยความพึงพอใจโครงการ'}
                            width={'10%'}
                        />
                        <TableHeaderColumn label={'งบประมาณ'} width={'10%'} />
                        <TableHeaderColumn label={''} width={''} />
                    </tr>
                </thead>
                <tbody>
                    {preparedata.map((p, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{p.chairman_fullname}</td>
                            <td>{p.avg_project_rating.toFixed(2)}</td>
                            <td>{Money(Number(p.total_project_budget))}</td>
                            <td>
                                <Progress
                                    w='100%'
                                    size='xl'
                                    sections={[
                                        {
                                            value:
                                                Number(p.total_project_budget) /
                                                (Number(
                                                    preparedata[0]
                                                        .total_project_budget
                                                ) /
                                                    100),
                                            color: 'blue',
                                        },
                                    ]}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </ChartFlexSection>
    )
}

export default ChairmanRankingMutipleYearsChart

import {
    AxiosError,
    AxiosRequestConfig,
    AxiosResponse,
    isAxiosError,
} from 'axios'
import { UseFormReturnType } from '@mantine/form'
import api from '@config/api'
import { notifications } from '@mantine/notifications'
import { IconCheck, IconExclamationCircle } from '@tabler/icons-react'
import { IRequest, IValidationError } from '@interfaces/api/IAxiosRequest'
import { useMutation } from 'react-query'

export function useFormMutationHandler<T, K>({
    form,
    data = {},
    withToken,
    aftersubmit,
    aftersuccess,
    pathWithMethod,
}: {
    form?: UseFormReturnType<T, (values: T) => T>
    data?: T | {}
    withToken?: string
    aftersuccess?: (aftersuccess?: K) => void
    aftersubmit?: (aftersuccess?: K) => void
    pathWithMethod: [path: string, method: 'put' | 'post' | 'delete']
}) {
    const onSuccess: (
        res: AxiosResponse<IRequest<K>>,
        variables: T,
        context: unknown
    ) => void | Promise<unknown> = (res, variables, context) => {
        notifications.show({
            title: res.data.message,
            message: '',
            color: 'teal',
            icon: <IconCheck size='1rem' />,
        })
        if (typeof aftersuccess === 'function') {
            aftersuccess(res.data.body)
        }
    }

    const onError: (
        error: AxiosError<IValidationError>,
        variables: T,
        context: unknown
    ) => void | Promise<unknown> = (error, variables, context) => {
        const errorProps: { [key: string]: string } = {}

        if (isAxiosError(error)) {
            notifications.show({
                title: 'เกิดข้อผิดพลาด',
                message: error.response?.data.message,
                color: 'red',
                icon: <IconExclamationCircle size='1rem' />,
            })
            if (error.response?.data.body.length) {
                error.response?.data.body.forEach(({ errormsg, errorpath }) => {
                    errorProps[errorpath] = errormsg
                })
                if (form) {
                    form.setErrors(errorProps)
                }
            }
        }
    }

    const onSettled:
        | ((
              data: AxiosResponse<IRequest<any>, any> | undefined,
              error: AxiosError<IValidationError, any> | null,
              variables: T,
              context: unknown
          ) => void | Promise<unknown>)
        | undefined = (res, error, variables, context) => {
        if (typeof aftersubmit === 'function' && res) {
            aftersubmit(res.data.body)
        }
    }

    const [path, method] = pathWithMethod
    const { isError, isLoading, isIdle, isPaused, isSuccess, mutate } =
        useMutation({
            mutationFn: (data: T) => {
                const headers: {
                    'Content-Type'?: string
                    Authorization?: string
                } = {}
                if (withToken) {
                    headers['Authorization'] = withToken
                }

                // console.log('withToken=>', withToken)
                return api[method](path, data as T & AxiosRequestConfig<T>, {
                    headers: headers,
                })
            },
            onError,
            onSuccess,
            onSettled,
        })

    const onSubmit = form
        ? form.onSubmit(() => mutate(form.values))
        : () => mutate(data as T)

    return { isError, isLoading, isIdle, isPaused, isSuccess, onSubmit }
}

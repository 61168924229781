import Project from '@api/Project'
import { Flex } from '@mantine/core'
import { useEffect, useState } from 'react'
import DateAndFullNameColumn from '@components/table/template/DateAndFullNameColumn'
import TablePagination from '@components/table/template/TablePagination'
import {
    IProjectID,
    IResponseAllProjectUpdateHistoriesRowData,
} from '@interfaces/api/IProject'
import TableTemplate from '@components/table/template/TableTemplate'
import { ITHeadMap } from '@interfaces/ITable'
import TableSearchFilter from '@components/table/filter/TableSearchFilter'

const TableProjectUpdateHistories = ({ project_id }: IProjectID) => {
    const [limit, setlimit] = useState(10)
    const [orderby, setorderby] =
        useState<keyof IResponseAllProjectUpdateHistoriesRowData>('update_id')
    const [order, setorder] = useState('desc')
    const [page, setpage] = useState(1)
    const [search, setsearch] = useState('')

    const histories = Project.useGetAllProjectUpdateHistories(project_id, {
        limit,
        orderby,
        order,
        page,
        search,
    })

    useEffect(() => {
        histories.refetch()
        // eslint-disable-next-line
    }, [page, limit, order, orderby])

    const thead: ITHeadMap<IResponseAllProjectUpdateHistoriesRowData>[] = [
        {
            label: 'ลำดับ',
            width: '7%',
        },
        {
            label: 'หัวข้อการแก้ไข',
            width: '20%',
        },
        {
            label: 'รายละเอียดการแก้ไข',
            width: '53%',
        },

        {
            sortkey: 'created_at',
            label: 'สร้างเมื่อ',
            width: '10%',
        },
    ]

    return (
        <>
            <Flex gap='md' direction={{ xs: 'column', lg: 'row' }}>
                <TableSearchFilter
                    page={page}
                    setpage={setpage}
                    setsearch={setsearch}
                    search={search}
                    refresh={histories.refetch}
                />
            </Flex>

            <TableTemplate<IResponseAllProjectUpdateHistoriesRowData>
                thead={thead}
                orderby={orderby}
                order={order}
                setorder={setorder}
                setorderby={setorderby}
                lists={histories?.data?.lists}
            >
                {histories?.data?.lists.map((value, index) => (
                    <tr key={index}>
                        <td>
                            {order === 'asc'
                                ? index + 1 + limit * (page - 1)
                                : histories.data.totalRows -
                                  index -
                                  limit * (page - 1)}
                        </td>
                        <td>{value.update_title}</td>
                        <td>{value.update_detail}</td>
                        <DateAndFullNameColumn
                            day={value.created_at}
                            fullname={value.created_by_user_fullname}
                            user_id={value.created_by_user_id}
                        />
                    </tr>
                ))}
            </TableTemplate>
            {histories.data?.lists.length ? (
                <TablePagination
                    limit={histories.data?.lists.length}
                    setlimit={setlimit}
                    setpage={setpage}
                    page={page}
                    totalPages={histories.data.totalPages}
                    totalRows={histories.data.totalRows}
                />
            ) : (
                <></>
            )}
        </>
    )
}

export default TableProjectUpdateHistories

import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'
import FormEditOrganization from '@components/form/chairman/organization/FormEditOrganization'
import ChairmanOrganization from '@api/ChairmanOrganization'
const EditOrganization = () => {
    const params = useParams()
    const chairman_organization_id = params.chairman_organization_id
    const { isLoading, isError, data, isRefetching } =
        ChairmanOrganization.useGetChairmanOrganizationByID({
            chairman_organization_id: chairman_organization_id!,
        })
    if (isLoading || isRefetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าสังกัดประธานโครงการ'
                url='/chairmans/organizations'
            />
        )
    }
    return (
        <PageLayout pageTitle='แก้ไขสังกัดประธานโครงการ'>
            <FormEditOrganization data={data} />
        </PageLayout>
    )
}

export default EditOrganization

import {
    IResponseGetProjectDataForDashboardRowData,
    IResponseGetProjectLecturerDataForDashboardRowData,
    IResponseGetProjectVendorDataForDashboardRowData,
} from '@interfaces/api/IDashboard'
import * as XLSX from 'xlsx'
import excelFormatCellAuthFitWidth from './excelFormatCellAuthFitWidth'

interface Data {
    projectData: IResponseGetProjectDataForDashboardRowData[]
    projectLecturerData: IResponseGetProjectLecturerDataForDashboardRowData[]
    projectVendorData: IResponseGetProjectVendorDataForDashboardRowData[]
}

function exportDataDashboardToExcel(data: Data, filename: string) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new()
    const prepareProjectData = data.projectData.map((p) => ({
        ไอดีโครงการ: p.project_id,
        ปีงบประมาณโครงการ: p.agency_budget_year,
        รหัสโครงการ: p.project_code,
        ชื่อโครงการ: p.project_name,
        ชื่อลูกค้า: p.agency_name,
        ประเภทลูกค้า: p.agency_type_name,
        ลูกค้าเมื่อปีงบประมาณ: p.agency_budget_year,
        ชื่อประธานโครงการ: p.chairman_fullname,
        ชื่อผู้ประสานงาน: p.coordinator_fullname,
        งบประมาณโครงการ: p.project_budget,
        คะแนนความพึงพอใจ: p.project_evaluation_result,
        โครงการในไตรมาสที่: p.quarter,
        ไอดีสถานะโครงการ: p.project_progress_status_id,
        ค่าความคืบหน้าโครงการ: p.project_progress,
        สีสถานะความคืบหน้าโครงการ: p.project_progress_status_color,
        ชื่อสถานะความคืบหน้าโครงการ: p.project_progress_status_name,
        สร้างเมื่อ: p.created_at,
        แก้ไขเมื่อ: p.updated_at,
    }))

    const prepareProjectLecturerData = data.projectLecturerData.map((p) => ({
        ไอดีโครงการ: p.project_id,
        ไอดีวิทยากร: p.lecturer_id,
        ชื่อวิทยากร: p.lecturer_fullname,
        งบประมาณโครงการ: p.project_budget,
        ปีงบประมาณโครงการ: p.project_budget_year,
        โครงการในไตรมาสที่: p.quarter,
        คะแนนความพึงพอใจวิทยากร: p.project_evaluation_result,
        คะแนนความพึงพอใจโครงการ: p.lecturer_evaluation_result,
        ความเชี่ยวชาญ: p.lecturer_expertises_name,
    }))

    const prepareProjectVendorData = data.projectVendorData.map((p) => ({
        ไอดีโครงการ: p.project_id,
        ไอดีผู้ค้า: p.vendor_id,
        ชื่อผู้ค้า: p.vendor_name,
        ความเชี่ยวชาญ: p.vendor_types_name,
        งบประมาณโครงการ: p.project_budget,
        ปีงบประมาณโครงการ: p.project_budget_year,
        ผู้ค้าเมื่อปีงบประมาณ: p.vendor_budget_year,
        ผลประเมินการประสานงานก่อนการซื้อขายหรือบริการ:
            p.vendor_evaluation_factor_1,
        ผลประเมินการซื้อขายและการให้บริการระหว่างการซื้อขายหรือบริการ:
            p.vendor_evaluation_factor_2,
        ผลประเมินการให้บริการหลังการขายหรือบริการ: p.vendor_evaluation_factor_3,
        ผลรวมการประเมิน: p.vendor_evaluation_result,
        ผลการประเมิน: p.vendor_evaluation_grade,
    }))

    // Create worksheets
    const worksheet1 = XLSX.utils.json_to_sheet(prepareProjectData)
    const worksheet2 = XLSX.utils.json_to_sheet(prepareProjectLecturerData)
    const worksheet3 = XLSX.utils.json_to_sheet(prepareProjectVendorData)

    excelFormatCellAuthFitWidth(worksheet1, prepareProjectData as any)
    excelFormatCellAuthFitWidth(worksheet2, prepareProjectLecturerData as any)
    excelFormatCellAuthFitWidth(worksheet3, prepareProjectVendorData as any)

    // Add worksheets to the workbook with different names
    XLSX.utils.book_append_sheet(workbook, worksheet1, 'project')
    XLSX.utils.book_append_sheet(workbook, worksheet2, 'lecturer')
    XLSX.utils.book_append_sheet(workbook, worksheet3, 'vendor')

    // Generate Excel data as an array buffer
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

    // Create a blob from the array buffer
    const blob = new Blob([excelData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Create a URL for the blob
    const url = URL.createObjectURL(blob)

    // Create a download link and trigger the download
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.click()

    // Clean up by revoking the blob URL
    URL.revokeObjectURL(url)
}

export default exportDataDashboardToExcel

import { Accordion, Flex, Text } from '@mantine/core'

import FormEvaluationProject from './FormEvaluationProject'
import FormEvaluationProjectLecturer from './FormEvaluationProjectLecturer'
import FormEvaluationProjectVendor from './FormEvaluationProjectVendor'

const FormEvaluationAll = ({
    project_id,
    editMode,
    refetchProjectInfo,
}: {
    project_id: number | string
    editMode: boolean
    refetchProjectInfo: () => void
}) => {
    return (
        <Accordion variant='separated'>
            <EvaluationSection
                section_id='project_evaluation'
                section_label='ประเมินความพึงพอใจโครงการ'
            >
                <FormEvaluationProject
                    project_id={project_id}
                    editMode={editMode}
                />
            </EvaluationSection>
            <EvaluationSection
                section_id='project_lecturer_evaluation'
                section_label='ประเมินวิทยากรโครงการ'
            >
                <FormEvaluationProjectLecturer
                    editMode={editMode}
                    project_id={project_id}
                    refetchProjectInfo={refetchProjectInfo}
                />
            </EvaluationSection>
            <EvaluationSection
                section_id='project_vendor_evaluation'
                section_label='ประเมินคู่ค้าโครงการ'
            >
                <FormEvaluationProjectVendor
                    editMode={editMode}
                    project_id={project_id}
                    refetchProjectInfo={refetchProjectInfo}
                />
            </EvaluationSection>
        </Accordion>
    )
}

export default FormEvaluationAll

const EvaluationSection = ({
    section_id,
    section_label,
    children,
}: {
    section_id: string
    section_label: string
    children: React.ReactElement
}) => {
    return (
        <Accordion.Item value={section_id}>
            <Accordion.Control px='md'>
                <Flex justify='space-between' align='center'>
                    <Text size='md'>{section_label}</Text>
                    <Flex
                        w='70%'
                        justify='end'
                        gap='xs'
                        align='center'
                        display={{ xs: 'none', md: 'flex' }}
                    ></Flex>
                </Flex>
            </Accordion.Control>
            <Accordion.Panel pos='relative'>{children}</Accordion.Panel>
        </Accordion.Item>
    )
}

import React from 'react'

const TableRowOneColumn: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    return (
        <tr>
            <td
                colSpan={7}
                rowSpan={4}
                style={{ textAlign: 'center', padding: '50px' }}
            >
                {children}
            </td>
        </tr>
    )
}

export default TableRowOneColumn

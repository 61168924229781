import PageLayout from '@components/page/PageLayout'
import TableAllProject from '@components/table/database/project/TableAllProject'

const AllMyProject = () => {
    return (
        <PageLayout pageTitle='โครงการที่รับผิดชอบ'>
            <TableAllProject defaultCheckOwner={1} />
        </PageLayout>
    )
}

export default AllMyProject

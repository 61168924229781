import { useForm } from '@mantine/form'
import {
    Button,
    Flex,
    LoadingOverlay,
    Paper,
    Textarea,
    TextInput,
} from '@mantine/core'

import { IRequestCreateAgency } from '@interfaces/api/IAgency'
import Agency from '@api/Agency'
import InputBudgetYear from '@components/form/input/InputBudgetYear'
import InputAgencyType from '@components/form/input/InputAgencyType'
import { closeModal, modals } from '@mantine/modals'
import { Link, useNavigate } from 'react-router-dom'

export const openFormCreateAgencyModal = ({
    aftersuccess,
}: {
    aftersuccess: () => any
}) => {
    return modals.open({
        modalId: 'createagency',
        title: 'สร้างหน่วยงาน',
        children: <FormCreateAgency aftersuccess={aftersuccess} />,
    })
}

const FormCreateAgency = ({ aftersuccess }: { aftersuccess?: () => any }) => {
    const form = useForm<IRequestCreateAgency>({
        initialValues: {
            agency_name: '',
            budget_year: undefined,
            agency_type_id: undefined,
            agency_address: '',
            agency_detail: '',
            agency_email: '',
            agency_phone: '',
        },
    })
    const navigate = useNavigate()

    const onAfterSuccess = () => {
        closeModal('createagency')
        if (aftersuccess) {
            aftersuccess()
        } else {
            navigate('/agencies')
        }
    }

    const { isLoading, onSubmit } = Agency.useCreateAgency({
        form,
        aftersuccess: onAfterSuccess,
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อหน่วยงาน'
                        placeholder='ชื่อหน่วยงาน'
                        {...form.getInputProps('agency_name')}
                    />
                    <InputAgencyType
                        withAsterisk
                        {...form.getInputProps('agency_type_id')}
                    />
                    <InputBudgetYear
                        withAsterisk
                        {...form.getInputProps('budget_year')}
                    />
                    <TextInput
                        label='อีเมล'
                        placeholder='อีเมล'
                        {...form.getInputProps('agency_email')}
                    />
                    <TextInput
                        label='เบอร์โทร'
                        placeholder='เบอร์โทร'
                        {...form.getInputProps('agency_phone')}
                    />

                    <TextInput
                        label='ที่อยู่'
                        placeholder='ที่อยู่'
                        {...form.getInputProps('agency_address')}
                    />
                    <Textarea
                        rows={2}
                        minRows={2}
                        label='เกี่ยวกับหน่วยงาน'
                        placeholder='เกี่ยวกับหน่วยงาน'
                        {...form.getInputProps('agency_detail')}
                    />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/agencies'
                            onClick={() => closeModal('createagency')}
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            สร้างหน่วยงาน
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormCreateAgency

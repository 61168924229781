import { Select, SelectItem } from '@mantine/core'
import { useEffect, useState } from 'react'
import { SelectProps } from '@mantine/core/lib/Select/Select'
import Status from '@api/Status'
interface IInputStatus
    extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
    withAll?: boolean
}

interface ItemProps extends SelectItem {}

const InputStatus = ({
    withAll = false,
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: IInputStatus) => {
    const { data } = Status.useGetAllDataSelectStatus()
    const [list, setlist] = useState<ItemProps[]>([])
    useEffect(() => {
        if (data) {
            const selectlists = data.map((e) => {
                return {
                    value: String(e.status),
                    label: e.status_description,
                }
            })
            if (withAll) {
                selectlists.unshift({
                    value: '',
                    label: 'ทั้งหมด',
                })
            }
            setlist(selectlists)
        }
        // eslint-disable-next-line
    }, [data])

    return (
        <Select
            key={list.length}
            className={className}
            label='สถานะข้อมูล'
            value={String(value)}
            placeholder='สถานะข้อมูล'
            data={list}
            filter={(value, item: ItemProps) =>
                item.status_description
                    .toLowerCase()
                    .includes(value.toLowerCase().trim())
            }
            {...others}
        />
    )
}

export default InputStatus

import { Flex, Image, Text } from '@mantine/core'
export function Logo(props: React.ComponentPropsWithoutRef<'svg'>) {
    return (
        <Flex justify='center' wrap='nowrap' align='center' gap='sm'>
            <Image
                src='/assets/Srinakharinwirot_Logo_TH_White.png'
                width={50}
                w='50px'
            />
            <Text
                size='10px'
                color='white'
                style={{ whiteSpace: 'nowrap' }}
                lh='15px'
            >
                ระบบติดตามผลการดำเนินโครงการ
                <br />
                ศูนย์บริการวิชาการ
                <br />
                มหาวิทยาลัยศรีนครินทรวิโรฒ
            </Text>
        </Flex>
    )
}

import { useForm } from '@mantine/form'
import { IRequestUpdateProjectPeriod } from '@interfaces/api/IProject'
import {
    Accordion,
    Button,
    Flex,
    Group,
    LoadingOverlay,
    NumberInput,
    Paper,
    Text,
} from '@mantine/core'

import { DateValue } from '@mantine/dates'
import InputDate from '@components/form/input/InputDate'
import dayjs from 'dayjs'
import InputCurrency from '../input/InputCurrency'
import Project from '@api/Project'
import LoadingPage from '@components/loading/LoadingPage'
import PageNotFound404 from '@pages/PageNotFound404'
import { IResponseAllProjectPeriodsRowData } from '../../../interfaces/api/IProject'
import ProgressBadge from '@components/badge/ProgressBadge'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'
import { v4 } from 'uuid'

const FormViewAndUpdateProjectPeriod = ({
    project_id,
    refetchProjectInfo,
    editMode,
}: {
    project_id: number | string
    refetchProjectInfo: () => void
    editMode: boolean
}) => {
    let projectperiods = Project.useGetAllProjectPeriodByID({
        project_id: project_id,
    })

    if (projectperiods.isLoading) {
        return <LoadingPage />
    }
    if (projectperiods.isError) {
        return <PageNotFound404 />
    }

    console.log(projectperiods?.data)

    const updatePeriodInfo = (target: number) => {
        return (info: IResponseAllProjectPeriodsRowData) => {
            if (
                projectperiods.data![target].period_progress !==
                info.period_progress
            ) {
                projectperiods.data![target] = info
                refetchProjectInfo()
            }
        }
    }

    return (
        <Accordion variant='separated'>
            {projectperiods.data?.length ? (
                projectperiods.data?.map((p, index) => {
                    return (
                        <ProjectPeriodViewAndEditComponent
                            editMode={editMode}
                            key={p.project_id + p.period_id + v4()}
                            data={p}
                            updatePeriodInfo={updatePeriodInfo(index)}
                        />
                    )
                })
            ) : (
                <Paper p='lg'>
                    <Group position='center'>ไม่พบข้อมูลงวดงาน</Group>
                </Paper>
            )}
        </Accordion>
    )
}

export default FormViewAndUpdateProjectPeriod

const ProjectPeriodViewAndEditComponent = ({
    data,
    editMode,
    updatePeriodInfo,
}: {
    data: IResponseAllProjectPeriodsRowData
    editMode: boolean
    updatePeriodInfo: (info: IResponseAllProjectPeriodsRowData) => void
}) => {
    const form = useForm<IRequestUpdateProjectPeriod>({
        initialValues: {
            period_budget:
                data.period_budget > 0 ? Number(data.period_budget) : null,
            period_start_date: new Date(data.period_start_date!),
            period_end_date: data.period_end_date,
            period_duration: data.period_duration
                ? Number(data.period_duration)
                : '',
            period_report_submission_date: data.period_report_submission_date,
            disbursement_submission_date: data.disbursement_submission_date,
            disbursement_received_date: data.disbursement_received_date,
            chairman_disbursement_date: data.chairman_disbursement_date,
        },
    })
    const { project_id, period_id } = data

    const { isLoading, onSubmit } = Project.useUpdateProjectPeriod({
        project_id,
        period_id,
        form,
        aftersuccess: updatePeriodInfo,
    })

    const onPeriodStartDateChange = (value: DateValue) => {
        const end_date = dayjs(value)
            .add(form.getInputProps('period_duration').value, 'day')
            .toDate()
        form.setFieldValue('period_end_date', end_date)
        return form.setFieldValue('period_start_date', value)
    }

    const onPeriodDurationChange = (value: number) => {
        if (form.getInputProps('period_start_date').value) {
            const start_date = form.getInputProps('period_start_date').value
            const new_date = dayjs(start_date).add(value, 'day').toDate()
            form.setFieldValue('period_end_date', new_date)
        }
        return form.setFieldValue('period_duration', value)
    }
    const onPeriodEndDateChange = (value: DateValue) => {
        if (form.getInputProps('period_start_date').value) {
            const start_date = dayjs(
                form.getInputProps('period_start_date').value
            )
            const end_date = dayjs(value)
            const duration = end_date.diff(start_date, 'day')
            form.setFieldValue('period_duration', duration)
        }
        return form.setFieldValue('period_end_date', value)
    }

    return (
        <Accordion.Item value={String(data.period_id)}>
            <Accordion.Control px='md'>
                <Flex justify='space-between' align='center'>
                    <Text size='md'>งวดงานที่ {data.period_id}</Text>
                    <Flex
                        w='70%'
                        justify='end'
                        gap='xs'
                        align='center'
                        display={{ xs: 'none', md: 'flex' }}
                    >
                        <ProgressBadge
                            progress_color={data.period_progress_status_color}
                            progress_label={data.period_progress_status_name}
                            progress_score={data.period_progress}
                        />
                    </Flex>
                </Flex>
            </Accordion.Control>
            <Accordion.Panel pos='relative'>
                <LoadingOverlay visible={isLoading} overlayBlur={2} />
                <form
                    onSubmit={onSubmit}
                    style={{ pointerEvents: editMode ? 'all' : 'none' }}
                >
                    <Flex gap='sm' direction='column'>
                        <InputCurrency
                            label='งบประมาณ'
                            {...form.getInputProps('period_budget')}
                        />
                        <InputDate
                            disabled={editMode ? true : false}
                            label='วันเริ่มงวดงาน'
                            placeholder='วันเริ่มงวดงาน'
                            {...form.getInputProps('period_start_date')}
                            onChange={onPeriodStartDateChange}
                        />
                        <NumberInput
                            label='ระยะเวลางวดงาน'
                            placeholder='ระยะเวลางวดงาน'
                            {...form.getInputProps('period_duration')}
                            onChange={onPeriodDurationChange}
                        />
                        <InputDate
                            label='วันสิ้นสุดงวดงาน'
                            placeholder='วันสิ้นสุดงวดงาน'
                            {...form.getInputProps('period_end_date')}
                            onChange={onPeriodEndDateChange}
                            minDate={
                                form.getInputProps('period_start_date').value
                            }
                        />
                        <InputDate
                            clearable
                            label='วันที่ส่งรายงานงวด'
                            placeholder='วันที่ส่งรายงานงวด'
                            {...form.getInputProps(
                                'period_report_submission_date'
                            )}
                        />
                        <InputDate
                            clearable
                            label='วันที่ส่งหนังสือเบิกเงิน'
                            placeholder='วันที่ส่งหนังสือเบิกเงิน'
                            {...form.getInputProps(
                                'disbursement_submission_date'
                            )}
                        />
                        <InputDate
                            clearable
                            label='วันที่หน่วยงานโอนเงินมายังศูนย์'
                            placeholder='วันที่หน่วยงานโอนเงินมายังศูนย์'
                            {...form.getInputProps(
                                'disbursement_received_date'
                            )}
                        />
                        <InputDate
                            clearable
                            label='วันที่ประธานเบิกเงิน'
                            placeholder='วันที่ประธานเบิกเงิน'
                            {...form.getInputProps(
                                'chairman_disbursement_date'
                            )}
                        />
                        {editMode ? (
                            <Button
                                type='submit'
                                w='fit-content'
                                mt='md'
                                ml='auto'
                            >
                                บันทึกการแก้ไขโครงการ
                            </Button>
                        ) : (
                            <Flex justify='end' gap='md'>
                                <TextCreatedBy {...data} />
                                <TextUpdatedBy {...data} />
                            </Flex>
                        )}
                    </Flex>
                </form>
            </Accordion.Panel>
        </Accordion.Item>
    )
}

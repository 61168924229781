import api from '@config/api'
import { useQuery } from 'react-query'
import {
    IRequestAllProjectsProps,
    IRequestCreateProject,
    IRequestUpdateProjectInfo,
    IRequestAddAndUpdateProjectLecturer,
    IRequestUpdateProjectPeriod,
    IResponseAllProjectLecturersRowData,
    IResponseAllProjectPeriodsRowData,
    IResponseAllProjects,
    IResponseProjectInfo,
    IRequestAddAndUpdateProjectVendor,
    IResponseAllProjectVendorsRowData,
    IRequestEvaluationProject,
    IRequestEvaluationProjectLecturer,
    IRequestEvaluationProjectVendor,
    IRequestUpdateCloseProjectInfo,
    IRequestUpdateProjectCompleteStatus,
    IRequestAllProjectUpdateHistories,
    IResponseAllProjectUpdateHistories,
    IRequestGetProjectsOverview,
    IResponseGetProjectsOverview,
} from '@interfaces/api/IProject'
import { useFormMutationHandler } from '@utils/useFormMutationHandler'
import { useQueryConfig } from './useQueryConfig'
import { IVendorID } from '../interfaces/api/IVendor'
import { ILecturerID } from '../interfaces/api/ILecturer'
import { IProjectID, IProjectPeriodID, IProjectVendorID, IProjectLecturerID } from '@interfaces/api/IProject'
import { IDataID } from '@interfaces/default/IDefault'
import { IFormMutationHandlerProps } from '../interfaces/default/IDefault'
interface IUseCreateProject extends IFormMutationHandlerProps<IRequestCreateProject> {}

interface IUseUpdateProjectInfo extends IProjectID, IFormMutationHandlerProps<IRequestUpdateProjectInfo> {}

interface IUseDeleteProject extends IProjectID, IFormMutationHandlerProps<IProjectID> {}

interface IUseUpdateProjectPeriod
    extends IProjectID,
        IProjectPeriodID,
        IFormMutationHandlerProps<IRequestUpdateProjectPeriod> {}

interface IUseUpdateProjectLecturer
    extends IProjectID,
        IProjectLecturerID,
        IFormMutationHandlerProps<IRequestAddAndUpdateProjectLecturer> {}

interface IUseAddProjectLecturer
    extends IProjectID,
        ILecturerID,
        IFormMutationHandlerProps<IRequestAddAndUpdateProjectLecturer> {}

interface IUseUpdateProjectVendor
    extends IProjectID,
        IProjectVendorID,
        IFormMutationHandlerProps<IRequestAddAndUpdateProjectVendor> {}

interface IUseAddProjectVendor
    extends IProjectID,
        IVendorID,
        IFormMutationHandlerProps<IRequestAddAndUpdateProjectVendor> {}

interface IUseEvaluationProject extends IProjectID, IFormMutationHandlerProps<IRequestEvaluationProject> {}

interface IUseEvaluationProjectLecturer
    extends IProjectID,
        IProjectLecturerID,
        IFormMutationHandlerProps<IRequestEvaluationProjectLecturer> {}

interface IUseEvaluationProjectVendor
    extends IProjectID,
        IProjectVendorID,
        IFormMutationHandlerProps<IRequestEvaluationProjectVendor> {}

interface IUseUpdateCloseProjectInfo extends IProjectID, IFormMutationHandlerProps<IRequestUpdateCloseProjectInfo> {}

interface IUseUpdateProjectCompleteStatus
    extends IProjectID,
        IFormMutationHandlerProps<IRequestUpdateProjectCompleteStatus> {}

const Project = {
    useGetAllProjects: (params: IRequestAllProjectsProps) =>
        useQuery(
            'allprojects' + params.orderby,
            () => api.get('/projects', { params }).then((res) => res.data.body as IResponseAllProjects),
            useQueryConfig
        ),

    useGetAllProjectsOverview: (params: IRequestGetProjectsOverview) =>
        useQuery(
            'projectsoverview' + params.checkowner,
            () =>
                api.get('/projects/overview', { params }).then((res) => res.data.body as IResponseGetProjectsOverview),
            useQueryConfig
        ),

    useGetAllProjectUpdateHistories: (project_id: IDataID, params: IRequestAllProjectUpdateHistories) =>
        useQuery(
            'allprojectupdatehistories',
            async () => {
                const res = await api.get(`/projects/${project_id}/project_update_histories`, {
                    params,
                })
                return res.data.body as IResponseAllProjectUpdateHistories
            },
            useQueryConfig
        ),
    useCreateProject: ({ form, aftersuccess }: IUseCreateProject) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: ['/projects', 'post'],
            aftersuccess,
        })
    },
    useGetProjectInfoByID: ({ project_id }: IProjectID) =>
        useQuery(
            'projectinfo' + project_id,
            async () => {
                const res = await api.get(`/projects/${project_id}/info`)
                return res.data.body as IResponseProjectInfo
            },
            useQueryConfig
        ),
    useUpdateProjectInfo: ({ project_id, form, aftersuccess }: IUseUpdateProjectInfo) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/projects/${project_id}/info`, 'put'],
            aftersuccess,
        })
    },
    useDeleteProject: ({ aftersuccess, project_id }: IUseDeleteProject) => {
        return useFormMutationHandler({
            pathWithMethod: [`/projects/${project_id}`, 'delete'],
            aftersuccess,
        })
    },
    useUpdateProjectPeriod: ({ project_id, period_id, form, aftersuccess }: IUseUpdateProjectPeriod) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/projects/${project_id}/periods/${period_id}`, 'put'],
            aftersuccess,
        })
    },
    useGetAllProjectPeriodByID: ({ project_id }: IProjectID) =>
        useQuery(
            'projectperiod' + project_id,
            () => {
                return api.get(`/projects/${project_id}/periods`).then((res) => {
                    return res.data.body as IResponseAllProjectPeriodsRowData[]
                })
            },

            useQueryConfig
        ),

    useGetAllProjectLecturerByID: ({ project_id }: IProjectID) =>
        useQuery(
            'projectlecturer' + project_id,
            async () => {
                const res = await api.get(`/projects/${project_id}/lecturers`)
                return res.data.body as IResponseAllProjectLecturersRowData[]
            },
            useQueryConfig
        ),
    useUpdateProjectLecturer: ({ project_id, project_lecturer_id, form, aftersuccess }: IUseUpdateProjectLecturer) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/projects/${project_id}/lecturers/${project_lecturer_id}`, 'put'],
            aftersuccess,
        })
    },
    useAddProjectLecturer: ({ form, aftersuccess, project_id, lecturer_id }: IUseAddProjectLecturer) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/projects/${project_id}/lecturers/${lecturer_id}`, 'post'],
            aftersuccess,
        })
    },

    useDeleteProjectLecturer: ({ form, aftersuccess, project_id, project_lecturer_id }: IUseUpdateProjectLecturer) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/projects/${project_id}/lecturers/${project_lecturer_id}`, 'delete'],
            aftersuccess,
        })
    },

    useGetAllProjectVendorByID: ({ project_id }: IProjectID) =>
        useQuery(
            'projectVendor' + project_id,
            async () => {
                const res = await api.get(`/projects/${project_id}/vendors`)
                return res.data.body as IResponseAllProjectVendorsRowData[]
            },
            useQueryConfig
        ),
    useUpdateProjectVendor: ({ project_id, project_vendor_id, form, aftersuccess }: IUseUpdateProjectVendor) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/projects/${project_id}/vendors/${project_vendor_id}`, 'put'],
            aftersuccess,
        })
    },

    useAddProjectVendor: ({ form, aftersuccess, project_id, vendor_id }: IUseAddProjectVendor) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/projects/${project_id}/vendors/${vendor_id}`, 'post'],
            aftersuccess,
        })
    },

    useDeleteProjectVendor: ({ form, aftersuccess, project_id, project_vendor_id }: IUseUpdateProjectVendor) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/projects/${project_id}/vendors/${project_vendor_id}`, 'delete'],
            aftersuccess,
        })
    },
    useEvaluationProject: ({ form, aftersuccess, project_id }: IUseEvaluationProject) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/projects/${project_id}/evaluation`, 'put'],
            aftersuccess,
        })
    },

    useEvaluationProjectLecturer: ({
        form,
        aftersuccess,
        project_id,
        project_lecturer_id,
    }: IUseEvaluationProjectLecturer) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/projects/${project_id}/lecturers/${project_lecturer_id}/evaluation`, 'put'],
            aftersuccess,
        })
    },

    useEvaluationProjectVendor: ({
        form,
        aftersuccess,
        project_id,
        project_vendor_id,
    }: IUseEvaluationProjectVendor) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/projects/${project_id}/vendors/${project_vendor_id}/evaluation`, 'put'],
            aftersuccess,
        })
    },

    useUpdateCloseProjectInfo: ({ form, aftersuccess, project_id }: IUseUpdateCloseProjectInfo) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/projects/${project_id}/update_close_project_info`, 'put'],
            aftersuccess,
        })
    },

    useUpdateProjectCompleteStatus: ({ form, aftersuccess, project_id }: IUseUpdateProjectCompleteStatus) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/projects/${project_id}/update_project_complete_status`, 'put'],
            aftersuccess,
        })
    },
}

export default Project

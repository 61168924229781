import TableHeaderColumn from '@components/table/template/TableHeaderColumn'
import TextChartTitle from '@components/text/TextChartTitle'
import { IResponseGetProjectVendorDataForDashboardRowData } from '@interfaces/api/IDashboard'
import { Table } from '@mantine/core'
import _ from 'lodash'
import React from 'react'
import ChartFlexSection from './ChartFlexSection'
interface IVendorRankingMultipleYearsChart {
    data: IResponseGetProjectVendorDataForDashboardRowData[]
    title: string
}

const VendorRankingMultipleYearsChart = ({ data, title }: IVendorRankingMultipleYearsChart) => {
    const preparedata = _(data)
        .groupBy('vendor_name')
        .map((values, key) => {
            const avg_vendor_evaluation_factor_1 = _.meanBy(values, (p) => Number(p.vendor_evaluation_factor_1))
            const avg_vendor_evaluation_factor_2 = _.meanBy(values, (p) => Number(p.vendor_evaluation_factor_2))
            const avg_vendor_evaluation_factor_3 = _.meanBy(values, (p) => Number(p.vendor_evaluation_factor_3))
            const avg_vendor_evaluation_result = _.meanBy(values, (p) => Number(p.vendor_evaluation_result))

            const avg_vendor_evaluation_grade =
                avg_vendor_evaluation_result >= 85 ? 'A' : avg_vendor_evaluation_result >= 70 ? 'B' : 'C'

            return {
                vendor_name: key,
                avg_vendor_evaluation_factor_1,
                avg_vendor_evaluation_factor_2,
                avg_vendor_evaluation_factor_3,
                avg_vendor_evaluation_result,
                avg_vendor_evaluation_grade,
                total_project_amount: _.uniqBy(values, 'project_id').length,
                total_project_budget: _.sumBy(
                    values.map((e) => {
                        return {
                            ...e,
                            project_budget: Number(e.project_budget),
                        }
                    }),
                    'project_budget'
                ),
            }
        })
        .value()

    preparedata.sort((a, b) => Number(b.avg_vendor_evaluation_result) - Number(a.avg_vendor_evaluation_result))
    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <Table pos={'relative'}>
                <thead>
                    <tr>
                        <TableHeaderColumn label='อันดับ' width='5%' />
                        <TableHeaderColumn label='ผู้ค้า' width='20%' />
                        <TableHeaderColumn label='จำนวนโครงการ' width='20%' />
                        <TableHeaderColumn label='การประสานงาน' width='5%' />
                        <TableHeaderColumn label='การซื้อขายและการให้บริการ' width='5%' />
                        <TableHeaderColumn label='การบริการหลังการขายหรือบริการ' width='5%' />
                        <TableHeaderColumn label='ผลรวมคะแนน' width='5%' />
                        <TableHeaderColumn label='ผลประเมิน' width='5%' />
                    </tr>
                </thead>
                <tbody>
                    {preparedata.map((p, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{p.vendor_name}</td>
                            <td>{p.total_project_amount}</td>
                            <td>{p.avg_vendor_evaluation_factor_1.toFixed(2)}</td>
                            <td>{p.avg_vendor_evaluation_factor_2.toFixed(2)}</td>
                            <td>{p.avg_vendor_evaluation_factor_3.toFixed(2)}</td>
                            <td>{p.avg_vendor_evaluation_result.toFixed(2)}</td>
                            <td>{p.avg_vendor_evaluation_grade}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </ChartFlexSection>
    )
}

export default VendorRankingMultipleYearsChart

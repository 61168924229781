import ProjectProgress from '@api/ProjectProgressStatus'
import { Select, SelectItem } from '@mantine/core'
import { useEffect, useState } from 'react'
import { SelectProps } from '@mantine/core/lib/Select/Select'

interface MyComponentProps
    extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    withAll?: boolean
    data?: readonly (string | SelectItem)[]
}
const InputProjectProgressStatus = ({
    withAll = true,
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: MyComponentProps) => {
    const { data } = ProjectProgress.useList()
    const [list, setlist] = useState<{ value: string; label: string }[]>([])
    useEffect(() => {
        if (data) {
            const selectlists = data.lists.map((e) => {
                return {
                    value: String(e.project_progress_status_id),
                    label: e.project_progress_status_name,
                }
            })
            if (withAll) {
                selectlists.unshift({
                    value: '',
                    label: 'ทั้งหมด',
                })
            }
            setlist(selectlists)
        }
        // eslint-disable-next-line
    }, [data])

    return (
        <Select
            key={list.length}
            className={className}
            label='สถานะ'
            value={value}
            placeholder='สถานะ'
            data={list}
            {...others}
        />
    )
}

export default InputProjectProgressStatus

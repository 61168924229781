import { IPrivilegeAccess } from '@interfaces/IAuth'

export const privilegeAccess: IPrivilegeAccess[] = [
    // 0 super admin
    {
        manageProjects: true,
        manageUsers: true,
        manageAgencies: true,
        manageVendors: true,
        manageLecturers: true,
        manageCoordinators: true,
        manageChairmans: true,
        manageExpertises: true,
        manageBudgetYears: true,
        viewDashboard: true,
        makeEvalution: true,
        makeClosedProject: true,
        downloadData: true,
    },
    // 1 admin
    {
        manageProjects: true,
        manageUsers: true,
        manageAgencies: true,
        manageVendors: true,
        manageLecturers: true,
        manageCoordinators: true,
        manageChairmans: true,
        manageExpertises: true,
        manageBudgetYears: true,
        viewDashboard: true,
        makeEvalution: true,
        makeClosedProject: true,
        downloadData: true,
    },
    // 2 high level
    {
        manageProjects: true,
        manageUsers: false,
        manageAgencies: true,
        manageVendors: true,
        manageLecturers: true,
        manageCoordinators: true,
        manageChairmans: true,
        manageExpertises: true,
        manageBudgetYears: true,
        viewDashboard: true,
        makeEvalution: true,
        makeClosedProject: true,
        downloadData: true,
    },
    // 3 manager
    {
        manageProjects: true,
        manageUsers: false,
        manageAgencies: true,
        manageVendors: true,
        manageLecturers: true,
        manageCoordinators: true,
        manageChairmans: true,
        manageExpertises: true,
        manageBudgetYears: true,
        viewDashboard: true,
        makeEvalution: true,
        makeClosedProject: true,
        downloadData: true,
    },
    // 4 normal
    {
        manageProjects: false,
        manageUsers: false,
        manageAgencies: false,
        manageVendors: false,
        manageLecturers: false,
        manageCoordinators: false,
        manageChairmans: false,
        manageExpertises: false,
        manageBudgetYears: false,
        viewDashboard: false,
        makeEvalution: false,
        makeClosedProject: false,
        downloadData: true,
    },
]

export const authPrivilege = (
    privilegeOption: keyof IPrivilegeAccess,
    privilege_id: number
) => {
    return privilegeAccess[privilege_id][privilegeOption] === true
}

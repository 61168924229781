import { Flex, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import TableViewButton from '@components/button/TableViewButton.'
import TableEditButton from '@components/button/TableEditButton'
import TableDeleteButton from '@components/button/TableDeleteButton'
import TablePagination from '@components/table/template/TablePagination'
import TableTemplate from '@components/table/template/TableTemplate'
import { ITHeadMap } from '@interfaces/ITable'
import { Link } from 'react-router-dom'
import TableAddNewButton from '@components/button/TableAddNewButton'
import { modals } from '@mantine/modals'
import { QueryObserverResult } from 'react-query'
import TableFilter from '@components/table/filter/TableFilter'
import {
    IResponseGetAllProjectTypesRowData,
    IResponseGetAllProjectTypes,
} from '@interfaces/api/IProjectType'
import ProjectType from '@api/ProjectType'
import { default_header } from '../common_table_header'

const ROUTE_URL = '/projects/types'

const TableAllProjectType = () => {
    const [limit, setlimit] = useState(10)
    const [orderby, setorderby] =
        useState<keyof IResponseGetAllProjectTypesRowData>('project_type_id')
    const [order, setorder] = useState('desc')
    const [page, setpage] = useState(1)
    const [search, setsearch] = useState('')
    const [checkowner, setcheckowner] = useState(0)
    const [status, setstatus] = useState('')

    const lists = ProjectType.useGetAll({
        limit,
        orderby,
        order,
        page,
        search,
        checkowner,
        status,
    })

    useEffect(() => {
        lists.refetch()
        // eslint-disable-next-line
    }, [page, status, limit, order, orderby, checkowner])

    const thead: ITHeadMap<IResponseGetAllProjectTypesRowData>[] = [
        {
            sortkey: 'project_type_name',
            label: 'ชื่อประเภท',
            width: '70%',
        },
        ...default_header,
    ]

    return (
        <>
            <TableFilter
                {...{
                    lists,
                    setpage,
                    page,
                    setsearch,
                    search,
                    setcheckowner,
                    checkowner,
                    setstatus,
                    status,
                }}
            >
                <TableAddNewButton
                    component={Link}
                    to={`${ROUTE_URL}/create`}
                    label='เพิ่มประเภท'
                />
            </TableFilter>

            <TableTemplate<IResponseGetAllProjectTypesRowData>
                thead={thead}
                orderby={orderby}
                order={order}
                setorder={setorder}
                setorderby={setorderby}
                lists={lists?.data?.lists}
            >
                {lists?.data?.lists.map((value) => (
                    <TableRow
                        key={value.project_type_id}
                        data={value}
                        refreshTable={lists.refetch}
                    />
                ))}
            </TableTemplate>
            {lists.data?.lists.length ? (
                <TablePagination
                    limit={lists.data?.lists.length}
                    setlimit={setlimit}
                    setpage={setpage}
                    page={page}
                    totalPages={lists.data.totalPages}
                    totalRows={lists.data.totalRows}
                />
            ) : (
                <></>
            )}
        </>
    )
}

export default TableAllProjectType

const TableRow = ({
    data,
    refreshTable,
}: {
    data: IResponseGetAllProjectTypesRowData
    refreshTable: () => Promise<
        QueryObserverResult<IResponseGetAllProjectTypes, unknown>
    >
}) => {
    const Delete = ProjectType.useDeleteByID({
        project_type_id: data.project_type_id,
        aftersuccess: refreshTable,
    })

    const openDeleteConfirmModal = () =>
        modals.openConfirmModal({
            title: 'กรุณายืนยันการลบ',
            children: (
                <Text>
                    ลบประเภท/ลูกค้า <br />
                    {data.project_type_name}
                </Text>
            ),
            labels: { confirm: 'ยืนยัน', cancel: 'ยกเลิก' },
            onConfirm: Delete.onSubmit,
        })

    return (
        <tr key={data.project_type_id}>
            <td>{data.project_type_name}</td>
            <td>{data.status_description}</td>
            <td>
                <Flex gap='xs' pos='relative'>
                    <TableViewButton
                        component={Link}
                        to={`${ROUTE_URL}/${data.project_type_id}/view`}
                    />
                    <TableEditButton
                        component={Link}
                        to={`${ROUTE_URL}/${data.project_type_id}/edit`}
                    />
                    <TableDeleteButton onClick={openDeleteConfirmModal} />
                </Flex>
            </td>
        </tr>
    )
}

import api from '@config/api'
import { useQuery } from 'react-query'
import {
    IChairmanID,
    IRequestCreateChairman,
    IRequestGetAllChairmans,
    IRequestUpdateChairman,
    IResponseGetAllChairmans,
    IResponseGetChairmanByID,
} from '@interfaces/api/IChairman'
import { useQueryConfig } from './useQueryConfig'
import { useFormMutationHandler } from '@utils/useFormMutationHandler'
import { IFormMutationHandlerProps } from '@interfaces/default/IDefault'
interface IUseCreateChairman
    extends IFormMutationHandlerProps<IRequestCreateChairman> {}

interface IUseUpdateChairman
    extends IChairmanID,
        IFormMutationHandlerProps<IRequestUpdateChairman> {}

interface IUseDeleteChairman
    extends IChairmanID,
        IFormMutationHandlerProps<IChairmanID> {}

const Chairman = {
    useGetAllChairmans: ({
        limit = 100,
        orderby = 'chairman_id',
        order = 'desc',
        page = 1,
        search = '',
        has_enabled,
        checkowner = 0,
        status = '',
        chairman_organization_id = '',
    }: IRequestGetAllChairmans) =>
        useQuery(
            'allchairmans' + orderby,
            async () => {
                try {
                    const res = await api.get('/chairmans', {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                            has_enabled,
                            checkowner,
                            status,
                            chairman_organization_id,
                        },
                    })
                    return res.data.body as IResponseGetAllChairmans
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useGetChairmanByID: ({ chairman_id }: IChairmanID) =>
        useQuery(
            'getChairmanByID' + chairman_id,
            async () => {
                try {
                    const res = await api.get(`/chairmans/${chairman_id}`)
                    return res.data.body as IResponseGetChairmanByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useCreateChairman: ({ form, aftersuccess }: IUseCreateChairman) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: ['/chairmans', 'post'],
            aftersuccess,
        })
    },
    useUpdateChairman: ({
        form,
        chairman_id,
        aftersuccess,
    }: IUseUpdateChairman) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/chairmans/${chairman_id}`, 'put'],
            aftersuccess,
        })
    },

    useDeleteChairman: ({ chairman_id, aftersuccess }: IUseDeleteChairman) => {
        return useFormMutationHandler({
            pathWithMethod: [`/chairmans/${chairman_id}`, 'delete'],
            aftersuccess,
        })
    },
}

export default Chairman

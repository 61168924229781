import {
    Center,
    createStyles,
    Flex,
    rem,
    Text,
    UnstyledButton,
} from '@mantine/core'
import {
    IconChevronDown,
    IconChevronUp,
    IconSelector,
} from '@tabler/icons-react'
import React from 'react'

const useStyles = createStyles((theme) => ({
    th: {
        padding: '0 !important',
    },

    control: {
        width: '100%',
    },

    icon: {
        width: rem(21),
        height: rem(21),
        borderRadius: rem(21),
    },
}))

interface ThProps {
    label: string
    width: string
    reversed?: boolean
    sorted?: boolean
    onSort?(): void
}

const TableHeaderColumn = ({
    label,
    width,
    reversed,
    sorted,
    onSort,
}: ThProps) => {
    const Icon = sorted
        ? reversed
            ? IconChevronUp
            : IconChevronDown
        : IconSelector
    const { classes } = useStyles()

    if (onSort && sorted !== undefined && reversed !== undefined) {
        return (
            <th
                style={{
                    overflow: 'hidden',
                    width: width,
                    whiteSpace: 'nowrap',
                    wordBreak: 'keep-all',
                }}
            >
                <UnstyledButton onClick={onSort} className={classes.control}>
                    <Flex justify='space-between'>
                        <Text size='sm' fw={500}>
                            {label}
                        </Text>
                        <Center className={classes.icon}>
                            <Icon size='0.9rem' stroke={1.5} />
                        </Center>
                    </Flex>
                </UnstyledButton>
            </th>
        )
    } else {
        return (
            <th
                style={{
                    overflow: 'hidden',
                    width: width,
                    whiteSpace: 'nowrap',
                    wordBreak: 'keep-all',
                }}
            >
                <Text size='sm' fw={500}>
                    {label}
                </Text>
            </th>
        )
    }
}

export default TableHeaderColumn

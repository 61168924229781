import { useForm } from '@mantine/form'
import React from 'react'
import { modals } from '@mantine/modals'
import { Button, Flex, LoadingOverlay, TextInput } from '@mantine/core'
import Auth from '@api/Auth'
import { IRequestForgetPassword } from '@interfaces/api/IAuth'

const FormForgetPassword = () => {
    const form = useForm<IRequestForgetPassword>({
        initialValues: {
            user_email: '',
        },
    })
    const { onSubmit, isLoading } = Auth.useRequestForgotPassword({
        form: form,
        aftersuccess: () => {
            modals.closeAll()
        },
    })
    return (
        <form onSubmit={onSubmit}>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <Flex direction='column' gap='md'>
                <TextInput
                    {...form.getInputProps('user_email')}
                    label='อีเมลบัญชีเพื่อกูรหัสผ่าน:'
                    placeholder='อีเมลบัญชีเพื่อกูรหัสผ่าน '
                    data-autofocus
                />
                <Button
                    fullWidth
                    type='submit'
                    radius='10px'
                    mt='md'
                    color='#e6007e'
                >
                    ยืนยัน
                </Button>
            </Flex>
        </form>
    )
}

export default FormForgetPassword

import FormCreateCoordinator from '@components/form/coordinator/FormCreateCoordinator'
import PageLayout from '@components/page/PageLayout'

const CreateCoordinator = () => {
    return (
        <PageLayout pageTitle='สร้างผู้ประสานงาน'>
            <FormCreateCoordinator />
        </PageLayout>
    )
}

export default CreateCoordinator

import { Skeleton } from '@mantine/core'

const LoadingInput = () => {
    return (
        <div>
            <Skeleton height={20} w='200px' mt={6} radius='sm' />
            <Skeleton height={40} mt={12} radius='sm' />
        </div>
    )
}

export default LoadingInput

import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core'
import { Link, useNavigate } from 'react-router-dom'

import InputStatus from '@components/form/input/InputStatus'
import { IRequestUpdateChairmanOrganization } from '@interfaces/api/IChairmanOrganization'
import { IResponseGetChairmanOrganizationByID } from '../../../../interfaces/api/IChairmanOrganization'
import ChairmanOrganization from '@api/ChairmanOrganization'

const FormEditOrganization = ({
    data,
    aftersuccess,
}: {
    data: IResponseGetChairmanOrganizationByID
    aftersuccess?: () => any
}) => {
    const form = useForm<IRequestUpdateChairmanOrganization>({
        initialValues: {
            chairman_organization_name: data.chairman_organization_name,
            status: data.status,
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } =
        ChairmanOrganization.useUpdateChairmanOrganization({
            chairman_organization_id: data.chairman_organization_id,
            form,
            aftersuccess: () => navigate('/chairmans/organizations'),
        })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='สังกัดประธานโครงการ'
                        placeholder='สังกัดประธานโครงการ'
                        {...form.getInputProps('chairman_organization_name')}
                    />
                    <InputStatus {...form.getInputProps('status')} />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/chairmans/organizations'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            บันทึกการแก้ไขสังกัดประธานโครงการ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormEditOrganization

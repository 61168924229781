import PageLayout from '@components/page/PageLayout'
import TableAllLecturer from '@components/table/database/TableAllLecturer'

const AllLecturer = () => {
    return (
        <PageLayout pageTitle='วิทยากร / ผู้เชี่ยวชาญ'>
            <TableAllLecturer />
        </PageLayout>
    )
}

export default AllLecturer

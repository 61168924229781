import Agency from '@api/Agency'
import FormEditAgencyType from '@components/form/agency/FormEditAgencyType'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const EditAgencyType = () => {
    const params = useParams()
    const agency_type_id = params.agency_type_id
    const { isLoading, isError, data, isRefetching } = Agency.useGetAgencyTypeByID({
        agency_type_id: agency_type_id!,
    })
    if (isLoading || isRefetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return <PageNotFound404 label='กลับไปหน้าประเภทหน่วยงานทั้งหมด' url='/agencies/types' />
    }
    return (
        <PageLayout pageTitle='แก้ไขประเภทหน่วยงาน'>
            <FormEditAgencyType data={data} />
        </PageLayout>
    )
}

export default EditAgencyType

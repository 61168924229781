import InputStatus from '@components/form/input/InputStatus'
import React from 'react'
interface ITableStatusFilter {
    setstatus: (value: React.SetStateAction<string>) => void
    setpage: (value: React.SetStateAction<number>) => void
    status: string | number
}
const TableStatusFilter = ({
    setpage,
    setstatus,
    status,
}: ITableStatusFilter) => (
    <InputStatus
        withAll
        miw='170px'
        value={String(status)}
        onChange={(value) => {
            if (value !== status) {
                setstatus(String(value))
                setpage(1)
            }
        }}
    />
)

export default TableStatusFilter

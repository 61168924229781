import { createStyles, rem, ScrollArea, Table } from '@mantine/core'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { ITable } from '@interfaces/ITable'
import TableRowOneColumn from '@components/table/template/TableRowOneColumn'
import TableHeaderColumn from '@components/table/template/TableHeaderColumn'

const useStyles = createStyles((theme) => ({
    header: {
        position: 'sticky',
        zIndex: 1,
        top: 0,
        backgroundColor: theme.white,
        transition: 'box-shadow 150ms ease',

        '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            borderBottom: `${rem(1)} solid ${
                theme.colorScheme === 'dark'
                    ? theme.colors.dark[3]
                    : theme.colors.gray[2]
            }`,
        },
    },

    scrolled: {
        boxShadow: theme.shadows.sm,
    },
}))
const TableTemplate = <T,>({
    height = 'auto',
    thead,
    order,
    orderby,
    setorder,
    setorderby,
    lists,
    children,
}: ITable<T>) => {
    const componentRef = useRef<HTMLDivElement>(null)
    const tableRef = useRef<HTMLTableElement>(null)
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })
    const [tableMaxHeight, setTableMaxHeight] = useState<number | undefined>()
    const [isOverflow, setIsOverflow] = useState(false)

    useLayoutEffect(() => {
        const handleResize = () => {
            // Measure the new window dimensions
            const newWidth = window.innerWidth
            const newHeight = window.innerHeight

            // Check if the dimensions have changed
            if (
                newWidth !== windowSize.width ||
                newHeight !== windowSize.height
            ) {
                // Dimensions have changed, do something
                setWindowSize({ width: newWidth, height: newHeight })
            }
        }

        if (componentRef.current) {
            const rect = componentRef.current.getBoundingClientRect()
            setTableMaxHeight(windowSize.height - rect.top - 100)
        }

        // Attach the event listener
        window.addEventListener('resize', handleResize)

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [windowSize])

    useEffect(() => {
        if (tableMaxHeight && tableMaxHeight > 1 && tableRef.current) {
            const tableHeight = tableRef.current.clientHeight
            // const scrollHeight = componentRef.current?.clientHeight
            if (tableHeight > tableMaxHeight) {
                setIsOverflow(true)
            } else {
                setIsOverflow(false)
            }
        }
        // eslint-disable-next-line
    }, [lists?.length])

    const [defaultOrderBy] = useState(orderby)

    const setSorting = (field: keyof T) => {
        const reversed =
            field === orderby ? (order === 'desc' ? 'asc' : 'desc') : 'desc'
        setorder(reversed)
        const resetFieldAt3 =
            (field === orderby ? (order === 'desc' ? '2' : '3') : '1') === '3'
                ? true
                : false

        setorderby(resetFieldAt3 ? (defaultOrderBy as keyof T) : field)
    }
    const { classes, cx } = useStyles()
    const [scrolled, setScrolled] = useState(false)

    return (
        <ScrollArea
            ref={componentRef}
            mt='md'
            style={{
                maxHeight: tableMaxHeight,
                height: isOverflow ? tableMaxHeight : height,
                overflowY: 'scroll',
            }}
            onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
            <Table pos={'relative'} ref={tableRef}>
                <thead
                    className={cx(classes.header, {
                        [classes.scrolled]: scrolled,
                    })}
                >
                    <tr>
                        {thead.map((th, index) => (
                            <TableHeaderColumn
                                key={index}
                                sorted={orderby === th.sortkey}
                                reversed={order === 'desc' ? true : false}
                                onSort={
                                    th.sortkey
                                        ? () => setSorting(th.sortkey!)
                                        : undefined
                                }
                                label={th.label}
                                width={th.width}
                            />
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {lists ? (
                        lists.length > 0 ? (
                            children
                        ) : (
                            <TableRowOneColumn>ไม่พบข้อมูล</TableRowOneColumn>
                        )
                    ) : (
                        <TableRowOneColumn>Loading...</TableRowOneColumn>
                    )}
                </tbody>
            </Table>
        </ScrollArea>
    )
}

export default TableTemplate

import BudgetYear from '@api/BudgetYear'
import { Select, SelectItem } from '@mantine/core'
import { useEffect, useState } from 'react'
import { SelectProps } from '@mantine/core/lib/Select/Select'

interface MyComponentProps extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
    withAll?: boolean
}
const InputBudgetYear = ({
    withAll = false,
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: MyComponentProps) => {
    const { data } = BudgetYear.useGetAllBudgetYears({ limit: 1000 })
    const [list, setlist] = useState<{ value: string; label: string }[]>([])
    useEffect(() => {
        if (data) {
            const selectlists = data.lists.map((e) => {
                return {
                    value: String(e.budget_year),
                    label: e.budget_year_name,
                }
            })
            if (withAll) {
                selectlists.unshift({
                    value: '',
                    label: 'ทั้งหมด',
                })
            }
            setlist(selectlists)
        }
        // eslint-disable-next-line
    }, [data])
    return (
        <Select
            key={list.length}
            className={className}
            label='ปีงบประมาณ'
            value={String(value)}
            placeholder='ปีงบประมาณ'
            data={list}
            {...others}
        />
    )
}

export default InputBudgetYear

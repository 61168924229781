import api from '@config/api'
import { useQuery } from 'react-query'

import { useFormMutationHandler } from '@utils/useFormMutationHandler'
import { IFormMutationHandlerProps } from '@interfaces/default/IDefault'
import { useQueryConfig } from './useQueryConfig'
import {
    IRequestCreateUser,
    IRequestUpdateUser,
    IResponseGetUserByID,
    IUserID,
} from '@interfaces/api/IUser'
import {
    IRequestGetAllUserPrivilege,
    IResponseGetAllUserPrivileges,
} from '@interfaces/api/IUserPrivilege'
interface IUseCreateUser
    extends IFormMutationHandlerProps<IRequestCreateUser> {}

interface IUseUpdateUser
    extends IUserID,
        IFormMutationHandlerProps<IRequestUpdateUser> {}

interface IUseDeleteUser extends IUserID, IFormMutationHandlerProps<IUserID> {}

const UserPrivilege = {
    useGetAllUserPrivileges: ({
        limit = 10,
        orderby = 'privilege_id',
        order = 'desc',
        page = 1,
        search = '',
    }: IRequestGetAllUserPrivilege) =>
        useQuery(
            'getAllUserPrivileges',
            async () => {
                try {
                    const res = await api.get('/users/privileges', {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                        },
                    })
                    return res.data.body as IResponseGetAllUserPrivileges
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),
    useGetUserByID: ({ user_id }: IUserID) =>
        useQuery(
            'getUserByID' + user_id,
            async () => {
                try {
                    const res = await api.get(`/users/${user_id}`)
                    return res.data.body as IResponseGetUserByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useCreateUser: ({ form, aftersuccess }: IUseCreateUser) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: ['/users', 'post'],
            aftersuccess,
        })
    },
    useUpdateUser: ({ form, user_id, aftersuccess }: IUseUpdateUser) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/users/${user_id}`, 'put'],
            aftersuccess,
        })
    },

    useDeleteUser: ({ user_id, aftersuccess }: IUseDeleteUser) => {
        return useFormMutationHandler({
            pathWithMethod: [`/users/${user_id}`, 'delete'],
            aftersuccess,
        })
    },
}

export default UserPrivilege

import ChairmanOrganization from '@api/ChairmanOrganization'
import CardChairmanOrganizationDetail from '@components/card/CardChairmanOrganizationDetail'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const ViewOrganization = () => {
    const params = useParams()
    const chairman_organization_id = params.chairman_organization_id
    const { isLoading, isFetching, isError, data } =
        ChairmanOrganization.useGetChairmanOrganizationByID({
            chairman_organization_id: chairman_organization_id!,
        })
    if (isLoading || isFetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าสังกัดประธานโครงการทั้งหมด'
                url='/chairmans/organizations'
            />
        )
    }
    return (
        <PageLayout pageTitle='ข้อมูลสังกัดประธานโครงการ'>
            <CardChairmanOrganizationDetail data={data} />
        </PageLayout>
    )
}

export default ViewOrganization

import { Flex, Text } from '@mantine/core';
import React from 'react';

interface ITextParameter {
    title: string;
    info: string | React.ReactNode;
}
const TextParameter = ({ title, info }: ITextParameter) => {
    return (
        <Flex gap='sm'>
            <Text size='sm'>{title} :</Text>
            <Text size='sm'>{info ?? '-'}</Text>
        </Flex>
    );
};

export default TextParameter;

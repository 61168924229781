import Auth from '@api/Auth'
import Project from '@api/Project'
import TableEditButton from '@components/button/TableEditButton'
import CardProjectOverview from '@components/card/CardProjectOverview'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import TextParameter from '@components/text/TextParameter'
import { AuthContext } from '@context/AuthContext'
import { Card, Flex, Paper, Text, Title } from '@mantine/core'
import PageNotFound404 from '@pages/PageNotFound404'
import { authPrivilege } from '@utils/authprivilege.lib'
import { useContext } from 'react'
import { Link } from 'react-router-dom'

const Main = () => {
    const { auth } = useContext(AuthContext)

    const { isLoading, data } = Auth.useGetProfile()
    const myprojectoverview = Project.useGetAllProjectsOverview({
        checkowner: 1,
    })
    const allprojectoverview = Project.useGetAllProjectsOverview({
        checkowner: 0,
    })

    if (isLoading || myprojectoverview.isLoading) {
        return <LoadingPage />
    }
    if (!data) {
        return (
            <PageNotFound404
                label='กรุณาเข้าสู่ระบบใหม่อีกครั้ง'
                url='/login'
            />
        )
    }
    return (
        <PageLayout>
            <Paper>
                <Text fw='bold' c='indigo'>
                    ยินดีต้อนรับเข้าสู่ระบบ คุณ {auth?.user_fullname}
                </Text>
                <Flex direction='column' gap='lg' mt='sm'>
                    <Card
                        withBorder
                        radius='md'
                        // className={classes.card}
                        padding='md'
                    >
                        <Flex gap='5px' direction='column' pos='relative'>
                            <TableEditButton
                                right={0}
                                w='35px'
                                pos='absolute'
                                component={Link}
                                to='/profile/edit'
                            />
                            <Title order={5} mb='5px'>
                                โปรไฟล์ของคุณ
                            </Title>
                            <TextParameter
                                title={'ชื่อ-นามสกุล'}
                                info={data.user_fullname}
                            />
                            <TextParameter
                                title={'อีเมล'}
                                info={data.user_email}
                            />
                            <TextParameter
                                title={'ตำแหน่ง'}
                                info={data.user_position_name}
                            />
                            <TextParameter
                                title={'สิทธิการใช้งาน'}
                                info={data.privilege_name}
                            />
                        </Flex>
                    </Card>
                    <CardProjectOverview
                        title='สรุปผลโครงการของฉัน'
                        data={myprojectoverview.data}
                    />
                    {authPrivilege('manageProjects', auth?.privilege_id!) && (
                        <CardProjectOverview
                            title='สรุปผลโครงการทั้งหมด'
                            data={allprojectoverview.data}
                        />
                    )}
                </Flex>
            </Paper>
        </PageLayout>
    )
}

export default Main

import api from '@config/api'
import { useQuery } from 'react-query'
import { useQueryConfig } from './useQueryConfig'

const Status = {
    useGetAllDataSelectStatus: () =>
        useQuery(
            'getAllStatusDataSelect',
            async () => {
                try {
                    const res = await api.get('/status/dataselect')
                    return res.data.body as IResponseGetAllStatusRowData[]
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),
    useGetAllUserStatus: () =>
        useQuery(
            'getAllStatusUser',
            async () => {
                try {
                    const res = await api.get('/status/user')
                    return res.data.body as IResponseGetAllStatusRowData[]
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),
}

export default Status

import FormCreateBudgetYear from '@components/form/budget_year/FormCreateBudgetYear'
import PageLayout from '@components/page/PageLayout'

const CreateBudgetYear = () => {
    return (
        <PageLayout pageTitle='สร้างปีงบประมาณ'>
            <FormCreateBudgetYear />
        </PageLayout>
    )
}

export default CreateBudgetYear

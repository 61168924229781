import TableHeaderColumn from '@components/table/template/TableHeaderColumn'
import TextChartTitle from '@components/text/TextChartTitle'
import { IResponseGetProjectLecturerDataForDashboardRowData } from '@interfaces/api/IDashboard'
import { Flex, Rating, Table, Text } from '@mantine/core'
import _ from 'lodash'
import React from 'react'
import ChartFlexSection from './ChartFlexSection'
interface ILecturerRankingChart {
    data: IResponseGetProjectLecturerDataForDashboardRowData[]
}

const LecturerRankingChart = ({ data }: ILecturerRankingChart) => {
    const groupedData = _.groupBy(data, 'project_budget_year')
    const prepareDatawithAnnotation = Object.keys(groupedData).map((Byear) => {
        let dataforeachyear = _(groupedData[Byear])
            .groupBy('lecturer_fullname')
            .map((values, key) => ({
                lecturer_fullname: key,
                avg_lecturer_rating_Q1: _.meanBy(
                    values.filter((e) => e.quarter === 'Q1'),
                    (p) => Number(p.lecturer_evaluation_result ?? 0)
                ),
                avg_lecturer_rating_Q2: _.meanBy(
                    values.filter((e) => e.quarter === 'Q2'),
                    (p) => Number(p.lecturer_evaluation_result)
                ),
                avg_lecturer_rating_Q3: _.meanBy(
                    values.filter((e) => e.quarter === 'Q3'),
                    (p) => Number(p.lecturer_evaluation_result)
                ),
                avg_lecturer_rating_Q4: _.meanBy(
                    values.filter((e) => e.quarter === 'Q4'),
                    (p) => Number(p.lecturer_evaluation_result)
                ),
                avg_project_rating_Q1: _.meanBy(
                    values.filter((e) => e.quarter === 'Q1'),
                    (p) => Number(p.project_evaluation_result)
                ),
                avg_project_rating_Q2: _.meanBy(
                    values.filter((e) => e.quarter === 'Q2'),
                    (p) => Number(p.project_evaluation_result)
                ),
                avg_project_rating_Q3: _.meanBy(
                    values.filter((e) => e.quarter === 'Q3'),
                    (p) => Number(p.project_evaluation_result)
                ),
                avg_project_rating_Q4: _.meanBy(
                    values.filter((e) => e.quarter === 'Q4'),
                    (p) => Number(p.project_evaluation_result)
                ),
                avg_project_rating_all: _.meanBy(values, (p) => Number(p.project_evaluation_result)),
                avg_lecturer_rating_all: _.meanBy(values, (p) => Number(p.lecturer_evaluation_result)),
                total_project_amount: _.uniqBy(values, 'project_id').length,
                total_project_budget: _.sumBy(
                    values.map((e) => {
                        return {
                            ...e,
                            project_budget: Number(e.project_budget),
                        }
                    }),
                    'project_budget'
                ),
            }))
            .value()

        dataforeachyear = _.orderBy(dataforeachyear, (p) => p.avg_lecturer_rating_all, ['desc'])

        return { budget_year: Byear, data: dataforeachyear }
    })

    data.sort((a, b) => Number(b.project_budget) - Number(a.project_budget))
    return (
        <Flex direction='column'>
            {prepareDatawithAnnotation.map((dataset, index) => {
                return (
                    <ChartFlexSection key={index}>
                        <TextChartTitle>
                            <Flex wrap='wrap'>
                                <Text mr='xs'>
                                    {`จัดอันดับวิทยากรที่ได้รับค่าเฉลี่ยผลการประเมินคะแนนความพึงพอใจสูงสุด ประจำปีงบประมาณ`}
                                </Text>
                            </Flex>
                        </TextChartTitle>
                        <Table pos={'relative'}>
                            <thead>
                                <tr>
                                    <TableHeaderColumn label={'อันดับ'} width={'2%'} />

                                    <TableHeaderColumn label={'วิทยากร'} width={'40%'} />
                                    <TableHeaderColumn label={'จำนวนโครงการ'} width={'20%'} />
                                    {/* <TableHeaderColumn
                                        label={'ไตรมาสที่ 1'}
                                        width={'10%'}
                                    />
                                    <TableHeaderColumn
                                        label={'ไตรมาสที่ 2'}
                                        width={'10%'}
                                    />
                                    <TableHeaderColumn
                                        label={'ไตรมาสที่ 3'}
                                        width={'10%'}
                                    />
                                    <TableHeaderColumn
                                        label={'ไตรมาสที่ 4'}
                                        width={'10%'}
                                    />
                                    <TableHeaderColumn
                                        label={'ทั้งปีงบประมาณ'}
                                        width={'10%'}
                                    /> */}
                                    <TableHeaderColumn label={'ผลการประเมินความพึงพอใจ'} width={'20%'} />
                                </tr>
                            </thead>
                            <tbody>
                                {dataset.data.map((p, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{p.lecturer_fullname}</td>
                                        <td>{p.total_project_amount}</td>
                                        {/* <TableColumnLecturerEvaluationStat
                                            left={p.avg_project_rating_Q1}
                                            right={p.avg_lecturer_rating_Q1}
                                        />
                                        <TableColumnLecturerEvaluationStat
                                            left={p.avg_project_rating_Q2}
                                            right={p.avg_lecturer_rating_Q2}
                                        />
                                        <TableColumnLecturerEvaluationStat
                                            left={p.avg_project_rating_Q3}
                                            right={p.avg_lecturer_rating_Q3}
                                        />
                                        <TableColumnLecturerEvaluationStat
                                            left={p.avg_project_rating_Q4}
                                            right={p.avg_lecturer_rating_Q4}
                                        />
                                        <TableColumnLecturerEvaluationStat
                                            left={p.avg_project_rating_all}
                                            right={p.avg_lecturer_rating_all}
                                        /> */}
                                        <td>
                                            <Flex gap='lg'>
                                                <Text>
                                                    {isNaN(p.avg_lecturer_rating_all) === false
                                                        ? p.avg_lecturer_rating_all.toFixed(2)
                                                        : '0.00'}
                                                </Text>
                                                <Rating fractions={5} value={p.avg_lecturer_rating_all} readOnly />
                                            </Flex>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </ChartFlexSection>
                )
            })}
        </Flex>
    )
}

export default LecturerRankingChart

// const TableColumnLecturerEvaluationStat = ({
//     left,
//     right,
// }: {
//     left: number
//     right: number
// }) => {
//     return (
//         <td>
//             <Flex>
//                 <Text color='blue' mr='5px'>
//                     {isNaN(left) === false ? left.toFixed(2) : '0.00'}
//                 </Text>
//                 /
//                 <Text color='red' ml='5px'>
//                     {isNaN(right) === false ? right.toFixed(2) : '0.00'}
//                 </Text>
//             </Flex>
//         </td>
//     )
// }

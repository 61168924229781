import { useForm } from '@mantine/form'
import { Button, Flex, Group, LoadingOverlay, NumberInput, Paper, Text } from '@mantine/core'
import Project from '@api/Project'
import LoadingPage from '@components/loading/LoadingPage'
import { IRequestEvaluationProjectLecturer, IResponseAllProjectLecturersRowData } from '@interfaces/api/IProject'
import InputLecturer from '../input/InputLecturer'
import { useEffect, useState } from 'react'
import TableEditButton from '@components/button/TableEditButton'
import TextEvaluationBy from '@components/text/TextEvaluationBy'
import CancelButton from '@components/button/CancleButton'

const FormEvaluationProjectLecturer = ({
    project_id,
    editMode,
    refetchProjectInfo,
}: {
    project_id: number | string
    editMode: boolean
    refetchProjectInfo: () => void
}) => {
    const [currentEditList, setCurrentEditList] = useState<number | null>(null)

    useEffect(() => {
        if (editMode === false) {
            setCurrentEditList(null)
        }
        // eslint-disable-next-line
    }, [editMode, currentEditList])

    let { isLoading, data, refetch } = Project.useGetAllProjectLecturerByID({
        project_id: project_id,
    })

    if (isLoading) {
        return <LoadingPage />
    }

    const refetchProjectLecturer = () => {
        refetchProjectInfo()
        setCurrentEditList(null)
        refetch()
    }

    return (
        <Flex gap='md' direction='column'>
            {data!.map((lecturer) => {
                return (
                    <EvaluationProjectLecturer
                        key={lecturer.project_lecturer_id}
                        editMode={editMode}
                        data={lecturer}
                        refetchProjectLecturer={refetchProjectLecturer}
                        currentEditList={currentEditList}
                        setCurrentEditList={setCurrentEditList}
                    />
                )
            })}
            {!data?.length && (
                <Paper p='lg'>
                    <Group position='center'>ไม่พบรายชื่อวิทยากรของโครงการ</Group>
                </Paper>
            )}
        </Flex>
    )
}

export default FormEvaluationProjectLecturer

interface IEvaluationProjectLecturerProps {
    data: IResponseAllProjectLecturersRowData
    editMode: boolean
    refetchProjectLecturer: () => void
    currentEditList: null | number
    setCurrentEditList: React.Dispatch<React.SetStateAction<number | null>>
}

const EvaluationProjectLecturer = ({
    data,
    editMode,
    refetchProjectLecturer,
    currentEditList,
    setCurrentEditList,
}: IEvaluationProjectLecturerProps) => {
    const form = useForm<IRequestEvaluationProjectLecturer>({
        initialValues: {
            lecturer_evaluation_result: data?.lecturer_evaluation_result ? Number(data.lecturer_evaluation_result) : '',
        },
    })

    const { isLoading, onSubmit } = Project.useEvaluationProjectLecturer({
        project_id: data.project_id,
        project_lecturer_id: data.project_lecturer_id,
        form,
        aftersuccess: refetchProjectLecturer,
    })

    const [editList, setEditList] = useState(false)

    useEffect(() => {
        if (currentEditList !== data.project_lecturer_id) {
            setEditList(false)
        }
        // eslint-disable-next-line
    }, [currentEditList])

    const editListOnClick = () => {
        setCurrentEditList(data.project_lecturer_id)
        setEditList(true)
        form.setFieldValue(
            'lecturer_evaluation_result',
            data?.lecturer_evaluation_result ? Number(data.lecturer_evaluation_result) : ''
        )
    }
    return (
        <Paper pos='relative' shadow='md' p='md'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit} style={{ pointerEvents: editMode ? 'all' : 'none' }}>
                <Flex gap='sm' direction='column'>
                    <InputLecturer label='' editMode={false} value={String(data.lecturer_id)}></InputLecturer>
                    {editList && (
                        <NumberInput
                            withAsterisk
                            placeholder='0.00'
                            label='ผลประเมินวิทยากร'
                            defaultValue={0.0}
                            precision={2}
                            min={0.0}
                            step={0.5}
                            max={5}
                            {...form.getInputProps('lecturer_evaluation_result')}
                        />
                    )}
                    {!editList && editMode && (
                        <Flex w='100%'>
                            {editMode && (
                                <TableEditButton right='10px' bottom='10px' pos='absolute' onClick={editListOnClick} />
                            )}
                            <TextEvaluationProjectLecturer data={data} />
                        </Flex>
                    )}

                    {editList && (
                        <Flex ml='auto' gap='md' mt='md'>
                            <CancelButton onClick={() => setEditList(false)} />
                            <Button type='submit' w='fit-content' ml='auto'>
                                บันทึกการประเมิน
                            </Button>
                        </Flex>
                    )}

                    {editMode === false && (
                        <Flex justify='space-between' gap='md' w='100%'>
                            <TextEvaluationProjectLecturer data={data} />
                            <TextEvaluationBy {...data} />
                        </Flex>
                    )}
                </Flex>
            </form>
        </Paper>
    )
}

const TextEvaluationProjectLecturer = ({ data }: { data: IResponseAllProjectLecturersRowData }) => {
    return (
        <Text size='sm'>
            ผลประเมินวิทยากร : {data.lecturer_evaluation_result ? data.lecturer_evaluation_result : ' ยังไม่ประเมิน'}
        </Text>
    )
}

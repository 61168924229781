import { joiResolver, useForm } from '@mantine/form'
import { IRequestCreateProject } from '@interfaces/api/IProject'
import { useNavigate } from 'react-router-dom'
import {
    Button,
    Flex,
    LoadingOverlay,
    NumberInput,
    Paper,
    Textarea,
    TextInput,
} from '@mantine/core'
import { DateValue } from '@mantine/dates'

import InputBudgetYear from '@components/form/input/InputBudgetYear'
import { ProjectCodeGenerate } from '@utils/ProjectCodeGenerate'
import InputDate from '@components/form/input/InputDate'
import dayjs from 'dayjs'
import InputCoordinator from '../input/InputCoordinator'
import InputChairman from '../input/InputChairman'
import InputAgency from '../input/InputAgency'
import InputCurrency from '../input/InputCurrency'
import Project from '@api/Project'
import { useEffect, useState } from 'react'
import Agency from '@api/Agency'
import { createproject } from './projectFormSchema'
import InputProjectType from '../input/InputProjectType'
import InputExpertises from '../input/InputExpertises'

const FormCreateProject = () => {
    const navigate = useNavigate()
    const agencies = Agency.useGetAllAgencies({})
    const [isAgencyTypeRegister, setIsAgencyTypeRegister] = useState(false)

    const form = useForm<IRequestCreateProject>({
        initialValues: {
            project_no: '',
            budget_year: null,
            project_promise_no: '',
            project_name: '',
            project_type_id: null,
            project_expertises: [],
            project_detail: '',
            project_start_date: null,
            project_end_date: null,
            project_duration: '',
            project_budget: null,
            project_period_amount: '',
            chairman_id: null,
            coordinator_id: null,
            agency_id: null,
        },
        validate: isAgencyTypeRegister ? undefined : joiResolver(createproject),
    })

    useEffect(() => {
        if (agencies?.data?.lists?.length) {
            const agency = agencies.data.lists.filter(
                (a) => a.agency_id === Number(form.values.agency_id)
            )
            if (agency?.length) {
                if (agency[0].agency_type_name.includes('ลงทะเบียน')) {
                    setIsAgencyTypeRegister(true)
                } else {
                    setIsAgencyTypeRegister(false)
                }
            }
        }
    }, [form.values.agency_id, agencies])

    const onProjectStartDateChange = (value: DateValue) => {
        if (form.getInputProps('project_duration').value && value) {
            const end_date = dayjs(value)
                .add(form.getInputProps('project_duration').value, 'day')
                .toDate()
            form.setFieldValue('project_end_date', end_date as any)
        }
        return form.setFieldValue('project_start_date', value)
    }

    const onProjectDurationChange = (value: number) => {
        if (form.getInputProps('project_start_date').value && value) {
            const start_date = form.getInputProps('project_start_date').value
            const new_date = dayjs(start_date).add(value, 'day').toDate()
            form.setFieldValue('project_end_date', new_date)
        }
        return form.setFieldValue('project_duration', value)
    }
    const onProjectEndDateChange = (value: DateValue) => {
        if (form.getInputProps('project_start_date').value && value) {
            const start_date = dayjs(
                form.getInputProps('project_start_date').value
            )
            const end_date = dayjs(value)
            const duration = end_date.diff(start_date, 'day')
            form.setFieldValue('project_duration', duration)
        }
        return form.setFieldValue('project_end_date', value)
    }
    const { isLoading, onSubmit } = Project.useCreateProject({
        form,
        aftersuccess: () => navigate('/myprojects'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อโครงการ'
                        placeholder='ชื่อโครงการ'
                        {...form.getInputProps('project_name')}
                    />
                    <InputBudgetYear
                        withAsterisk
                        {...form.getInputProps('budget_year')}
                    />
                    <NumberInput
                        withAsterisk
                        label='ลำดับโครงการ'
                        placeholder='ลำดับโครงการ'
                        {...form.getInputProps('project_no')}
                    />
                    <TextInput
                        label='รหัสโครงการ'
                        placeholder='รหัสโครงการ'
                        value={ProjectCodeGenerate(
                            form.getInputProps('budget_year').value,
                            form.getInputProps('project_no').value
                        )}
                        disabled
                    />
                    <TextInput
                        label='เลขที่สัญญา'
                        placeholder='เลขที่สัญญา'
                        {...form.getInputProps('project_promise_no')}
                    />
                    <InputAgency
                        withAsterisk
                        {...form.getInputProps('agency_id')}
                    />
                    <InputCoordinator
                        withAsterisk
                        {...form.getInputProps('coordinator_id')}
                    />
                    <InputChairman
                        withAsterisk
                        {...form.getInputProps('chairman_id')}
                    />
                    <InputProjectType
                        withAsterisk
                        {...form.getInputProps('project_type_id')}
                    />
                    <InputExpertises
                        label='สาขาและความเชี่ยวชาญ'
                        {...form.getInputProps('project_expertises')}
                    />
                    <Textarea
                        withAsterisk={!isAgencyTypeRegister}
                        rows={10}
                        minRows={5}
                        label='รายละเอียดโครงการ'
                        placeholder='รายละเอียดโครงการ'
                        {...form.getInputProps('project_detail')}
                    />
                    <InputDate
                        clearable
                        withAsterisk={!isAgencyTypeRegister}
                        label='วันเริ่มโครงการ'
                        placeholder='วันเริ่มโครงการ'
                        {...form.getInputProps('project_start_date')}
                        onChange={onProjectStartDateChange}
                    />
                    <NumberInput
                        withAsterisk={!isAgencyTypeRegister}
                        label='ระยะเวลาโครงการ'
                        placeholder='ระยะเวลาโครงการ'
                        {...form.getInputProps('project_duration')}
                        onChange={onProjectDurationChange}
                    />
                    <InputDate
                        clearable
                        withAsterisk={!isAgencyTypeRegister}
                        label='วันสิ้นสุดโครงการ'
                        placeholder='วันสิ้นสุดโครงการ'
                        {...form.getInputProps('project_end_date')}
                        onChange={onProjectEndDateChange}
                        minDate={form.getInputProps('project_start_date').value}
                    />

                    <InputCurrency
                        withAsterisk={!isAgencyTypeRegister}
                        label='งบประมาณ'
                        {...form.getInputProps('project_budget')}
                    />
                    <NumberInput
                        withAsterisk={!isAgencyTypeRegister}
                        label='จำนวนงวด'
                        placeholder='จำนวนงวด'
                        {...form.getInputProps('project_period_amount')}
                    />
                    <Button type='submit' w='fit-content' mt='md' ml='auto'>
                        สร้างโครงการ
                    </Button>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormCreateProject

import { Select, SelectItem } from '@mantine/core'
import { SelectProps } from '@mantine/core/lib/Select/Select'

interface MyComponentProps extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
}
const InputProjectCompleteStatus = ({
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: MyComponentProps) => {
    return (
        <Select
            label='สถานะการปิดโครงการ'
            className={className}
            value={String(value)}
            data={[
                { value: '6', label: 'ปิดโครงการสมบูรณ์' },
                {
                    value: '3',
                    label: 'อยู่ระหว่างการดำเนินการ / โครงการยังไม่สมบูรณ์',
                },
                {
                    value: '0',
                    label: 'ยกเลิกโครงการ',
                },
            ]}
            {...others}
        />
    )
}

export default InputProjectCompleteStatus

import { Text } from '@mantine/core'
import { useContext } from 'react'
import dayjs from '@config/dayjs'
import { AuthContext } from '@context/AuthContext'

interface ITextEvaluationBy {
    evaluation_by_user_fullname?: string
    evaluation_by_user_id?: number
    evaluation_at?: string
}

const TextEvaluationBy = ({
    evaluation_by_user_fullname,
    evaluation_at,
    evaluation_by_user_id,
}: ITextEvaluationBy) => {
    const { auth } = useContext(AuthContext)
    if (!evaluation_by_user_fullname) {
        return <></>
    }
    return (
        <Text size='sm'>
            ประเมินเมื่อ : {dayjs(evaluation_at).fromNow()} โดย{' '}
            {evaluation_by_user_id === auth?.user_id
                ? 'คุณ'
                : evaluation_by_user_fullname}
        </Text>
    )
}

export default TextEvaluationBy

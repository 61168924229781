import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'
import FormEditProjectType from '@components/form/project/type/FormEditProjectType'
import ProjectType from '@api/ProjectType'
const EditProjectType = () => {
    const params = useParams()
    const project_type_id = params.project_type_id
    const { isLoading, isError, data, isRefetching } = ProjectType.useGetByID({
        project_type_id: project_type_id!,
    })
    if (isLoading || isRefetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าประเภทกิจกรรมบริการวิชาการ'
                url='/projects/types'
            />
        )
    }
    return (
        <PageLayout pageTitle='แก้ไขประเภทกิจกรรมบริการวิชาการ'>
            <FormEditProjectType data={data} />
        </PageLayout>
    )
}

export default EditProjectType

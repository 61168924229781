import { Text } from '@mantine/core'
import { useContext } from 'react'
import dayjs from '@config/dayjs'
import { AuthContext } from '@context/AuthContext'

interface ITextUpdatedBy {
    updated_by_user_fullname: string
    updated_by_user_id: number
    updated_at: string
}

const TextUpdatedBy = ({
    updated_by_user_fullname,
    updated_at,
    updated_by_user_id,
}: ITextUpdatedBy) => {
    const { auth } = useContext(AuthContext)

    return (
        <Text size='sm'>
            แก้ไขเมื่อ : {dayjs(updated_at).fromNow()} โดย{' '}
            {updated_by_user_id === auth?.user_id
                ? 'คุณ'
                : updated_by_user_fullname}
        </Text>
    )
}

export default TextUpdatedBy

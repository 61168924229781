import { DateInput, DateInputProps } from '@mantine/dates'
import 'dayjs/locale/th'
import dayjs from '@config/dayjs'
interface MyComponentProps extends DateInputProps {}

const InputDate = ({
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: MyComponentProps) => {
    return (
        <DateInput
            key={value === null ? null : 'dateinput'}
            className={className}
            valueFormat='DD MMMM YYYY'
            locale='th'
            value={value ? dayjs(value).toDate() : undefined}
            {...others}
        />
    )
}

export default InputDate

import {
    Anchor,
    Button,
    Flex,
    Paper,
    PasswordInput,
    TextInput,
    Title,
    LoadingOverlay,
    Image,
    Text,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useFormHandler } from '@utils/useFormHandler'
import { useContext, useEffect } from 'react'
import { IRequestLogin, IResponseLogin } from '@interfaces/api/IAuth'
import { AuthContext } from '@context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { modals } from '@mantine/modals'
import FormForgetPassword from '@components/form/auth/FormForgetPassword'
import LOGO from '../../images/LOGO.png'

const Login = () => {
    const { login, isAuthenticated } = useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        if (isAuthenticated() === true) {
            navigate('/main')
        }
    }, [isAuthenticated, navigate])

    const form = useForm({
        initialValues: {
            username: '',
            password: '',
        },
    })
    const { loading, onSubmit } = useFormHandler<IRequestLogin, IResponseLogin>(
        'post',
        '/auth/login',
        form,
        login
    )
    const openForgetPassword = () =>
        modals.open({
            title: 'กู้รหัสผ่านบัญชี',
            children: <FormForgetPassword />,
        })

    return (
        <Flex
            justify='center'
            direction='column'
            align='center'
            w='100%'
            mih='100vh'
            bg='indigo.9'
        >
            <Paper
                maw={400}
                shadow='xl'
                w='100%'
                mx='auto'
                p='xl'
                m='xl'
                my='auto'
                bg='white'
                radius='lg'
                pos='relative'
                style={{ overflow: 'hidden' }}
            >
                <LoadingOverlay visible={loading} />
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <Flex justify='center' mb='lg'>
                        <Image width='100px' src={LOGO} />
                    </Flex>
                    <Title order={3} ta='center' c='text.2'>
                        เข้าสู่ระบบ
                    </Title>
                    <Text size='xs' ta='center' mb='lg' mt='sm' c='text.2'>
                        ยินดีต้อนรับเข้าสู่ <br />
                        ระบบติดตามผลการดำเนินโครงการ
                        <br />
                        ศูนย์บริการวิชาการ มหาวิทยาลัยศรีนครินทรวิโรฒ
                    </Text>
                    <TextInput
                        withAsterisk
                        label='ชื่อผู้ใช้งาน'
                        placeholder='ชื่อผู้ใ้ช้งาน'
                        {...form.getInputProps('username')}
                    />
                    <PasswordInput
                        autoComplete='on'
                        withAsterisk
                        mt='md'
                        label='รหัสผ่าน'
                        placeholder='รหัสผ่าน!'
                        {...form.getInputProps('password')}
                    />

                    <Flex justify='end' mt='lg'>
                        <Anchor
                            type='button'
                            c='fuchasia.4'
                            component='button'
                            onClick={openForgetPassword}
                            size='sm'
                        >
                            ลืมรหัสผ่าน?
                        </Anchor>
                    </Flex>
                    <Button
                        radius='xl'
                        color='fuchasia.4'
                        type='submit'
                        fullWidth
                        fz='sm'
                        size='md'
                        mt='md'
                    >
                        เข้าสู่ระบบ
                    </Button>
                </form>
            </Paper>
            <Text c='white' size='xs' p='lg'>
                THE CENTER FOR ACADEMIC SERVICES, SRINAKHARINWIROT UNIVERSITY.
            </Text>
        </Flex>
    )
}

export default Login

import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core'
import { Link, useNavigate } from 'react-router-dom'
import {
    IRequestUpdateExpertise,
    IResponseGetExpertiseByID,
} from '@interfaces/api/IExpertise'
import Expertise from '@api/Expertise'
import InputStatus from '@components/form/input/InputStatus'

const FormEditExpertise = ({
    data,
    aftersuccess,
}: {
    data: IResponseGetExpertiseByID
    aftersuccess?: () => any
}) => {
    const form = useForm<IRequestUpdateExpertise>({
        initialValues: {
            expertise_name: data.expertise_name,
            status: data.status,
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = Expertise.useUpdateExpertise({
        expertise_id: data.expertise_id,
        form,
        aftersuccess: () => navigate('/expertises'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ความเชี่ยวชาญ'
                        placeholder='ความเชี่ยวชาญ'
                        {...form.getInputProps('expertise_name')}
                    />
                    <InputStatus {...form.getInputProps('status')} />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/expertises'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            บันทึกการแก้ไขความเชี่ยวชาญ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormEditExpertise

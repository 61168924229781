import React from 'react'

import { Card, Flex, Title } from '@mantine/core'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'
import { IResponseGetChairmanOrganizationByID } from '../../interfaces/api/IChairmanOrganization'

const CardChairmanOrganizationDetail = ({
    data,
}: {
    data: IResponseGetChairmanOrganizationByID
}) => {
    return (
        <Card
            key={data.chairman_organization_id}
            shadow='md'
            radius='md'
            padding='md'
        >
            <Flex gap='5px' direction='column'>
                <Title order={5}>{data.chairman_organization_name}</Title>
                <TextCreatedBy {...data} />
                <TextUpdatedBy {...data} />
            </Flex>
        </Card>
    )
}

export default CardChairmanOrganizationDetail

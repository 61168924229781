import TextChartTitle from '@components/text/TextChartTitle'
import _ from 'lodash'
import React from 'react'
import Chart from 'react-google-charts'
import ChartFlexSection from './ChartFlexSection'
import { PieChartOptions } from './setting'

interface Idata {
    agency_name: string
    agency_type_name: string
    agency_budget_year: number
    total_project_amount: number
    total_project_budget: number
}
interface IAgencyAmountByAgencyTypePieChart {
    title: string
    typeName: string
    valueName: string
    data: Idata[]
}

const AgencyAmountByAgencyTypePieChart = ({
    title,
    typeName,
    valueName,
    data,
}: IAgencyAmountByAgencyTypePieChart) => {
    const prepareData = _(data)
        .groupBy('agency_type_name')
        .map((values, key) => ({
            agency_type_name: key,
            total_agency_amount: _.uniqBy(values, (v) => v.agency_name).length,
        }))
        .value()

    const prepareDatawithAnnotation = prepareData.map((p) => [
        `${p.agency_type_name} (${p.total_agency_amount} หน่วยงาน)`,
        p.total_agency_amount,
    ])

    const AgencyAmountByAgencyTypePieChartData = [
        [typeName, valueName],
        ...prepareDatawithAnnotation,
    ]

    const options = PieChartOptions(valueName, typeName)
    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <Chart
                width={'100%'}
                height={'500px'}
                chartType='PieChart'
                loader={<div>Loading Chart</div>}
                data={AgencyAmountByAgencyTypePieChartData}
                options={options}
            />
        </ChartFlexSection>
    )
}

export default AgencyAmountByAgencyTypePieChart

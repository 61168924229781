import FormCreateLecturer from '@components/form/lecturer/FormCreateLecturer'
import PageLayout from '@components/page/PageLayout'
const CreateLecturer = () => {
    return (
        <PageLayout pageTitle='สร้างวิทยากร'>
            <FormCreateLecturer />
        </PageLayout>
    )
}

export default CreateLecturer

import { Box, Group, Paper, Select, SelectItem, Text } from '@mantine/core'
import { forwardRef, useEffect, useState } from 'react'
import { SelectProps } from '@mantine/core/lib/Select/Select'
import Lecturer from '@api/Lecturer'

interface IInputLecturer extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
    editMode?: boolean
    label?: string
    children?: React.ReactNode
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    value: string
    fullname: string
    email: string
    phone: string
    label: string
    organization: string
}

const InputLecturer = ({
    editMode = true,
    classNames,
    label = 'วิทยากรโครงการ',
    w = '100%',
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    children,
    ...others
}: IInputLecturer) => {
    const { data } = Lecturer.useGetAllLecturers({ limit: 1000 })
    const [list, setlist] = useState<ItemProps[]>([])
    useEffect(() => {
        if (data) {
            setlist(
                data.lists.map((e) => {
                    return {
                        value: String(e.lecturer_id),
                        fullname: e.lecturer_fullname,
                        email: e.lecturer_email,
                        phone: e.lecturer_phone,
                        organization: e.lecturer_organization,
                        label: e.lecturer_fullname,
                    }
                })
            )
        }
    }, [data])
    if (editMode === false) {
        const valueinput = list.filter((e) => e.value === String(value))
        if (valueinput.length) {
            return (
                <Box pos='relative' w={w}>
                    <Text size='sm'>{label}</Text>
                    <Paper p='sm' withBorder mt='2px'>
                        <ShowLecturer {...valueinput[0]} />
                    </Paper>
                    {children}
                </Box>
            )
        }
    }

    return (
        <Select
            key={list.length}
            w={w}
            className={className}
            itemComponent={ShowLecturer}
            label={label}
            value={String(value)}
            placeholder={label}
            searchable
            data={list}
            filter={(value, item: ItemProps) => item.fullname.toLowerCase().includes(value.toLowerCase().trim())}
            {...others}
        />
    )
}

export default InputLecturer

const ShowLecturer = forwardRef<HTMLDivElement, ItemProps>(
    ({ fullname, email, phone, label, organization, ...others }: ItemProps, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <div>
                    <Text size='sm'>{fullname}</Text>
                    {phone && <Text size='xs'>เบอร์โทร : {phone}</Text>}
                    {email && <Text size='xs'>อีเมล : {email}</Text>}
                    {organization && <Text size='xs'>หน่วยงาน / สังกัด : {organization}</Text>}
                </div>
            </Group>
        </div>
    )
)

import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, NumberInput, Paper, TextInput } from '@mantine/core'

import { Link, useNavigate } from 'react-router-dom'
import { IRequestCreateBudgetYear } from '@interfaces/api/IBudgetYear'
import BudgetYear from '@api/BudgetYear'

const FormCreateBudgetYear = ({ aftersuccess }: { aftersuccess?: () => any }) => {
    const form = useForm<IRequestCreateBudgetYear>({
        initialValues: {
            budget_year: '',
            budget_year_name: '',
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = BudgetYear.useCreateBudgetYear({
        form,
        aftersuccess: () => navigate('/budgetyears'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <NumberInput
                        withAsterisk
                        label='ปีงบประมาณ'
                        placeholder='ปีงบประมาณ'
                        {...form.getInputProps('budget_year')}
                    />
                    <TextInput
                        withAsterisk
                        label='ชื่อปีงบประมาณ'
                        placeholder='ชื่อปีงบประมาณ'
                        {...form.getInputProps('budget_year_name')}
                    />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button component={Link} to='/budgetyears' variant='default' w='fit-content'>
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            สร้างปีงบประมาณ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormCreateBudgetYear

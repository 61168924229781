import { Flex, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import TableViewButton from '@components/button/TableViewButton.'
import TableEditButton from '@components/button/TableEditButton'
import TableDeleteButton from '@components/button/TableDeleteButton'
import TablePagination from '@components/table/template/TablePagination'
import TableTemplate from '@components/table/template/TableTemplate'
import { ITHeadMap } from '@interfaces/ITable'
import { Link } from 'react-router-dom'
import TableAddNewButton from '@components/button/TableAddNewButton'
import { modals } from '@mantine/modals'
import { QueryObserverResult } from 'react-query'
import {
    IResponseAllCoordinators,
    IResponseAllCoordinatorsRowData,
} from '@interfaces/api/ICoordinator'
import Coordinator from '@api/Coordinator'
import TableFilter from '@components/table/filter/TableFilter'

const TableAllCoordinator = () => {
    const [limit, setlimit] = useState(10)
    const [orderby, setorderby] =
        useState<keyof IResponseAllCoordinatorsRowData>('coordinator_id')
    const [order, setorder] = useState('desc')
    const [page, setpage] = useState(1)
    const [status, setstatus] = useState('')
    const [search, setsearch] = useState('')

    const [checkowner, setcheckowner] = useState(0)
    const lists = Coordinator.useGetAllCoordinators({
        limit,
        orderby,
        order,
        page,
        search,
        checkowner,
        status,
    })

    useEffect(() => {
        lists.refetch()
        // eslint-disable-next-line
    }, [status, page, limit, order, orderby, checkowner])

    const thead: ITHeadMap<IResponseAllCoordinatorsRowData>[] = [
        {
            sortkey: 'coordinator_fullname',
            label: 'ชื่อผู้ประสานงาน',
            width: '70%',
        },
        {
            sortkey: 'status_description',
            label: 'สถานะ',
            width: '20%',
        },
        // {
        //     sortkey: 'created_at',
        //     label: 'สร้างเมื่อ',
        //     width: '11%',
        // },
        // {
        //     sortkey: 'updated_at',
        //     label: 'แก้ไขล่าสุด',
        //     width: '11%',
        // },
        {
            label: 'Action',
            width: '10%',
        },
    ]

    return (
        <>
            <TableFilter
                {...{
                    setcheckowner,
                    checkowner,
                    setpage,
                    page,
                    search,
                    setsearch,
                    setstatus,
                    status,
                    lists,
                }}
            >
                <TableAddNewButton
                    component={Link}
                    to='/coordinators/create'
                    label='เพิ่มผู้ประสานงาน'
                />
            </TableFilter>

            <TableTemplate<IResponseAllCoordinatorsRowData>
                thead={thead}
                orderby={orderby}
                order={order}
                setorder={setorder}
                setorderby={setorderby}
                lists={lists?.data?.lists}
            >
                {lists?.data?.lists.map((value) => (
                    <AllProjectTableRow
                        key={value.coordinator_id}
                        data={value}
                        refreshTable={lists.refetch}
                    />
                ))}
            </TableTemplate>
            {lists.data?.lists.length ? (
                <TablePagination
                    limit={lists.data?.lists.length}
                    setlimit={setlimit}
                    setpage={setpage}
                    page={page}
                    totalPages={lists.data.totalPages}
                    totalRows={lists.data.totalRows}
                />
            ) : (
                <></>
            )}
        </>
    )
}

const AllProjectTableRow = ({
    data,
    refreshTable,
}: {
    data: IResponseAllCoordinatorsRowData
    refreshTable: () => Promise<
        QueryObserverResult<IResponseAllCoordinators, unknown>
    >
}) => {
    const Delete = Coordinator.useDeleteCoordinator({
        coordinator_id: data.coordinator_id,
        aftersuccess: refreshTable,
    })

    const openDeleteConfirmModal = () =>
        modals.openConfirmModal({
            title: 'กรุณายืนยันการลบ',
            children: (
                <Text>
                    ลบผู้ประสานงาน <br />
                    {data.coordinator_fullname}
                </Text>
            ),
            labels: { confirm: 'ยืนยัน', cancel: 'ยกเลิก' },
            onConfirm: Delete.onSubmit,
        })

    return (
        <tr key={data.coordinator_id}>
            <td>{data.coordinator_fullname}</td>
            <td>{data.status_description}</td>
            {/* <DateAndFullNameColumn
                day={data.created_at}
                fullname={data.created_by_user_fullname}
                user_id={data.created_by_user_id}
            />
            <DateAndFullNameColumn
                day={data.updated_at}
                fullname={data.updated_by_user_fullname}
                user_id={data.updated_by_user_id}
            /> */}
            <td>
                <Flex gap='xs' pos='relative'>
                    <TableViewButton
                        component={Link}
                        to={`/coordinators/${data.coordinator_id}/view`}
                    />
                    <TableEditButton
                        component={Link}
                        to={`/coordinators/${data.coordinator_id}/edit`}
                    />
                    <TableDeleteButton onClick={openDeleteConfirmModal} />
                </Flex>
            </td>
        </tr>
    )
}

export default TableAllCoordinator

import api from '@config/api'
import { useQuery } from 'react-query'

import { useFormMutationHandler } from '@utils/useFormMutationHandler'
import { IFormMutationHandlerProps } from '@interfaces/default/IDefault'
import { useQueryConfig } from './useQueryConfig'
import {
    IRequestCreateUser,
    IRequestGetAllUsers,
    IRequestUpdateUserPassword,
    IRequestUpdateUser,
    IResponseGetAllUsers,
    IResponseGetUserByID,
    IUserID,
} from '@interfaces/api/IUser'
interface IUseCreateUser
    extends IFormMutationHandlerProps<IRequestCreateUser> {}

interface IUseUpdateUser
    extends IUserID,
        IFormMutationHandlerProps<IRequestUpdateUser> {}

interface IUseUpdateUserPassword
    extends IUserID,
        IFormMutationHandlerProps<IRequestUpdateUserPassword> {}

interface IUseDeleteUser extends IUserID, IFormMutationHandlerProps<IUserID> {}

const User = {
    useGetAllUsers: ({
        limit = 10,
        orderby = 'user_id',
        order = 'desc',
        page = 1,
        search = '',
        status = '',
    }: IRequestGetAllUsers) =>
        useQuery(
            'getAllUsers' + orderby,
            async () => {
                try {
                    const res = await api.get('/users', {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                            status,
                        },
                    })
                    return res.data.body as IResponseGetAllUsers
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),
    useGetUserByID: ({ user_id }: IUserID) =>
        useQuery(
            'getUserByID' + user_id,
            async () => {
                try {
                    const res = await api.get(`/users/${user_id}`)
                    return res.data.body as IResponseGetUserByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useCreateUser: ({ form, aftersuccess }: IUseCreateUser) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: ['/users', 'post'],
            aftersuccess,
        })
    },
    useUpdateUser: ({ form, user_id, aftersuccess }: IUseUpdateUser) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/users/${user_id}`, 'put'],
            aftersuccess,
        })
    },
    useUpdateUserPassword: ({
        form,
        user_id,
        aftersuccess,
    }: IUseUpdateUserPassword) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/users/${user_id}/password`, 'put'],
            aftersuccess,
        })
    },

    useDeleteUser: ({ user_id, aftersuccess }: IUseDeleteUser) => {
        return useFormMutationHandler({
            pathWithMethod: [`/users/${user_id}`, 'delete'],
            aftersuccess,
        })
    },
}

export default User

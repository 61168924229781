import { Flex, FlexProps } from '@mantine/core'
import React from 'react'
interface ICardDashboardStatGroup extends FlexProps {}
const CardDashboardStatGroup = (props: ICardDashboardStatGroup) => {
    return (
        <Flex wrap='wrap' {...props}>
            {props.children}
        </Flex>
    )
}

export default CardDashboardStatGroup

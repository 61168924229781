import {
    Button,
    ButtonProps,
    createPolymorphicComponent,
    Text,
} from '@mantine/core'
import { IconSquareRoundedPlus } from '@tabler/icons-react'
interface MyComponentProps extends ButtonProps {
    label: string
}

const _TableAddNewButton = ({
    label,
    classNames,
    styles,
    unstyled,
    className,
    ...others
}: MyComponentProps) => {
    return (
        <Button
            className={className}
            size='sm'
            px='xs'
            color='teal'
            h={'36px'}
            mt='auto'
            {...others}
        >
            <IconSquareRoundedPlus size={'1.2rem'} />
            <Text ml='5px'>{label}</Text>
        </Button>
    )
}

const TableAddNewButton = createPolymorphicComponent<
    'button',
    MyComponentProps
>(_TableAddNewButton)
export default TableAddNewButton

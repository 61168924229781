import api from '@config/api'
import { useQuery } from 'react-query'
import { IResponseAllProjectProgressStatus } from '@interfaces/api/IProjectProgressStatus'

const ProjectProgressStatus = {
    useList: () =>
        useQuery(
            'allprojectprogress',
            async () => {
                try {
                    const res = await api.get('/projectprogress')
                    return res.data.body as IResponseAllProjectProgressStatus
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            { refetchOnWindowFocus: false }
        ),
}

export default ProjectProgressStatus

import Auth from '@api/Auth'
import FormChangePassword from '@components/form/auth/FormChangePassword'
import FormEditProfile from '@components/form/auth/FormEditProfile'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import { Paper, Text } from '@mantine/core'

const EditProfile = () => {
    const { isLoading, data } = Auth.useGetProfile()

    if (isLoading || !data) {
        return <LoadingPage />
    }

    return (
        <PageLayout pageTitle='แก้ไขโปรไฟล์'>
            <Paper withBorder p='lg' radius='sm'>
                <Text fw='bold' mb='lg'>
                    แก้ไขข้อมูลทั่วไป
                </Text>
                <FormEditProfile data={data} />
            </Paper>
            <Paper withBorder p='lg' radius='sm' mt='lg'>
                <Text fw='bold' mb='lg'>
                    แก้ไขรหัสผ่าน
                </Text>
                <FormChangePassword data={data} />
            </Paper>
        </PageLayout>
    )
}

export default EditProfile

import { joiResolver, useForm } from '@mantine/form'
import { Button, Checkbox, Flex, Paper, Text } from '@mantine/core'

import { useNavigate } from 'react-router-dom'
import InputBudgetYear from '../input/InputBudgetYear'
import { useState } from 'react'
import InputProjectProgressStatus from '../input/InputProjectProgressStatus'
import { IRequestMutipleDashboard } from '@interfaces/api/IDashboard'
import { notifications } from '@mantine/notifications'
import { IconExclamationCircle } from '@tabler/icons-react'
import Joi from 'joi'

const joischema = Joi.object().keys({
    start_budget_year: Joi.number().min(2000).max(2300).required().messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'number.max': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'number.min': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'any.required': `กรุณากรอกปีงบประมาณ`,
        'any.only': `กรุณากรอกปีงบประมาณให้ถูกต้อง`,
    }),
    end_budget_year: Joi.number()
        .min(2000)
        .max(2300)
        .messages({
            'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
            'number.max': 'รูปแบบข้อมูลไม่ถูกต้อง',
            'number.greater': 'ปีสิ้นสุดต้องมากกว่าปีเริ่มต้น',
            'number.min': 'รูปแบบข้อมูลไม่ถูกต้อง',
            'any.required': `กรุณากรอกปีงบประมาณ`,
            'any.only': `กรุณากรอกปีงบประมาณให้ถูกต้อง`,
        })
        .required()
        .greater(Joi.ref('start_budget_year')),
    project_progress_status_id: Joi.number().allow(null, '').required().messages({
        'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
        'any.required': `กรุณากรอกข้อมูล`,
    }),

    page: Joi.any(),
    opt1_project_stats: Joi.any(),
    opt2_project_quarter: Joi.any(),
    opt3_project_budget_ranking: Joi.any(),
    opt3_6_budget_by_project_type: Joi.any(),
    opt4_agency_stats: Joi.any(),
    opt5_agency_new_and_old: Joi.any(),
    opt6_agency_by_type: Joi.any(),
    opt7_budget_by_agency_type: Joi.any(),
    opt8_agency_budget_ranking: Joi.any(),
    opt9_coodinator_budget_rank: Joi.any(),
    opt10_chairman_budget_rank: Joi.any(),
    opt11_lecturer_rating_rank: Joi.any(),
    opt12_vendor_stats: Joi.any(),
    opt13_vendor_rating_rank: Joi.any(),
})

const FormMutipleDashboard = ({ aftersuccess }: { aftersuccess?: () => any }) => {
    const navigate = useNavigate()
    const [allCheck, setAllCheck] = useState(false)
    const [allProjectOptionCheck, setAllProjectOptionCheck] = useState(false)
    const [allAgencyOptionCheck, setAllAgencyOptionCheck] = useState(false)
    const [allPersonOptionCheck, setAllPersonOptionCheck] = useState(false)

    const form = useForm<IRequestMutipleDashboard>({
        initialValues: {
            start_budget_year: '',
            end_budget_year: '',
            page: 1,
            project_progress_status_id: '',
            // project
            opt1_project_stats: false,
            opt2_project_quarter: false,
            opt3_project_budget_ranking: false,
            opt3_6_budget_by_project_type: false,
            // agency
            opt4_agency_stats: false,
            opt5_agency_new_and_old: false,
            opt6_agency_by_type: false,
            opt7_budget_by_agency_type: false,
            opt8_agency_budget_ranking: false,
            // coordinator chairman lecturer and vendor
            opt9_coodinator_budget_rank: false,
            opt10_chairman_budget_rank: false,
            opt11_lecturer_rating_rank: false,
            opt12_vendor_stats: false,
            opt13_vendor_rating_rank: false,
        },
        validate: joiResolver(joischema),
    })

    const checkForm = () => {
        return Object.values(form.values).filter((v) => v === true).length ? true : false
    }

    const formOnSubmit = async () => {
        if (checkForm() === false) {
            notifications.show({
                title: 'เกิดข้อผิดพลาด',
                message: 'กรุณาเลือกรายการข้อมูลที่ต้องการสรุปผล',
                color: 'red',
                icon: <IconExclamationCircle size='1rem' />,
            })
            return
        }
        navigate('/dashboard/mutiple', { state: form.values })
    }

    const onAllCheckChange = (check: boolean) => {
        toggleCheckAllProjectOption(check)
        toggleCheckAllAgencyOption(check)
        toggleCheckAllPersonOption(check)
        setAllCheck(check)
    }

    const toggleCheckAllProjectOption = (check: boolean) => {
        setAllProjectOptionCheck(check)
        form.setFieldValue('opt1_project_stats', check)
        form.setFieldValue('opt2_project_quarter', check)
        form.setFieldValue('opt3_project_budget_ranking', check)
        form.setFieldValue('opt3_6_budget_by_project_type', check)
    }
    const toggleCheckAllAgencyOption = (check: boolean) => {
        setAllAgencyOptionCheck(check)
        form.setFieldValue('opt4_agency_stats', check)
        form.setFieldValue('opt5_agency_new_and_old', check)
        form.setFieldValue('opt6_agency_by_type', check)
        form.setFieldValue('opt7_budget_by_agency_type', check)
        form.setFieldValue('opt8_agency_budget_ranking', check)
    }

    const toggleCheckAllPersonOption = (check: boolean) => {
        setAllPersonOptionCheck(check)
        form.setFieldValue('opt9_coodinator_budget_rank', check)
        form.setFieldValue('opt10_chairman_budget_rank', check)
        form.setFieldValue('opt11_lecturer_rating_rank', check)
        form.setFieldValue('opt12_vendor_stats', check)
        form.setFieldValue('opt13_vendor_rating_rank', check)
    }

    return (
        <Paper pos='relative' p='lg' withBorder>
            <form onSubmit={form.onSubmit(formOnSubmit)}>
                <Flex gap='sm' direction='column'>
                    <Flex gap='md' w='100%'>
                        <InputBudgetYear
                            w='100%'
                            withAsterisk
                            {...form.getInputProps('start_budget_year')}
                            label='ปีงบประมาณที่ต้องการดูผลการดำเนินโครงการ (เริ่มต้น)'
                        />
                        <InputBudgetYear
                            w='100%'
                            withAsterisk
                            {...form.getInputProps('end_budget_year')}
                            label='ปีงบประมาณที่ต้องการดูผลการดำเนินโครงการ (สิ้นสุด)'
                        />

                        <InputProjectProgressStatus
                            withAsterisk
                            miw='200px'
                            label='สถานะโครงการ'
                            {...form.getInputProps('project_progress_status_id')}
                        />
                    </Flex>
                    <Flex direction='column' gap='lg' mt='sm'>
                        <Checkbox
                            checked={allCheck}
                            onChange={(e) => onAllCheckChange(e.target.checked)}
                            label={
                                <Text fw='bold'>
                                    รายการข้อมูลที่ต้องการสำหรับสรุปข้อมูลผลการดำเนินโครงการประจำปีงบประมาณที่เลือก
                                </Text>
                            }
                        />
                        <Flex direction='column' gap='sm'>
                            <Checkbox
                                checked={allProjectOptionCheck}
                                onChange={(e) => toggleCheckAllProjectOption(e.target.checked)}
                                label={
                                    <Text size='sm' fw='bold'>
                                        สรุปผลข้อมูลโครงการ
                                    </Text>
                                }
                            />
                            <Checkbox
                                {...form.getInputProps('opt1_project_stats', {
                                    type: 'checkbox',
                                })}
                                label='สถิติยอดจำนวนโครงการ และมูลค่าของโครงการทั้งหมด (Stats)'
                            />
                            <Checkbox
                                {...form.getInputProps('opt2_project_quarter', {
                                    type: 'checkbox',
                                })}
                                label='ยอดมูลค่าโครงการทั้งหมดแบ่งสัดส่วนตามไตรมาส (Column Chart)'
                            />
                            <Checkbox
                                {...form.getInputProps('opt3_project_budget_ranking', { type: 'checkbox' })}
                                label='จัดอันดับโครงการสำคัญที่มียอดมูลค่าโครงการสูงสุด 5 อันดับแรก (Table Bar Chart)'
                            />
                            <Checkbox
                                {...form.getInputProps('opt3_6_budget_by_project_type', { type: 'checkbox' })}
                                label='ยอดมูลค่าโครงการทั้งหมด แบ่งสัดส่วนตามประเภทกิจกรรมบริการวิชาการ (Pie Chart)'
                            />
                        </Flex>
                        <Flex direction='column' gap='sm'>
                            <Checkbox
                                checked={allAgencyOptionCheck}
                                onChange={(e) => toggleCheckAllAgencyOption(e.target.checked)}
                                label={
                                    <Text size='sm' fw='bold'>
                                        สรุปผลข้อมูลหน่วยงาน/ลูกค้า
                                    </Text>
                                }
                            />
                            <Checkbox
                                {...form.getInputProps('opt4_agency_stats', {
                                    type: 'checkbox',
                                })}
                                label='สถิติยอดจำนวนลูกค้าทั้งหมด และจำนวนลูกค้ารายเก่าและลูกค้ารายใหม่ (Stats)'
                            />
                            <Checkbox
                                {...form.getInputProps('opt5_agency_new_and_old', {
                                    type: 'checkbox',
                                })}
                                label='ยอดจำนวนลูกค้ารายใหม่และรายเก่า (Column Chart)'
                            />

                            <Checkbox
                                {...form.getInputProps('opt6_agency_by_type', {
                                    type: 'checkbox',
                                })}
                                label='ยอดจำนวนลูกค้าทั้งหมด แบ่งสัดส่วนตามประเภทหน่วยงาน (Pie Chart)'
                            />
                            <Checkbox
                                {...form.getInputProps('opt7_budget_by_agency_type', { type: 'checkbox' })}
                                label='ยอดมูลค่าโครงการทั้งหมด แบ่งสัดส่วนตามประเภทหน่วยงาน (Pie Chart)'
                            />
                            <Checkbox
                                {...form.getInputProps('opt8_agency_budget_ranking', { type: 'checkbox' })}
                                label='จัดอันดับลูกค้าสำคัญที่มียอดมูลค่าโครงการสูงสุด (Table Bar Chart)'
                            />
                        </Flex>
                        <Flex direction='column' gap='sm'>
                            <Checkbox
                                checked={allPersonOptionCheck}
                                onChange={(e) => toggleCheckAllPersonOption(e.target.checked)}
                                label={
                                    <Text size='sm' fw='bold'>
                                        สรุปผลข้อมูลผลดำเนินโครงการ ของผู้ประสานงาน ประธานโครงการ วิทยากร และผู้ค้า
                                    </Text>
                                }
                            />
                            <Checkbox
                                {...form.getInputProps('opt9_coodinator_budget_rank', { type: 'checkbox' })}
                                label='จัดอันดับผู้ประสานงานโครงการที่มียอดมูลค่าโครงการสูงสุด (Table Bar Chart)'
                            />
                            <Checkbox
                                {...form.getInputProps('opt10_chairman_budget_rank', { type: 'checkbox' })}
                                label='จัดอันดับประธานโครงการที่มียอดมูลค่าโครงการสูงสุด (Table Bar Chart)'
                            />
                            <Checkbox
                                {...form.getInputProps('opt11_lecturer_rating_rank', { type: 'checkbox' })}
                                label='จัดอันดับวิทยากรที่ได้รับค่าเฉลี่ยผลการประเมินคะแนนความพึงพอใจสูงสุด (Table)'
                            />
                            <Checkbox
                                {...form.getInputProps('opt12_vendor_stats', {
                                    type: 'checkbox',
                                })}
                                label='สถิติยอดจำนวนผู้ค้าทั้งหมดแบ่งสัดส่วนตามผู้ค้ารายใหม่และรายเก่า และผลต่าง (Stats)'
                            />
                            <Checkbox
                                {...form.getInputProps('opt13_vendor_rating_rank', { type: 'checkbox' })}
                                label='จัดอันดับผู้ค้าที่ได้รับค่าเฉลี่ยผลการประเมินคะแนนความพึงพอใจสูงสุด (Table)'
                            />
                        </Flex>

                        <Flex mt='md' justify='end' gap='sm'>
                            <Button type='submit' w='fit-content'>
                                ประมวลผลสรุปข้อมูล
                            </Button>
                        </Flex>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormMutipleDashboard

import Project from '@api/Project'
import LoadingForm from '@components/loading/LoadingForm'
import { IRequestUpdateCloseProjectInfo, IRequestUpdateProjectCompleteStatus } from '@interfaces/api/IProject'
import { Button, Flex, Group, LoadingOverlay, Paper, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import React, { useState } from 'react'
import PageNotFound404 from '@pages/PageNotFound404'
import InputHasOrNot from '../input/InputHasOrNot'
import InputDate from '@components/form/input/InputDate'
import InputProjectCompleteStatus from '../input/InputProjectCompleteStatus'
const FormCloseProject = ({ project_id, editMode }: { project_id: number | string; editMode: boolean }) => {
    const [editCloseProjectInfo] = useState(true)
    const [editProjectCompleteStatus] = useState(true)
    let projectinfo = Project.useGetProjectInfoByID({
        project_id: project_id!,
    })

    // useEffect(() => {
    //     if (editMode) {
    //         if (editCloseProjectInfo === true) {
    //             setEditProjectCompleteStatus(false)
    //         }
    //     } else {
    //         setEditCloseProjectInfo(false)
    //     }
    // }, [editCloseProjectInfo, editMode])

    // useEffect(() => {
    //     if (editMode) {
    //         if (editProjectCompleteStatus === true) {
    //             setEditCloseProjectInfo(false)
    //         }
    //     } else {
    //         setEditProjectCompleteStatus(false)
    //     }
    // }, [editProjectCompleteStatus, editMode])

    const form = useForm<IRequestUpdateCloseProjectInfo>({
        initialValues: {
            has_parcel: projectinfo.data!.has_parcel,
            submit_to_finance_department_date: projectinfo.data!.submit_to_finance_department_date,
            submit_to_fixed_date: projectinfo.data!.submit_to_fixed_date,
            manager_sign_date: projectinfo.data!.manager_sign_date,
            director_sign_date: projectinfo.data!.director_sign_date,
            accounting_department_collect_date: projectinfo.data!.accounting_department_collect_date,
        },
    })

    console.log(projectinfo)

    const form2 = useForm<IRequestUpdateProjectCompleteStatus>({
        initialValues: {
            project_complete_status:
                projectinfo.data!.project_progress_status_id === 0 || projectinfo.data!.project_progress_status_id === 6
                    ? projectinfo.data!.project_progress_status_id
                    : 3,
        },
    })

    const onAfterCloseProjectUpdateInfoAndStatus = () => {
        projectinfo.refetch()
    }

    const updateCloseProjectInfo = Project.useUpdateCloseProjectInfo({
        project_id,
        form,
        aftersuccess: () => onAfterCloseProjectUpdateInfoAndStatus(),
    })

    const updateProjectCompleteStatus = Project.useUpdateProjectCompleteStatus({
        project_id,
        form: form2,
        aftersuccess: () => onAfterCloseProjectUpdateInfoAndStatus(),
    })

    if (projectinfo.isLoading) {
        return <LoadingForm />
    }
    if (projectinfo.isError || projectinfo.data === undefined) {
        return <PageNotFound404 />
    }

    return (
        <Flex direction='column' gap='md'>
            <Paper p='md' withBorder pos='relative'>
                <Group align='center' position='apart' mb='md'>
                    <Text fw='bold'>ข้อมูลปิดโครงการ</Text>
                    {/* 
                    {editMode && (
                        <TableEditButton
                            onClick={() =>
                                setEditCloseProjectInfo(!editCloseProjectInfo)
                            }
                        />
                    )} */}
                </Group>
                <LoadingOverlay visible={updateCloseProjectInfo.isLoading} overlayBlur={2} />
                <form
                    onSubmit={updateCloseProjectInfo.onSubmit}
                    style={{
                        pointerEvents: editCloseProjectInfo && editMode ? 'all' : 'none',
                    }}
                >
                    <Flex gap='sm' direction='column'>
                        <InputHasOrNot
                            clearable={editCloseProjectInfo && editMode}
                            label='มีการจัดทำพัสดุหรือไม่'
                            {...form.getInputProps('has_parcel')}
                        />
                        <InputDate
                            clearable={editCloseProjectInfo && editMode}
                            label='วันที่ส่งการเงิน'
                            placeholder='วันที่ส่งการเงิน'
                            {...form.getInputProps('submit_to_finance_department_date')}
                        />
                        <InputDate
                            clearable={editCloseProjectInfo && editMode}
                            label='วันที่ส่งคืนแก้ไข'
                            placeholder='วันที่ส่งคืนแก้ไข'
                            {...form.getInputProps('submit_to_fixed_date')}
                        />
                        <InputDate
                            clearable={editCloseProjectInfo && editMode}
                            label='วันที่ผู้จัดการลงนาม'
                            placeholder='วันที่ผู้จัดการลงนาม'
                            {...form.getInputProps('manager_sign_date')}
                        />
                        <InputDate
                            clearable={editCloseProjectInfo && editMode}
                            label='วันที่ผู้อำนวยการลงนาม'
                            placeholder='วันที่ผู้อำนวยการลงนาม'
                            {...form.getInputProps('director_sign_date')}
                        />
                        <InputDate
                            clearable={editCloseProjectInfo && editMode}
                            label='วันที่บัญชีจัดเก็บ'
                            placeholder='วันที่บัญชีจัดเก็บ'
                            {...form.getInputProps('accounting_department_collect_date')}
                        />
                        {editCloseProjectInfo && editMode && (
                            <Button type='submit' w='fit-content' mt='md' ml='auto'>
                                บันทึกการแก้ไขข้อมูลปิดโครงการ
                            </Button>
                        )}
                    </Flex>
                </form>
            </Paper>
            <Paper p='md' withBorder pos='relative'>
                <Group align='center' position='apart' mb='md'>
                    <Text fw='bold'>ข้อมูลปิดโครงการ</Text>

                    {/* {editMode && (
                        <TableEditButton
                            onClick={() =>
                                setEditProjectCompleteStatus(
                                    !editProjectCompleteStatus
                                )
                            }
                        />
                    )} */}
                </Group>
                <LoadingOverlay visible={updateProjectCompleteStatus.isLoading} overlayBlur={2} />
                <form
                    onSubmit={updateProjectCompleteStatus.onSubmit}
                    style={{
                        pointerEvents: editProjectCompleteStatus && editMode ? 'all' : 'none',
                    }}
                >
                    <Flex gap='sm' direction='column'>
                        <InputProjectCompleteStatus {...form2.getInputProps('project_complete_status')} />

                        {editProjectCompleteStatus && editMode && (
                            <Button type='submit' w='fit-content' mt='md' ml='auto'>
                                บันทึกสถานะการปิดโครงการ
                            </Button>
                        )}
                    </Flex>
                </form>
            </Paper>
        </Flex>
    )
}

export default FormCloseProject

import api from '@config/api'
import { useQuery } from 'react-query'
import { IRequestGetAllLecturers } from '@interfaces/api/ILecturer'
import { useQueryConfig } from './useQueryConfig'
import { useFormMutationHandler } from '@utils/useFormMutationHandler'
import { IFormMutationHandlerProps } from '@interfaces/default/IDefault'
import {
    IExpertiseID,
    IRequestCreateExpertise,
    IRequestUpdateExpertise,
    IResponseGetAllExpertises,
    IResponseGetExpertiseByID,
} from '@interfaces/api/IExpertise'

interface IUseCreateExpertise
    extends IFormMutationHandlerProps<IRequestCreateExpertise> {}

interface IUseUpdateExpertise
    extends IExpertiseID,
        IFormMutationHandlerProps<IRequestUpdateExpertise> {}

interface IUseDeleteExpertise
    extends IExpertiseID,
        IFormMutationHandlerProps<IExpertiseID> {}

const Expertise = {
    useGetAllExpertise: ({
        limit = 100,
        orderby = 'expertise_id',
        order = 'desc',
        page = 1,
        search = '',
        has_enabled,
        status = '',
        checkowner = 0,
    }: IRequestGetAllLecturers) =>
        useQuery(
            'getAllExpertise' + orderby,
            async () => {
                try {
                    const res = await api.get('/expertises', {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                            has_enabled,
                            checkowner,
                            status,
                        },
                    })
                    return res.data.body as IResponseGetAllExpertises
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useGetExpertiseByID: ({ expertise_id }: IExpertiseID) =>
        useQuery(
            'getExpertiseByID' + expertise_id,
            async () => {
                try {
                    const res = await api.get(`/expertises/${expertise_id}`)
                    return res.data.body as IResponseGetExpertiseByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useCreateExpertise: ({ form, aftersuccess }: IUseCreateExpertise) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: ['/expertises', 'post'],
            aftersuccess,
        })
    },
    useUpdateExpertise: ({
        form,
        expertise_id,
        aftersuccess,
    }: IUseUpdateExpertise) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/expertises/${expertise_id}`, 'put'],
            aftersuccess,
        })
    },

    useDeleteExpertise: ({
        expertise_id,
        aftersuccess,
    }: IUseDeleteExpertise) => {
        return useFormMutationHandler({
            pathWithMethod: [`/expertises/${expertise_id}`, 'delete'],
            aftersuccess,
        })
    },
}

export default Expertise

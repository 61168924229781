import Lecturer from '@api/Lecturer'
import FormEditLecturer from '@components/form/lecturer/FormEditLecturer'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const EditLecturer = () => {
    const params = useParams()
    const lecturer_id = params.lecturer_id
    const { isLoading, isError, data, isRefetching } =
        Lecturer.useGetLecturerByID({
            lecturer_id: lecturer_id!,
        })
    if (isLoading || isRefetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าวิทยากรทั้งหมด'
                url='/lecturers'
            />
        )
    }
    return (
        <PageLayout pageTitle='แก้ไขวิทยากร'>
            <FormEditLecturer data={data} />
        </PageLayout>
    )
}

export default EditLecturer

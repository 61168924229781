import UserPosition from '@api/UserPosition'
import CardUserPositionDetail from '@components/card/CardUserPositionDetail'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const ViewUserPosition = () => {
    const params = useParams()
    const user_position_id = params.user_position_id
    const { isLoading, isError, data, isRefetching } =
        UserPosition.useGetUserPostionByID({
            user_position_id: user_position_id!,
        })
    if (isLoading || isRefetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าตำแหน่งทั้งหมด'
                url='/users#user_position'
            />
        )
    }
    return (
        <PageLayout pageTitle='ข้อมูลตำแหน่ง'>
            <CardUserPositionDetail data={data} />
        </PageLayout>
    )
}

export default ViewUserPosition

import PageLayout from '@components/page/PageLayout'
import TableAllProjectType from '@components/table/database/project/TableAllProjectType'

const AllProjectType = () => {
    return (
        <PageLayout pageTitle='ประเภทกิจกรรมบริการวิชาการ'>
            <TableAllProjectType />
        </PageLayout>
    )
}

export default AllProjectType

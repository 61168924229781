import Lecturer from '@api/Lecturer'
import CardLecturerDetail from '@components/card/CardLecturerDetail'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const ViewLecturer = () => {
    const params = useParams()
    const lecturer_id = params.lecturer_id
    const { isLoading, isFetching, isError, data } =
        Lecturer.useGetLecturerByID({
            lecturer_id: lecturer_id!,
        })
    if (isLoading || isFetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าวิทยากรทั้งหมด'
                url='/lecturers'
            />
        )
    }
    return (
        <PageLayout pageTitle='ข้อมูลวิทยากร'>
            <CardLecturerDetail data={data}></CardLecturerDetail>
        </PageLayout>
    )
}

export default ViewLecturer

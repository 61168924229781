import ProjectType from '@api/ProjectType'
import CardProjectTypeDetail from '@components/card/CardProjectTypeDetail'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const ViewProjectType = () => {
    const params = useParams()
    const project_type_id = params.project_type_id
    const { isLoading, isFetching, isError, data } = ProjectType.useGetByID({
        project_type_id: project_type_id!,
    })
    if (isLoading || isFetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าประเภทกิจกรรมบริการวิชาการทั้งหมด'
                url='/projects/types'
            />
        )
    }
    return (
        <PageLayout pageTitle='ข้อมูลประเภทกิจกรรมบริการวิชาการ'>
            <CardProjectTypeDetail data={data} />
        </PageLayout>
    )
}

export default ViewProjectType

import { ButtonProps, createPolymorphicComponent } from '@mantine/core'
import { IconEdit } from '@tabler/icons-react'
import React from 'react'
import TableActionButton from './TableActionButton'
interface MyComponentProps
    extends ButtonProps,
        React.RefAttributes<HTMLButtonElement> {}
const _TableEditButton = ({
    classNames,
    styles,
    unstyled,
    className,
    ...others
}: MyComponentProps) => {
    return (
        <TableActionButton
            Icon={IconEdit}
            label={'แก้ไข'}
            classNames={classNames}
            styles={styles}
            unstyled={unstyled}
            className={className}
            {...others}
        />
    )
}

const TableEditButton = createPolymorphicComponent<'button', MyComponentProps>(
    _TableEditButton
)
export default TableEditButton

import CardDashboardStat from '@components/card/CardDashboardStat'
import CardDashboardStatGroup from '@components/card/CardDashboardStatGroup'
import TextChartTitle from '@components/text/TextChartTitle'
import { IResponseGetProjectVendorDataForDashboardRowData } from '@interfaces/api/IDashboard'
import { Flex } from '@mantine/core'
import Percen from '@utils/Percen'
import PercenDiff from '@utils/PercenDiff'
import _ from 'lodash'
import React from 'react'
import ChartFlexSection from './ChartFlexSection'

const VendorStatsGroup = ({
    data,
}: {
    data: IResponseGetProjectVendorDataForDashboardRowData[]
}) => {
    const groupedData = _.groupBy(data, 'project_budget_year')
    const start_budget_year = Number(Object.keys(groupedData)[0]) + 543
    const end_budget_year =
        Number(Object.keys(groupedData)[Object.keys(groupedData).length - 1]) +
        543

    const all_vendor = _.uniqBy(data, 'vendor_name').length

    const dataForEachYear = Object.keys(groupedData).map((Byear, index) => {
        const all_vendor = _.uniqBy(groupedData[Byear], 'vendor_name').length

        const old_vendor = _.uniqBy(
            _.filter(
                groupedData[Byear],
                (p) => p.vendor_budget_year !== Number(Byear)
            ),
            'vendor_name'
        ).length

        const new_vendor = _.uniqBy(
            _.filter(groupedData[Byear], {
                vendor_budget_year: Number(Byear),
            }),
            'vendor_name'
        ).length
        return {
            budget_year: Number(Byear) + 543,
            all_vendor,
            old_vendor,
            new_vendor,
        }
    })

    if (data.length === 0) {
        return <></>
    }

    return (
        <ChartFlexSection>
            <TextChartTitle>
                {`สถิติยอดจำนวนผู้ค้าทั้งหมด และจำนวนผู้ค้ารายเก่าและผู้ค้ารายใหม่
                ประจำปีงบประมาณ
                ${start_budget_year} - ${end_budget_year}`}
            </TextChartTitle>
            <CardDashboardStatGroup>
                <CardDashboardStat
                    w={200}
                    unit='หน่วยงาน'
                    label='จำนวนหน่วยงานทั้งหมด'
                    value={all_vendor}
                />
            </CardDashboardStatGroup>
            <Flex direction='column'>
                {dataForEachYear.map((p, index) => (
                    <CardDashboardStatGroup key={index}>
                        <CardDashboardStat
                            w={120}
                            label={index === 0 ? 'ปีงบประมาณ' : ''}
                            value={p.budget_year}
                        />
                        <CardDashboardStat
                            w={180}
                            diff={
                                index === 0
                                    ? undefined
                                    : PercenDiff(
                                          dataForEachYear[index - 1].all_vendor,
                                          p.all_vendor
                                      )
                            }
                            unit='หน่วยงาน'
                            label={index === 0 ? 'จำนวนผู้ค้าทั้งหมด' : ''}
                            value={p.all_vendor}
                        />
                        <CardDashboardStat
                            w={330}
                            unit='หน่วยงาน'
                            label={index === 0 ? 'จำนวนผู้ค้ารายเก่า' : ''}
                            value={`${p.old_vendor} (${Percen(
                                p.old_vendor,
                                p.all_vendor
                            )}%)`}
                        />
                        <CardDashboardStat
                            w={330}
                            unit='หน่วยงาน'
                            label={index === 0 ? 'จำนวนผู้ค้ารายใหม่' : ''}
                            value={`${p.new_vendor} (${Percen(
                                p.new_vendor,
                                p.all_vendor
                            )}%)`}
                        />
                    </CardDashboardStatGroup>
                ))}
            </Flex>
        </ChartFlexSection>
    )
}

export default VendorStatsGroup

import { Navigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import { useContext } from 'react'
import LoadingPage from '@components/loading/LoadingPage'
import { IPrivilegeAccess } from '@interfaces/IAuth'
import { authPrivilege } from './authprivilege.lib'
import AccessDenied from '@pages/AccessDenied'

const ProtectedRoute: React.FC<{
    children: React.ReactNode
    privilege?: keyof IPrivilegeAccess
}> = ({ children, privilege }) => {
    const { isAuthenticated, isLoading, auth } = useContext(AuthContext)

    if (isLoading === true) {
        return <LoadingPage />
    }

    if (isAuthenticated() === false) {
        return <Navigate to={'/login'} />
    }

    if (
        privilege &&
        auth &&
        authPrivilege(privilege, auth.privilege_id) === false
    ) {
        return <AccessDenied />
    }

    return <>{children}</>
}

export default ProtectedRoute

import User from '@api/User'
import CardUserDetail from '@components/card/CardUserDetail'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const ViewUser = () => {
    const params = useParams()
    const user_id = params.user_id
    const { isLoading, isError, data, isRefetching } = User.useGetUserByID({
        user_id: user_id!,
    })
    if (isLoading || isRefetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าผู้ใช้งานทั้งหมด'
                url='/lecturers'
            />
        )
    }
    return (
        <PageLayout pageTitle='ข้อมูลผู้ใช้งานระบบ'>
            <CardUserDetail data={data} />
        </PageLayout>
    )
}

export default ViewUser

import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
interface MyComponentProps extends ButtonProps {}

const _TableDownloadButton = ({
    classNames,
    styles,
    unstyled,
    className,
    ...others
}: MyComponentProps) => {
    return (
        <Button
            className={className}
            size='sm'
            px='8px'
            h={'36px'}
            mt='auto'
            {...others}
        >
            <IconDownload size={'1.2rem'} />
        </Button>
    )
}

const TableDownloadButton = createPolymorphicComponent<
    'button',
    MyComponentProps
>(_TableDownloadButton)
export default TableDownloadButton

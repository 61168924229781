import api from '@config/api'
import { useQuery } from 'react-query'
import {
    IBudgetYearID,
    IRequestCreateBudgetYear,
    IRequestGetAllBudgetYear,
    IRequestUpdateBudgetYear,
    IResponseGetAllBudgetYears,
    IResponseGetBudgetYearByID,
} from '@interfaces/api/IBudgetYear'
import { IFormMutationHandlerProps } from '@interfaces/default/IDefault'
import { useQueryConfig } from './useQueryConfig'
import { useFormMutationHandler } from '@utils/useFormMutationHandler'

interface IUseCreateBudgetYear
    extends IFormMutationHandlerProps<IRequestCreateBudgetYear> {}

interface IUseUpdateBudgetYear
    extends IBudgetYearID,
        IFormMutationHandlerProps<IRequestUpdateBudgetYear> {}

interface IUseDeleteBudgetYear
    extends IBudgetYearID,
        IFormMutationHandlerProps<IBudgetYearID> {}

const BudgetYear = {
    useGetAllBudgetYears: ({
        limit = 100,
        orderby = 'budget_year',
        order = 'desc',
        page = 1,
        search = '',
    }: IRequestGetAllBudgetYear) =>
        useQuery(
            'getAllBudgetYear' + orderby,
            async () => {
                try {
                    const res = await api.get('/budgetyears', {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                        },
                    })
                    return res.data.body as IResponseGetAllBudgetYears
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            { refetchOnWindowFocus: false }
        ),

    useGetBudgetYearByID: ({ budget_year }: IBudgetYearID) =>
        useQuery(
            'getBudgetYearByID',
            async () => {
                try {
                    const res = await api.get(`/budgetyears/${budget_year}`)
                    return res.data.body as IResponseGetBudgetYearByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),
    useCreateBudgetYear: ({ form, aftersuccess }: IUseCreateBudgetYear) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: ['/budgetyears', 'post'],
            aftersuccess,
        })
    },
    useUpdateBudgetYear: ({
        form,
        budget_year,
        aftersuccess,
    }: IUseUpdateBudgetYear) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/budgetyears/${budget_year}`, 'put'],
            aftersuccess,
        })
    },

    useDeleteBudgetYear: ({
        budget_year,
        aftersuccess,
    }: IUseDeleteBudgetYear) => {
        return useFormMutationHandler({
            pathWithMethod: [`/budgetyears/${budget_year}`, 'delete'],
            aftersuccess,
        })
    },
}
export default BudgetYear

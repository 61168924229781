import PageLayout from '@components/page/PageLayout'
import {
    createStyles,
    Title,
    Text,
    Button,
    Container,
    Group,
    rem,
} from '@mantine/core'
import { Link } from 'react-router-dom'

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(80),
        paddingBottom: rem(80),
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    label: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: rem(220),
        lineHeight: 1,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
        color:
            theme.colorScheme === 'dark'
                ? theme.colors.dark[4]
                : theme.colors.gray[2],

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(120),
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: 'center',
        fontWeight: 900,
        fontSize: rem(38),

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(32),
        },
    },

    description: {
        maxWidth: rem(500),
        margin: 'auto',
        marginTop: theme.spacing.xl,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
}))

const PageNotFound404 = ({
    label = 'กลับไปหน้าหลัก',
    url = '/',
}: {
    label?: string
    url?: string
}) => {
    const { classes } = useStyles()

    return (
        <PageLayout>
            <Container className={classes.root}>
                <div className={classes.label}>404</div>
                <Title className={classes.title}>ไม่พบหน้า</Title>
                <Text
                    color='dimmed'
                    size='lg'
                    align='center'
                    className={classes.description}
                >
                    ขออภัย ไม่พบหน้าที่คุณต้องการเข้าถึง กรุณาตรวจสอบ URL
                    หรือลิงก์ที่คุณกำลังค้นหาอีกครั้ง หากคุณมั่นใจว่า URL
                    ถูกต้องและหน้านี้ควรมีอยู่ อาจเกิดข้อผิดพลาดของเซิร์ฟเวอร์
                    กรุณารอสักครู่และลองใหม่อีกครั้ง ขออภัยในความไม่สะดวก
                </Text>
                <Group position='center'>
                    <Button
                        to={url}
                        component={Link}
                        variant='subtle'
                        size='md'
                    >
                        {label}
                    </Button>
                </Group>
            </Container>
        </PageLayout>
    )
}
export default PageNotFound404

import FormCreateAgency from '@components/form/agency/FormCreateAgency'
import PageLayout from '@components/page/PageLayout'

const CreateAgency = () => {
    return (
        <PageLayout pageTitle='สร้างหน่วยงาน/ลูกค้า'>
            <FormCreateAgency />
        </PageLayout>
    )
}

export default CreateAgency

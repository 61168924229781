import { useForm } from '@mantine/form'
import {
    Button,
    Flex,
    LoadingOverlay,
    Paper,
    Textarea,
    TextInput,
} from '@mantine/core'

import { Link, useNavigate } from 'react-router-dom'
import { IRequestCreateChairman } from '@interfaces/api/IChairman'
import Chairman from '@api/Chairman'
import InputChairmanOrganization from '../input/InputChairmanOrganization'
import InputExpertises from '../input/InputExpertises'

const FormCreateChairman = ({ aftersuccess }: { aftersuccess?: () => any }) => {
    const form = useForm<IRequestCreateChairman>({
        initialValues: {
            chairman_fullname: '',
            chairman_organization_id: '',
            chairman_email: '',
            chairman_phone: '',
            chairman_detail: '',
            chairman_expertises: [],
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = Chairman.useCreateChairman({
        form,
        aftersuccess: () => navigate('/chairmans'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อประธานโครงการ'
                        placeholder='ชื่อประธานโครงการ'
                        {...form.getInputProps('chairman_fullname')}
                    />
                    <InputChairmanOrganization
                        withAsterisk
                        label='สังกัดประธานโครงการ'
                        placeholder='สังกัดประธานโครงการ'
                        {...form.getInputProps('chairman_organization_id')}
                    />
                    <TextInput
                        label='อีเมล'
                        placeholder='อีเมล'
                        {...form.getInputProps('chairman_email')}
                    />
                    <TextInput
                        label='เบอร์โทร'
                        placeholder='เบอร์โทร'
                        {...form.getInputProps('chairman_phone')}
                    />
                    <Textarea
                        rows={2}
                        minRows={2}
                        label='เกี่ยวกับประธานโครงการ'
                        placeholder='เกี่ยวกับประธานโครงการ'
                        {...form.getInputProps('chairman_detail')}
                    />
                    <InputExpertises
                        {...form.getInputProps('chairman_expertises')}
                    />
                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/chairmans'
                            variant='default'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            สร้างประธานโครงการ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormCreateChairman

import React from 'react'

import { Card, createStyles, Flex, Textarea, Title } from '@mantine/core'
import TextParameter from '../text/TextParameter'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'
import { IResponseGetLecturerByID } from '@interfaces/api/ILecturer'
import GroupTagBadge from '@components/badge/GroupTagBadge'

const useStyles = createStyles((theme) => ({
    textarea: {
        '&:focus': {
            borderColor: theme.colors.gray[4],
        },
    },
}))

const CardLecturerDetail = ({ data }: { data: IResponseGetLecturerByID }) => {
    const { classes } = useStyles()
    const textparameters = [
        {
            title: 'สังกัด',
            info: data.lecturer_organization,
        },
        {
            title: 'ความเชี่ยวชาญ',
            info: <GroupTagBadge labels={data.lecturer_expertises_name} />,
        },
        {
            title: 'อีเมล',
            info: data.lecturer_email,
        },
        {
            title: 'เบอรโทร',
            info: data.lecturer_phone,
        },
        {
            title: 'เกี่ยวกับวิทยากร',
            type: 'textarea',
            info: data.lecturer_detail,
        },
        {
            title: 'สถานะข้อมูล',
            info: data.status_description,
        },
    ]
    return (
        <Card key={data.lecturer_id} shadow='md' radius='md' padding='md'>
            <Flex gap='5px' direction='column'>
                <Title order={5}>{data.lecturer_fullname}</Title>
                {textparameters.map((value) => {
                    return value.type === 'textarea' ? (
                        <Textarea
                            key={value.title}
                            classNames={{ input: classes.textarea }}
                            autosize
                            rows={0}
                            readOnly
                            label={value.title + ':'}
                            defaultValue={value.info}
                        />
                    ) : (
                        <TextParameter
                            key={value.title}
                            title={value.title}
                            info={value.info!}
                        />
                    )
                })}
                <TextCreatedBy {...data} />
                <TextUpdatedBy {...data} />
            </Flex>
        </Card>
    )
}

export default CardLecturerDetail

import Vendor from '@api/Vendor'
import FormEditVendorType from '@components/form/vendor/FormEditVendorType'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const EditVendorType = () => {
    const params = useParams()
    const vendor_type_id = params.vendor_type_id
    const { isLoading, isError, data, isRefetching } =
        Vendor.useGetVendorTypeByID({
            vendor_type_id: vendor_type_id!,
        })
    if (isLoading || isRefetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าผู้ค้าทั้งหมด'
                url='/vendors#vendor_type'
            />
        )
    }
    return (
        <PageLayout pageTitle='แก้ไขประเภทผู้ค้า'>
            <FormEditVendorType data={data} />
        </PageLayout>
    )
}

export default EditVendorType

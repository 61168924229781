import * as XLSX from 'xlsx'
import excelFormatCellAuthFitWidth from './excelFormatCellAuthFitWidth'

function exportTableToExcel<T>(
    data: T,
    mapFunc: (data: T) => any,
    filename: string | undefined = 'data.xlsx'
) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new()

    const prepareProjectData = mapFunc(data)
    // Create worksheets
    const worksheet1 = XLSX.utils.json_to_sheet(prepareProjectData)
    excelFormatCellAuthFitWidth(worksheet1, prepareProjectData as any)

    // Add worksheets to the workbook with different names
    XLSX.utils.book_append_sheet(workbook, worksheet1, 'data')

    // Generate Excel data as an array buffer
    const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

    // Create a blob from the array buffer
    const blob = new Blob([excelData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Create a URL for the blob
    const url = URL.createObjectURL(blob)

    // Create a download link and trigger the download
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.click()

    // Clean up by revoking the blob URL
    URL.revokeObjectURL(url)
}

export default exportTableToExcel

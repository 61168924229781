import { useForm } from '@mantine/form'
import {
    Button,
    Flex,
    LoadingOverlay,
    Paper,
    Textarea,
    TextInput,
} from '@mantine/core'

import InputBudgetYear from '@components/form/input/InputBudgetYear'
import { Link, useNavigate } from 'react-router-dom'
import Vendor from '@api/Vendor'
import {
    IRequestUpdateVendor,
    IResponseGetVendorByID,
} from '@interfaces/api/IVendor'
import InputVendorTypes from '../input/InputVendorTypes'
import InputStatus from '../input/InputStatus'

const FormEditVendor = ({
    aftersuccess,
    data,
}: {
    aftersuccess?: () => any
    data: IResponseGetVendorByID
}) => {
    const form = useForm<IRequestUpdateVendor>({
        initialValues: {
            vendor_name: data.vendor_name ?? '',
            vendor_types:
                data.vendor_types_id ?? ''
                    ? String(data.vendor_types_id).split(',')
                    : [],
            vendor_address: data.vendor_address ?? '',
            vendor_detail: data.vendor_detail ?? '',
            vendor_email: data.vendor_email ?? '',
            vendor_phone: data.vendor_phone ?? '',
            budget_year: data.budget_year,
            status: data.status,
        },
    })
    const navigate = useNavigate()

    const { isLoading, onSubmit } = Vendor.useUpdateVendor({
        form,
        vendor_id: data.vendor_id,
        aftersuccess: () => navigate('/vendors'),
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อผู้ค้า'
                        placeholder='ชื่อผู้ค้า'
                        {...form.getInputProps('vendor_name')}
                    />

                    <InputBudgetYear
                        withAsterisk
                        {...form.getInputProps('budget_year')}
                    />
                    <TextInput
                        label='อีเมล'
                        placeholder='อีเมล'
                        {...form.getInputProps('vendor_email')}
                    />
                    <TextInput
                        label='เบอร์โทร'
                        placeholder='เบอร์โทร'
                        {...form.getInputProps('vendor_phone')}
                    />
                    <TextInput
                        label='ที่อยู่'
                        placeholder='ที่อยู่'
                        {...form.getInputProps('vendor_address')}
                    />
                    <Textarea
                        rows={2}
                        minRows={2}
                        label='เกี่ยวกับผู้ค้า'
                        placeholder='เกี่ยวกับผู้ค้า'
                        {...form.getInputProps('vendor_detail')}
                    />
                    <InputVendorTypes {...form.getInputProps('vendor_types')} />
                    <InputStatus {...form.getInputProps('status')} />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/vendors'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            บันทึกการแก้ไขผู้ค้า
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormEditVendor

import api from '@config/api'
import {
    IRequestChangePassword,
    IRequestForgetPassword,
    IRequestUpdateProfile,
    IResponseGetProfile,
} from '@interfaces/api/IAuth'
import { IFormMutationHandlerProps } from '@interfaces/default/IDefault'
import { IRequestSavePasswordAfterRequestForgotPassword } from '@interfaces/IAuth'
import { useFormMutationHandler } from '@utils/useFormMutationHandler'
import { useQuery } from 'react-query'
import { useQueryConfig } from './useQueryConfig'

interface IUseUpdateProfile
    extends IFormMutationHandlerProps<IRequestUpdateProfile> {}

interface IUseChangePassword
    extends IFormMutationHandlerProps<IRequestChangePassword> {}

interface IUseRequestForgotPassword
    extends IFormMutationHandlerProps<IRequestForgetPassword> {}

interface IUseRequestSavePasswordAfterRequestForgotPassword
    extends IFormMutationHandlerProps<IRequestSavePasswordAfterRequestForgotPassword> {
    withToken: string
}

const Auth = {
    useGetProfile: () => {
        return useQuery(
            'getProfile',
            async () => {
                try {
                    const res = await api.get('/auth/profile')
                    return res.data.body as IResponseGetProfile
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        )
    },
    useUpdateProfile: ({ form, aftersuccess }: IUseUpdateProfile) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/auth/profile`, 'put'],
            aftersuccess,
        })
    },
    useChangePassword: ({ form, aftersuccess }: IUseChangePassword) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/auth/changepassword`, 'put'],
            aftersuccess,
        })
    },
    useRequestForgotPassword: ({
        form,
        aftersuccess,
    }: IUseRequestForgotPassword) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/auth/forgotpassword`, 'post'],
            aftersuccess,
        })
    },
    useRequestSavePasswordAfterRequestForgotPassword: ({
        form,
        aftersuccess,
        withToken,
    }: IUseRequestSavePasswordAfterRequestForgotPassword) => {
        return useFormMutationHandler({
            form,
            withToken: withToken,
            pathWithMethod: [`/auth/forgotpassword`, 'put'],
            aftersuccess,
        })
    },
}

export default Auth

import { Box, Flex, Group, Paper, Select, SelectItem, Text } from '@mantine/core'
import { forwardRef, useEffect, useState } from 'react'
import { SelectProps } from '@mantine/core/lib/Select/Select'
import Vendor from '@api/Vendor'
import { IResponseGetAllVendorsRowData } from '@interfaces/api/IVendor'
import GroupTagBadge from '@components/badge/GroupTagBadge'

interface IInputVendor extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
    editMode?: boolean
    label?: string
    children?: React.ReactNode
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'>, IResponseGetAllVendorsRowData {
    value: string
    label: string
}

const InputVendor = ({
    editMode = true,
    classNames,
    label = 'คู่ค้าโครงการ',
    w = '100%',
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    children,
    ...others
}: IInputVendor) => {
    const { data } = Vendor.useGetAllVendors({ limit: 1000 })
    const [list, setlist] = useState<ItemProps[]>([])
    useEffect(() => {
        if (data) {
            setlist(
                data.lists.map((e) => {
                    return {
                        value: String(e.vendor_id),
                        label: e.vendor_name,
                        ...e,
                    }
                })
            )
        }
    }, [data])
    if (editMode === false) {
        const valueinput = list.filter((e) => e.value === String(value))
        if (valueinput.length) {
            return (
                <Box pos='relative' w={w}>
                    <Text size='sm'>{label}</Text>
                    <Paper p='sm' withBorder mt='2px'>
                        <SelectItemComponent {...valueinput[0]} />
                    </Paper>
                    {children}
                </Box>
            )
        }
    }
    return (
        <Select
            key={list.length}
            w={w}
            className={className}
            itemComponent={SelectItemComponent}
            label={label}
            value={String(value)}
            placeholder={label}
            searchable
            data={list}
            filter={(value, item: ItemProps) => item.vendor_name.toLowerCase().includes(value.toLowerCase().trim())}
            {...others}
        />
    )
}

export default InputVendor

const SelectItemComponent = forwardRef<HTMLDivElement, ItemProps>(
    (
        { vendor_id, vendor_email, vendor_phone, vendor_types_name, vendor_name, vendor_address, ...others }: ItemProps,
        ref
    ) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <Flex direction='column' gap='4px'>
                    <Text size='sm'>{vendor_name}</Text>
                    {vendor_phone && <Text size='xs'>เบอร์โทร : {vendor_phone}</Text>}
                    {vendor_email && <Text size='xs'>อีเมล : {vendor_email}</Text>}
                    {vendor_address && <Text size='xs'>ที่อยู่ : {vendor_address}</Text>}
                    {vendor_types_name && (
                        <Flex gap='xs'>
                            <Text size='xs'>ประเภท : </Text>
                            <GroupTagBadge labels={vendor_types_name} />
                        </Flex>
                    )}

                    {/* <Text
                        size='xs'
                        opacity={0.65}
                        maw='100%'
                        truncate='end'
                        sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                    >
                        {vendor_detail}
                    </Text> */}
                </Flex>
            </Group>
        </div>
    )
)

import api from '@config/api'
import { useQuery } from 'react-query'
import { useQueryConfig } from './useQueryConfig'
import { useFormMutationHandler } from '@utils/useFormMutationHandler'
import { IFormMutationHandlerProps } from '@interfaces/default/IDefault'
import {
    IChairmanOrganizationID,
    IRequestCreateChairmanOrganization,
    IRequestGetAllChairmanOrganizations,
    IRequestUpdateChairmanOrganization,
    IResponseGetAllChairmanOrganizations,
} from '@interfaces/api/IChairmanOrganization'
import { IResponseGetChairmanOrganizationByID } from '../interfaces/api/IChairmanOrganization'

interface IUseCreateChairmanOrganization
    extends IFormMutationHandlerProps<IRequestCreateChairmanOrganization> {}

interface IUseUpdateChairmanOrganization
    extends IChairmanOrganizationID,
        IFormMutationHandlerProps<IRequestUpdateChairmanOrganization> {}

interface IUseDeleteChairmanOrganization
    extends IChairmanOrganizationID,
        IFormMutationHandlerProps<IChairmanOrganizationID> {}

const API_URL = '/chairmans/organizations'

const ChairmanOrganization = {
    useGetAllChairmanOrganizations: ({
        limit = 100,
        orderby = 'chairman_organization_id',
        order = 'desc',
        page = 1,
        search = '',
        has_enabled,
        checkowner = 0,
        status = '',
    }: IRequestGetAllChairmanOrganizations) =>
        useQuery(
            'allchairmanorganizations' + orderby,
            async () => {
                try {
                    const res = await api.get(API_URL, {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                            has_enabled,
                            checkowner,
                            status,
                        },
                    })
                    return res.data.body as IResponseGetAllChairmanOrganizations
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useGetChairmanOrganizationByID: ({
        chairman_organization_id,
    }: IChairmanOrganizationID) =>
        useQuery(
            'getChairmanOrganizationByID' + chairman_organization_id,
            async () => {
                try {
                    const res = await api.get(
                        `${API_URL}/${chairman_organization_id}`
                    )
                    return res.data.body as IResponseGetChairmanOrganizationByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useCreateChairmanOrganization: ({
        form,
        aftersuccess,
    }: IUseCreateChairmanOrganization) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [API_URL, 'post'],
            aftersuccess,
        })
    },
    useUpdateChairmanOrganization: ({
        form,
        chairman_organization_id,
        aftersuccess,
    }: IUseUpdateChairmanOrganization) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`${API_URL}/${chairman_organization_id}`, 'put'],
            aftersuccess,
        })
    },

    useDeleteChairman: ({
        chairman_organization_id,
        aftersuccess,
    }: IUseDeleteChairmanOrganization) => {
        return useFormMutationHandler({
            pathWithMethod: [
                `${API_URL}/${chairman_organization_id}`,
                'delete',
            ],
            aftersuccess,
        })
    },
}

export default ChairmanOrganization

import { useForm } from '@mantine/form'
import {
    Button,
    Flex,
    LoadingOverlay,
    Paper,
    PasswordInput,
} from '@mantine/core'
import { Link } from 'react-router-dom'
import {
    IRequestChangePassword,
    IResponseGetProfile,
} from '@interfaces/api/IAuth'
import Auth from '@api/Auth'

const FormChangePassword = ({
    aftersuccess,
    data,
}: {
    aftersuccess?: () => any
    data: IResponseGetProfile
}) => {
    const form = useForm<IRequestChangePassword>({
        initialValues: {
            oldpassword: '',
            password: '',
            confirmpassword: '',
        },
    })

    const { isLoading, onSubmit } = Auth.useChangePassword({
        form,
        aftersuccess: () => {
            form.reset()
        },
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <PasswordInput
                        withAsterisk
                        label='รหัสผ่านเก่า'
                        placeholder='รหัสผ่านเก่า'
                        {...form.getInputProps('oldpassword')}
                    />
                    <PasswordInput
                        withAsterisk
                        label='รหัสผ่านใหม่'
                        placeholder='รหัสผ่านใหม่'
                        {...form.getInputProps('password')}
                    />
                    <PasswordInput
                        withAsterisk
                        label='ยืนยันรหัสผ่านใหม่'
                        placeholder='ยืนยันรหัสผ่าน'
                        {...form.getInputProps('confirmpassword')}
                    />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/main'
                            variant='default'
                            w='fit-content'
                        >
                            กลับไปหน้าหลัก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            บันทึกการแก้ไขรหัสผ่าน
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormChangePassword

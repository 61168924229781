import { Flex, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import InputBudgetYear from '@components/form/input/InputBudgetYear'
import TableViewButton from '@components/button/TableViewButton.'
import TableEditButton from '@components/button/TableEditButton'
import TableDeleteButton from '@components/button/TableDeleteButton'
import TablePagination from '@components/table/template/TablePagination'
import TableTemplate from '@components/table/template/TableTemplate'
import { ITHeadMap } from '@interfaces/ITable'
import { Link } from 'react-router-dom'
import TableAddNewButton from '@components/button/TableAddNewButton'
import { modals } from '@mantine/modals'
import { QueryObserverResult } from 'react-query'
import {
    IResponseAllAgencies,
    IResponseAllAgenciesRowData,
} from '@interfaces/api/IAgency'
import Agency from '@api/Agency'
import TableFilter from '../filter/TableFilter'
const TableAllAgencies = () => {
    const [limit, setlimit] = useState(10)
    const [orderby, setorderby] =
        useState<keyof IResponseAllAgenciesRowData>('agency_id')
    const [order, setorder] = useState('desc')
    const [page, setpage] = useState(1)
    const [search, setsearch] = useState('')
    const [budget_year, setbudget_year] = useState('')
    const [status, setstatus] = useState('')

    const [checkowner, setcheckowner] = useState(0)
    const lists = Agency.useGetAllAgencies({
        limit,
        orderby,
        order,
        page,
        search,
        checkowner,
        budget_year,
        status,
    })

    useEffect(() => {
        lists.refetch()
        // eslint-disable-next-line
    }, [status, budget_year, page, limit, order, orderby, checkowner])

    const thead: ITHeadMap<IResponseAllAgenciesRowData>[] = [
        {
            sortkey: 'agency_name',
            label: 'ชื่อหน่วยงาน',
            width: '30%',
        },
        {
            sortkey: 'agency_type_id',
            label: 'ประเภท',
            width: '15%',
        },
        {
            sortkey: 'status',
            label: 'สถานะ',
            width: '20%',
        },
        // {
        //     sortkey: 'created_at',
        //     label: 'สร้างเมื่อ',
        //     width: '11%',
        // },
        // {
        //     sortkey: 'updated_at',
        //     label: 'แก้ไขล่าสุด',
        //     width: '11%',
        // },
        {
            label: 'Action',
            width: '10%',
        },
    ]

    return (
        <>
            <TableFilter
                {...{
                    lists,
                    setpage,
                    page,
                    setsearch,
                    search,
                    setcheckowner,
                    checkowner,
                    setstatus,
                    status,
                }}
            >
                <InputBudgetYear
                    withAll
                    miw='200px'
                    value={String(budget_year)}
                    onChange={(value) => {
                        if (value !== String(budget_year)) {
                            setbudget_year(value!)
                            setpage(1)
                        }
                    }}
                />
                <TableAddNewButton
                    component={Link}
                    to='/agencies/create'
                    label='เพิ่มหน่วยงาน'
                />
            </TableFilter>

            <TableTemplate<IResponseAllAgenciesRowData>
                key={page}
                thead={thead}
                orderby={orderby}
                order={order}
                setorder={setorder}
                setorderby={setorderby}
                lists={lists?.data?.lists}
            >
                {lists?.data?.lists.map((value) => (
                    <AllProjectTableRow
                        key={value.agency_id}
                        data={value}
                        refreshTable={lists.refetch}
                    />
                ))}
            </TableTemplate>
            {lists.data?.lists.length ? (
                <TablePagination
                    limit={lists.data?.lists.length}
                    setlimit={setlimit}
                    setpage={setpage}
                    page={page}
                    totalPages={lists.data.totalPages}
                    totalRows={lists.data.totalRows}
                />
            ) : (
                <></>
            )}
        </>
    )
}

export default TableAllAgencies

const AllProjectTableRow = ({
    data,
    refreshTable,
}: {
    data: IResponseAllAgenciesRowData
    refreshTable: () => Promise<
        QueryObserverResult<IResponseAllAgencies, unknown>
    >
}) => {
    const Delete = Agency.useDeleteAgency({
        agency_id: data.agency_id,
        aftersuccess: refreshTable,
    })

    const openDeleteConfirmModal = () =>
        modals.openConfirmModal({
            title: 'กรุณายืนยันการลบ',
            children: (
                <Text>
                    ลบหน่วยงาน/ลูกค้า <br />
                    {data.agency_name}
                </Text>
            ),
            labels: { confirm: 'ยืนยัน', cancel: 'ยกเลิก' },
            onConfirm: Delete.onSubmit,
        })

    return (
        <tr key={data.agency_id}>
            <td>{data.agency_name}</td>
            <td>{data.agency_type_name}</td>
            <td>{data.status_description}</td>
            <td>
                <Flex gap='xs' pos='relative'>
                    <TableViewButton
                        component={Link}
                        to={`/agencies/${data.agency_id}/view`}
                    />
                    <TableEditButton
                        component={Link}
                        to={`/agencies/${data.agency_id}/edit`}
                    />
                    <TableDeleteButton onClick={openDeleteConfirmModal} />
                </Flex>
            </td>
        </tr>
    )
}

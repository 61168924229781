import { IResponseGetAllVendorsRowData } from '@interfaces/api/IVendor'

const mapVendorTable = (data: IResponseGetAllVendorsRowData[]) => {
    const mapData = data.map((value) => {
        return {
            ไอดีผู้ค้า: value.vendor_id,
            ชื่อผู้ค้า: value.vendor_name,
            อีเมล: value.vendor_email,
            เบอร์โทร: value.vendor_phone,
            'ประเภทสินค้า/บริการ': value.vendor_types_name?.split('&')?.join(' ') ?? '',
            ผู้ค้าเมื่อปีงบประมาณ: value.budget_year,
            ไอดีสถานะข้อมูล: value.status,
            ชื่อสถานะข้อมูล: value.status_description,
            สร้างเมื่อ: value.created_at,
            สร้างโดยผู้ใช้งานไอดี: value.created_by_user_id,
            สร้างโดยผู้ใช้งานชื่อ: value.created_by_user_fullname,
            แก้ไขเมื่อ: value.updated_at,
            แก้ไขโดยผู้ใช้งานไอดี: value.updated_by_user_id,
            แก้ไขโดยผู้ใช้งานชื่อ: value.updated_by_user_fullname,
        }
    })

    return mapData
}

export default mapVendorTable

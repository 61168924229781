import axios from 'axios'
import { Cookies } from 'react-cookie'
const BASE_URL =
    process.env.REACT_APP_MODE === 'development'
        ? process.env.REACT_APP_API
        : process.env.REACT_APP_G_API

export const api = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
})

export const refreshAPI = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
})

// api.interceptors.request.use(
//     (config) => {
//         const cookies = new Cookies('token')
//         const token = cookies.get('token')

//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`
//         }
//         return config
//     },
//     (error) => {
//         Promise.reject(error)
//     }
// )
// // Add a response interceptor
export const installHandleRefreshAPI = (afterExpire: () => void) => {
    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config

            // it means the token has expired and we need to refresh it
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true

                const cookies = new Cookies('session')
                try {
                    await refreshAPI.post('/auth/refreshtoken')

                    // const data = res.data.body as IResponseLogin
                    // const token = data.token

                    return api(originalRequest)
                } catch (error) {
                    cookies.remove('session')
                    afterExpire()
                    // window.location.href = '/login?session=Expired'
                    throw error
                }
            }

            return Promise.reject(error)
        }
    )
}

export default api

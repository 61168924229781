import TableHeaderColumn from '@components/table/template/TableHeaderColumn'
import TextChartTitle from '@components/text/TextChartTitle'
import { IResponseGetProjectDataForDashboardRowData } from '@interfaces/api/IDashboard'
import { Flex, Progress, Table } from '@mantine/core'
import Money from '@utils/Money'
import _ from 'lodash'
import React from 'react'
import ChartFlexSection from './ChartFlexSection'
interface ICoordinatorRankingChart {
    data: IResponseGetProjectDataForDashboardRowData[]
}

const CoordinatorRankingChart = ({ data }: ICoordinatorRankingChart) => {
    const groupedData = _.groupBy(data, 'project_budget_year')
    const prepareDatawithAnnotation = Object.keys(groupedData).map((Byear) => {
        let dataforeachyear = _(groupedData[Byear])
            .groupBy('coordinator_fullname')
            .map((values, key) => ({
                coordinator_fullname: key,
                avg_project_rating: _.meanBy(values, (p) =>
                    Number(p.project_evaluation_result)
                ),
                total_project_amount: _.uniqBy(values, 'project_id').length,
                total_project_budget: _.sumBy(
                    values.map((e) => {
                        return {
                            ...e,
                            project_budget: Number(e.project_budget),
                        }
                    }),
                    'project_budget'
                ),
            }))
            .value()

        dataforeachyear = _.orderBy(
            dataforeachyear,
            (p) => p.total_project_budget,
            ['desc']
        )

        return { budget_year: Byear, data: dataforeachyear }
    })

    data.sort((a, b) => Number(b.project_budget) - Number(a.project_budget))
    return (
        <Flex direction='column'>
            {prepareDatawithAnnotation.map((dataset, index) => {
                return (
                    <ChartFlexSection key={index}>
                        <TextChartTitle>{`จัดอันดับผู้ประสานงานโครงการที่มียอดมูลค่าโครงการสูงสุด ประจำปีงบประมาณ ${
                            Number(dataset.budget_year) + 543
                        }`}</TextChartTitle>
                        <Table pos={'relative'}>
                            <thead>
                                <tr>
                                    <TableHeaderColumn
                                        label={'อันดับ'}
                                        width={'5%'}
                                    />
                                    <TableHeaderColumn
                                        label={'ผู้ประสานงาน'}
                                        width={'25%'}
                                    />
                                    <TableHeaderColumn
                                        label={'ค่าเฉลี่ยความพึงพอใจโครงการ'}
                                        width={'10%'}
                                    />
                                    <TableHeaderColumn
                                        label={'งบประมาณ'}
                                        width={'10%'}
                                    />
                                    <TableHeaderColumn label={''} width={''} />
                                </tr>
                            </thead>
                            <tbody>
                                {dataset.data.map((p, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{p.coordinator_fullname}</td>
                                        <td>
                                            {p.avg_project_rating.toFixed(2)}
                                        </td>
                                        <td>
                                            {Money(
                                                Number(p.total_project_budget)
                                            )}
                                        </td>
                                        <td>
                                            <Progress
                                                w='100%'
                                                size='xl'
                                                sections={[
                                                    {
                                                        value:
                                                            Number(
                                                                p.total_project_budget
                                                            ) /
                                                            (Number(
                                                                dataset.data[0]
                                                                    .total_project_budget
                                                            ) /
                                                                100),
                                                        color: 'blue',
                                                    },
                                                ]}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </ChartFlexSection>
                )
            })}
        </Flex>
    )
}

export default CoordinatorRankingChart

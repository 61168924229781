import { useForm } from '@mantine/form'
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core'

import { IRequestCreateAgencyType } from '@interfaces/api/IAgency'
import Agency from '@api/Agency'
import { closeModal } from '@mantine/modals'
import { Link, useNavigate } from 'react-router-dom'

const FormCreateAgencyType = ({
    aftersuccess,
}: {
    aftersuccess?: () => any
}) => {
    const form = useForm<IRequestCreateAgencyType>({
        initialValues: {
            agency_type_name: '',
        },
    })
    const navigate = useNavigate()

    const onAfterSuccess = () => {
        closeModal('createagency')
        if (aftersuccess) {
            aftersuccess()
        } else {
            navigate('/agencies/types')
        }
    }

    const { isLoading, onSubmit } = Agency.useCreateAgencyType({
        form,
        aftersuccess: onAfterSuccess,
    })

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ชื่อประเภทหน่วยงาน'
                        placeholder='ชื่อประเภทหน่วยงาน'
                        {...form.getInputProps('agency_type_name')}
                    />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/agencies/types'
                            onClick={() => closeModal('createagency')}
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            สร้างประเภทหน่วยงาน
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

export default FormCreateAgencyType

import api from '@config/api'
import { useQuery } from 'react-query'
import { useFormMutationHandler } from '@utils/useFormMutationHandler'
import { IFormMutationHandlerProps } from '@interfaces/default/IDefault'
import { useQueryConfig } from './useQueryConfig'

import {
    IRequestCreateUserPosition,
    IRequestGetAllUserPosition,
    IRequestUpdateUserPosition,
    IResponseGetAllUserPositions,
    IResponseGetUserPositionByID,
    IUserPositionID,
} from '@interfaces/api/IUserPosition'
interface IUseCreateUserPosition
    extends IFormMutationHandlerProps<IRequestCreateUserPosition> {}

interface IUseUpdateUserPosition
    extends IUserPositionID,
        IFormMutationHandlerProps<IRequestUpdateUserPosition> {}

interface IUseDeleteUserPosition
    extends IUserPositionID,
        IFormMutationHandlerProps<IUserPositionID> {}

const UserPosition = {
    useGetAllUserPositions: ({
        limit = 10,
        orderby = 'user_position_id',
        order = 'desc',
        page = 1,
        search = '',
    }: IRequestGetAllUserPosition) =>
        useQuery(
            'getAllUserPositions',
            async () => {
                try {
                    const res = await api.get('/users/positions', {
                        params: {
                            limit,
                            orderby,
                            order,
                            page,
                            search,
                        },
                    })
                    return res.data.body as IResponseGetAllUserPositions
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),
    useGetUserPostionByID: ({ user_position_id }: IUserPositionID) =>
        useQuery(
            'getUserPositionByID' + user_position_id,
            async () => {
                try {
                    const res = await api.get(
                        `/users/positions/${user_position_id}`
                    )
                    return res.data.body as IResponseGetUserPositionByID
                } catch (error) {
                    return Promise.reject(error)
                }
            },
            useQueryConfig
        ),

    useCreateUserPosition: ({ form, aftersuccess }: IUseCreateUserPosition) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: ['/users/positions', 'post'],
            aftersuccess,
        })
    },
    useUpdateUserPosition: ({
        form,
        user_position_id,
        aftersuccess,
    }: IUseUpdateUserPosition) => {
        return useFormMutationHandler({
            form,
            pathWithMethod: [`/users/positions/${user_position_id}`, 'put'],
            aftersuccess,
        })
    },

    useDeleteUserPosition: ({
        user_position_id,
        aftersuccess,
    }: IUseDeleteUserPosition) => {
        return useFormMutationHandler({
            pathWithMethod: [`/users/positions/${user_position_id}`, 'delete'],
            aftersuccess,
        })
    },
}

export default UserPosition

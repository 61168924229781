import FormCreateChairman from '@components/form/chairman/FormCreateChairman'
import PageLayout from '@components/page/PageLayout'

const CreateChairman = () => {
    return (
        <PageLayout pageTitle='สร้างประธานโครงการ'>
            <FormCreateChairman />
        </PageLayout>
    )
}

export default CreateChairman

import { Card, Flex, Text, Title, CardProps, Badge } from '@mantine/core'
import accounting from 'accounting'
import React from 'react'
interface ICardDashboardStat extends Omit<CardProps, 'children'> {
    label?: string
    isMoney?: boolean
    unit?: string
    diff?: number
    value: string | number
}
const CardDashboardStat = ({
    label,
    isMoney,
    unit,
    diff,
    value,
    ...others
}: ICardDashboardStat) => {
    let stat_value = value
    if (isMoney) {
        stat_value = accounting.formatMoney(Number(value), {
            symbol: '',
            precision: 2,
        })
    }

    return (
        <Card radius='md' px='lg' py='xs' {...others} miw='fit-content'>
            {label && <Text mb='xs'>{label}</Text>}
            <Flex align='stretch' h={40}>
                <Title size='35px'>{stat_value}</Title>
                {unit && (
                    <Flex ml='xs' direction='column' justify='end'>
                        {typeof diff === 'number' && (
                            <Badge
                                py='xs'
                                px='4px'
                                radius='sm'
                                variant='filled'
                                color={
                                    diff > 0
                                        ? 'teal.5'
                                        : diff < 0
                                        ? 'red'
                                        : 'gray'
                                }
                            >
                                {diff > 0 ? '+' : diff < 0 ? '-' : ''}
                                {Math.abs(diff).toFixed(2)}%
                            </Badge>
                        )}
                        <Text size='xs' mb='3px'>
                            {unit}
                        </Text>
                    </Flex>
                )}
            </Flex>
        </Card>
    )
}

export default CardDashboardStat

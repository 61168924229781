import FormCreateUserPosition from '@components/form/userposition/FormCreateUserPosition'
import PageLayout from '@components/page/PageLayout'
const CreateUserPosition = () => {
    return (
        <PageLayout pageTitle='สร้างตำแหน่ง'>
            <FormCreateUserPosition />
        </PageLayout>
    )
}

export default CreateUserPosition

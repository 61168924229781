import { Flex, Paper, Skeleton } from '@mantine/core'
import LoadingInput from './LoadingInput'

const LoadingForm = ({ numberInput = 1 }) => {
    const inputs: React.FC[] = []
    for (let i = 0; i < numberInput; i++) {
        inputs.push(LoadingInput)
    }
    return (
        <Paper w='100%' mah='100%' style={{ overflow: 'hidden' }}>
            <Flex direction='column' gap='md'>
                {inputs.map((Component) => (
                    <Component />
                ))}
            </Flex>
            <Flex justify='end' gap='md'>
                <Skeleton w={100} height={40} mt={12} radius='md' />
                <Skeleton w={100} height={40} mt={12} radius='md' />
            </Flex>
        </Paper>
    )
}

export default LoadingForm

import TextChartTitle from '@components/text/TextChartTitle'
import { IResponseGetProjectDataForDashboardRowData } from '@interfaces/api/IDashboard'
import _ from 'lodash'
import React from 'react'
import Chart from 'react-google-charts'
import ChartFlexSection from './ChartFlexSection'
import { ColumnChartOptions } from './setting'
interface IAgenciesAmountColumnChart {
    title: string
    typeName: string
    valueName: string
    data: IResponseGetProjectDataForDashboardRowData[]
    keySort: keyof IResponseGetProjectDataForDashboardRowData
    keyValue: keyof IResponseGetProjectDataForDashboardRowData
    keyType: keyof IResponseGetProjectDataForDashboardRowData
}

const AgenciesAmountColumnChart = ({
    title,
    typeName,
    valueName,
    data,
    keyValue,
    keyType,
    keySort,
}: IAgenciesAmountColumnChart) => {
    const groupedData = _.groupBy(data, 'project_budget_year')
    const prepareDatawithAnnotation = Object.keys(groupedData).map((Byear) => {
        const oldAgency = _.uniqBy(
            _.filter(
                groupedData[Byear],
                (p) => p.agency_budget_year < Number(Byear)
            ),
            'agency_name'
        ).length

        const newAgency = _.uniqBy(
            _.filter(groupedData[Byear], {
                agency_budget_year: Number(Byear),
            }),
            'agency_name'
        ).length

        return [
            `ปีงบประมาณ ${Number(Byear) + 543}`,
            oldAgency,
            oldAgency,
            newAgency,
            newAgency,
        ]
    })

    const columnChartData = [
        [
            'ปีงบประมาณ',
            'ลูกค้าเก่า',
            { role: 'annotation' },
            'ลูกค้าใหม่',
            { role: 'annotation' },
        ],
        ...prepareDatawithAnnotation,
    ]

    const options = ColumnChartOptions(valueName, typeName)
    return (
        <ChartFlexSection>
            <TextChartTitle>{title}</TextChartTitle>
            <Chart
                width={'100%'}
                height={'500px'}
                chartType='ColumnChart'
                loader={<div>Loading Chart</div>}
                data={columnChartData}
                options={options}
            />
        </ChartFlexSection>
    )
}

export default AgenciesAmountColumnChart

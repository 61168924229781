import React from 'react'
import { IResponseGetAgencyByID } from '@interfaces/api/IAgency'
import { Card, createStyles, Flex, Textarea, Title } from '@mantine/core'
import TextParameter from '../text/TextParameter'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'

const useStyles = createStyles((theme) => ({
    textarea: {
        '&:focus': {
            borderColor: theme.colors.gray[4],
        },
    },
}))

const CardAgencyDetail = ({ data }: { data: IResponseGetAgencyByID }) => {
    const { classes } = useStyles()
    const textparameters = [
        {
            title: 'ประเภทหน่วยงาน',
            info: data.agency_type_name,
        },
        {
            title: 'ประจำปีงบประมาณ',
            info: data.budget_year_name,
        },
        {
            title: 'อีเมล',
            info: data.agency_email,
        },
        {
            title: 'เบอร์โทร',
            info: data.agency_phone,
        },
        {
            title: 'สถานะข้อมูล',
            info: data.status_description,
        },
        {
            title: 'ที่อยู่',
            type: 'textarea',
            info: data.agency_address,
        },
        {
            title: 'ข้อมูลอื่น ๆ',
            type: 'textarea',
            info: data.agency_detail,
        },
    ]
    return (
        <Card
            key={data.agency_id}
            shadow='md'
            radius='md'
            // className={classes.card}
            padding='md'
        >
            <Flex gap='5px' direction='column'>
                <Title order={5}>{data.agency_name}</Title>
                {textparameters.map((value) => {
                    return value.type === 'textarea' ? (
                        <Textarea
                            key={value.title}
                            classNames={{ input: classes.textarea }}
                            autosize
                            rows={0}
                            readOnly
                            label={value.title + ':'}
                            defaultValue={value.info ?? ''}
                        />
                    ) : (
                        <TextParameter
                            key={value.title}
                            title={value.title}
                            info={value.info!}
                        />
                    )
                })}
                <TextCreatedBy {...data} />
                <TextUpdatedBy {...data} />
            </Flex>
        </Card>
    )
}

export default CardAgencyDetail

import { Flex, Select, SelectItem, Text } from '@mantine/core'
import { SelectProps } from '@mantine/core/lib/Select/Select'

interface MyComponentProps
    extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
}
const InputPageLimit = ({
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: MyComponentProps) => {
    return (
        <Flex align='center' gap='md'>
            <Text>ขนาดหน้า</Text>
            <Select
                size='sm'
                w='100px'
                className={className}
                placeholder='ปีงบประมาณ'
                defaultValue={'10'}
                value={value}
                data={[
                    { value: '10', label: '10' },
                    { value: '50', label: '50' },
                    { value: '100', label: '100' },
                ]}
                {...others}
            />
        </Flex>
    )
}

export default InputPageLimit

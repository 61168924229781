import Coordinator from '@api/Coordinator'
import CardCoordinatorDetail from '@components/card/CardCoordinatorDetail'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const ViewCoordinator = () => {
    const params = useParams()
    const coordinator_id = params.coordinator_id
    const { isLoading, isFetching, isError, data } =
        Coordinator.useGetCoordinatorByID({
            coordinator_id: coordinator_id!,
        })
    if (isLoading || isFetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าผู้ประสานงานทั้งหมด'
                url='/coordinators'
            />
        )
    }
    return (
        <PageLayout pageTitle='ข้อมูลผู้ประสานงาน'>
            <CardCoordinatorDetail data={data}></CardCoordinatorDetail>
        </PageLayout>
    )
}

export default ViewCoordinator

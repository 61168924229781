import Vendor from '@api/Vendor'
import FormEditVendor from '@components/form/vendor/FormEditVendor'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const EditVendor = () => {
    const params = useParams()
    const vendor_id = params.vendor_id
    const { isLoading, isError, data, isRefetching } = Vendor.useGetVendorByID({
        vendor_id: vendor_id!,
    })
    if (isLoading || isRefetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404 label='กลับไปหน้าผู้ค้าทั้งหมด' url='/vendors' />
        )
    }
    return (
        <PageLayout pageTitle='แก้ไขผู้ค้า'>
            <FormEditVendor data={data} />
        </PageLayout>
    )
}

export default EditVendor

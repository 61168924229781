import { Flex, Text } from '@mantine/core'
import { useEffect, useState } from 'react'
import TableViewButton from '@components/button/TableViewButton.'
import TableEditButton from '@components/button/TableEditButton'
import TableDeleteButton from '@components/button/TableDeleteButton'
import TablePagination from '@components/table/template/TablePagination'
import TableTemplate from '@components/table/template/TableTemplate'
import { ITHeadMap } from '@interfaces/ITable'
import { Link } from 'react-router-dom'
import TableAddNewButton from '@components/button/TableAddNewButton'
import { modals } from '@mantine/modals'
import { QueryObserverResult } from 'react-query'
import InputUserStatus from '@components/form/input/InputUserStatus'
import {
    IResponseGetAllUserPositions,
    IResponseGetAllUserPositionsRowData,
} from '@interfaces/api/IUserPosition'
import UserPosition from '@api/UserPosition'
import TableSearchFilter from '@components/table/filter/TableSearchFilter'
// import DateAndFullNameColumn from './DateAndFullNameColumn'

const TableAllUserPosition = () => {
    const [limit, setlimit] = useState(10)
    const [orderby, setorderby] =
        useState<keyof IResponseGetAllUserPositionsRowData>('user_position_id')
    const [order, setorder] = useState('desc')
    const [page, setpage] = useState(1)
    const [search, setsearch] = useState('')
    const [status, setstatus] = useState('')
    const lists = UserPosition.useGetAllUserPositions({
        limit,
        orderby,
        order,
        page,
        search,
    })

    useEffect(() => {
        lists.refetch()
        // eslint-disable-next-line
    }, [status, page, limit, order, orderby])

    const thead: ITHeadMap<IResponseGetAllUserPositionsRowData>[] = [
        {
            sortkey: 'user_position_name',
            label: 'ตำแหน่ง',
            width: '40%',
        },
        // {
        //     sortkey: 'created_at',
        //     label: 'สร้างเมื่อ',
        //     width: '11%',
        // },
        // {
        //     sortkey: 'updated_at',
        //     label: 'แก้ไขล่าสุด',
        //     width: '11%',
        // },
        {
            label: 'Action',
            width: '10%',
        },
    ]

    return (
        <>
            <Flex gap='md' direction={{ xs: 'column', lg: 'row' }}>
                <TableSearchFilter
                    page={page}
                    setpage={setpage}
                    setsearch={setsearch}
                    search={search}
                    refresh={lists.refetch}
                />
                <InputUserStatus
                    withAll
                    miw='180px'
                    value={String(status)}
                    onChange={(value) => {
                        if (value !== status) {
                            setstatus(String(value))
                            setpage(1)
                        }
                    }}
                />
                <TableAddNewButton
                    component={Link}
                    to='/users/positions/create'
                    label='เพิ่มตำแหน่ง'
                />
            </Flex>

            <TableTemplate<IResponseGetAllUserPositionsRowData>
                thead={thead}
                orderby={orderby}
                order={order}
                setorder={setorder}
                setorderby={setorderby}
                lists={lists?.data?.lists}
            >
                {lists?.data?.lists.map((value) => (
                    <TableRow
                        key={value.user_position_id}
                        data={value}
                        refreshTable={lists.refetch}
                    />
                ))}
            </TableTemplate>
            {lists.data?.lists.length ? (
                <TablePagination
                    limit={lists.data?.lists.length}
                    setlimit={setlimit}
                    setpage={setpage}
                    page={page}
                    totalPages={lists.data.totalPages}
                    totalRows={lists.data.totalRows}
                />
            ) : (
                <></>
            )}
        </>
    )
}

export default TableAllUserPosition

const TableRow = ({
    data,
    refreshTable,
}: {
    data: IResponseGetAllUserPositionsRowData
    refreshTable: () => Promise<
        QueryObserverResult<IResponseGetAllUserPositions, unknown>
    >
}) => {
    const Delete = UserPosition.useDeleteUserPosition({
        user_position_id: data.user_position_id,
        aftersuccess: refreshTable,
    })

    const openDeleteConfirmModal = () =>
        modals.openConfirmModal({
            title: 'กรุณายืนยันการลบ',
            children: (
                <Text>
                    ตำแหน่ง <br />
                    {data.user_position_name}
                </Text>
            ),
            labels: { confirm: 'ยืนยัน', cancel: 'ยกเลิก' },
            onConfirm: Delete.onSubmit,
        })

    return (
        <tr key={data.user_position_id}>
            <td>{data.user_position_name}</td>
            {/* <DateAndFullNameColumn
                day={data.created_at}
                fullname={data.created_by_user_fullname}
                user_id={data.created_by_user_id}
            />
            <DateAndFullNameColumn
                day={data.updated_at}
                fullname={data.updated_by_user_fullname}
                user_id={data.updated_by_user_id}
            /> */}
            <td>
                <Flex gap='xs' pos='relative'>
                    <TableViewButton
                        component={Link}
                        to={`/users/positions/${data.user_position_id}/view`}
                    />
                    <TableEditButton
                        component={Link}
                        to={`/users/positions/${data.user_position_id}/edit`}
                    />
                    <TableDeleteButton onClick={openDeleteConfirmModal} />
                </Flex>
            </td>
        </tr>
    )
}

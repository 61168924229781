import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core'
import { TablerIconsProps } from '@tabler/icons-react'
import React from 'react'
interface MyComponentProps extends ButtonProps {
    label: string

    Icon: (props: TablerIconsProps) => JSX.Element
}

const _TableActionButton = ({
    label,
    Icon,
    classNames,
    styles,
    unstyled,
    className,
    ...others
}: MyComponentProps) => {
    return (
        // <Tooltip
        //     label={label}
        //     position='right'
        //     transitionProps={{ duration: 0 }}
        // >
        <Button
            className={className}
            variant='subtle'
            size='sm'
            p='4px'
            {...others}
        >
            <Icon size={'1.2rem'} />
        </Button>
        // </Tooltip>
    )
}

const TableActionButton = createPolymorphicComponent<
    'button',
    MyComponentProps
>(_TableActionButton)
export default TableActionButton

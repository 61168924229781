import api from '@config/api'

import {
    IRequestGetAllLecturers,
    IResponseGetAllLecturers,
    IResponseGetAllLecturersRowData,
} from '@interfaces/api/ILecturer'

import { Button, Flex, LoadingOverlay, Paper, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { modals } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import { IconExclamationCircle } from '@tabler/icons-react'
import mapLecturerTable from '@utils/excel_map_functions/mapLecturerTable'
import exportTableToExcel from '@utils/exportTableToExcel'
import React, { useState } from 'react'
import ModalDownloadButton from './ModalDownloadButton'

const ModalDownloadLecturerToExcel = () => {
    const openModal = () => {
        modals.open({
            closeOnClickOutside: false,
            closeOnEscape: false,
            withCloseButton: false,
            title: <Text fw='bold'>ดาวน์โหลดข้อมูลวิทยากร</Text>,
            children: <ModalBody />,
        })
    }

    return <ModalDownloadButton onClick={openModal} />
}

export default ModalDownloadLecturerToExcel

const ModalBody = () => {
    const [isLoading, setIsLoading] = useState(false)
    const form = useForm<IRequestGetAllLecturers>({
        initialValues: {
            limit: 100,
            orderby: 'lecturer_id',
            order: 'asc',
            search: '',
            status: '',
            has_enabled: 0,
            page: 1,
            checkowner: 0,
        },
    })
    const LoadData = async (values: IRequestGetAllLecturers) => {
        try {
            let page = 1
            let maxPage = 1

            let bulkData: IResponseGetAllLecturersRowData[] = []

            while (page <= maxPage) {
                const reqbody: IRequestGetAllLecturers = {
                    ...values,
                    page: page,
                }
                const res = await api.get('/lecturers', { params: reqbody })
                const data = res.data.body as IResponseGetAllLecturers
                maxPage = data.totalPages
                bulkData = [...bulkData, ...data.lists]
                page += 1

                await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait for 1 second
            }

            return bulkData
        } catch (err) {
            throw err
        }
    }

    const onSubmit = async (values: IRequestGetAllLecturers) => {
        setIsLoading(true)
        try {
            const data = await LoadData(values)

            if (!data?.length) {
                throw new Error('ไม่พบข้อมูล')
            }
            exportTableToExcel(data, mapLecturerTable, 'ข้อมูลวิทยากร.xlsx')

            modals.closeAll()
        } catch (error) {
            modals.closeAll()
            notifications.show({
                title: 'เกิดข้อผิดพลาด',
                message: 'ไม่พบข้อมูล',
                color: 'red',
                icon: <IconExclamationCircle size='1rem' />,
            })
        }
        setIsLoading(false)
    }

    return (
        <>
            <Text size='sm' c='gray.7'>
                กดยืนยันเพื่อดาวน์โหลดข้อมูล
            </Text>
            <Paper mt='md'>
                <LoadingOverlay visible={isLoading} overlayBlur={2} />
                <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                    <Flex gap='sm' direction='column'>
                        <Flex mt='md' justify='end' gap='sm'>
                            <Button
                                onClick={() => modals.closeAll()}
                                variant='default'
                                w='fit-content'
                            >
                                ยกเลิก
                            </Button>
                            <Button type='submit' w='fit-content'>
                                ดาวน์โหลดข้อมูล
                            </Button>
                        </Flex>
                    </Flex>
                </form>
            </Paper>
        </>
    )
}

import InputBudgetYear from '@components/form/input/InputBudgetYear'
import api from '@config/api'

import {
    IRequestGetAllVendors,
    IResponseGetAllVendors,
    IResponseGetAllVendorsRowData,
} from '@interfaces/api/IVendor'

import { Button, Flex, LoadingOverlay, Paper, Text } from '@mantine/core'
import { joiResolver, useForm } from '@mantine/form'
import { modals } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import { IconExclamationCircle } from '@tabler/icons-react'
import mapVendorTable from '@utils/excel_map_functions/mapVendorTable'
import exportTableToExcel from '@utils/exportTableToExcel'
import Joi from 'joi'
import React, { useState } from 'react'
import ModalDownloadButton from './ModalDownloadButton'

const ModalDownloadVendorToExcel = () => {
    const openModal = () => {
        modals.open({
            closeOnClickOutside: false,
            closeOnEscape: false,
            withCloseButton: false,
            title: <Text fw='bold'>ดาวน์โหลดข้อมูลผู้ค้า</Text>,
            children: <ModalBody />,
        })
    }

    return <ModalDownloadButton onClick={openModal} />
}

export default ModalDownloadVendorToExcel

const ModalBody = () => {
    const [isLoading, setIsLoading] = useState(false)
    const form = useForm<IRequestGetAllVendors>({
        initialValues: {
            limit: 100,
            orderby: 'vendor_id',
            order: 'asc',
            search: '',
            status: '',
            has_enabled: 0,
            page: 1,
            checkowner: 0,
            budget_year: '',
        },
        validate: joiResolver(schema),
    })
    const LoadData = async (values: IRequestGetAllVendors) => {
        try {
            let page = 1
            let maxPage = 1

            let bulkData: IResponseGetAllVendorsRowData[] = []

            while (page <= maxPage) {
                const reqbody: IRequestGetAllVendors = {
                    ...values,
                    page: page,
                }
                const res = await api.get('/vendors', { params: reqbody })
                const data = res.data.body as IResponseGetAllVendors
                maxPage = data.totalPages
                bulkData = [...bulkData, ...data.lists]
                page += 1

                await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait for 1 second
            }

            return bulkData
        } catch (err) {
            throw err
        }
    }

    const onSubmit = async (values: IRequestGetAllVendors) => {
        setIsLoading(true)
        try {
            const data = await LoadData(values)

            if (!data?.length) {
                throw new Error('ไม่พบข้อมูล')
            }
            exportTableToExcel(data, mapVendorTable, 'ข้อมูลผู้ค้า.xlsx')

            modals.closeAll()
        } catch (error) {
            modals.closeAll()
            notifications.show({
                title: 'เกิดข้อผิดพลาด',
                message: 'ไม่พบข้อมูล',
                color: 'red',
                icon: <IconExclamationCircle size='1rem' />,
            })
        }
        setIsLoading(false)
    }

    return (
        <>
            <Text size='sm' c='gray.7'>
                กดยืนยันเพื่อดาวน์โหลดข้อมูล
            </Text>
            <Paper mt='md'>
                <LoadingOverlay visible={isLoading} overlayBlur={2} />
                <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                    <Flex gap='sm' direction='column' mih={150}>
                        <InputBudgetYear
                            maxDropdownHeight={80}
                            dropdownPosition='bottom'
                            {...form.getInputProps('budget_year')}
                        />
                        <Flex mt='auto' justify='end' gap='sm'>
                            <Button
                                onClick={() => modals.closeAll()}
                                variant='default'
                                w='fit-content'
                            >
                                ยกเลิก
                            </Button>
                            <Button type='submit' w='fit-content'>
                                ดาวน์โหลดข้อมูล
                            </Button>
                        </Flex>
                    </Flex>
                </form>
            </Paper>
        </>
    )
}

const schema = Joi.object().keys({
    limit: Joi.number().required().max(100),
    orderby: Joi.any().required(),
    order: Joi.string().required().valid('asc', 'desc'),
    page: Joi.number().required().min(1),
    search: Joi.string().required().allow(''),
    status: Joi.number().valid(0, 1, 2).allow(null, ''),
    has_enabled: Joi.number().valid(0, 1),
    checkowner: Joi.number().required().valid(1, 0),
    budget_year: Joi.number()
        .min(2000)
        .max(2300)
        .required()
        .messages({
            'number.base': 'รูปแบบข้อมูลไม่ถูกต้อง',
            'number.max': 'รูปแบบข้อมูลไม่ถูกต้อง',
            'number.min': 'รูปแบบข้อมูลไม่ถูกต้อง',
            'any.required': `กรุณากรอกปีงบประมาณ`,
            'any.only': `กรุณากรอกปีงบประมาณให้ถูกต้อง`,
        })
        .allow(0, null, ''),
})

import React from 'react'

import { Card, Flex, Title } from '@mantine/core'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'

import { IResponseGetUserPositionByID } from '@interfaces/api/IUserPosition'

const CardUserPositionDetail = ({
    data,
}: {
    data: IResponseGetUserPositionByID
}) => {
    return (
        <Card key={data.user_position_id} shadow='md' radius='md' padding='md'>
            <Flex gap='5px' direction='column'>
                <Title order={5}>{data.user_position_name}</Title>

                <TextCreatedBy {...data} />
                <TextUpdatedBy {...data} />
            </Flex>
        </Card>
    )
}

export default CardUserPositionDetail

import { IResponseGetAllChairmansRowData } from '@interfaces/api/IChairman'

const mapChairmanTable = (data: IResponseGetAllChairmansRowData[]) => {
    const mapData = data.map((value) => {
        return {
            ไอดีประธานโครงการ: value.chairman_id,
            ชื่อประธานโครงการ: value.chairman_fullname,
            อีเมล: value.chairman_email,
            เบอร์โทร: value.chairman_phone,
            ไอดีสถานะข้อมูล: value.status,
            ชื่อสถานะข้อมูล: value.status_description,
            สร้างเมื่อ: value.created_at,
            สร้างโดยผู้ใช้งานไอดี: value.created_by_user_id,
            สร้างโดยผู้ใช้งานชื่อ: value.created_by_user_fullname,
            แก้ไขเมื่อ: value.updated_at,
            แก้ไขโดยผู้ใช้งานไอดี: value.updated_by_user_id,
            แก้ไขโดยผู้ใช้งานชื่อ: value.updated_by_user_fullname,
        }
    })

    return mapData
}

export default mapChairmanTable

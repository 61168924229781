import PageLayout from '@components/page/PageLayout'
import {
    createStyles,
    Title,
    Text,
    Button,
    Container,
    Group,
    rem,
} from '@mantine/core'
import { useNavigate } from 'react-router-dom'

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(80),
        paddingBottom: rem(80),
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    label: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: rem(100),
        lineHeight: 1,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
        color:
            theme.colorScheme === 'dark'
                ? theme.colors.dark[4]
                : theme.colors.gray[2],

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(120),
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: 'center',
        fontWeight: 900,
        fontSize: rem(38),

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(32),
        },
    },

    description: {
        maxWidth: rem(500),
        margin: 'auto',
        marginTop: theme.spacing.xl,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
}))

const AccessDenied = ({ label = 'ย้อนกลับ' }: { label?: string }) => {
    const { classes } = useStyles()
    const navigate = useNavigate()
    const goBack = () => navigate(-1)
    return (
        <PageLayout>
            <Container className={classes.root}>
                <div className={classes.label}>Access Denied</div>
                <Title className={classes.title}>ไม่มีสิทธิเข้าถึงได้</Title>
                <Text
                    color='dimmed'
                    size='lg'
                    align='center'
                    className={classes.description}
                >
                    ขออภัย คุณไม่สามารถเข้าถึงหน้านี้ได้
                    <br />
                    เนื่องจากสิทธิการใช้งานไม่ครอบคลุมการใช้งานในส่วนนี้
                    <br />
                    หากเป็นข้อผิดพลาดกรุณาลองใหม่อีกครั้งภายหลัง
                    หรือติดต่อเจ้าหน้าที่
                </Text>
                <Group position='center'>
                    <Button onClick={goBack} variant='subtle' size='md'>
                        {label}
                    </Button>
                </Group>
            </Container>
        </PageLayout>
    )
}
export default AccessDenied

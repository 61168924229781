import { useForm } from '@mantine/form'
import { Button, Flex, Group, LoadingOverlay, Paper, Text } from '@mantine/core'
import Project from '@api/Project'
import LoadingPage from '@components/loading/LoadingPage'
import {
    IRequestAddAndUpdateProjectLecturer,
    IResponseAllProjectLecturersRowData,
} from '@interfaces/api/IProject'
import InputLecturer from '../input/InputLecturer'
import { useEffect, useState } from 'react'
import TableEditButton from '@components/button/TableEditButton'
import TableDeleteButton from '@components/button/TableDeleteButton'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'
import TableAddNewButton from '@components/button/TableAddNewButton'
import { modals } from '@mantine/modals'
import CancelButton from '@components/button/CancleButton'

const FormManageProjectLecturer = ({
    project_id,
    refetchProjectInfo,
    editMode,
}: {
    project_id: number | string
    refetchProjectInfo: () => void
    editMode: boolean
}) => {
    const [createMode, setCreateMode] = useState(false)
    const [currentEditList, setCurrentEditList] = useState<number | null>(null)

    useEffect(() => {
        if (editMode === false) {
            setCurrentEditList(null)
        }
        // eslint-disable-next-line
    }, [editMode, createMode, currentEditList])

    let { isLoading, data, refetch } = Project.useGetAllProjectLecturerByID({
        project_id: project_id,
    })

    if (isLoading) {
        return <LoadingPage />
    }

    const refetchProjectLecturer = async () => {
        refetchProjectInfo()
        setCurrentEditList(null)
        await refetch()
    }

    return (
        <Flex gap='md' direction='column'>
            {data!.map((lecturer) => {
                return (
                    <ProjectLecturerViewAndEditComponent
                        key={lecturer.project_lecturer_id}
                        editMode={editMode}
                        data={lecturer}
                        refetchProjectLecturer={refetchProjectLecturer}
                        currentEditList={currentEditList}
                        setCurrentEditList={setCurrentEditList}
                    />
                )
            })}
            {!data?.length && !editMode && (
                <Paper p='lg'>
                    <Group position='center'>
                        ไม่พบรายชื่อวิทยากรของโครงการ
                    </Group>
                </Paper>
            )}
            <ProjectLecturerCreateComponent
                key={project_id}
                project_id={project_id}
                editMode={editMode}
                createMode={createMode}
                setCreateMode={setCreateMode}
                refetchProjectLecturer={refetchProjectLecturer}
            />
            {editMode && (
                <TableAddNewButton
                    label='เพิ่มวิทยากรโครงการ'
                    onClick={() => setCreateMode(!createMode)}
                />
            )}
        </Flex>
    )
}

export default FormManageProjectLecturer

interface IProjectLecturerViewAndEditComponentProps {
    data: IResponseAllProjectLecturersRowData
    editMode: boolean
    refetchProjectLecturer: () => void
    currentEditList: null | number
    setCurrentEditList: React.Dispatch<React.SetStateAction<number | null>>
}

const ProjectLecturerViewAndEditComponent = ({
    data,
    editMode,
    refetchProjectLecturer,
    currentEditList,
    setCurrentEditList,
}: IProjectLecturerViewAndEditComponentProps) => {
    const form = useForm<IRequestAddAndUpdateProjectLecturer>({
        initialValues: {
            lecturer_id: data.lecturer_id,
        },
    })

    const { isLoading, onSubmit } = Project.useUpdateProjectLecturer({
        project_id: data.project_id,
        project_lecturer_id: data.project_lecturer_id,
        form,
        aftersuccess: refetchProjectLecturer,
    })

    const reqDeleteProjectLecturer = Project.useDeleteProjectLecturer({
        project_id: data.project_id,
        project_lecturer_id: data.project_lecturer_id,
        aftersuccess: refetchProjectLecturer,
    })

    const openDeleteConfirmModal = () =>
        modals.openConfirmModal({
            title: 'กรุณายืนยันการลบ',
            children: (
                <Text>
                    ลบวิทยากรออกจากโครงการ <br />
                    {data.lecturer_fullname}{' '}
                </Text>
            ),
            labels: { confirm: 'ยืนยัน', cancel: 'ยกเลิก' },
            onConfirm: reqDeleteProjectLecturer.onSubmit,
        })

    const [editList, setEditList] = useState(false)

    useEffect(() => {
        if (currentEditList !== data.project_lecturer_id) {
            setEditList(false)
        }
        // eslint-disable-next-line
    }, [currentEditList])

    const editListOnClick = () => {
        setCurrentEditList(data.project_lecturer_id)
        setEditList(true)
    }
    return (
        <Paper w='100%' p='md' shadow='md' pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form
                onSubmit={onSubmit}
                style={{ pointerEvents: editMode ? 'all' : 'none' }}
            >
                <Flex gap='sm' direction='column'>
                    <InputLecturer
                        editMode={editList}
                        label={editList ? 'วิทยากรโครงการ' : ''}
                        {...form.getInputProps('lecturer_id')}
                    >
                        {editMode && (
                            <Group
                                h='100%'
                                pos='absolute'
                                top='0%'
                                right='0%'
                                px='md'
                                spacing='xs'
                            >
                                <TableEditButton onClick={editListOnClick} />
                                <TableDeleteButton
                                    onClick={openDeleteConfirmModal}
                                />
                            </Group>
                        )}
                    </InputLecturer>
                    {editList && (
                        <Flex ml='auto' gap='md'>
                            <CancelButton onClick={() => setEditList(false)} />
                            <Button type='submit' w='fit-content'>
                                บันทันการแก้ไข
                            </Button>
                        </Flex>
                    )}
                    {editMode === false && (
                        <Flex justify='end' gap='md'>
                            <TextCreatedBy {...data} />
                            <TextUpdatedBy {...data} />
                        </Flex>
                    )}
                </Flex>
            </form>
        </Paper>
    )
}

interface IProjectLecturerCreateComponent {
    editMode: boolean
    createMode: boolean
    project_id: string | number
    setCreateMode: React.Dispatch<React.SetStateAction<boolean>>
    refetchProjectLecturer: () => Promise<void>
}
const ProjectLecturerCreateComponent = ({
    editMode,
    createMode,
    project_id,
    setCreateMode,
    refetchProjectLecturer,
}: IProjectLecturerCreateComponent) => {
    const form = useForm<IRequestAddAndUpdateProjectLecturer>()
    const onSubmitCreate = async () => {
        await refetchProjectLecturer()
        setCreateMode(false)
        form.reset()
    }

    const { isLoading, onSubmit } = Project.useAddProjectLecturer({
        project_id: project_id,
        lecturer_id: form.getInputProps('lecturer_id').value,
        form,
        aftersuccess: onSubmitCreate,
    })

    return (
        <Paper
            display={createMode && editMode ? 'initial' : 'none'}
            w='100%'
            p='md'
            shadow='md'
            pos='relative'
        >
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form
                onSubmit={onSubmit}
                style={{ pointerEvents: editMode ? 'all' : 'none' }}
            >
                <Flex gap='sm' direction='column'>
                    <InputLecturer
                        withAsterisk
                        {...form.getInputProps('lecturer_id')}
                    />
                    <Flex ml='auto' gap='md'>
                        <CancelButton onClick={() => setCreateMode(false)} />
                        <Button type='submit' w='fit-content'>
                            เพิ่มวิทยากรโครงการ
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    )
}

import FormCreateVendorType from '@components/form/vendor/FormCreateVendorType'
import PageLayout from '@components/page/PageLayout'

const CreateVendorType = () => {
    return (
        <PageLayout pageTitle='สร้างประเภทผู้ค้า'>
            <FormCreateVendorType />
        </PageLayout>
    )
}

export default CreateVendorType

import { Select, SelectItem } from '@mantine/core'
import { useEffect, useState } from 'react'
import { SelectProps } from '@mantine/core/lib/Select/Select'
import Agency from '@api/Agency'
import { IResponseAllAgencyTypesRowData } from '@interfaces/api/IAgency'
interface IInputAgency extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
}

interface ItemProps extends SelectItem, IResponseAllAgencyTypesRowData {}

const InputAgencyType = ({ classNames, value, styles, unstyled, className, defaultValue, ...others }: IInputAgency) => {
    const { data } = Agency.useGetAllAgencyTypes({ limit: 1000 })
    const [list, setlist] = useState<ItemProps[]>([])
    useEffect(() => {
        if (data) {
            setlist(
                data.lists.map((e) => {
                    return {
                        ...e,
                        value: String(e.agency_type_id),
                        label: e.agency_type_name,
                    }
                })
            )
        }
    }, [data])

    return (
        <Select
            key={list.length}
            className={className}
            label='ประเภทหน่วยงาน'
            value={String(value)}
            placeholder='ประเภทหน่วยงาน'
            searchable
            clearable
            data={list}
            filter={(value, item: ItemProps) =>
                item.agency_type_name.toLowerCase().includes(value.toLowerCase().trim())
            }
            {...others}
        />
    )
}

export default InputAgencyType

import { Group, Paper, Select, SelectItem, Text } from '@mantine/core'
import { forwardRef, useEffect, useState } from 'react'
import { SelectProps } from '@mantine/core/lib/Select/Select'
import Agency from '@api/Agency'
import { IResponseAllAgenciesRowData } from '@interfaces/api/IAgency'
interface IInputAgency
    extends Omit<SelectProps & React.RefAttributes<HTMLInputElement>, 'data'> {
    data?: readonly (string | SelectItem)[]
    editMode?: boolean
}

interface ItemProps extends SelectItem, IResponseAllAgenciesRowData {}

const InputAgency = ({
    editMode = true,
    classNames,
    value,
    styles,
    unstyled,
    className,
    defaultValue,
    ...others
}: IInputAgency) => {
    const { data } = Agency.useGetAllAgencies({ limit: 1000 })
    const [list, setlist] = useState<ItemProps[]>([])
    useEffect(() => {
        if (data) {
            setlist(
                data.lists.map((e) => {
                    return {
                        ...e,
                        value: String(e.agency_id),
                        label: e.agency_name,
                    }
                })
            )
        }
    }, [data])

    if (editMode === false) {
        const valueinput = list.filter((e) => e.value === String(value))
        if (valueinput.length) {
            return (
                <div>
                    <Text size='sm'>หน่วยงาน/ลูกค้า</Text>
                    <Paper p='sm' withBorder mt='2px'>
                        <SelectItemComponent {...valueinput[0]} />
                    </Paper>
                </div>
            )
        }
    }

    return (
        <Select
            key={list.length}
            className={className}
            itemComponent={SelectItemComponent}
            label='หน่วยงาน/ลูกค้า'
            value={String(value)}
            placeholder='หน่วยงาน/ลูกค้า'
            searchable
            clearable
            data={list}
            filter={(value, item: ItemProps) =>
                item.agency_name
                    .toLowerCase()
                    .includes(value.toLowerCase().trim())
            }
            {...others}
        />
    )
}

export default InputAgency

const SelectItemComponent = forwardRef<HTMLDivElement, ItemProps>(
    (
        {
            agency_name,
            agency_email,
            agency_type_name,
            agency_phone,
            label,
            ...others
        }: ItemProps,
        ref
    ) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <div>
                    <Text size='sm'>{agency_name}</Text>
                    <Text size='xs' opacity={0.65}>
                        ประเภทหน่วยงาน : {agency_type_name}
                    </Text>
                    <Text size='xs' opacity={0.65}>
                        {agency_phone}
                    </Text>
                    <Text size='xs' opacity={0.65}>
                        {agency_email}
                    </Text>
                </div>
            </Group>
        </div>
    )
)

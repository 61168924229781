import { useState } from 'react'
import { AxiosError, AxiosResponse, isAxiosError } from 'axios'
import { UseFormReturnType } from '@mantine/form'
import api from '@config/api'
import { notifications } from '@mantine/notifications'
import { IconCheck, IconExclamationCircle } from '@tabler/icons-react'
import { IRequest, IValidationError } from '@interfaces/api/IAxiosRequest'

export function useFormHandler<T, K>(
    apiMethod: 'post' | 'put',
    apiUrl: string,
    form: UseFormReturnType<T, (values: T) => T>,
    aftersuccess?: (aftersuccess: K) => void
) {
    const [loading, setLoading] = useState(false)

    const onSubmit = async () => {
        setLoading(true)
        try {
            const res = (await api[apiMethod](
                apiUrl,
                form.values!
            )) as AxiosResponse<IRequest<K>>
            notifications.show({
                title: res.data.message,
                message: '',
                color: 'teal',
                icon: <IconCheck size='1rem' />,
            })
            if (typeof aftersuccess === 'function') {
                aftersuccess(res.data.body)
            }
        } catch (err: any) {
            if (isAxiosError(err)) {
                const error: { [key: string]: string } = {}
                const axiosError = err as AxiosError<IValidationError>
                notifications.show({
                    title: 'เกิดข้อผิดพลาด',
                    autoClose: 5000,
                    message: err.response?.data.message,
                    color: 'red',
                    icon: <IconExclamationCircle size='1rem' />,
                })
                if (axiosError.response?.data.body.length) {
                    axiosError.response?.data.body.forEach(
                        ({ errormsg, errorpath }) => {
                            error[errorpath] = errormsg
                        }
                    )
                    form.setErrors(error)
                }
            }
        }
        setLoading(false)
    }

    return { loading, onSubmit }
}

import { Text, TextProps } from '@mantine/core'
import React from 'react'

const TextDashboardSectionTitle = (prop: TextProps) => {
    return (
        <Text color='gray' size='xl' mb='lg' fw='bold' {...prop}>
            {prop.children}
        </Text>
    )
}

export default TextDashboardSectionTitle

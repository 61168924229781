import { useForm } from '@mantine/form';
import { Button, Flex, LoadingOverlay, Paper, TextInput } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';
import {
    IRequestUpdateVendorType,
    IResponseGetVendorTypeByID,
} from '@interfaces/api/IVendor';
import InputStatus from '../input/InputStatus';
import Vendor from '@api/Vendor';

const FormEditVendorType = ({
    data,
    aftersuccess,
}: {
    data: IResponseGetVendorTypeByID;
    aftersuccess?: () => any;
}) => {
    const form = useForm<IRequestUpdateVendorType>({
        initialValues: {
            vendor_type_name: data.vendor_type_name,
            status: data.status,
        },
    });
    const navigate = useNavigate();

    const { isLoading, onSubmit } = Vendor.useUpdateVendorType({
        vendor_type_id: data.vendor_type_id,
        form,
        aftersuccess: () => navigate('/vendors/types'),
    });

    return (
        <Paper pos='relative'>
            <LoadingOverlay visible={isLoading} overlayBlur={2} />
            <form onSubmit={onSubmit}>
                <Flex gap='sm' direction='column'>
                    <TextInput
                        withAsterisk
                        label='ประเภทผู้ค้า'
                        placeholder='ประเภทผู้ค้า'
                        {...form.getInputProps('vendor_type_name')}
                    />
                    <InputStatus {...form.getInputProps('status')} />

                    <Flex mt='md' justify='end' gap='sm'>
                        <Button
                            component={Link}
                            to='/vendors/types'
                            variant='default'
                            w='fit-content'
                        >
                            ยกเลิก
                        </Button>
                        <Button type='submit' w='fit-content'>
                            บันทึกการแก้ไขประเภทผู้ค้า
                        </Button>
                    </Flex>
                </Flex>
            </form>
        </Paper>
    );
};

export default FormEditVendorType;

import { IResponseAllProjectsRowData } from '@interfaces/api/IProject'

const mapProjectTable = (data: IResponseAllProjectsRowData[]) => {
    const mapData = data.map((value) => {
        return {
            ไอดีโครงการ: value.project_id,
            รหัสโครงการ: value.project_code,
            ลำดับโครงการ: value.project_no,
            ชื่อโครงการ: value.project_name,
            งบประมาณโครงการ: value.project_budget,
            ประเภทกิจกรรมบริการวิชาการ: value.project_type_name,
            ความคืบหน้าโครงการ: value.project_progress,
            สีสถานะ: value.project_progress_status_color,
            ชื่อสถานะ: value.project_progress_status_name,
            ผลประเมินความพึงพอใจโครงการ: value.project_evaluation_result,
            จำนวนวิทยากร: value.total_project_lecturer,
            จำนวนผู้ค้า: value.total_project_vendor,
            สร้างเมื่อ: value.created_at,
            สร้างโดยผู้ใช้งานไอดี: value.created_by_user_id,
            สร้างโดยผู้ใช้งานชื่อ: value.created_by_user_fullname,
            แก้ไขเมื่อ: value.updated_at,
            แก้ไขโดยผู้ใช้งานไอดี: value.updated_by_user_id,
            แก้ไขโดยผู้ใช้งานชื่อ: value.updated_by_user_fullname,
        }
    })

    return mapData
}

export default mapProjectTable

import Vendor from '@api/Vendor';
import CardVendorTypeDetail from '@components/card/CardVendorTypeDetail';
import LoadingPage from '@components/loading/LoadingPage';
import PageLayout from '@components/page/PageLayout';
import PageNotFound404 from '@pages/PageNotFound404';
import { useParams } from 'react-router-dom';

const ViewVendorType = () => {
    const params = useParams();
    const vendor_type_id = params.vendor_type_id;
    const { isLoading, isFetching, isError, data } =
        Vendor.useGetVendorTypeByID({
            vendor_type_id: vendor_type_id!,
        });
    if (isLoading || isFetching) {
        return <LoadingPage />;
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าประเภทผู้ค้าทั้งหมด'
                url='/vendors/types'
            />
        );
    }
    return (
        <PageLayout pageTitle='ข้อมูลประเภทผู้ค้า'>
            <CardVendorTypeDetail data={data} />
        </PageLayout>
    );
};

export default ViewVendorType;

export const h_status = {
    sortkey: 'status',
    label: 'สถานะ',
    width: '16%',
}

export const h_action = {
    label: 'Action',
    width: '10%',
}

export const default_header = [h_status, h_action]

import {
    Button,
    Flex,
    Paper,
    PasswordInput,
    Title,
    LoadingOverlay,
    Text,
} from '@mantine/core'
import { useForm } from '@mantine/form'

import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { IRequestSavePasswordAfterRequestForgotPassword } from '@interfaces/IAuth'
import Auth from '@api/Auth'

const ForgotPassword = () => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const form = useForm<IRequestSavePasswordAfterRequestForgotPassword>({
        initialValues: {
            password: '',
            confirmpassword: '',
        },
    })

    const { onSubmit, isLoading } =
        Auth.useRequestSavePasswordAfterRequestForgotPassword({
            form,
            withToken: `Bearer ${searchParams.getAll('token')[0]}`,
            aftersuccess: () => {
                navigate('/login')
            },
        })
    return (
        <Flex
            justify='center'
            direction='column'
            align='center'
            w='100%'
            mih='100vh'
            bg='indigo.9'
        >
            <Paper
                maw={400}
                shadow='xl'
                w='100%'
                mx='auto'
                p='xl'
                m='xl'
                my='auto'
                bg='white'
                radius='lg'
                pos='relative'
                style={{ overflow: 'hidden' }}
            >
                <LoadingOverlay visible={isLoading} overlayBlur={2} />
                <form onSubmit={onSubmit}>
                    <Title order={3} ta='center' c='text.2'>
                        กู้รหัสผ่าน
                    </Title>
                    <Text size='xs' ta='center' mb='lg' mt='sm' c='text.2'>
                        กรุณาตั้งรหัสผ่านใหม่สำหรับใช้เข้าสู่ระบบ
                    </Text>

                    <PasswordInput
                        withAsterisk
                        mt='md'
                        label='รหัสผ่าน'
                        placeholder='รหัสผ่าน!'
                        {...form.getInputProps('password')}
                    />
                    <PasswordInput
                        withAsterisk
                        mt='md'
                        label='ยืนยันรหัสผ่าน'
                        placeholder='รหัสผ่าน!'
                        {...form.getInputProps('confirmpassword')}
                    />

                    <Button
                        radius='xl'
                        color='fuchasia.4'
                        type='submit'
                        fullWidth
                        fz='sm'
                        size='md'
                        mt='md'
                    >
                        ยืนยันเปลี่ยนรหัสผ่าน
                    </Button>
                    <Button
                        radius='xl'
                        c='dark'
                        color='gray.1'
                        type='button'
                        fullWidth
                        fz='sm'
                        size='md'
                        mt='md'
                        to='/login'
                        component={Link}
                    >
                        กลับหน้าเข้าสู่ระบบ
                    </Button>
                </form>
            </Paper>
            <Text c='white' size='xs' p='lg'>
                THE CENTER FOR ACADEMIC SERVICES, SRINAKHARINWIROT UNIVERSITY.
            </Text>
        </Flex>
    )
}

export default ForgotPassword

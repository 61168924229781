import React from 'react'
import { Card, Flex, Title } from '@mantine/core'
import TextParameter from '../text/TextParameter'
import TextCreatedBy from '@components/text/TextCreatedBy'
import TextUpdatedBy from '@components/text/TextUpdatedBy'
import { IResponseGetCoordinatorByID } from '@interfaces/api/ICoordinator'

const CardCoordinatorDetail = ({
    data,
}: {
    data: IResponseGetCoordinatorByID
}) => {
    const textparameters = [
        {
            title: 'ชื่อผู้ประสานงาน',
            info: data.coordinator_fullname,
        },
        {
            title: 'อีเมล',
            info: data.coordinator_email,
        },
        {
            title: 'เบอรโทร',
            info: data.coordinator_phone,
        },
        {
            title: 'สถานะข้อมูล',
            info: data.status_description,
        },
    ]
    return (
        <Card
            key={data.coordinator_id}
            shadow='md'
            radius='md'
            // className={classes.card}
            padding='md'
        >
            <Flex gap='5px' direction='column'>
                <Title order={5}>{data.coordinator_fullname}</Title>
                {textparameters.map((value) => (
                    <TextParameter
                        key={value.title}
                        title={value.title}
                        info={value.info!}
                    />
                ))}
                <TextCreatedBy {...data} />
                <TextUpdatedBy {...data} />
            </Flex>
        </Card>
    )
}

export default CardCoordinatorDetail

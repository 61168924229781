import { Text, TextProps } from '@mantine/core'
import React from 'react'

const TextChartTitle = (prop: TextProps) => {
    return (
        <Text size='md' fw='bold' {...prop}>
            {prop.children}
        </Text>
    )
}

export default TextChartTitle

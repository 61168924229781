import { Badge, BadgeProps, Text } from '@mantine/core'

interface IProgressBadge extends BadgeProps {
    progress_color: string
    progress_score?: number
    progress_label?: string
}

const ProgressBadge = ({
    progress_color,
    progress_score,
    progress_label,
    classNames,
    styles,
    unstyled,
    className,
    ...others
}: IProgressBadge) => {
    return (
        <Badge
            className={className}
            {...others}
            style={{ backgroundColor: progress_color }}
        >
            <Text
                color={
                    isColorDarkOrLight(progress_color) === 'dark'
                        ? 'white'
                        : 'dark'
                }
                fw='bold'
            >
                {progress_score ? progress_score + '% ' : ''}
                {progress_label}
            </Text>
        </Badge>
    )
}

function isColorDarkOrLight(color: string) {
    // Remove any leading #
    color = color.replace('#', '')

    // Convert to RGB
    const r = parseInt(color.substr(0, 2), 16)
    const g = parseInt(color.substr(2, 2), 16)
    const b = parseInt(color.substr(4, 2), 16)

    // Calculate luminance
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

    // Return 'dark' for low luminance, 'light' for higher luminance
    return luminance <= 0.5 ? 'dark' : 'light'
}

export default ProgressBadge

import Chairman from '@api/Chairman'
import CardChairmanDetail from '@components/card/CardChairmanDetail'
import LoadingPage from '@components/loading/LoadingPage'
import PageLayout from '@components/page/PageLayout'
import PageNotFound404 from '@pages/PageNotFound404'
import { useParams } from 'react-router-dom'

const ViewChairman = () => {
    const params = useParams()
    const chairman_id = params.chairman_id
    const { isLoading, isFetching, isError, data } =
        Chairman.useGetChairmanByID({
            chairman_id: chairman_id!,
        })
    if (isLoading || isFetching) {
        return <LoadingPage />
    }
    if (isError || data === undefined) {
        return (
            <PageNotFound404
                label='กลับไปหน้าประธานโครงการทั้งหมด'
                url='/chairmans'
            />
        )
    }
    return (
        <PageLayout pageTitle='ข้อมูลประธานโครงการ'>
            <CardChairmanDetail data={data}></CardChairmanDetail>
        </PageLayout>
    )
}

export default ViewChairman

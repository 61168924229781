import { Badge, Flex } from '@mantine/core'
import React from 'react'

const GroupTagBadge = ({ labels }: { labels: string | null | undefined }) => {
    if (!labels) {
        return <></>
    }
    let label_arr = labels.split('&')
    return (
        <Flex gap='xs' wrap='wrap'>
            {label_arr.map((label) => (
                <Badge key={label} color='blue'>
                    {label}
                </Badge>
            ))}
        </Flex>
    )
}

export default GroupTagBadge
